import {
    ButtonLinkCancel,
    ButtonValidate,
    FieldBlock,
    Form,
    FormLayoutColumns,
    FormLayoutRows,
    FormValidationType,
    InputNumber,
    InputText,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    Slider,
    Textarea
} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {CategoryBQRequest, MAX_VALUE_POINTS, MIN_VALUE_POINTS, STEP_VALUE_POINTS} from "../../../api/model/audience/category/CategoryBQRequest";
import {CategoryBQRequestField} from "../../../api/model/audience/category/CategoryBQRequestField";
import {TranslationPortalFile} from "../../../utils/constants";
import {SelectStatus} from "../../snippet";
import {Status} from "../../../utils/Status";

type ModalEditCategoryBQRequestProps = {
    initRequest: CategoryBQRequest | undefined;
    onSubmit: (request: CategoryBQRequest) => void;
    onClose: () => void;
};

const ModalEditCategoryBQRequest: FunctionComponent<ModalEditCategoryBQRequestProps> = ({initRequest, onSubmit, onClose}) => {
    const {t: textCategories} = useTranslation(TranslationPortalFile.CATEGORIES);
    const [request, setRequest] = useState<CategoryBQRequest>(new CategoryBQRequest());
    const FORM_ID = "form-edit-category-bq-request";

    useEffect(() => {
        if (initRequest) {
            setRequest(new CategoryBQRequest(initRequest));
        }
    }, [initRequest]);

    const handleChange = (field: CategoryBQRequestField, value: any) => {
        setRequest((prevState) => new CategoryBQRequest({...prevState, [field]: value}));
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmit(request);
    };

    return (
        <ModalNew onClose={onClose} active={!!initRequest}>
            <ModalHeader>
                <ModalHeaderTitle title={textCategories("modal.edit_bq_request")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FormLayoutColumns columns={3}>
                            <FieldBlock label={textCategories("field.bq_request.status")}>
                                <SelectStatus
                                    value={request.active ? Status.ACTIVE.name : Status.INACTIVE.name}
                                    statuses={Status.getActiveStatuses()}
                                    onChange={(status) => handleChange(CategoryBQRequestField.ACTIVE, status === Status.ACTIVE)}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FieldBlock label={textCategories(`field.bq_request.${CategoryBQRequestField.NAME}`)} required>
                            <InputText
                                value={request.name}
                                onChange={(value) => handleChange(CategoryBQRequestField.NAME, value)}
                            />
                        </FieldBlock>
                        <FormLayoutColumns columns={3}>
                            <FieldBlock label={textCategories(`field.bq_request.${CategoryBQRequestField.POINTS}`)}>
                                <Slider
                                    value={request.points}
                                    onChange={(value) => handleChange(CategoryBQRequestField.POINTS, value)}
                                    minValue={MIN_VALUE_POINTS}
                                    maxValue={MAX_VALUE_POINTS}
                                    step={STEP_VALUE_POINTS}
                                />
                            </FieldBlock>
                            <FieldBlock name={CategoryBQRequestField.EXPIRATION_DAY} label={textCategories(`field.bq_request.${CategoryBQRequestField.EXPIRATION_DAY}`)} required>
                                <InputNumber
                                    value={request.expiration_day}
                                    minValue={0}
                                    onChange={(value) => handleChange(CategoryBQRequestField.EXPIRATION_DAY, value)}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FieldBlock name={CategoryBQRequestField.REQUEST} label={textCategories(`field.bq_request.${CategoryBQRequestField.REQUEST}`)} required>
                            <Textarea
                                value={request.request}
                                rows={10}
                                onChange={(value) => handleChange(CategoryBQRequestField.REQUEST, value)}
                            />
                        </FieldBlock>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalEditCategoryBQRequest;
