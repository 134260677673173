import {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Action, ContentBlock, ContentBlockAction, SearchError, SearchToolbar, Table} from "@sirdata/ui-lib";
import {PAGE_SIZE, TranslationPortalFile} from "../../../utils/constants";
import {RestrictedContent} from "../../../common/component/widget";
import {Authorization} from "../../../api/model/account/Authorization";
import {session} from "../../../api/ApiSession";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import useAlert from "../../../utils/hooks/useAlert";
import {sortItems} from "../../../common/utils/helper";
import {CmpSirdataListV2VendorsEditableRow} from "../../../component/snippet";
import {GlobalVendorList} from "../../../api/model/cmp/list/global-vendor-list/GlobalVendorList";
import {SirdataListLanguage} from "../../../api/model/cmp/list/sirdata-list-v2/SirdataListLanguage";
import {SirdataListVendor} from "../../../api/model/cmp/list/sirdata-list-v2/SirdataListVendor";
import {SirdataListVendorField} from "../../../api/model/cmp/list/sirdata-list-v2/SirdataListVendorField";
import {SirdataListVersionField} from "../../../api/model/cmp/list/sirdata-list-v2/SirdataListVersionField";
import ModalCreateCmpSirdataListV2Vendor from "../../modal/cmp-sirdata-list-v2/ModalCreateCmpSirdataListV2Vendor";
import ModalEditCmpSirdataListV2Vendor from "../../modal/cmp-sirdata-list-v2/ModalEditCmpSirdataListV2Vendor";
import ModalConfirmDelete from "../../modal/ModalConfirmDelete";
import ModalConfirmMessage from "../../modal/ModalConfirmMessage";
import {SirdataListVersion} from "../../../api/model/cmp/list/sirdata-list-v2/SirdataListVersion";
import {Formatter} from "../../../common/utils/Formatter";

type CmpSirdataListV2VendorsEditableProps = {
    sirdataList: SirdataListVersion;
    globalVendorList: GlobalVendorList;
    listLanguage: SirdataListLanguage;
    listVendors: SirdataListVendor[];
    onChange: () => void;
};

const CmpSirdataListV2VendorsEditable: FC<CmpSirdataListV2VendorsEditableProps> = ({sirdataList, globalVendorList, listLanguage, listVendors, onChange}) => {
    const alert = useAlert();
    const {t: textCmpSirdataList} = useTranslation(TranslationPortalFile.CMP_SIRDATA_LIST_V2);
    const [query, setQuery] = useState("");
    const [filteredVendors, setFilteredVendors] = useState<SirdataListVendor[]>([]);
    const [isShowModalCreateCmpSirdataListV2Vendor, setShowModalCreateCmpSirdataListV2Vendor] = useState(false);
    const [activeEditVendor, setActiveEditVendor] = useState<SirdataListVendor>();
    const [activeDeleteVendor, setActiveDeleteVendor] = useState<SirdataListVendor>();
    const [activeRestoreVendor, setActiveRestoreVendor] = useState<SirdataListVendor>();

    useEffect(() => {
        const sortedVendors = sortItems(listVendors, SirdataListVendorField.ID, true);
        const filteredVendors = sortedVendors.filter((vendor) => vendor.name.toLowerCase().includes(query.toLowerCase()));
        setFilteredVendors(filteredVendors);
    }, [query, listVendors]);

    const handleDeleteVendor = async () => {
        if (!activeDeleteVendor) {
            return;
        }
        try {
            const versionVendor = sirdataList.vendors.find((it) => it.id === activeDeleteVendor.id);
            if (versionVendor) {
                activeDeleteVendor.deletedDate = versionVendor.deletedDate || Formatter.convertDateToUTC(new Date());
                await session.restCmpSirdataListVendor.update(activeDeleteVendor);
            } else {
                await session.restCmpSirdataListVendor.delete(activeDeleteVendor.id);
            }
            alert.deleteWithSuccess("vendor");
            onChange();
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToDelete("vendor", e.message);
            }
        } finally {
            setActiveDeleteVendor(undefined);
        }
    };

    const handleRestoreVendor = async () => {
        if (!activeRestoreVendor) {
            return;
        }
        try {
            const newVendor = new SirdataListVendor({
                ...activeRestoreVendor,
                [SirdataListVendorField.DELETED_DATE]: undefined
            });
            await session.restCmpSirdataListVendor.update(newVendor);
            alert.actionWithSuccess("vendor restored");
            onChange();
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failTo("restore vendor", e.message);
            }
        } finally {
            setActiveRestoreVendor(undefined);
        }
    };

    return (
        <ContentBlock
            header={{
                title: {label: textCmpSirdataList(`field.${SirdataListVersionField.VENDORS}`)},
                actions: (
                    <RestrictedContent allowedTo={Authorization.CMP_SIRDATA_LIST.update}>
                        <ContentBlockAction action={Action.ADD} onClick={() => setShowModalCreateCmpSirdataListV2Vendor(true)}/>
                    </RestrictedContent>
                )
            }}
        >
            <SearchToolbar
                searchBar={{
                    value: query,
                    placeholder: textCmpSirdataList(`search.placeholder.${SirdataListVersionField.VENDORS}`),
                    onSubmit: setQuery
                }}
            />
            {!!filteredVendors.length ?
                <Table
                    columns={[
                        {width: 3},
                        {width: 5, label: textCmpSirdataList(`field.vendor.${SirdataListVendorField.ID}`)},
                        {width: 25, label: textCmpSirdataList(`field.vendor.${SirdataListVendorField.NAME}`)},
                        {width: 60, label: textCmpSirdataList(`field.vendor.${SirdataListVendorField.POLICY_URL}`)},
                        {width: 7}
                    ]}
                    pagination={PAGE_SIZE}
                >
                    {filteredVendors.map((vendor) =>
                        <CmpSirdataListV2VendorsEditableRow
                            key={vendor.id}
                            vendor={vendor}
                            globalVendorList={globalVendorList}
                            extraPurposes={listLanguage.purposes}
                            currentVersionVendor={sirdataList.vendors.find((it) => it.id === vendor.id)}
                            onEdit={() => setActiveEditVendor(vendor)}
                            onDelete={() => setActiveDeleteVendor(vendor)}
                            onRestore={() => setActiveRestoreVendor(vendor)}
                        />
                    )}
                </Table> :
                <SearchError query={query}/>
            }
            <ModalCreateCmpSirdataListV2Vendor
                purposes={globalVendorList.purposes}
                extraPurposes={listLanguage.purposes}
                active={isShowModalCreateCmpSirdataListV2Vendor}
                onClose={async (refresh) => {
                    setShowModalCreateCmpSirdataListV2Vendor(false);
                    refresh && onChange();
                }}
            />
            <ModalEditCmpSirdataListV2Vendor
                initVendor={activeEditVendor}
                purposes={globalVendorList.purposes}
                extraPurposes={listLanguage.purposes}
                onClose={async (refresh) => {
                    setActiveEditVendor(undefined);
                    refresh && onChange();
                }}
            />
            <ModalConfirmDelete
                active={!!activeDeleteVendor}
                entity={`vendor ${activeDeleteVendor?.name}`}
                confirm={handleDeleteVendor}
                cancel={() => setActiveDeleteVendor(undefined)}
            />
            <ModalConfirmMessage
                active={!!activeRestoreVendor}
                message={textCmpSirdataList("message.confirm_vendor_restore", {vendor: activeRestoreVendor?.name})}
                confirm={handleRestoreVendor}
                confirmAction={textCmpSirdataList("action.restore")}
                cancel={() => setActiveRestoreVendor(undefined)}
            />
        </ContentBlock>
    );
};

export default CmpSirdataListV2VendorsEditable;
