import {ContentBlock, SearchError, SearchToolbar, Table} from "@sirdata/ui-lib";
import React, {FC, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Purpose} from "../../../api/model/cmp/list/global-vendor-list/Purpose";
import {SirdataVendor} from "../../../api/model/cmp/list/sirdata-list/SirdataVendor";
import {SirdataVendorField} from "../../../api/model/cmp/list/sirdata-list/SirdataVendorField";
import {TranslationPortalFile} from "../../../utils/constants";
import useSearch from "../../../utils/hooks/useSearch";
import {CmpListsSirdataVendorRow} from "../index";

type SirdataListVendorsProps = {
    vendors: SirdataVendor[];
    purposes: Purpose[];
};

const SirdataListVendors: FC<SirdataListVendorsProps> = ({vendors, purposes}) => {
    const {t: textCmpLists} = useTranslation(TranslationPortalFile.CMP_LISTS);
    const {buildSearchResult, ...search} = useSearch(SirdataVendor);

    useEffect(() => {
        let currentVendors = (vendors || []);
        if (search.searchQuery.query) {
            currentVendors = currentVendors.filter((item) => item.name.toLowerCase().includes(search.searchQuery.query.toLowerCase()));
        }
        buildSearchResult(currentVendors);
    }, [vendors, search.searchQuery, buildSearchResult]);

    return (
        <ContentBlock>
            <SearchToolbar searchBar={{placeholder: textCmpLists("sirdata.search.placeholder"), value: search.searchQuery.query, onSubmit: search.changeQuery}}/>
            {!!search.searchResult.elements.length ?
                <Table
                    columns={[
                        {width: 5},
                        {width: 5, label: textCmpLists(`sirdata.field.vendor.${SirdataVendorField.ID}`)},
                        {width: 20, label: textCmpLists(`sirdata.field.vendor.${SirdataVendorField.NAME}`)},
                        {width: 55, label: textCmpLists(`sirdata.field.vendor.${SirdataVendorField.POLICY_URL}`)},
                        {width: 15, label: textCmpLists(`sirdata.field.vendor.${SirdataVendorField.DELETED_DATE}`)}
                    ]}
                    pagination={search.searchResult.getPagination(search.changePage)}
                >
                    {search.searchResult.elements.map((vendor) =>
                        <CmpListsSirdataVendorRow key={vendor.id} vendor={vendor} purposes={purposes}/>
                    )}
                </Table> :
                <SearchError query={search.searchQuery.query}/>
            }
        </ContentBlock>
    );
};

export default SirdataListVendors;
