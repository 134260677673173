import {ButtonLinkCancel, ButtonValidate, FieldBlock, FlexContentLayout, Form, FormLayoutColumns, FormLayoutRows, FormValidationType, InputText, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Pricing} from "../../../api/model/partner/pricing/Pricing";
import {PricingField} from "../../../api/model/partner/pricing/PricingField";
import {TranslationPortalFile} from "../../../utils/constants";
import {SelectService} from "../../snippet";
import {session} from "../../../api/ApiSession";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {useHistory} from "react-router-dom";
import useAlert from "../../../utils/hooks/useAlert";
import {ApiService} from "../../../api/model/ApiService";

export type ModalCreatePricingProps = {
    onClose: () => void;
    active: boolean;
};

const ModalCreatePricing: FunctionComponent<ModalCreatePricingProps> = ({onClose, active}) => {
    const alert = useAlert();
    const history = useHistory();
    const {t: textPricings} = useTranslation(TranslationPortalFile.PRICINGS);
    const [pricing, setPricing] = useState<Pricing>(new Pricing());
    const FORM_ID = "form-create-pricing";

    useEffect(() => {
        if (active) {
            setPricing(new Pricing({[PricingField.API_SERVICE]: ApiService.CMP.name}));
        }
    }, [active]);

    const handleChange = (field: PricingField, value: any) => {
        setPricing((prevState) => new Pricing({...prevState, [field]: value}));
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        try {
            const newPricing = await session.restPricing.create(pricing);
            history.push(newPricing.getRoute());
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToCreate("rate card", e.message);
            }
        }
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textPricings("create_pricing")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FormLayoutColumns layout={FlexContentLayout.TWO_COLUMNS_WIDE_RIGHT}>
                            <FieldBlock label={textPricings(`field.${PricingField.API_SERVICE}`)} required>
                                <SelectService
                                    value={pricing.api_service}
                                    services={Pricing.apiServices()}
                                    onChange={(service) => handleChange(PricingField.API_SERVICE, service?.name || "")}
                                />
                            </FieldBlock>
                            <FieldBlock label={textPricings(`field.${PricingField.NAME}`)} required>
                                <InputText
                                    value={pricing.name}
                                    onChange={(value) => handleChange(PricingField.NAME, value)}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FieldBlock label={textPricings(`field.${PricingField.DESCRIPTION}`)}>
                            <InputText
                                value={pricing.description}
                                onChange={(value) => handleChange(PricingField.DESCRIPTION, value)}
                            />
                        </FieldBlock>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalCreatePricing;
