import React, {FunctionComponent, useEffect, useState} from "react";
import {ModalContent, ModalNew} from "@sirdata/ui-lib";
import {UIEventManager} from "../../common/utils/UIEventManager";
import {Modules} from "../snippet";

const ModalModules: FunctionComponent = () => {
    const [isActive, setActive] = useState(false);
    const [onClose, setOnClose] = useState<() => void>(() => {});

    const handleClose = () => {
        setActive(false);
        onClose();
    };

    useEffect(() => {
        UIEventManager.addListener(ModalModulesUiEvent, (onClose: () => void) => {
            setActive(true);
            setOnClose(() => onClose);
        });
        return () => UIEventManager.removeAllListeners(ModalModulesUiEvent);
    }, []);

    return (
        <ModalNew onClose={handleClose} active={isActive}>
            <ModalContent>
                <Modules/>
            </ModalContent>
        </ModalNew>
    );
};

export default ModalModules;
export const ModalModulesUiEvent = "ModalModules";
