import {
    Action,
    Button,
    ButtonLink,
    ButtonLinkCancel,
    ButtonSize,
    ButtonStyle,
    ButtonUpload,
    ButtonValidate,
    ElementList,
    ElementListSize,
    FieldBlock,
    FormLayoutColumns,
    FormLayoutRows,
    FormLayoutSeparator,
    InputText,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    Select,
    TranslationLibFile
} from "@sirdata/ui-lib";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as XLSX from "xlsx";
import {session} from "../../../api/ApiSession";
import {Distribution} from "../../../api/model/audience/distribution/Distribution";
import {DistributionSegmentsStatusUpdate} from "../../../api/model/audience/distribution/DistributionSegmentsStatusUpdate";
import {DISTRIBUTION_TYPES, DistributionType} from "../../../api/model/audience/distribution/DistributionType";
import {Segment} from "../../../api/model/audience/segment/Segment";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import {Status} from "../../../utils/Status";
import {SelectSegment, SelectStatus, TagSegmentRow} from "../../snippet";
import ModalConfirmMessage from "../ModalConfirmMessage";

type ModalManageDistributionSegmentsProps = {
    active: boolean;
    distribution: Distribution;
    onSubmit: (newDistributionSegmentsStatusUpdate: DistributionSegmentsStatusUpdate) => void;
    onClose: () => void;
};

const ModalManageDistributionSegments: FunctionComponent<ModalManageDistributionSegmentsProps> = ({active, distribution, onSubmit, onClose}) => {
    const alert = useAlert();
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textDistribution} = useTranslation(TranslationPortalFile.DISTRIBUTIONS);

    const [isShowModalConfirmSave, setShowModalConfirmSave] = useState(false);
    const [segments, setSegments] = useState<Segment[]>([]);
    const [selectedSegments, setSelectedSegments] = useState<Segment[]>([]);
    const [distributionType, setDistributionType] = useState<DistributionType>(DistributionType.SPECIFIC_SEGMENTS);
    const [status, setStatus] = useState<string>(Status.OPEN.name);
    const [segmentIdsAsString, setSegmentIdsAsString] = useState("");
    const [isLoadingImport, setLoadingImport] = useState(false);

    useEffect(() => {
        if (!active) return;
        setSelectedSegments([]);
        (async () => {
            try {
                const segments = await session.getSegments();
                const activeSegments = segments.filter((it) => it.active);
                setSegments(activeSegments);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("segments", e.message);
                }
            }
        })();
    }, [active, alert]);

    useEffect(() => {
        if (distributionType === DistributionType.ALL_LINKED_SEGMENTS) {
            setStatus(Status.CLOSE.name);
        }
    }, [distributionType]);

    const handleAddSegmentIds = () => {
        const separator = segmentIdsAsString.includes(",") ? "," : " ";
        const newSegmentsIds: number[] = segmentIdsAsString.split(separator).map((segmentId) => +segmentId.trim()).filter((segmentId) => (selectedSegments.findIndex((it) => it.id === segmentId) === -1));
        setSelectedSegments((prevState) => [...segments.filter((it) => newSegmentsIds.includes(it.id)), ...prevState]);
        setSegmentIdsAsString("");
    };

    const handleImportFile = (file?: File) => {
        setLoadingImport(true);
        try {
            if (file) {
                const reader = new FileReader();
                reader.onload = (evt) => {
                    const fileResult = evt.target?.result;
                    const workBook = XLSX.read(fileResult, {type: "binary"});
                    const workSheetName = workBook.SheetNames[0];
                    const workSheet = workBook.Sheets[workSheetName];
                    const dataString = XLSX.utils.sheet_to_csv(workSheet);

                    const dataList = dataString.split(/\r\n|\n|,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/).filter((it) => it);
                    const newSegmentsIds: number[] = dataList.map((segmentId) => +segmentId.trim()).filter((segmentId) => (selectedSegments.findIndex((it) => it.id === segmentId) === -1));
                    setSelectedSegments((prevState) => [...segments.filter((it) => newSegmentsIds.includes(it.id)), ...prevState]);
                };
                reader.readAsBinaryString(file);
            }
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failTo("import keywords", e.message);
            }
        } finally {
            setLoadingImport(false);
        }
    };

    const handleSave = () => {
        let newDistributionSegmentsStatusUpdate = new DistributionSegmentsStatusUpdate();
        newDistributionSegmentsStatusUpdate.link_external_name = distribution.name;
        newDistributionSegmentsStatusUpdate.active = status === Status.OPEN.name;
        switch (distributionType) {
            case DistributionType.ALL_PUBLIC_SEGMENTS:
                newDistributionSegmentsStatusUpdate.segments = segments.filter((it) => !it.private).map((it) => it.id);
                newDistributionSegmentsStatusUpdate.all_segment = false;
                break;
            case DistributionType.SPECIFIC_SEGMENTS:
                newDistributionSegmentsStatusUpdate.segments = selectedSegments.map((it) => it.id);
                newDistributionSegmentsStatusUpdate.all_segment = false;
                break;
            case DistributionType.ALL_LINKED_SEGMENTS:
                newDistributionSegmentsStatusUpdate.segments = [];
                newDistributionSegmentsStatusUpdate.all_segment = true;
                break;
        }
        onSubmit(newDistributionSegmentsStatusUpdate);
        setShowModalConfirmSave(false);
    };

    return (
        <ModalNew onClose={onClose} active={active} loading={false}>
            <ModalHeader>
                <ModalHeaderTitle title={textDistribution("modal.manage_segments", {distribution: distribution.label || distribution.name})}/>
            </ModalHeader>
            <ModalContent>
                <FormLayoutRows>
                    <FormLayoutColumns>
                        <FieldBlock label={textDistribution("field.distribution")}>
                            <Select
                                value={distributionType}
                                options={DISTRIBUTION_TYPES.map((it) => ({label: textDistribution(`field.distribution_type.${it}`), value: it}))}
                                onChange={(option) => setDistributionType(option?.value as DistributionType)}
                            />
                        </FieldBlock>
                        <FormLayoutColumns columns={2}>
                            <FieldBlock label={textDistribution("field.status")}>
                                <SelectStatus
                                    value={status}
                                    statuses={distributionType === DistributionType.ALL_LINKED_SEGMENTS ? [Status.CLOSE] : [Status.OPEN, Status.CLOSE]}
                                    onChange={(status) => setStatus(status!.name)}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                    </FormLayoutColumns>
                    {distributionType === DistributionType.SPECIFIC_SEGMENTS &&
                        <>
                            <FormLayoutSeparator/>
                            <FieldBlock label={textDistribution("modal.search_segment_to_manage")} required>
                                <SelectSegment
                                    value={undefined}
                                    onChange={(segment) => segment && setSelectedSegments((prevState) => ([segment, ...prevState]))}
                                    excludedSegments={selectedSegments}
                                />
                            </FieldBlock>
                            <FormLayoutColumns>
                                <FieldBlock
                                    label={textDistribution("modal.add_segment_ids_list")}
                                    tooltip={textDistribution("message.valid_ids_separators")}
                                >
                                    <InputText value={segmentIdsAsString} onChange={setSegmentIdsAsString}/>
                                    <Button style={ButtonStyle.PRIMARY_MIDNIGHT} onClick={handleAddSegmentIds}>
                                        {textCommon(Action.ADD.labelKey)}
                                    </Button>
                                </FieldBlock>
                                <FieldBlock label={textDistribution("field.import_csv")} content={{noFullWidth: true}}>
                                    <ButtonUpload
                                        label={textDistribution(isLoadingImport ? "modal.import_button_loading" : "modal.import_button")}
                                        onChange={handleImportFile}
                                        style={ButtonStyle.PRIMARY_MIDNIGHT}
                                        size={ButtonSize.SMALL}
                                        disabled={isLoadingImport}
                                    />
                                </FieldBlock>
                            </FormLayoutColumns>
                            <FieldBlock
                                label={textDistribution("modal.selected", {count: selectedSegments.length})}
                                actions={<ButtonLink onClick={() => setSelectedSegments([])}>{textCommon(Action.REMOVE_ALL.labelKey)}</ButtonLink>}
                            >
                                <ElementList placeholder={textDistribution("modal.add_segments_for_platform")} size={ElementListSize.BIG}>
                                    {selectedSegments.map((segment) =>
                                        <TagSegmentRow
                                            key={segment.id}
                                            segment={segment}
                                            onRemove={() => setSelectedSegments((prevState) => prevState.filter((it) => it.id !== segment.id))}
                                        />
                                    )}
                                </ElementList>
                            </FieldBlock>
                        </>}
                </FormLayoutRows>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate onClick={() => setShowModalConfirmSave(true)} disabled={distributionType === DistributionType.SPECIFIC_SEGMENTS && !selectedSegments.length}/>
            </ModalActions>
            <ModalConfirmMessage
                active={isShowModalConfirmSave}
                message={textDistribution(status === Status.OPEN.name ? "message.confirm_open_segments" : "message.confirm_close_segments")}
                confirm={handleSave}
                confirmAction={status === Status.OPEN.name ? Action.OPEN : Action.CLOSE}
                cancel={() => setShowModalConfirmSave(false)}
            />
        </ModalNew>
    );
};

export default ModalManageDistributionSegments;
