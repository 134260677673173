import {Action, ContentBlock, ContentBlockAction, Paragraph, Table, TableColumnStyle} from "@sirdata/ui-lib";
import React, {FC, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {CategorizerConfigFilterRow} from "../../snippet";
import {Authorization} from "../../../api/model/account/Authorization";
import {CategorizerConfigEntry} from "../../../api/model/categorizer/CategorizerConfigEntry";
import {CategorizerConfigEntryField} from "../../../api/model/categorizer/CategorizerConfigEntryField";
import {CategorizerConfigType} from "../../../api/model/categorizer/CategorizerConfigType";
import {CategorizerConfigFilter} from "../../../api/model/categorizer/config/CategorizerConfigFilter";
import {CategorizerConfigFilterField} from "../../../api/model/categorizer/config/CategorizerConfigFilterField";
import {RestrictedContent} from "../../../common/component/widget";
import {CategorizerConfigEntriesProps} from "../../../utils/categorizer/CategorizerConfigEntriesProps";
import {TranslationPortalFile} from "../../../utils/constants";
import ModalEditCategorizerConfigFilter from "../../modal/categorizer/ModalEditCategorizerConfigFilter";
import ModalAddCategorizerConfigFilter from "../../modal/categorizer/ModalAddCategorizerConfigFilter";
import {CategorizerConfigEntryGroup} from "../../../api/model/categorizer/CategorizerConfigEntryGroup";
import {CategorizerConfigEntryGroupField} from "../../../api/model/categorizer/CategorizerConfigEntryGroupField";

const CategorizerConfigFilterEntries: FC<CategorizerConfigEntriesProps<CategorizerConfigFilter>> = ({configs, params, onSubmit, onDelete, editable}) => {
    const {t: textCategorizer} = useTranslation(TranslationPortalFile.CATEGORIZER);
    const history = useHistory();
    const [activeEditCategorizerConfigEntry, setActiveEditCategorizerConfigEntry] = useState<CategorizerConfigEntry<CategorizerConfigFilter>>();
    const [isShowModalAddCategorizerConfigFilter, setShowModalAddCategorizerConfigFilter] = useState(false);

    const hasConfig = (): boolean => {
        return configs.some((config) => config.match(params));
    };

    const handleClickFilterRow = (entry: CategorizerConfigEntry<CategorizerConfigFilter>) => {
        if (entry.match(params) || editable) {
            setActiveEditCategorizerConfigEntry(entry);
            return;
        }
        const group = new CategorizerConfigEntryGroup({
            [CategorizerConfigEntryGroupField.DOMAIN]: entry.domain,
            [CategorizerConfigEntryGroupField.HOST]: entry.host,
            [CategorizerConfigEntryGroupField.PATH]: entry.path
        });
        history.push(group.getRoute());
    };

    return (
        <ContentBlock
            header={{
                title: {label: textCategorizer(`config_type.${CategorizerConfigType.FILTER.name}`), icon: {name: "block"}},
                actions: [
                    !hasConfig() &&
                    <RestrictedContent key="add_config_filter" allowedTo={Authorization.CATEGORIZER.update}>
                        <ContentBlockAction action={Action.ADD} onClick={() => setShowModalAddCategorizerConfigFilter(true)}/>
                    </RestrictedContent>
                ]
            }}
        >
            <Paragraph>{textCategorizer(`config_type.description.${CategorizerConfigType.FILTER.name}`)}</Paragraph>
            <Table
                columns={[
                    {width: 15, label: textCategorizer(`field.${CategorizerConfigEntryField.DOMAIN}`), styles: TableColumnStyle.FIXED_WIDTH},
                    {width: 15, label: textCategorizer(`field.${CategorizerConfigEntryField.HOST}`), styles: TableColumnStyle.FIXED_WIDTH},
                    {width: 15, label: textCategorizer(`field.${CategorizerConfigEntryField.PATH}`), styles: TableColumnStyle.FIXED_WIDTH},
                    {label: textCategorizer(`field.${CategorizerConfigEntryField.CONFIG}.${CategorizerConfigFilterField.FILTERED}`), styles: TableColumnStyle.ALIGN_CENTER},
                    {width: 2}
                ]}
            >
                {configs.map((entry) =>
                    <CategorizerConfigFilterRow
                        key={entry.id}
                        entry={entry}
                        isEditable={entry.match(params) || !!editable}
                        onClick={() => handleClickFilterRow(entry)}
                        onDelete={() => onDelete(entry)}
                    />
                )}
            </Table>
            <ModalAddCategorizerConfigFilter
                params={params}
                active={isShowModalAddCategorizerConfigFilter}
                onSubmit={(entry) => {
                    onSubmit(entry);
                    setShowModalAddCategorizerConfigFilter(false);
                }}
                onClose={() => setShowModalAddCategorizerConfigFilter(false)}
            />
            <ModalEditCategorizerConfigFilter
                initEntry={activeEditCategorizerConfigEntry}
                onSubmit={(entry) => {
                    onSubmit(entry);
                    setActiveEditCategorizerConfigEntry(undefined);
                }}
                onClose={() => setActiveEditCategorizerConfigEntry(undefined)}
            />
        </ContentBlock>
    );
};

export default CategorizerConfigFilterEntries;
