import {ButtonLinkCancel, ButtonValidate, FieldBlock, FlexContentLayout, Form, FormLayoutColumns, FormLayoutRows, FormLayoutSeparator, FormValidationType, InputText, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, Select} from "@sirdata/ui-lib";
import {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {PartnerOrganizationGroup} from "../../../api/model/partner/organization/PartnerOrganizationGroup";
import {PartnerOrganizationGroupField} from "../../../api/model/partner/organization/PartnerOrganizationGroupField";
import {PartnerOrganizationType} from "../../../api/model/partner/organization/PartnerOrganizationType";
import {TranslationPortalFile} from "../../../utils/constants";
import {SelectUser} from "../../snippet";
import {session} from "../../../api/ApiSession";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import useAlert from "../../../utils/hooks/useAlert";
import {useHistory} from "react-router-dom";

export type ModalCreatePartnerOrganizationGroupProps = {
    active: boolean;
    onClose: () => void;
};

const ModalCreatePartnerOrganizationGroup: FunctionComponent<ModalCreatePartnerOrganizationGroupProps> = ({active, onClose}) => {
    const alert = useAlert();
    const history = useHistory();
    const {t: textOrganizations} = useTranslation(TranslationPortalFile.ORGANIZATIONS);
    const [organizationGroup, setOrganizationGroup] = useState<PartnerOrganizationGroup>(new PartnerOrganizationGroup());
    const FORM_ID = "form-create-partner-organization-group";

    useEffect(() => {
        if (active) {
            setOrganizationGroup(new PartnerOrganizationGroup({[PartnerOrganizationGroupField.TYPE]: PartnerOrganizationType.ADTECH.name}));
        }
    }, [active]);

    const handleChange = (field: PartnerOrganizationGroupField, value: any) => {
        setOrganizationGroup((prevState) => new PartnerOrganizationGroup({...prevState, [field]: value}));
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        try {
            const newOrganizationGroup = await session.restPartnerOrganizationGroup.create(organizationGroup);
            history.push(newOrganizationGroup.getRoute());
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToCreate("organization group", e.message);
            }
        }
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textOrganizations("create_organization_group")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FormLayoutColumns layout={FlexContentLayout.TWO_COLUMNS_WIDE_LEFT}>
                            <FieldBlock label={textOrganizations(`field.${PartnerOrganizationGroupField.NAME}`)} required>
                                <InputText
                                    value={organizationGroup.name}
                                    onChange={(value) => handleChange(PartnerOrganizationGroupField.NAME, value)}
                                />
                            </FieldBlock>
                            <FieldBlock label={textOrganizations(`field.${PartnerOrganizationGroupField.TYPE}`)} required>
                                <Select
                                    value={organizationGroup.type}
                                    options={PartnerOrganizationType.values().map((type) => ({value: type.name, label: textOrganizations(`type.${type.name}`)}))}
                                    onChange={(option) => handleChange(PartnerOrganizationGroupField.TYPE, `${option?.value || ""}`)}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FieldBlock label={textOrganizations(`field.${PartnerOrganizationGroupField.DESCRIPTION}`)}>
                            <InputText
                                value={organizationGroup.description}
                                onChange={(value) => handleChange(PartnerOrganizationGroupField.DESCRIPTION, value)}
                            />
                        </FieldBlock>
                        <FormLayoutSeparator/>
                        <FieldBlock
                            label={textOrganizations(`field.${PartnerOrganizationGroupField.OWNER_ID}`)}
                            required
                        >
                            <SelectUser
                                value={organizationGroup.owner_id}
                                onChange={(user) => handleChange(PartnerOrganizationGroupField.OWNER_ID, user?.id || 0)}
                            />
                        </FieldBlock>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalCreatePartnerOrganizationGroup;
