import {
    ButtonAdd,
    ButtonLinkCancel,
    ButtonValidate,
    ElementList,
    ElementListSize,
    FieldBlock,
    FlexContentSpacing,
    Form,
    FormLayoutColumns,
    FormLayoutRows,
    FormValidationType,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    Select,
    Textarea
} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {SegmentLinkAreaCodes} from "../../../api/model/audience/segment/SegmentLinkAreaCodes";
import {SegmentLinkAreaCodesField} from "../../../api/model/audience/segment/SegmentLinkAreaCodesField";
import {SegmentLinkAreaCodeType} from "../../../api/model/audience/segment/SegmentLinkAreaCodeType";
import {CountryCode, CountryIsoCode} from "../../../common/api/model/Country";
import {TranslationPortalFile} from "../../../utils/constants";
import {SelectStatus, TagWithAction} from "../../snippet";
import {Status} from "../../../utils/Status";

export type ModalAddSegmentLinkAreaCodesProps = {
    active: boolean;
    onSubmit: (link: SegmentLinkAreaCodes) => void;
    onClose: () => void;
};

const ModalAddSegmentLinksAreaCodes: FunctionComponent<ModalAddSegmentLinkAreaCodesProps> = ({active, onSubmit, onClose}) => {
    const {t: textSegments} = useTranslation(TranslationPortalFile.SEGMENTS);
    const [link, setLink] = useState<SegmentLinkAreaCodes>(new SegmentLinkAreaCodes());
    const [codesAsString, setCodesAsString] = useState<string>("");
    const CODE_SEPARATOR = ",";
    const FORM_ID = "form-add-segment-links-area-codes";

    useEffect(() => {
        if (active) {
            setLink(new SegmentLinkAreaCodes());
        }
    }, [active]);

    const handleChange = (field: SegmentLinkAreaCodesField, value: any) => {
        setLink((prevState) => new SegmentLinkAreaCodes({...prevState, [field]: value}));
    };

    const handleChangeNewCodes = (codesAsString: string) => {
        let value = codesAsString.replace(/[\n|;]/g, CODE_SEPARATOR);
        value = value.split(CODE_SEPARATOR).map((it) => it.trim()).join(CODE_SEPARATOR);
        setCodesAsString(value);
    };

    const handleAddCodes = () => {
        const newCodes = codesAsString.split(",").map((it) => it.trim()).filter((it) => !link.getCodes().includes(it));
        const newLink = new SegmentLinkAreaCodes({...link});
        newLink.setCodes([...newLink.getCodes(), ...newCodes]);
        setLink(newLink);
        setCodesAsString("");
    };

    const handleRemoveCode = (code: string) => {
        const newLink = new SegmentLinkAreaCodes({...link});
        newLink.setCodes(newLink.getCodes().filter((it) => it !== code));
        setLink(newLink);
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmit(link);
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textSegments("modal.add_area_codes")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FormLayoutColumns>
                            <FieldBlock label={textSegments("field.area_codes.status")}>
                                <SelectStatus
                                    value={link.active ? Status.ACTIVE.name : Status.INACTIVE.name}
                                    statuses={Status.getActiveStatuses()}
                                    onChange={(status) => handleChange(SegmentLinkAreaCodesField.ACTIVE, status === Status.ACTIVE)}
                                />
                            </FieldBlock>
                            <FieldBlock label={textSegments(`field.area_codes.${SegmentLinkAreaCodesField.TYPE}`)}>
                                <Select
                                    value={link.type}
                                    options={SegmentLinkAreaCodeType.values().map((it) => ({label: it.name, value: it.name}))}
                                    onChange={(option) => handleChange(SegmentLinkAreaCodesField.TYPE, `${option?.value || ""}`)}
                                />
                            </FieldBlock>
                            <FieldBlock label={textSegments(`field.area_codes.${SegmentLinkAreaCodesField.ISO_COUNTRY}`)}>
                                <Select
                                    value={link.iso_country}
                                    options={CountryCode.values().map((it) => ({label: it.name, value: it.code}))}
                                    onChange={(option) => handleChange(SegmentLinkAreaCodesField.ISO_COUNTRY, option?.value as CountryIsoCode)}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                            <FieldBlock
                                label={textSegments("field.area_codes.enter_new_codes")}
                                actions={<ButtonAdd onClick={handleAddCodes} disabled={!codesAsString.trim().length}/>}
                            >
                                <Textarea
                                    value={codesAsString}
                                    rows={5}
                                    onChange={handleChangeNewCodes}
                                />
                            </FieldBlock>
                            <ElementList placeholder={textSegments("placeholder.no_area_code_defined")} size={ElementListSize.MEDIUM} inline>
                                {link.getCodes().map((code) =>
                                    <TagWithAction key={code} value={code} onDelete={() => handleRemoveCode(code)} active/>
                                )}
                            </ElementList>
                        </FormLayoutRows>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalAddSegmentLinksAreaCodes;
