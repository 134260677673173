import {ButtonLinkCancel, ButtonValidate, FieldBlock, Form, FormLayoutColumns, FormLayoutRows, FormValidationType, InputDate, InputDateType, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {PartnerLicenseLinkPartner} from "../../../api/model/partner/license/PartnerLicenseLinkPartner";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {TranslationPortalFile} from "../../../utils/constants";
import {SelectPartner, SelectStatus} from "../../snippet";
import {PartnerLicenseLinkPartnerField} from "../../../api/model/partner/license/PartnerLicenseLinkPartnerField";
import useAlert from "../../../utils/hooks/useAlert";
import {Status} from "../../../utils/Status";

export type ModalAddLicenseLinkPartnerProps = {
    active: boolean;
    licenseId: number;
    onClose: (refresh: boolean) => void;
};

const ModalAddLicenseLinkPartner: FunctionComponent<ModalAddLicenseLinkPartnerProps> = ({active, licenseId, onClose}) => {
    const alert = useAlert();
    const {t: textLicenses} = useTranslation(TranslationPortalFile.LICENSES);
    const [linkPartner, setLinkPartner] = useState<PartnerLicenseLinkPartner>(new PartnerLicenseLinkPartner());
    const FORM_ID = "form-add-license-link-partner";

    useEffect(() => {
        if (active) {
            setLinkPartner(new PartnerLicenseLinkPartner());
        }
    }, [active]);

    const handleChange = (field: PartnerLicenseLinkPartnerField, value: any) => {
        setLinkPartner((prevState) => new PartnerLicenseLinkPartner({...prevState, [field]: value}));
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        try {
            linkPartner.license.id = licenseId;
            await session.restPartnerLicense.addPartnerLink(linkPartner);
            alert.createWithSuccess("license");
            onClose(true);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToCreate("license", e.message);
            }
        }
    };

    return (
        <ModalNew onClose={() => onClose(false)} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textLicenses("modal.link.license.title")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FieldBlock
                            label={textLicenses(`field.link_partner.${PartnerLicenseLinkPartnerField.PARTNER_ID}`)}
                            required
                        >
                            <SelectPartner
                                value={linkPartner.partner_id}
                                onChange={(partner) => handleChange(PartnerLicenseLinkPartnerField.PARTNER_ID, partner?.id || 0)}
                            />
                        </FieldBlock>
                        <FormLayoutColumns>
                            <FieldBlock label={textLicenses("field.link_partner.status")}>
                                <SelectStatus
                                    value={linkPartner.active ? Status.ACTIVE.name : Status.INACTIVE.name}
                                    statuses={Status.getActiveStatuses()}
                                    onChange={(status) => handleChange(PartnerLicenseLinkPartnerField.ACTIVE, status === Status.ACTIVE)}
                                />
                            </FieldBlock>
                            <FieldBlock label={textLicenses(`field.link_partner.${PartnerLicenseLinkPartnerField.EXPIRES_AT}`)}>
                                <InputDate
                                    type={InputDateType.DATETIME_LOCAL}
                                    value={linkPartner.expires_at}
                                    onChange={(value) => handleChange(PartnerLicenseLinkPartnerField.EXPIRES_AT, value)}
                                    disabled={!linkPartner.active}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={() => onClose(false)}/>
                <ButtonValidate form={FORM_ID}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalAddLicenseLinkPartner;
