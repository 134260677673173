import {SearchParams} from "../../../../utils/SearchParams";
import {SearchParamsField} from "../../../../utils/SearchParamsField";
import {SearchQuery} from "../../../interface/SearchQuery";

export enum CmpConfigSearchParamsField {
    VERSION = "version"
}

export class CmpConfigSearchQuery extends SearchQuery {
    partner_id: number | undefined;
    status: string | undefined;
    version: string | undefined;

    withSearchParams(params: SearchParams): CmpConfigSearchQuery {
        let newQuery = super.withSearchParams(params).withDefaultSorting("creation_time", true) as CmpConfigSearchQuery;
        newQuery.partner_id = params.getNumber(SearchParamsField.PARTNER);
        newQuery.status = params.getString(SearchParamsField.STATUS);
        newQuery.version = params.getString(CmpConfigSearchParamsField.VERSION);
        return newQuery;
    }

    getJsonParameters(): {} {
        return {
            partner_id: this.partner_id,
            status: this.status,
            version: this.version
        };
    }
}
