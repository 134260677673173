import {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {Color, IconTooltip, TableColumn, TableRow} from "@sirdata/ui-lib";
import {SirdataListPurpose} from "../../../../api/model/cmp/list/sirdata-list-v2/SirdataListPurpose";
import {TranslationPortalFile} from "../../../../utils/constants";
import {detectChanges} from "../../../../common/utils/portal";

type CmpSirdataListV2PurposesEditableRowProps = {
    purpose: SirdataListPurpose;
    currentVersionPurpose?: SirdataListPurpose;
};

const CmpSirdataListV2PurposesEditableRow: FunctionComponent<CmpSirdataListV2PurposesEditableRowProps> = ({purpose, currentVersionPurpose}) => {
    const {t: textCmpSirdataList} = useTranslation(TranslationPortalFile.CMP_SIRDATA_LIST_V2);

    return (
        <TableRow>
            <TableColumn>{purpose.id}</TableColumn>
            <TableColumn>
                {purpose.name}
                {(!currentVersionPurpose || detectChanges(purpose, currentVersionPurpose)) &&
                    <IconTooltip
                        icon={{name: "warning", colorIcon: Color.ORANGE}}
                        text={textCmpSirdataList("tooltip.warning")}
                    />
                }
            </TableColumn>
            <TableColumn>{purpose.description}</TableColumn>
        </TableRow>
    );
};

export default CmpSirdataListV2PurposesEditableRow;
