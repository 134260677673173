import {
    Action,
    Alert,
    AlertSeverity,
    Box,
    Button,
    ButtonLink,
    ButtonSize,
    ButtonStyle,
    ElementList,
    ElementListSize,
    FieldBlock,
    FlexContentSpacing,
    FormFieldMessageType,
    FormLayoutButtons,
    FormLayoutColumns,
    FormLayoutRows,
    InputDate,
    InputDateType,
    ModalActions,
    ModalContent,
    ModalDescription,
    ModalDescriptionAlignment,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    RadioButtons,
    SearchIds,
    SearchIdsResult,
    Select,
    TranslationLibFile
} from "@sirdata/ui-lib";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {NotificationDispatch} from "../../../api/model/notifications/NotificationDispatch";
import {Partner} from "../../../api/model/partner/Partner";
import {Formatter} from "../../../common/utils/Formatter";
import {TranslationPortalFile} from "../../../utils/constants";
import {SelectPartner, TagOrganizationRow, TagPartnerRow} from "../../snippet";
import {PartnerOrganizationType} from "../../../api/model/partner/organization/PartnerOrganizationType";
import {sortItems} from "../../../common/utils/helper";
import {PartnerOrganizationField} from "../../../api/model/partner/organization/PartnerOrganizationField";
import {PartnerOrganization} from "../../../api/model/partner/organization/PartnerOrganization";
import {NotificationDispatchField} from "../../../api/model/notifications/NotificationDispatchField";

type ModalCreatePartnerDispatchesProps = {
    onSubmit: (notificationDispatch: NotificationDispatch, broadcast: boolean) => void;
    onClose: () => void;
    active: boolean;
};

enum ModalCreatePartnerDispatchesRecipientType {
    ALL = "ALL",
    PARTNER_LIST = "PARTNER_LIST",
    ORGANIZATION_LIST = "ORGANIZATION_LIST"
}

type ModalCreatePartnerDispatchesForm = {
    dispatchDate?: string;
    recipientType: ModalCreatePartnerDispatchesRecipientType;
}

const ModalCreatePartnerDispatches: FunctionComponent<ModalCreatePartnerDispatchesProps> = ({onSubmit, onClose, active}) => {
    const {t: textNotifications} = useTranslation(TranslationPortalFile.NOTIFICATIONS);
    const {t: textOrganizations} = useTranslation(TranslationPortalFile.ORGANIZATIONS);
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);

    const [dispatchForm, setDispatchForm] = useState<ModalCreatePartnerDispatchesForm>({recipientType: ModalCreatePartnerDispatchesRecipientType.ORGANIZATION_LIST});
    const [partners, setPartners] = useState<Partner[]>([]);
    const [selectedPartners, setSelectedPartners] = useState<Partner[]>([]);
    const [searchIdResult, setSearchIdResult] = useState<SearchIdsResult>();
    const [highlightedPartners, setHighlightedPartners] = useState<Partner[]>();
    const [activeDispatchPartnerIds, setActiveDispatchPartnerIds] = useState<number[]>();

    const [organizations, setOrganizations] = useState<PartnerOrganization[]>([]);
    const [currentOrganizations, setCurrentOrganizations] = useState<PartnerOrganization[]>([]);
    const [selectedOrganizations, setSelectedOrganizations] = useState<PartnerOrganization[]>([]);
    const [highlightedOrganizations, setHighlightedOrganizations] = useState<PartnerOrganization[]>();

    useEffect(() => {
        setDispatchForm({recipientType: ModalCreatePartnerDispatchesRecipientType.ORGANIZATION_LIST});
        setSelectedPartners([]);
        if (active) {
            (async () => {
                try {
                    const partners = await session.getPartners();
                    setPartners(partners);

                    let organizations = await session.restPartnerOrganization.list();
                    organizations = sortItems(organizations, PartnerOrganizationField.NAME);
                    setOrganizations(organizations);
                    setCurrentOrganizations([...organizations]);
                } catch (e) {
                    // Push error alert
                }
            })();
        }
    }, [active]);

    useEffect(() => {
        const newCurrentOrganizations = [...organizations].filter((it) => !selectedOrganizations.find((selectedOrganization) => selectedOrganization.id === it.id));
        setCurrentOrganizations(newCurrentOrganizations);
    }, [selectedOrganizations, organizations]);

    const handleAddOrganizationType = (organizationType: string) => {
        let typeOrganizations = organizations.filter((it) => it.type === organizationType);
        if (typeOrganizations) {
            const mapSelectedOrganizations = new Map<number, PartnerOrganization>(selectedOrganizations.map((it) => [it.id, it]));
            const addedOrganizations: PartnerOrganization[] = [];
            typeOrganizations.forEach((it) => {
                if (!mapSelectedOrganizations.get(it.id)) {
                    mapSelectedOrganizations.set(it.id, it);
                    addedOrganizations.push(it);
                }
            });
            const newSelectedOrganizations = Array.from(mapSelectedOrganizations, ([key, value]) => ({key, value})).map((it) => it.value);
            setSelectedOrganizations(newSelectedOrganizations);
            setHighlightedOrganizations(addedOrganizations);
            setTimeout(() => {
                setHighlightedOrganizations([]);
            }, 1000);
        }
    };

    const handleAddOrganization = (organizationId: number) => {
        const newSelectedOrganization = [...organizations].find((it) => it.id === organizationId);
        if (newSelectedOrganization) {
            setSelectedOrganizations([...selectedOrganizations, newSelectedOrganization]);
            setHighlightedOrganizations([newSelectedOrganization]);
            setTimeout(() => {
                setHighlightedOrganizations([]);
            }, 1000);
        }
    };

    const handleAddPartners = (partners: Partner[]) => {
        setSelectedPartners((prevState) => [...prevState, ...partners]);
        setHighlightedPartners(partners);
        setTimeout(() => {
            setHighlightedPartners([]);
        }, 1000);
    };

    const handleAddPartnersByIds = (result: SearchIdsResult) => {
        handleAddPartners(result.matchedItems);
        setSearchIdResult(result);
        setTimeout(() => {
            setSearchIdResult(undefined);
        }, 10000);
    };

    const handleActiveDispatch = () => {
        let recipientIds: number[] = [];
        if (dispatchForm.recipientType === ModalCreatePartnerDispatchesRecipientType.ORGANIZATION_LIST) {
            const organizationIds = selectedOrganizations.map((it) => it.id);
            const organizationPartners = partners.filter((it) => organizationIds.find((organizationId) => organizationId === it.organization_id));
            recipientIds = organizationPartners.map((it) => it.id);
        } else if (dispatchForm.recipientType === ModalCreatePartnerDispatchesRecipientType.PARTNER_LIST) {
            recipientIds = selectedPartners.map((it) => it.id);
        }
        setActiveDispatchPartnerIds(recipientIds);
    };

    const handleSubmit = () => {
        if (activeDispatchPartnerIds === undefined) return;
        const notificationDispatch = new NotificationDispatch({
            [NotificationDispatchField.IDS]: activeDispatchPartnerIds,
            [NotificationDispatchField.DISPATCH_DATE]: Formatter.convertDateToUTC(dispatchForm.dispatchDate)
        });
        onSubmit(notificationDispatch, dispatchForm.recipientType === ModalCreatePartnerDispatchesRecipientType.ALL);
        setActiveDispatchPartnerIds(undefined);
    };

    const hasEmptyField = () => {
        return !dispatchForm.dispatchDate
            || (dispatchForm.recipientType === ModalCreatePartnerDispatchesRecipientType.ORGANIZATION_LIST && !selectedOrganizations.length)
            || (dispatchForm.recipientType === ModalCreatePartnerDispatchesRecipientType.PARTNER_LIST && !selectedPartners.length);
    };

    return (
        <>
            <ModalNew onClose={onClose} active={active}>
                <ModalHeader>
                    <ModalHeaderTitle title={textNotifications("create_dispatches_to_partners")}/>
                </ModalHeader>
                <ModalContent>
                    <FormLayoutRows>
                        <Alert text={textNotifications("message.recipients_mention")} severity={AlertSeverity.WARNING}/>
                        <FormLayoutColumns columns={3}>
                            <FieldBlock
                                label={textNotifications("field.dispatch_date")}
                                tooltip={textNotifications("tooltip.dispatch_date")}
                                required
                            >
                                <InputDate
                                    type={InputDateType.DATETIME_LOCAL}
                                    value={dispatchForm.dispatchDate}
                                    onChange={(value) => setDispatchForm((prevState) => ({...prevState, dispatchDate: value}) as ModalCreatePartnerDispatchesForm)}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FieldBlock label={textNotifications("field.recipients")}>
                            <RadioButtons
                                id="recipientType"
                                value={dispatchForm.recipientType}
                                options={[
                                    {value: ModalCreatePartnerDispatchesRecipientType.ORGANIZATION_LIST, label: textNotifications(`partners.${ModalCreatePartnerDispatchesRecipientType.ORGANIZATION_LIST}`)},
                                    {value: ModalCreatePartnerDispatchesRecipientType.PARTNER_LIST, label: textNotifications(`partners.${ModalCreatePartnerDispatchesRecipientType.PARTNER_LIST}`)},
                                    {value: ModalCreatePartnerDispatchesRecipientType.ALL, label: textNotifications(`partners.${ModalCreatePartnerDispatchesRecipientType.ALL}`)}
                                ]}
                                onChange={(value) => setDispatchForm((prevState) => ({...prevState, recipientType: value}) as ModalCreatePartnerDispatchesForm)}
                            />
                        </FieldBlock>
                        {dispatchForm.recipientType === ModalCreatePartnerDispatchesRecipientType.ORGANIZATION_LIST &&
                            <Box>
                                <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                                    <FormLayoutColumns>
                                        <Select
                                            value=""
                                            placeholder={textNotifications("actions.select_organization_type")}
                                            options={PartnerOrganizationType.values().map((it) => ({value: it.name, label: textOrganizations(`type.${it.name}`)}))}
                                            onChange={(option) => handleAddOrganizationType(option?.value as string)}
                                        />
                                        <Select
                                            value=""
                                            placeholder={textNotifications("actions.select_organization")}
                                            options={currentOrganizations.map((it) => ({value: it.id, label: it.name}))}
                                            onChange={(option) => handleAddOrganization(option?.value as number)}
                                        />
                                    </FormLayoutColumns>
                                    <FieldBlock
                                        label={textNotifications("organizations.selected", {count: selectedOrganizations.length})}
                                        actions={!!selectedOrganizations.length &&
                                            <ButtonLink onClick={() => setSelectedOrganizations([])}>
                                                {textCommon(Action.REMOVE_ALL.labelKey)}
                                            </ButtonLink>
                                        }
                                    >
                                        <ElementList placeholder={textNotifications("organizations.no_selected")} size={ElementListSize.BIG}>
                                            {selectedOrganizations.map((item) =>
                                                <TagOrganizationRow
                                                    key={item.id}
                                                    organization={item}
                                                    isHighlighted={highlightedOrganizations?.some(({id}) => item.id === id)}
                                                    onRemove={() => setSelectedOrganizations((prevState) => prevState.filter((it) => it.id !== item.id))}
                                                />
                                            )}
                                        </ElementList>
                                    </FieldBlock>
                                </FormLayoutRows>
                            </Box>
                        }
                        {dispatchForm.recipientType === ModalCreatePartnerDispatchesRecipientType.PARTNER_LIST &&
                            <Box>
                                <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                                    <FormLayoutRows spacing={FlexContentSpacing.XSMALL}>
                                        <SelectPartner
                                            value={undefined}
                                            onChange={(partner) => partner && handleAddPartners([partner])}
                                            excludedPartners={selectedPartners}
                                        />
                                        <SearchIds items={partners} selectedItems={selectedPartners} onSubmit={handleAddPartnersByIds}/>
                                        {searchIdResult?.messages.map((message) =>
                                            <Alert
                                                key={message.message}
                                                text={message.message}
                                                severity={message.type === FormFieldMessageType.ERROR ? AlertSeverity.DANGER : message.type === FormFieldMessageType.WARNING ? AlertSeverity.WARNING : AlertSeverity.SUCCESS}
                                            />
                                        )}
                                    </FormLayoutRows>
                                    <FieldBlock
                                        label={textNotifications("partners.selected", {count: selectedPartners.length})}
                                        actions={!!selectedPartners.length &&
                                            <ButtonLink onClick={() => setSelectedPartners([])}>
                                                {textCommon(Action.REMOVE_ALL.labelKey)}
                                            </ButtonLink>
                                        }
                                    >
                                        <ElementList placeholder={textNotifications("partners.no_selected")} size={ElementListSize.BIG}>
                                            {selectedPartners.map((partner) =>
                                                <TagPartnerRow
                                                    key={partner.id}
                                                    partner={partner}
                                                    isHighlighted={highlightedPartners?.some(({id}) => partner.id === id)}
                                                    onRemove={() => setSelectedPartners(selectedPartners.filter(({id}) => partner.id !== id))}
                                                />
                                            )}
                                        </ElementList>
                                    </FieldBlock>
                                </FormLayoutRows>
                            </Box>
                        }
                    </FormLayoutRows>
                </ModalContent>
                <ModalActions>
                    <Button
                        size={ButtonSize.BIG}
                        style={ButtonStyle.PRIMARY_GREEN}
                        onClick={handleActiveDispatch}
                        disabled={hasEmptyField()}
                    >
                        {textCommon(Action.SEND.labelKey)}
                    </Button>
                </ModalActions>
            </ModalNew>
            <ModalNew active={activeDispatchPartnerIds !== undefined}>
                <ModalContent>
                    <FormLayoutRows>
                        {dispatchForm.recipientType === ModalCreatePartnerDispatchesRecipientType.ALL ?
                            <Alert
                                severity={AlertSeverity.WARNING}
                                text={textNotifications("message.create_summary_all", {
                                    dispatchDate: Formatter.formatDate(dispatchForm.dispatchDate, Formatter.DATETIME_FORMAT)
                                })}
                            /> :
                            <Alert
                                severity={AlertSeverity.WARNING}
                                text={textNotifications("message.create_summary_specific", {
                                    dispatchDate: Formatter.formatDate(dispatchForm.dispatchDate, Formatter.DATETIME_FORMAT),
                                    count: activeDispatchPartnerIds?.length
                                })}
                            />
                        }
                        <ModalDescription alignment={ModalDescriptionAlignment.CENTER}>
                            <span dangerouslySetInnerHTML={{__html: textNotifications("message.create_summary_confirm")}}/>
                        </ModalDescription>
                        <FormLayoutButtons>
                            <Button
                                size={ButtonSize.BIG}
                                style={ButtonStyle.PRIMARY_MIDNIGHT}
                                onClick={() => setActiveDispatchPartnerIds(undefined)}
                            >
                                {textCommon(Action.CANCEL.labelKey)}
                            </Button>
                            <Button
                                size={ButtonSize.BIG}
                                style={ButtonStyle.DEFAULT_MIDNIGHT}
                                onClick={handleSubmit}
                            >
                                {textNotifications("actions.create_dispatch")}
                            </Button>
                        </FormLayoutButtons>
                    </FormLayoutRows>
                </ModalContent>
            </ModalNew>
        </>
    );
};

export default ModalCreatePartnerDispatches;
