import {Action, Box, BoxRadius, BoxSpacing, ContentBlock, ContentBlockAction, FlexContent, FlexContentDirection, FlexContentLayout, FlexContentSpacing, LayoutRows, Loadable, SvgSirdataLogoMidnight, Table, TableColumn, TableColumnStyle, TableImage, TableRow, TagStyle} from "@sirdata/ui-lib";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {SearchResult} from "../../../api/interface/SearchResult";
import {Authorization} from "../../../api/model/account/Authorization";
import {CmpConfigVendorList} from "../../../api/model/cmp/config/CmpConfigVendorList";
import {Vendor} from "../../../api/model/cmp/list/global-vendor-list/Vendor";
import {GoogleACProvider} from "../../../api/model/cmp/list/google-ac-provider-list/GoogleACProvider";
import {Network} from "../../../api/model/cmp/list/network-list/Network";
import {NetworkList} from "../../../api/model/cmp/list/network-list/NetworkList";
import {SirdataVendor} from "../../../api/model/cmp/list/sirdata-list/SirdataVendor";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {RestrictedContent} from "../../../common/component/widget";
import {sortItems} from "../../../common/utils/helper";
import {CmpPartner} from "../../../utils/cmp/CmpPartner";
import {CmpPartnerField} from "../../../utils/cmp/CmpPartnerField";
import {CmpPartnerType} from "../../../utils/cmp/CmpPartnerType";
import {MODAL_PAGE_SIZE, TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import ModalEditCmpConfigPartners from "../../modal/cmp-configurations/ModalEditCmpConfigPartners";
import {Tag} from "../index";
import clsx from "clsx";

type CmpConfigurationPartnersProps = {
    vendorList: CmpConfigVendorList;
    onChange: (vendorList: CmpConfigVendorList) => void;
};

type PartnerLists = {
    iabVendors: CmpPartner[];
    googleProviders: CmpPartner[];
    sirdataVendors: CmpPartner[];
};

const CmpConfigurationPartners: FunctionComponent<CmpConfigurationPartnersProps> = ({vendorList, onChange}) => {
    const alert = useAlert();
    const {t: textCmpConfigurations} = useTranslation(TranslationPortalFile.CMP_CONFIGURATIONS);
    const [isLoading, setLoading] = useState(true);
    const [searchResult, setSearchResult] = useState<SearchResult<CmpPartner>>(new SearchResult(CmpPartner));

    const [partners, setPartners] = useState<CmpPartner[]>([]);
    const [partnerLists, setPartnerLists] = useState<PartnerLists>();
    const [selectedPartners, setSelectedPartners] = useState<CmpPartner[]>([]);
    const [networkList, setNetworkList] = useState<NetworkList>();
    const [selectedNetworks, setSelectedNetworks] = useState<Network[]>([]);
    const [isShowModalEditCmpConfigPartners, setShowModalEditCmpConfigPartners] = useState(false);

    useEffect(() => {
        (async function () {
            try {
                setNetworkList(await session.restCmpList.getNetworkList());
                const globalVendorList = await session.restCmpList.getGlobalVendorList();
                const acProviderList = await session.restCmpList.getGoogleACProviderList();
                const sirdataList = await session.restCmpList.getSirdataList();

                const iabVendors = buildPartners(globalVendorList.vendors.filter((vendor) => !vendor.deletedDate), CmpPartnerType.IAB);
                const googleProviders = buildPartners(acProviderList.providers, CmpPartnerType.Google);
                const sirdataVendors = buildPartners(sirdataList.vendors.filter((sirdataVendor) => !sirdataVendor.deletedDate), CmpPartnerType.Sirdata);
                setPartnerLists({
                    iabVendors: iabVendors,
                    googleProviders: googleProviders,
                    sirdataVendors: sirdataVendors
                });
                setPartners(sortItems([...iabVendors, ...googleProviders, ...sirdataVendors], CmpPartnerField.NAME));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("partner lists", e.message);
                }
            }
        })();
    }, [alert]);

    useEffect(() => {
        if (!(vendorList && partnerLists && networkList)) return;
        (async function () {
            try {
                setLoading(true);
                let mapPartners = new Map<CmpPartner, boolean>();
                partnerLists.iabVendors.filter((vendor) => vendorList.vendors?.includes(vendor.id)).forEach((it) => it && mapPartners.set(it, true));
                partnerLists.googleProviders.filter((provider) => vendorList.googleProviders?.includes(provider.id)).forEach((it) => it && mapPartners.set(it, true));
                partnerLists.sirdataVendors.filter((vendor) => vendorList.sirdataVendors?.includes(vendor.id)).forEach((it) => it && mapPartners.set(it, true));

                const selectedNetworks = networkList.networks.filter((it) => vendorList.networks?.includes(it.id));
                setSelectedNetworks(selectedNetworks);
                selectedNetworks.forEach((network) => {
                    network.vendors.map((id) => partnerLists.iabVendors.find((it) => it.id === id)).forEach((it) => it && mapPartners.set(it, true));
                    network.providers.map((id) => partnerLists.googleProviders.find((it) => it.id === id)).forEach((it) => it && mapPartners.set(it, true));
                    network.sirdataVendors.map((id) => partnerLists.sirdataVendors.find((it) => it.id === id)).forEach((it) => it && mapPartners.set(it, true));
                });

                let currentPartners = Array.from(mapPartners.keys());
                currentPartners = sortItems(currentPartners, CmpPartnerField.NAME);
                setSelectedPartners(currentPartners);
                setSearchResult(SearchResult.buildFromList(CmpPartner, currentPartners, 0, MODAL_PAGE_SIZE));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("partners", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [vendorList, partnerLists, networkList, alert]);

    const buildPartners = (items: (Vendor | GoogleACProvider | SirdataVendor)[], type: CmpPartnerType): CmpPartner[] => {
        return items.map((it) => CmpPartner.create(it, type));
    };

    const handleChangePage = (page: number) => {
        setSearchResult(SearchResult.buildFromList(CmpPartner, selectedPartners, page - 1, MODAL_PAGE_SIZE));
    };

    const getPartnerSelectedNetworkLists = (partner: CmpPartner) => {
        return selectedNetworks.filter((network) => {
            return (partner.isIABVendor && network.hasIABVendor(partner.id))
                || (partner.isGoogleProvider && network.hasGoogleProvider(partner.id))
                || (partner.isSirdataVendor && network.hasSirdataVendor(partner.id));
        });
    };

    const handleChangePartners = (partners: CmpPartner[]) => {
        setShowModalEditCmpConfigPartners(false);

        const selectedIabVendors = partners.filter((partner) => partner.isIABVendor).map((it) => it.id);
        const selectedGoogleProviders = partners.filter((partner) => partner.isGoogleProvider).map((it) => it.id);
        const selectedSirdataVendors = partners.filter((partner) => partner.isSirdataVendor).map((it) => it.id);

        const newVendorList = new CmpConfigVendorList(vendorList);
        newVendorList.vendors = selectedIabVendors.length ? selectedIabVendors : undefined;
        newVendorList.googleProviders = selectedGoogleProviders.length ? selectedGoogleProviders : undefined;
        newVendorList.sirdataVendors = selectedSirdataVendors.length ? selectedSirdataVendors : undefined;

        onChange(newVendorList);
    };

    const handleSelectNetwork = (network: Network) => {
        const newSelectedNetworks = [...selectedNetworks];
        const index = newSelectedNetworks.findIndex((it) => it.id === network.id);
        if (index !== -1) {
            newSelectedNetworks.splice(index, 1);
        } else {
            newSelectedNetworks.push(network);
        }
        const newVendorList = new CmpConfigVendorList(vendorList);
        newVendorList.networks = newSelectedNetworks.length ? newSelectedNetworks.map((it) => it.id) : undefined;

        onChange(newVendorList);
    };

    return (
        <Loadable loading={isLoading}>
            <LayoutRows>
                <ContentBlock header={{title: {label: textCmpConfigurations("section.networks")}}}>
                    <FlexContent
                        direction={FlexContentDirection.ROW}
                        layout={FlexContentLayout.COLUMNS}
                        spacing={FlexContentSpacing.SMALL}
                        allowWrap
                        cssClass="cmp-networks"
                    >
                        {networkList?.networks.map((network) =>
                            <Box
                                key={network.id}
                                radius={BoxRadius.SM}
                                spacing={BoxSpacing.MEDIUM}
                                cssClass={clsx("cmp-networks__item", {"cmp-networks__item--active": vendorList.networks?.includes(network.id)})}
                                onClick={() => handleSelectNetwork(network)}
                            >
                                <div className="cmp-networks__item__image-container">
                                    {network.id === 1 ? <SvgSirdataLogoMidnight/> : <img src={`/images/cmp/networks/logo-${network.id}.png`} alt={network.name}/>}
                                </div>
                                <span className="cmp-networks__item__name">{network.name}</span>
                            </Box>
                        )}
                    </FlexContent>
                </ContentBlock>
                <ContentBlock
                    header={{
                        title: {label: textCmpConfigurations("section.partners")},
                        actions: [
                            <RestrictedContent key="edit_partners" allowedTo={Authorization.CMP_CONFIGURATIONS.update}>
                                <ContentBlockAction
                                    action={Action.EDIT}
                                    onClick={() => setShowModalEditCmpConfigPartners(true)}
                                />
                            </RestrictedContent>
                        ]
                    }}
                >
                    <Table
                        columns={[
                            {width: 5, label: textCmpConfigurations(`partners.${CmpPartnerField.TYPE}`), styles: TableColumnStyle.ALIGN_CENTER},
                            {width: 5, label: textCmpConfigurations(`partners.${CmpPartnerField.ID}`), styles: TableColumnStyle.ALIGN_CENTER},
                            {width: 85, label: textCmpConfigurations(`partners.${CmpPartnerField.NAME}`)}
                        ]}
                        pagination={searchResult.getPagination(handleChangePage)}
                    >
                        {searchResult.elements.map((item) =>
                            <TableRow key={item.id}>
                                <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                                    <TableImage image={item.logo}/>
                                </TableColumn>
                                <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{item.id}</TableColumn>
                                <TableColumn>
                                    <span>{item.name}</span>
                                    {getPartnerSelectedNetworkLists(item)?.map(({name}) =>
                                        <Tag key={name} label={name} style={TagStyle.PRIMARY_GREY_LIGHT}/>
                                    )}
                                </TableColumn>
                            </TableRow>
                        )}
                    </Table>
                    <ModalEditCmpConfigPartners
                        active={isShowModalEditCmpConfigPartners}
                        partners={partners}
                        currentSelectedPartners={selectedPartners}
                        onSubmit={handleChangePartners}
                        onClose={() => setShowModalEditCmpConfigPartners(false)}
                    />
                </ContentBlock>
            </LayoutRows>
        </Loadable>
    );
};

export default CmpConfigurationPartners;
