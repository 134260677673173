import {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Action, LayoutColumns, LayoutRows, Loadable, Tabs} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../utils/constants";
import {Module} from "../../utils/Module";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {session} from "../../api/ApiSession";
import useAlert from "../../utils/hooks/useAlert";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {
    CmpSirdataListV2Purposes,
    CmpSirdataListV2PurposesEditable,
    CmpSirdataListV2Stacks,
    CmpSirdataListV2StacksEditable,
    CmpSirdataListV2Vendors,
    CmpSirdataListV2VendorsEditable,
    MainContentHeader,
    MainContentHeaderAction
} from "../../component/snippet";
import {GlobalVendorList} from "../../api/model/cmp/list/global-vendor-list/GlobalVendorList";
import {SirdataListVersion} from "../../api/model/cmp/list/sirdata-list-v2/SirdataListVersion";
import {SirdataListLanguage} from "../../api/model/cmp/list/sirdata-list-v2/SirdataListLanguage";
import {SirdataListVendor} from "../../api/model/cmp/list/sirdata-list-v2/SirdataListVendor";
import {CmpConfigLanguage} from "../../api/model/cmp/config/CmpConfigLanguage";
import {Authorization} from "../../api/model/account/Authorization";
import ModalCreateCmpSirdataListV2Version from "../../component/modal/cmp-sirdata-list-v2/ModalCreateCmpSirdataListV2Version";

function CmpSirdataListV2() {
    const alert = useAlert();
    const {t: textCmpSirdataList} = useTranslation(TranslationPortalFile.CMP_SIRDATA_LIST_V2);
    const [isLoading, setLoading] = useState(true);
    const [latestVersion, setLatestVersion] = useState<SirdataListVersion>(new SirdataListVersion());
    const [globalVendorList, setGlobalVendorList] = useState<GlobalVendorList>(new GlobalVendorList());
    const [listLanguages, setListLanguages] = useState<SirdataListLanguage[]>([]);
    const [defaultListLanguage, setDefaultListLanguage] = useState<SirdataListLanguage>(new SirdataListLanguage());
    const [listVendors, setListVendors] = useState<SirdataListVendor[]>([]);
    const [isShowModalCreateCmpSirdataListV2Version, setShowModalCreateCmpSirdataListV2Version] = useState(false);

    const loadLatestVersion = useCallback(async () => {
        try {
            const latestVersion = await session.restCmpSirdataListVersion.getLatestVersion();
            setLatestVersion(latestVersion);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToLoad("latest version", e.message);
            }
        }
    }, [alert]);

    const loadListLanguages = useCallback(async () => {
        try {
            const listLanguages = await session.restCmpSirdataListLanguage.list();
            setListLanguages(listLanguages);
            setDefaultListLanguage(listLanguages.find((it) => it.language === CmpConfigLanguage.ENGLISH.name) as SirdataListLanguage);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToLoad("languages", e.message);
            }
        }
    }, [alert]);

    const loadListVendors = useCallback(async () => {
        try {
            const vendors = await session.restCmpSirdataListVendor.list();
            setListVendors(vendors);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToLoad("vendors", e.message);
            }
        }
    }, [alert]);

    useEffect(() => {
        (async () => {
            try {
                const globalVendorList = await session.restCmpList.getGlobalVendorList(CmpConfigLanguage.ENGLISH);
                setGlobalVendorList(globalVendorList);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("Global Vendor List", e.message);
                }
            }
            await loadLatestVersion();
            await loadListLanguages();
            await loadListVendors();

            setLoading(false);
        })();
    }, [alert, loadLatestVersion, loadListLanguages, loadListVendors]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.CMP_SIRDATA_LIST_V2} element={latestVersion.toContentElement()}>
                <RestrictedContent allowedTo={Authorization.CMP_SIRDATA_LIST_PUBLISH.name}>
                    <MainContentHeaderAction action={Action.NEW} onClick={() => setShowModalCreateCmpSirdataListV2Version(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <Loadable loading={isLoading}>
                    <Tabs
                        headers={[
                            {label: textCmpSirdataList("tabs.current_version")},
                            {label: textCmpSirdataList("tabs.edit_vendors")},
                            {label: textCmpSirdataList("tabs.edit_stacks")},
                            {label: textCmpSirdataList("tabs.edit_purposes")}
                        ]}
                    >
                        <LayoutRows>
                            <CmpSirdataListV2Vendors
                                sirdataList={latestVersion}
                                globalVendorList={globalVendorList}
                            />
                            <LayoutColumns>
                                <CmpSirdataListV2Stacks
                                    sirdataList={latestVersion}
                                    globalVendorList={globalVendorList}
                                />
                                <CmpSirdataListV2Purposes
                                    sirdataList={latestVersion}
                                />
                            </LayoutColumns>
                        </LayoutRows>
                        <CmpSirdataListV2VendorsEditable
                            sirdataList={latestVersion}
                            globalVendorList={globalVendorList}
                            listLanguage={defaultListLanguage}
                            listVendors={listVendors}
                            onChange={async () => await loadListVendors()}
                        />
                        <CmpSirdataListV2StacksEditable
                            sirdataList={latestVersion}
                            globalVendorList={globalVendorList}
                            listLanguage={defaultListLanguage}
                            onChange={async () => await loadListLanguages()}
                        />
                        <CmpSirdataListV2PurposesEditable
                            sirdataList={latestVersion}
                            listLanguage={defaultListLanguage}
                            onChange={async () => await loadListLanguages()}
                        />
                    </Tabs>
                </Loadable>
                <ModalCreateCmpSirdataListV2Version
                    sirdataList={latestVersion}
                    listLanguages={listLanguages}
                    listVendors={listVendors}
                    active={isShowModalCreateCmpSirdataListV2Version}
                    onClose={async (refresh) => {
                        setShowModalCreateCmpSirdataListV2Version(false);
                        if (refresh) {
                            await loadLatestVersion();
                            await loadListVendors();
                        }
                    }}
                />
            </MainContent>
        </Wrapper>
    );
}

export default CmpSirdataListV2;
