import {
    Action,
    ActionsMenu,
    AlertSeverity,
    ButtonLinkCancel,
    ButtonValidate,
    FieldBlock,
    FlexContentDirection,
    FlexContentSpacing,
    Form,
    FormLayoutRows,
    FormValidationType,
    InputText,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    Select,
    Table,
    TableActionColumn,
    TableColumn,
    TableRow,
    TagStyle,
    ToggleSwitch,
    TranslationLibFile
} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {CategorizerConfigEntry} from "../../../api/model/categorizer/CategorizerConfigEntry";
import {CategorizerConfigEntryField} from "../../../api/model/categorizer/CategorizerConfigEntryField";
import {CategorizerConfigType} from "../../../api/model/categorizer/CategorizerConfigType";
import {CategorizerConfigField} from "../../../api/model/categorizer/config/CategorizerConfigField";
import {CategorizerConfigUrlCategorization} from "../../../api/model/categorizer/config/CategorizerConfigUrlCategorization";
import {CategorizerConfigUrlCategorizationExtraction} from "../../../api/model/categorizer/config/CategorizerConfigUrlCategorizationExtraction";
import {CategorizerConfigUrlCategorizationExtractionField} from "../../../api/model/categorizer/config/CategorizerConfigUrlCategorizationExtractionField";
import {CategorizerConfigUrlCategorizationExtractionMode} from "../../../api/model/categorizer/config/CategorizerConfigUrlCategorizationExtractionMode";
import {CategorizerConfigUrlCategorizationField} from "../../../api/model/categorizer/config/CategorizerConfigUrlCategorizationField";
import {TranslationPortalFile} from "../../../utils/constants";
import {CategorizerConfigEntryInfo, Tag} from "../../snippet";
import useFormValidator from "../../../utils/hooks/useFormValidator";
import {FormLayoutMessage} from "../../../common/component/snippet";

type ModalEditCategorizerConfigUrlCategorizationProps = {
    initEntry: CategorizerConfigEntry<CategorizerConfigUrlCategorization> | undefined;
    onSubmit: (entry: CategorizerConfigEntry<CategorizerConfigUrlCategorization>) => void;
    onClose: () => void;
};

const ModalEditCategorizerConfigUrlCategorization: FunctionComponent<ModalEditCategorizerConfigUrlCategorizationProps> = ({initEntry, onSubmit, onClose}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textCategorizer} = useTranslation(TranslationPortalFile.CATEGORIZER);
    const [entry, setEntry] = useState<CategorizerConfigEntry<CategorizerConfigUrlCategorization>>(CategorizerConfigEntry.forType(CategorizerConfigType.URL_CATEGORIZATION));
    const [availableModes, setAvailableModes] = useState<CategorizerConfigUrlCategorizationExtractionMode[]>([]);
    const FORM_ID = "form-edit-categorizer-config-url-categorization";
    const {setErrors, setShowErrors, ...formValidator} = useFormValidator<CategorizerConfigUrlCategorizationField>();

    useEffect(() => {
        if (initEntry) {
            setEntry(initEntry);
            setShowErrors(false);
        }
    }, [initEntry, setShowErrors]);

    useEffect(() => {
        if (entry) {
            const currentModes = entry.config.extractions.map((it) => it.mode);
            setAvailableModes(CategorizerConfigUrlCategorizationExtractionMode.values().filter((it) => !currentModes.includes(it.name)));
        }
    }, [entry]);

    useEffect(() => {
        setErrors((prevState) => ({
            ...prevState,
            [CategorizerConfigUrlCategorizationField.EXTRACTIONS]: !entry.config.disabled && !entry.config.extractions.length
        }));
    }, [setErrors, entry.config.disabled, entry.config.extractions.length]);

    const handleChange = (field: CategorizerConfigEntryField, value: any) => {
        setEntry((prevState) => new CategorizerConfigEntry<CategorizerConfigUrlCategorization>({...prevState, [field]: value}));
    };

    const handleChangeConfig = (field: CategorizerConfigUrlCategorizationField | CategorizerConfigField, value: any) => {
        handleChange(CategorizerConfigEntryField.CONFIG, new CategorizerConfigUrlCategorization({...entry.config, [field]: value}));
    };

    const handleAddExtraction = (mode: CategorizerConfigUrlCategorizationExtractionMode) => {
        const newEntry = new CategorizerConfigEntry<CategorizerConfigUrlCategorization>({...entry});
        const newExtraction = new CategorizerConfigUrlCategorizationExtraction({[CategorizerConfigUrlCategorizationExtractionField.MODE]: mode.name});
        const newExtractions = [...newEntry.config.extractions, newExtraction];
        handleChangeConfig(CategorizerConfigUrlCategorizationField.EXTRACTIONS, newExtractions);
    };

    const handleChangeExtractionConf = (mode?: CategorizerConfigUrlCategorizationExtractionMode) => (conf: string) => {
        if (!mode) return;
        const newEntry = new CategorizerConfigEntry<CategorizerConfigUrlCategorization>({...entry});
        const newExtractions = newEntry.config.extractions.map((it) =>
            it.mode === mode.name ? new CategorizerConfigUrlCategorizationExtraction({...it, [CategorizerConfigUrlCategorizationExtractionField.CONF]: conf}) : it
        );
        handleChangeConfig(CategorizerConfigUrlCategorizationField.EXTRACTIONS, newExtractions);
    };

    const handleRemoveExtraction = (mode?: CategorizerConfigUrlCategorizationExtractionMode) => {
        if (!mode) return;
        const newExtractions = entry.config.extractions.filter((it) => it.mode !== mode.name);
        handleChangeConfig(CategorizerConfigUrlCategorizationField.EXTRACTIONS, newExtractions);
    };

    const handleSubmitCapture = () => {
        setShowErrors(true);
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (formValidator.hasErrors()) {
            return;
        }
        onSubmit(entry);
        setShowErrors(false);
    };

    return (
        <ModalNew onClose={onClose} active={!!initEntry}>
            <ModalHeader>
                <ModalHeaderTitle title={textCategorizer("modal.edit_configuration", {type: textCategorizer(`config_type.${CategorizerConfigType.URL_CATEGORIZATION.name}`)})}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmitCapture={handleSubmitCapture} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <CategorizerConfigEntryInfo currentEntry={entry}/>
                        <FieldBlock
                            label={textCategorizer(`field.${CategorizerConfigEntryField.CONFIG}.${CategorizerConfigField.DISABLED}`)}
                            name={`${CategorizerConfigEntryField.CONFIG}.${CategorizerConfigField.DISABLED}`}
                        >
                            <ToggleSwitch
                                checked={entry.config.disabled}
                                onChange={(value) => handleChangeConfig(CategorizerConfigField.DISABLED, value)}
                            />
                        </FieldBlock>
                        {!entry.config.disabled &&
                            <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                                <FieldBlock
                                    label={textCategorizer(`field.${CategorizerConfigEntryField.CONFIG}.${CategorizerConfigUrlCategorizationField.EXTRACTIONS}`)}
                                    content={{direction: FlexContentDirection.COLUMN}}
                                >
                                    <Select
                                        value={""}
                                        options={[{value: "", label: textCategorizer("action.select_extraction_mode"), isDisabled: true},
                                            ...availableModes.map((mode) => ({label: textCategorizer(`url_categorization_mode.${mode.name}`), value: mode.name, mode: mode}))]}
                                        onChange={(option) => option && handleAddExtraction(option.mode as CategorizerConfigUrlCategorizationExtractionMode)}
                                        menuPlacement={"top"}
                                    />
                                    {formValidator.isError(CategorizerConfigUrlCategorizationField.EXTRACTIONS) &&
                                        <FormLayoutMessage message={textCategorizer("message.extraction_mode_required")} small severity={AlertSeverity.DANGER}/>
                                    }
                                </FieldBlock>
                                {!!entry.config.extractions.length &&
                                    <Table
                                        columns={[
                                            {width: 35, label: textCategorizer(`field.${CategorizerConfigEntryField.CONFIG}.${CategorizerConfigUrlCategorizationExtractionField.MODE}`)},
                                            {width: 60, label: textCategorizer(`field.${CategorizerConfigEntryField.CONFIG}.${CategorizerConfigUrlCategorizationExtractionField.CONF}`)},
                                            {width: 5}
                                        ]}
                                    >
                                        {entry.config.extractions.map((item, index) =>
                                            <TableRow key={`config-extraction-${index.toString()}`}>
                                                <TableColumn>
                                                    <Tag key={item.mode} label={textCategorizer(`url_categorization_mode.${item.mode}`)} style={TagStyle.PRIMARY_MIDNIGHT_LIGHT}/>
                                                </TableColumn>
                                                <TableColumn>
                                                    {item.mode === CategorizerConfigUrlCategorizationExtractionMode.QUERY_PARAM.name ?
                                                        <FieldBlock required>
                                                            <InputText
                                                                value={item.conf}
                                                                onChange={handleChangeExtractionConf(CategorizerConfigUrlCategorizationExtractionMode.getByName(item.mode))}
                                                                small
                                                            />
                                                        </FieldBlock>
                                                        : "-"
                                                    }
                                                </TableColumn>
                                                <TableActionColumn>
                                                    <ActionsMenu
                                                        iconTooltip={{icon: Action.MORE.icon, text: textCommon(Action.MORE.labelKey)}}
                                                        items={[{
                                                            label: textCommon(Action.REMOVE.labelKey),
                                                            critical: true,
                                                            separator: true,
                                                            onClick: () => handleRemoveExtraction(CategorizerConfigUrlCategorizationExtractionMode.getByName(item.mode))
                                                        }]}
                                                    />
                                                </TableActionColumn>
                                            </TableRow>
                                        )}
                                    </Table>
                                }
                            </FormLayoutRows>
                        }
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalEditCategorizerConfigUrlCategorization;
