import {Color} from "@sirdata/ui-lib";
import {ApiModel} from "../../../../common/api/model/ApiModel";
import {ContentElement} from "../../../interface/ContentElement";
import {Category} from "./Category";
import {CategoryGroupField} from "./CategoryGroupField";
import {CategoryTaxonomy} from "./CategoryTaxonomy";
import {CategoryType} from "./CategoryType";
import {Module} from "../../../../utils/Module";

const DEFAULT_PARTNER_ID = 1000;
const CODE_NAME_SEPARATOR = "/";

export class CategoryGroup extends ApiModel {
    id: number = 0;
    id_partner: number = DEFAULT_PARTNER_ID;
    id_parent?: number;
    id_ext?: number;
    name: string = "";
    code_name: string = "";
    description: string = "";
    taxonomy: string = CategoryTaxonomy.SIRDATA.name;
    last_update: string = "";
    cats: Category[] = [];
    links: CategoryGroup[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) return;
        if (typeof o === "object") {
            for (let key in o) {
                if (key === CategoryGroupField.CATS) {
                    this.cats = [];
                    if (o[key]) {
                        (o[key] as Category[]).forEach((obj) => {
                            const it = new Category(obj);
                            this.cats.push(it);
                        });
                    }
                } else if (key === CategoryGroupField.LINKS) {
                    this.links = [];
                    if (o[key]) {
                        (o[key] as CategoryGroup[]).forEach((obj) => {
                            const it = new CategoryGroup(obj);
                            this.links.push(it);
                        });
                    }
                } else {
                    this[key] = o[key];
                }
            }
        }
    }

    get fullName(): string {
        return this.code_name.replaceAll(CODE_NAME_SEPARATOR, " > ");
    }

    get topTier(): string | undefined {
        const top_tier = this.code_name.split(CODE_NAME_SEPARATOR)[0];
        return top_tier === this.name ? undefined : top_tier;
    }

    get taxonomyPath(): string[] {
        return this.code_name.split(CODE_NAME_SEPARATOR).slice(0, -1);
    }

    setTaxonomyPath(path: string) {
        this.code_name = path + CODE_NAME_SEPARATOR + this.name;
    }

    getCategory(type: CategoryType): Category | undefined {
        return this.cats.find((it) => it.type === type.name);
    }

    getColorIcon(type: CategoryType): Color {
        return !!this.getCategory(type) ? type.getIconColor() : Color.GREY;
    }

    getJsonParameters(): {} {
        return {
            id: this.id,
            id_parent: this.id_parent,
            id_ext: this.id_ext,
            taxonomy: this.taxonomy,
            name: this.name,
            description: this.description,
            cats: this.cats.map((cat) => cat.getJsonParameters()),
            links: this.links.map((link) => link.getJsonParameters())
        };
    }

    getRoute(): string {
        return Module.CATEGORIES.buildRoute(this.id);
    }

    hasEmptyField(): boolean {
        return !this.name;
    };

    toContentElement(): ContentElement {
        let title = this.name;
        if (this.topTier) {
            title = `[${this.topTier}] ${this.name}`;
        }
        const element = new ContentElement(this.id, title);
        element.lastUpdated = new Date(this.last_update);
        return element;
    }
}
