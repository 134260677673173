import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {CmpWebinar} from "../../../api/model/cmp/webinar/CmpWebinar";
import {CmpWebinarInfo} from "../../../api/model/cmp/webinar/CmpWebinarInfo";
import {Locale} from "../../../common/utils/Locale";
import {
    Alert,
    ButtonLinkCancel,
    ButtonValidate,
    FieldBlock,
    FlexContentLayout,
    FlexContentSpacing,
    Form,
    FormLayoutColumns,
    FormLayoutRows,
    FormLayoutSeparator,
    FormValidationType,
    InputDate,
    InputDateType,
    InputText,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    Tabs,
    Textarea,
    ToggleSwitch
} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../../utils/constants";
import {CmpWebinarField} from "../../../api/model/cmp/webinar/CmpWebinarField";
import {CmpWebinarInfoField} from "../../../api/model/cmp/webinar/CmpWebinarInfoField";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import useAlert from "../../../utils/hooks/useAlert";
import {TranslationCommonFile} from "../../../common/utils/constants";
import {Formatter} from "../../../common/utils/Formatter";

export type ModalCreateCmpWebinarProps = {
    active: boolean;
    onClose: () => void;
};

const ModalCreateCmpWebinar: FunctionComponent<ModalCreateCmpWebinarProps> = ({active, onClose}) => {
    const alert = useAlert();
    const {t: textMain} = useTranslation(TranslationCommonFile.MAIN);
    const {t: textCmpWebinars} = useTranslation(TranslationPortalFile.CMP_WEBINARS);
    const history = useHistory();
    const [cmpWebinar, setCmpWebinar] = useState<CmpWebinar>(new CmpWebinar());
    const FORM_ID = "form-create-cmp-webinar";

    useEffect(() => {
        if (active) {
            setCmpWebinar(new CmpWebinar());
        }
    }, [active]);

    const handleChange = (field: CmpWebinarField, value: any) => {
        setCmpWebinar((prevState) => new CmpWebinar({...prevState, [field]: value}));
    };

    const handleChangeInfo = (locale: Locale, field: CmpWebinarInfoField, value: any) => {
        const newWebinarInfos = new Map(cmpWebinar.info);
        const newWebinarInfo = new CmpWebinarInfo({...newWebinarInfos.get(locale), [field]: value});
        newWebinarInfos.set(locale, newWebinarInfo);

        if (field === CmpWebinarInfoField.IS_MAIN_LANGUAGE && value === true) {
            const otherLanguage = locale === Locale.FRENCH ? Locale.ENGLISH : Locale.FRENCH;
            const otherLanguageWebinarInfo = new CmpWebinarInfo({...newWebinarInfos.get(otherLanguage), [CmpWebinarInfoField.IS_MAIN_LANGUAGE]: false});
            newWebinarInfos.set(otherLanguage, otherLanguageWebinarInfo);
        }
        handleChange(CmpWebinarField.INFO, newWebinarInfos);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        try {
            const newCmpWebinar = await session.restCmpWebinar.create(cmpWebinar);
            history.push(newCmpWebinar.getRoute());
            onClose();
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToCreate("CMP webinar", e.message);
            }
        }
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textCmpWebinars("title_create")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FormLayoutColumns columns={3}>
                            <FieldBlock label={textCmpWebinars(`field.${CmpWebinarField.DATE}`)} required>
                                <InputDate
                                    type={InputDateType.DATE}
                                    value={Formatter.formatDate(cmpWebinar.date, Formatter.API_DATE_FORMAT)}
                                    onChange={(value) => handleChange(CmpWebinarField.DATE, value)}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FormLayoutSeparator/>
                        <Alert text={textCmpWebinars("message.content_mention")}/>
                        <Tabs headers={Locale.values().map((locale) => ({label: textMain(`language.${locale}`)}))}>
                            {Locale.values().map((locale) =>
                                <FormLayoutRows key={`${locale}`} spacing={FlexContentSpacing.MEDIUM}>
                                    <FieldBlock label={textCmpWebinars(`field.${CmpWebinarInfoField.TITLE}`)} required>
                                        <InputText
                                            value={cmpWebinar.getInfoForLanguage(locale).title}
                                            onChange={(value) => handleChangeInfo(locale, CmpWebinarInfoField.TITLE, value)}
                                        />
                                    </FieldBlock>
                                    <FieldBlock label={textCmpWebinars(`field.${CmpWebinarInfoField.DESCRIPTION}`)} required>
                                        <Textarea
                                            value={cmpWebinar.getInfoForLanguage(locale)?.description}
                                            rows={5}
                                            onChange={(value) => handleChangeInfo(locale, CmpWebinarInfoField.DESCRIPTION, value)}
                                        />
                                    </FieldBlock>
                                    <FormLayoutColumns layout={FlexContentLayout.TWO_COLUMNS_WIDE_RIGHT}>
                                        <FieldBlock label={textCmpWebinars(`field.${CmpWebinarInfoField.YOUTUBE_ID}`)} required>
                                            <InputText
                                                value={cmpWebinar.getInfoForLanguage(locale).youtube_id}
                                                onChange={(value) => handleChangeInfo(locale, CmpWebinarInfoField.YOUTUBE_ID, value)}
                                            />
                                        </FieldBlock>
                                        <FieldBlock name={CmpWebinarInfoField.IS_MAIN_LANGUAGE} label={textCmpWebinars(`field.${CmpWebinarInfoField.IS_MAIN_LANGUAGE}`)}>
                                            <ToggleSwitch
                                                checked={cmpWebinar.getInfoForLanguage(locale).is_main_language}
                                                onChange={(value) => handleChangeInfo(locale, CmpWebinarInfoField.IS_MAIN_LANGUAGE, value)}
                                            />
                                        </FieldBlock>
                                    </FormLayoutColumns>
                                </FormLayoutRows>
                            )}
                        </Tabs>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalCreateCmpWebinar;
