import {Loadable, ModalContent, ModalNew, ModalTitle, Table, TableColumn, TableColumnStyle, TableRow} from "@sirdata/ui-lib";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {SearchResult} from "../../../api/interface/SearchResult";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {MODAL_PAGE_SIZE, TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import {Network} from "../../../api/model/cmp/list/network-list/Network";
import {CmpPartner} from "../../../utils/cmp/CmpPartner";
import {CmpPartnerType} from "../../../utils/cmp/CmpPartnerType";
import {GlobalVendorList} from "../../../api/model/cmp/list/global-vendor-list/GlobalVendorList";
import {GoogleACProviderList} from "../../../api/model/cmp/list/google-ac-provider-list/GoogleACProviderList";
import {SirdataListVersion} from "../../../api/model/cmp/list/sirdata-list-v2/SirdataListVersion";

type ModalNetworkProps = {
    network: Network | undefined;
    onClose: () => void;
};

const ModalNetwork: FunctionComponent<ModalNetworkProps> = ({network, onClose}) => {
    const alert = useAlert();
    const {t: textCmpLists} = useTranslation(TranslationPortalFile.CMP_LISTS);
    const [isLoading, setLoading] = useState(true);

    const [globalVendorList, setGlobalVendorList] = useState<GlobalVendorList>(new GlobalVendorList());
    const [googleACProviderList, setGoogleACProviderList] = useState<GoogleACProviderList>(new GoogleACProviderList());
    const [sirdataList, setSirdataList] = useState<SirdataListVersion>(new SirdataListVersion());

    const [cmpPartners, setCmpPartners] = useState<CmpPartner[]>([]);
    const [searchResult, setSearchResult] = useState<SearchResult<CmpPartner>>(new SearchResult(CmpPartner));

    useEffect(() => {
        (async () => {
            try {
                setGlobalVendorList(await session.restCmpList.getGlobalVendorList());
                setGoogleACProviderList(await session.restCmpList.getGoogleACProviderList());
                setSirdataList(await session.restCmpList.getSirdataList());
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("partner list", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [alert]);

    useEffect(() => {
        if (!network) return;

        (async () => {
            try {
                const iabVendors = globalVendorList.vendors.filter(({id}) => network.vendors.includes(id));
                const googleACProviders = googleACProviderList.providers.filter(({id}) => network.providers.includes(id));
                const sirdataVendors = sirdataList.vendors.filter(({id}) => network.sirdataVendors.includes(id));

                const cmpPartners: CmpPartner[] = [];
                cmpPartners.push(...iabVendors.map((it) => CmpPartner.create(it, CmpPartnerType.IAB)));
                cmpPartners.push(...googleACProviders.map((it) => CmpPartner.create(it, CmpPartnerType.Google)));
                cmpPartners.push(...sirdataVendors.map((it) => CmpPartner.create(it, CmpPartnerType.Sirdata)));
                setCmpPartners(cmpPartners);

                setSearchResult(SearchResult.buildFromList(CmpPartner, cmpPartners, 0, MODAL_PAGE_SIZE));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("network's IAB vendors", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [globalVendorList.vendors, googleACProviderList.providers, sirdataList.vendors, network, alert]);

    const handleChangePage = (page: number) => {
        setSearchResult(SearchResult.buildFromList(CmpPartner, cmpPartners, page - 1, MODAL_PAGE_SIZE));
    };

    return (
        <ModalNew onClose={() => onClose()} active={!!network}>
            <ModalContent>
                <ModalTitle>{network?.name}</ModalTitle>
                <Loadable loading={isLoading}>
                    <Table
                        columns={[
                            {width: 10, label: textCmpLists("network.field.vendor.type")},
                            {width: 15, label: textCmpLists("network.field.vendor.id"), styles: TableColumnStyle.ALIGN_CENTER},
                            {width: 75, label: textCmpLists("network.field.vendor.name")}
                        ]}
                        pagination={searchResult.getPagination(handleChangePage)}
                    >
                        {searchResult.elements.map((partner) =>
                            <TableRow key={partner.id}>
                                <TableColumn>{partner.type}</TableColumn>
                                <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{partner.id}</TableColumn>
                                <TableColumn>{partner.name}</TableColumn>
                            </TableRow>
                        )}
                    </Table>
                </Loadable>
            </ModalContent>
        </ModalNew>
    );
};

export default ModalNetwork;
