import {Action, Color, ContentBlock, ContentBlockAction, IconTooltip, Loadable, Table, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TranslationLibFile} from "@sirdata/ui-lib";
import React, {FunctionComponent, useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {SearchResult} from "../../api/interface/SearchResult";
import {Formatter} from "../../common/utils/Formatter";
import {PAGE_SIZE, TranslationPortalFile} from "../../utils/constants";
import {LabelPartner} from "./index";
import {PartnerPricing} from "../../api/model/partner/pricing/PartnerPricing";
import {PartnerPricingField} from "../../api/model/partner/pricing/PartnerPricingField";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {PartnerPricingSearchQuery} from "../../api/model/partner/pricing/PartnerPricingSearchQuery";
import {PartnerField} from "../../api/model/partner/PartnerField";
import ModalEditPartnerPricing from "../modal/pricings/ModalEditPartnerPricing";
import {RestrictedContent} from "../../common/component/widget";
import {Authorization} from "../../api/model/account/Authorization";
import useAlert from "../../utils/hooks/useAlert";
import ModalCreatePartnerPricing from "../modal/pricings/ModalCreatePartnerPricing";
import {sortItems} from "../../common/utils/helper";

type PartnerPricingsProps = {
    pricingId: number;
};

const PartnerPricings: FunctionComponent<PartnerPricingsProps> = ({pricingId}) => {
    const alert = useAlert();
    const {t: textPartners} = useTranslation(TranslationPortalFile.PARTNERS);
    const {t: textPricings} = useTranslation(TranslationPortalFile.PRICINGS);
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const [isLoading, setLoading] = useState(true);
    const [partnerPricings, setPartnerPricings] = useState<PartnerPricing[]>([]);
    const [searchResult, setSearchResult] = useState<SearchResult<PartnerPricing>>(new SearchResult(PartnerPricing));
    const [activeEditPartnerPricing, setActiveEditPartnerPricing] = useState<PartnerPricing>();
    const [isShowModalCreatePartnerPricing, setShowModalCreatePartnerPricing] = useState(false);

    const loadPartnerPricings = useCallback(async () => {
        try {
            setLoading(true);
            const searchQuery = new PartnerPricingSearchQuery();
            searchQuery.pricing_id = pricingId;
            let newPartnerPricings = await session.restPartnerPricing.list(searchQuery);
            newPartnerPricings = newPartnerPricings.map((it) => {
                const item = new PartnerPricing(it);
                item.start_date = item.start_date ? Formatter.convertUTCToDate(item.start_date).format(Formatter.API_DATE_FORMAT) : "";
                item.end_date = item.end_date ? Formatter.convertUTCToDate(item.end_date).format(Formatter.API_DATE_FORMAT) : "";
                return item;
            });
            newPartnerPricings = sortItems(newPartnerPricings, PartnerPricingField.START_DATE, true);
            setPartnerPricings(newPartnerPricings);
            setSearchResult(SearchResult.buildFromList(PartnerPricing, newPartnerPricings, 0, PAGE_SIZE));
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToLoad("partners", e.message);
            }
        } finally {
            setLoading(false);
        }
    }, [pricingId, alert]);

    useEffect(() => {
        if (!pricingId) return;
        (async function () {
            await loadPartnerPricings();
        })();
    }, [pricingId, loadPartnerPricings]);

    const handleChangePage = (page: number) => {
        setSearchResult(SearchResult.buildFromList(PartnerPricing, partnerPricings, page - 1, PAGE_SIZE));
    };

    const handleSave = async (partnerPricing: PartnerPricing) => {
        try {
            partnerPricing.start_date = partnerPricing.start_date ? Formatter.formatDate(partnerPricing.start_date, Formatter.API_DATE_FORMAT) : "";
            partnerPricing.end_date = partnerPricing.end_date ? Formatter.formatDate(partnerPricing.end_date, Formatter.API_DATE_FORMAT) : "";
            await session.restPartnerPricing.create(partnerPricing);

            const newPartnerPricings = partnerPricings.map((it) => (it.id === partnerPricing.id) ? partnerPricing : it);
            setPartnerPricings(newPartnerPricings);
            setSearchResult(SearchResult.buildFromList(PartnerPricing, newPartnerPricings, searchResult.page, PAGE_SIZE));
            alert.updateWithSuccess("rate card setting for partner");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("rate card setting for partner", e.message);
            }
        } finally {
            setActiveEditPartnerPricing(undefined);
        }
    };

    const handleCreate = async () => {
        await loadPartnerPricings();
        setShowModalCreatePartnerPricing(false);
    };

    return (
        <Loadable loading={isLoading}>
            <ContentBlock
                header={{
                    title: {label: textPricings("section.partners")},
                    actions: [
                        <RestrictedContent key="add_partner_pricing" allowedTo={Authorization.PRICINGS.update}>
                            <ContentBlockAction action={Action.ADD} onClick={() => setShowModalCreatePartnerPricing(true)}/>
                        </RestrictedContent>
                    ]
                }}
            >
                <Table
                    columns={[
                        {width: 10, label: textPricings(`field.partner.${PartnerPricingField.ID_PARTNER}`)},
                        {width: 50, label: textPartners(`field.${PartnerField.COMPANY}`)},
                        {width: 15, label: textPricings(`field.partner.${PartnerPricingField.START_DATE}`)},
                        {width: 15, label: textPricings(`field.partner.${PartnerPricingField.END_DATE}`)},
                        {width: 5, label: textPricings(`field.partner.${PartnerPricingField.DISCOUNT}`)},
                        {width: 5}
                    ]}
                    pagination={searchResult.getPagination(handleChangePage)}
                >
                    {searchResult.elements.map((item) =>
                        <TableRow key={item.id} onClick={() => window.open(item.partner.getRoute(), "_blank")}>
                            <TableColumn>
                                <LabelPartner partner={item.id_partner}/>
                            </TableColumn>
                            <TableColumn>
                                {item.partner.company}
                            </TableColumn>
                            <TableColumn>
                                {item.start_date ? Formatter.formatDate(item.start_date) : "-"}
                            </TableColumn>
                            <TableColumn>
                                {item.end_date ? Formatter.formatDate(item.end_date) : "-"}
                            </TableColumn>
                            <TableColumn>
                                {item.discount || 0}%
                            </TableColumn>
                            <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                                <IconTooltip
                                    icon={{name: "receipt_long", colorIcon: item.active ? Color.GREEN : Color.GREY_DARK}}
                                    text={textPricings(item.active ? "automatic_billing.enabled" : "automatic_billing.disabled")}
                                />
                                <RestrictedContent allowedTo={Authorization.PRICINGS.update}>
                                    <IconTooltip
                                        icon={Action.EDIT.icon}
                                        text={textCommon(Action.EDIT.labelKey)}
                                        onClick={() => setActiveEditPartnerPricing(item)}
                                    />
                                </RestrictedContent>
                            </TableActionColumn>
                        </TableRow>
                    )}
                </Table>
            </ContentBlock>
            <ModalEditPartnerPricing
                initialValue={activeEditPartnerPricing}
                onSubmit={handleSave}
                onClose={() => setActiveEditPartnerPricing(undefined)}
            />
            <ModalCreatePartnerPricing
                pricingId={pricingId}
                onSubmit={handleCreate}
                onClose={() => setShowModalCreatePartnerPricing(false)}
                active={isShowModalCreatePartnerPricing}
            />
        </Loadable>
    );
};

export default PartnerPricings;
