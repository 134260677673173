import {
    Action,
    ButtonLink,
    ButtonLinkCancel,
    ButtonValidate,
    ElementList,
    ElementListSize,
    FieldBlock,
    FlexContentSpacing,
    FormLayoutRows,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    TranslationLibFile
} from "@sirdata/ui-lib";
import React, {FunctionComponent, useState} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {Partner} from "../../../api/model/partner/Partner";
import {SelectPartner, TagPartnerRow} from "../../snippet";

type ModalAddPartnerToOrganizationProps = {
    active: boolean;
    onSubmit: (partners: Partner[]) => void;
    onClose: () => void;
};

const ModalAddPartnerToOrganization: FunctionComponent<ModalAddPartnerToOrganizationProps> = ({active, onSubmit, onClose}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textOrganizations} = useTranslation(TranslationPortalFile.ORGANIZATIONS);
    const [selectedPartners, setSelectedPartners] = useState<Partner[]>([]);
    const [highlightedPartners, setHighlightedPartners] = useState<Partner[]>([]);

    const handleAddPartners = (partners: Partner[]) => {
        setSelectedPartners((prevState) => [...prevState, ...partners]);
        setHighlightedPartners(partners);
        setTimeout(() => {
            setHighlightedPartners([]);
        }, 1000);
    };

    const handleSubmit = () => {
        onSubmit(selectedPartners);
        setSelectedPartners([]);
    };

    const handleClose = () => {
        setSelectedPartners([]);
        onClose();
    };

    const hasEmptyField = () => !selectedPartners.length;

    return (
        <ModalNew onClose={handleClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textOrganizations("modal.add_partners.title")}/>
            </ModalHeader>
            <ModalContent>
                <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                    <SelectPartner
                        value={undefined}
                        onChange={(partner) => partner && handleAddPartners([partner])}
                        excludedPartners={selectedPartners}
                    />
                    <FieldBlock
                        label={textOrganizations("partners.selected", {count: selectedPartners.length})}
                        actions={!!selectedPartners.length ? <ButtonLink onClick={() => setSelectedPartners([])}>{textCommon(Action.REMOVE_ALL.labelKey)}</ButtonLink> : []}
                    >
                        <ElementList placeholder={textOrganizations("partners.list_placeholder")} size={ElementListSize.BIG}>
                            {selectedPartners.map((partner) =>
                                <TagPartnerRow
                                    key={partner.id}
                                    partner={partner}
                                    isHighlighted={highlightedPartners?.some(({id}) => partner.id === id)}
                                    onRemove={() => setSelectedPartners(selectedPartners.filter(({id}) => partner.id !== id))}
                                />
                            )}
                        </ElementList>
                    </FieldBlock>
                </FormLayoutRows>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={handleClose}/>
                <ButtonValidate onClick={handleSubmit} disabled={hasEmptyField()}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalAddPartnerToOrganization;
