import {FC} from "react";
import {useTranslation} from "react-i18next";
import {Color, IconProps, IconTooltip} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../../utils/constants";
import {SirdataListEntityState} from "../../../api/model/cmp/list/sirdata-list-v2/SirdataListEntityState";

type CmpSirdataListV2EntityStateProps = {
    state: SirdataListEntityState | undefined;
};

const CmpSirdataListV2EntityState: FC<CmpSirdataListV2EntityStateProps> = ({state}) => {
    const {t: textCmpSirdataList} = useTranslation(TranslationPortalFile.CMP_SIRDATA_LIST_V2);

    const getIcon = (): IconProps => {
        switch (state) {
            case SirdataListEntityState.ADDED:
                return {name: "add_box", colorIcon: Color.GREEN, outlined: true};
            case SirdataListEntityState.DELETED:
                return {name: "indeterminate_check_box", colorIcon: Color.RED, outlined: true};
            case SirdataListEntityState.RESTORED:
                return {name: "keyboard_capslock_badge", colorIcon: Color.ORANGE, outlined: true};
            case SirdataListEntityState.UPDATED:
                return {name: "dialogs", colorIcon: Color.ORANGE, outlined: true};
        }
        return {name: ""};
    };

    if (!state) {
        return <></>;
    }

    return (
        <IconTooltip icon={getIcon()} text={textCmpSirdataList(`state.${state}`)}/>
    );
};

export default CmpSirdataListV2EntityState;
