import {
    ButtonAdd,
    ButtonLinkCancel,
    ButtonSize,
    ButtonStyle,
    ButtonUpload,
    ButtonValidate,
    ElementList,
    ElementListSize,
    FieldBlock,
    FormLayoutColumns,
    FormLayoutRows,
    FormLayoutSeparator,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    Slider,
    Textarea
} from "@sirdata/ui-lib";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as XLSX from "xlsx";
import {session} from "../../../api/ApiSession";
import {CategoryGroup} from "../../../api/model/audience/category/CategoryGroup";
import {CategoryGroupLinkKeyword} from "../../../api/model/audience/category/CategoryGroupLinkKeyword";
import {CategoryGroupLinkKeywordField} from "../../../api/model/audience/category/CategoryGroupLinkKeywordField";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import {TagWithAction} from "../../snippet";

type ModalAddCategoryGroupLinksKeywordsProps = {
    active: boolean;
    categoryGroupId: number;
    existingKeywords: string[];
    onSubmit: (keywords: string[], threshold: number) => void;
    onClose: () => void;
};

const ModalAddCategoryGroupLinksKeywords: FunctionComponent<ModalAddCategoryGroupLinksKeywordsProps> = ({active, categoryGroupId, existingKeywords, onSubmit, onClose}) => {
    const {t: textKeywords} = useTranslation(TranslationPortalFile.CATEGORY_KEYWORDS);
    const alert = useAlert();
    const [isLoadingImport, setLoadingImport] = useState<boolean>(false);
    const [threshold, setThreshold] = useState<number>(CategoryGroupLinkKeyword.DEFAULT_THRESHOLD);
    const [keywordsAsString, setKeywordsAsString] = useState<string>("");
    const [currentKeywords, setCurrentKeywords] = useState<string[]>([]);
    const [currentCategoryGroup, setCurrentCategoryGroup] = useState<CategoryGroup>();

    useEffect(() => {
        if (!active) {
            setKeywordsAsString("");
            setCurrentKeywords([]);
        }
    }, [active]);

    useEffect(() => {
        (async () => {
            try {
                setCurrentCategoryGroup(await session.restCategoryGroup.get(categoryGroupId));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("category group", e.message);
                }
            }
        })();
    }, [categoryGroupId, alert]);

    const handleImportFile = (file?: File) => {
        setLoadingImport(true);
        try {
            if (file) {
                const reader = new FileReader();
                reader.onload = (evt) => {
                    const fileResult = evt.target?.result;
                    const workBook = XLSX.read(fileResult, {type: "binary"});
                    const workSheetName = workBook.SheetNames[0];
                    const workSheet = workBook.Sheets[workSheetName];
                    const dataString = XLSX.utils.sheet_to_csv(workSheet);

                    let newKeywords = dataString.split(/\r\n|\n|,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/).filter((it) => it.trim());
                    newKeywords = newKeywords.filter((keyword, index) => !existingKeywords.includes(keyword) && !currentKeywords.includes(keyword) && newKeywords.indexOf(keyword) === index);
                    setCurrentKeywords((prevState) => [...prevState, ...newKeywords]);
                };
                reader.readAsBinaryString(file);
            }
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failTo("import keywords", e.message);
            }
        } finally {
            setLoadingImport(false);
        }
    };

    const handleAddKeywords = () => {
        let newKeywords = keywordsAsString.split(",").map((it) => it.trim());
        newKeywords = newKeywords.filter((keyword, index) => !existingKeywords.includes(keyword) && !currentKeywords.includes(keyword) && newKeywords.indexOf(keyword) === index);
        setCurrentKeywords((prevState) => [...prevState, ...newKeywords]);
        setKeywordsAsString("");
    };

    const handleDelete = (keyword: string) => {
        setCurrentKeywords((prevState) => prevState.filter((it) => it !== keyword));
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textKeywords("modal.add_new_keywords")}/>
            </ModalHeader>
            <ModalContent>
                <FormLayoutRows>
                    <FieldBlock label={textKeywords("field.category_group")}>
                        {currentCategoryGroup?.fullName || "-"}
                    </FieldBlock>
                    <FieldBlock
                        label={textKeywords("field.enter_new_keywords")}
                        actions={
                            <ButtonAdd onClick={handleAddKeywords} disabled={!keywordsAsString.trim().length}/>
                        }
                    >
                        <Textarea
                            value={keywordsAsString}
                            rows={5}
                            onChange={(value) => setKeywordsAsString(value.replaceAll("\n", ","))}
                            placeholder={textKeywords("modal.placeholder_add_keywords")}
                        />
                    </FieldBlock>
                    <FormLayoutColumns>
                        <FieldBlock label={textKeywords("field.or_import_keywords")} content={{noFullWidth: true}}>
                            <ButtonUpload
                                label={textKeywords(isLoadingImport ? "import.button_loading" : "import.button")}
                                style={ButtonStyle.PRIMARY_MIDNIGHT}
                                size={ButtonSize.SMALL}
                                onChange={handleImportFile}
                                disabled={isLoadingImport}
                            />
                        </FieldBlock>
                        <FieldBlock label={textKeywords(`field.${CategoryGroupLinkKeywordField.THRESHOLD}`)}>
                            <Slider
                                value={threshold}
                                onChange={(value) => setThreshold(value)}
                                minValue={CategoryGroupLinkKeyword.MIN_THRESHOLD}
                                maxValue={CategoryGroupLinkKeyword.MAX_THRESHOLD}
                                step={CategoryGroupLinkKeyword.THRESHOLD_STEP}
                            />
                        </FieldBlock>
                    </FormLayoutColumns>
                    <FormLayoutSeparator/>
                    <FieldBlock label={textKeywords("field.keywords_to_add")}>
                        <ElementList size={ElementListSize.MEDIUM} inline>
                            {currentKeywords.map((keyword) =>
                                <TagWithAction active key={keyword} value={keyword} onDelete={() => handleDelete(keyword)}/>
                            )}
                        </ElementList>
                    </FieldBlock>
                </FormLayoutRows>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate disabled={isLoadingImport || !currentKeywords.length} onClick={() => onSubmit(currentKeywords, threshold)}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalAddCategoryGroupLinksKeywords;
