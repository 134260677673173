import {LayoutRows} from "@sirdata/ui-lib";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {session} from "../../api/ApiSession";
import {DataleaksAudit} from "../../api/model/dataleaks/audit/DataleaksAudit";
import {DataleaksQuestion} from "../../api/model/dataleaks/question/DataleaksQuestion";
import {DataleaksVersion} from "../../api/model/dataleaks/version/DataleaksVersion";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {DataleaksAuditReportContact, DataleaksAuditReportInformation, DataleaksAuditReportQuestions, DataleaksAuditReportResults, DataleaksAuditReportScoring, PrintViewer} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import {Locale} from "../../common/utils/Locale";
import {useSearchParams} from "react-router-dom-v5-compat";

function DataleaksAuditsReport() {
    const {t: textDataleaksAuditsReport} = useTranslation(TranslationPortalFile.DATALEAKS_AUDITS_REPORT);
    const {id} = useParams<{ id: string }>();
    const [searchParams] = useSearchParams();
    const alert = useAlert();
    const [audit, setAudit] = useState<DataleaksAudit>(new DataleaksAudit());
    const [version, setVersion] = useState<DataleaksVersion>(new DataleaksVersion());
    const [questions, setQuestions] = useState<DataleaksQuestion[]>([]);
    const [locale, setLocale] = useState<Locale>(Locale.getCurrentLocale());
    const [isLightVersion, setLightVersion] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                setAudit(await session.restDataleaksAudit.get(+id));

                const locale = Locale.values().find((it) => it === searchParams.get("locale")) || Locale.getCurrentLocale();
                setLocale(locale);

                setLightVersion(searchParams.has("light"));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("audit", e.message);
                }
            }
        })();
    }, [id, alert, searchParams]);

    useEffect(() => {
        if (!audit.id) return;
        (async () => {
            try {
                const version = await session.restDataleaksVersion.get(audit.version_id);
                setVersion(version);

                const questions = await session.restDataleaksQuestion.list();
                const versionQuestions: DataleaksQuestion[] = [];
                version.questions.forEach((it) => {
                    const question = questions.find((item) => item.id === it.question_id);
                    if (question) {
                        versionQuestions.push(question);
                    }
                });
                setQuestions(versionQuestions);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("version", e.message);
                }
            }
        })();
    }, [audit, alert]);

    return (
        <PrintViewer>
            <div className="dataleaks__report">
                <p className="dataleaks__report__warning">{textDataleaksAuditsReport("header_warning", {lng: locale})}</p>
                <LayoutRows>
                    <DataleaksAuditReportInformation audit={audit} locale={locale}/>
                    <DataleaksAuditReportScoring scoring={audit.scoring} locale={locale}/>
                    {
                        isLightVersion ?
                            <DataleaksAuditReportContact locale={locale}/> :
                            <>
                                <DataleaksAuditReportQuestions audit={audit} version={version} questions={questions} locale={locale}/>
                                <DataleaksAuditReportResults audit={audit} questions={questions} locale={locale}/>
                            </>
                    }
                </LayoutRows>
            </div>
        </PrintViewer>
    );
}

export default DataleaksAuditsReport;
