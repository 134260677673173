import {
    ButtonLinkCancel,
    ButtonValidate,
    FieldBlock,
    Form,
    FormLayoutRows,
    FormValidationType,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    ToggleSwitch
} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {CategorizerConfigEntry} from "../../../api/model/categorizer/CategorizerConfigEntry";
import {CategorizerConfigEntryField} from "../../../api/model/categorizer/CategorizerConfigEntryField";
import {CategorizerConfigType} from "../../../api/model/categorizer/CategorizerConfigType";
import {CategorizerConfigScraperApi} from "../../../api/model/categorizer/config/CategorizerConfigScraperApi";
import {CategorizerConfigScraperApiField} from "../../../api/model/categorizer/config/CategorizerConfigScraperApiField";
import {TranslationPortalFile} from "../../../utils/constants";
import {CategorizerConfigEntryInfo} from "../../snippet";
import {CategorizerConfigEntryParams} from "../../../api/model/categorizer/CategorizerConfigEntryParams";

type ModalAddCategorizerConfigScraperApiProps = {
    params: CategorizerConfigEntryParams;
    active: boolean;
    onSubmit: (entry: CategorizerConfigEntry<CategorizerConfigScraperApi>) => void;
    onClose: () => void;
};

const ModalAddCategorizerConfigScraperApi: FunctionComponent<ModalAddCategorizerConfigScraperApiProps> = ({params, active, onSubmit, onClose}) => {
    const {t: textCategorizer} = useTranslation(TranslationPortalFile.CATEGORIZER);
    const [entry, setEntry] = useState<CategorizerConfigEntry<CategorizerConfigScraperApi>>(CategorizerConfigEntry.forType(CategorizerConfigType.SCRAPER_API, params));
    const FORM_ID = "form-add-categorizer-config-scraper-api";

    useEffect(() => {
        if (active) {
            setEntry(CategorizerConfigEntry.forType(CategorizerConfigType.SCRAPER_API, params));
        }
    }, [active, params]);

    const handleChange = (field: CategorizerConfigEntryField, value: any) => {
        setEntry((prevState) => new CategorizerConfigEntry<CategorizerConfigScraperApi>({...prevState, [field]: value}));
    };

    const handleChangeConfig = (field: CategorizerConfigScraperApiField, value: any) => {
        handleChange(CategorizerConfigEntryField.CONFIG, new CategorizerConfigScraperApi({...entry.config, [field]: value}));
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmit(entry);
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textCategorizer("modal.add_new_configuration", {type: textCategorizer(`config_type.${CategorizerConfigType.SCRAPER_API.name}`)})}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <CategorizerConfigEntryInfo currentEntry={entry}/>
                        <FieldBlock
                            label={textCategorizer(`field.${CategorizerConfigEntryField.CONFIG}.${CategorizerConfigScraperApiField.PREMIUM}`)}
                            name={CategorizerConfigScraperApiField.PREMIUM}
                        >
                            <ToggleSwitch
                                checked={entry.config.premium}
                                onChange={(value) => handleChangeConfig(CategorizerConfigScraperApiField.PREMIUM, value)}
                            />
                        </FieldBlock>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalAddCategorizerConfigScraperApi;
