export enum NotificationField {
    ID = "id",
    TYPE = "type",
    USER_ID = "user_id",
    SERVICE = "service",
    NAME = "name",
    IS_BROADCAST = "is_broadcast",
    EXPIRES_AT = "expires_at",
    CLEAR_EXPIRES_AT = "clear_expires_at",
    FIRST_DISPATCH_DATE = "date",
    CREATED_AT = "created_at",
    UPDATED_AT = "updated_at"
}
