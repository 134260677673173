import {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    Alert,
    AlertSeverity,
    ButtonLinkCancel,
    ButtonLinkClose,
    ButtonValidate,
    FlexContent,
    FlexContentDirection,
    FlexContentSpacing,
    Form,
    FormLayoutButtons,
    FormLayoutRows,
    FormValidationType,
    Loader,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    Paragraph,
    ParagraphAlignment
} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../../utils/constants";
import {session} from "../../../api/ApiSession";
import useAlert from "../../../utils/hooks/useAlert";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {CmpSirdataListV2PurposesNewVersion, CmpSirdataListV2StacksNewVersion, CmpSirdataListV2VendorsNewVersion} from "../../snippet";
import {SirdataListVersion} from "../../../api/model/cmp/list/sirdata-list-v2/SirdataListVersion";
import {SirdataListLanguage} from "../../../api/model/cmp/list/sirdata-list-v2/SirdataListLanguage";
import {SirdataListVendor} from "../../../api/model/cmp/list/sirdata-list-v2/SirdataListVendor";
import {CmpConfigLanguage} from "../../../api/model/cmp/config/CmpConfigLanguage";

type ModalCreateCmpSirdataListV2VersionProps = {
    sirdataList: SirdataListVersion;
    listLanguages: SirdataListLanguage[];
    listVendors: SirdataListVendor[];
    active: boolean;
    onClose: (refresh?: boolean) => void;
};

enum ModalCreateCmpSirdataListV2VersionStep {
    VERIFYING,
    INVALID_DATA,
    VALID_DATA
}

const ModalCreateCmpSirdataListV2Version: FunctionComponent<ModalCreateCmpSirdataListV2VersionProps> = ({sirdataList, listLanguages, listVendors, active, onClose}) => {
    const alert = useAlert();
    const {t: textCmpSirdataList} = useTranslation(TranslationPortalFile.CMP_SIRDATA_LIST_V2);
    const {t: textCmpConfigurations} = useTranslation(TranslationPortalFile.CMP_CONFIGURATIONS);
    const [isSubmitting, setSubmitting] = useState(false);
    const [step, setStep] = useState<ModalCreateCmpSirdataListV2VersionStep>(ModalCreateCmpSirdataListV2VersionStep.VERIFYING);
    const [defaultListLanguage, setDefaultListLanguage] = useState<SirdataListLanguage>(new SirdataListLanguage());
    const [errors, setErrors] = useState<string[]>([]);
    const FORM_ID = "form-create-cmp-sirdata-list-v2-version";

    useEffect(() => {
        setStep(ModalCreateCmpSirdataListV2VersionStep.VERIFYING);

        if (active) {
            setErrors([]);
            setTimeout(() => {
                const errors: string[] = [];
                listVendors.filter((it) => !it.isValid()).forEach((vendor) => {
                    errors.push(textCmpSirdataList("invalid_vendor", {name: vendor.name, id: vendor.id}));
                });
                listLanguages.forEach((language) => {
                    if (!language.isValid() || (language.language !== defaultListLanguage.language && !language.isAlike(defaultListLanguage))) {
                        errors.push(textCmpSirdataList("invalid_language", {language: textCmpConfigurations(`language.${language.language}`)}));
                    }
                });

                if (errors.length) {
                    setErrors(errors);
                    setStep(ModalCreateCmpSirdataListV2VersionStep.INVALID_DATA);
                } else {
                    setStep(ModalCreateCmpSirdataListV2VersionStep.VALID_DATA);
                }
            }, 2000);
        }
    }, [active, listLanguages, listVendors, textCmpSirdataList, textCmpConfigurations, defaultListLanguage]);

    useEffect(() => {
        setDefaultListLanguage(listLanguages.find((it) => it.language === CmpConfigLanguage.ENGLISH.name) as SirdataListLanguage);
    }, [listLanguages]);

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            await session.restCmpSirdataListVersion.create();
            alert.createWithSuccess("version");
            onClose(true);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToCreate("version", e.message);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <ModalNew onClose={() => onClose(false)} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textCmpSirdataList("create_version")}/>
            </ModalHeader>
            {step === ModalCreateCmpSirdataListV2VersionStep.VERIFYING &&
                <ModalContent>
                    <FormLayoutRows>
                        <Loader/>
                        <Paragraph alignment={ParagraphAlignment.CENTER}>
                            {textCmpSirdataList("data_verifying")}
                        </Paragraph>
                    </FormLayoutRows>
                </ModalContent>
            }
            {step === ModalCreateCmpSirdataListV2VersionStep.INVALID_DATA &&
                <ModalContent>
                    <FormLayoutRows>
                        <Paragraph>
                            {textCmpSirdataList("invalid_data")}
                        </Paragraph>
                        <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.XSMALL}>
                            {errors.map((error) =>
                                <Alert key={error} text={error} severity={AlertSeverity.DANGER}/>
                            )}
                        </FlexContent>
                        <FormLayoutButtons>
                            <ButtonLinkClose onClick={() => onClose(false)}/>
                        </FormLayoutButtons>
                    </FormLayoutRows>
                </ModalContent>
            }
            {step === ModalCreateCmpSirdataListV2VersionStep.VALID_DATA &&
                <>
                    <ModalContent>
                        <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                            <FormLayoutRows>
                                <CmpSirdataListV2VendorsNewVersion
                                    sirdataList={sirdataList}
                                    listVendors={listVendors}
                                />
                                <CmpSirdataListV2StacksNewVersion
                                    sirdataList={sirdataList}
                                    listLanguage={defaultListLanguage}
                                />
                                <CmpSirdataListV2PurposesNewVersion
                                    sirdataList={sirdataList}
                                    listLanguage={defaultListLanguage}
                                />
                            </FormLayoutRows>
                        </Form>
                    </ModalContent>
                    <ModalActions>
                        <ButtonLinkCancel onClick={() => onClose(false)}/>
                        <ButtonValidate form={FORM_ID} loading={isSubmitting}/>
                    </ModalActions>
                </>
            }
        </ModalNew>
    );
};

export default ModalCreateCmpSirdataListV2Version;
