import {Action, ContentBlock, ContentBlockRow, ContentBlockRowElementRight, LayoutRows, Loadable, Pagination, RadioButtons, SearchError, SearchField, SearchToolbar} from "@sirdata/ui-lib";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {MediaSearchQuery} from "../../api/model/media/search/MediaSearchQuery";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import ModalCreateMedia from "../../component/modal/medias/ModalCreateMedia";
import {MainContentHeader, MainContentHeaderAction, MediaCard} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import {onClickLink} from "../../utils/helper";
import {Module} from "../../utils/Module";
import {SearchParamsField} from "../../utils/SearchParamsField";
import useSearch from "../../utils/hooks/useSearch";
import {Media} from "../../api/model/media/Media";
import {MediaField} from "../../api/model/media/MediaField";
import useAlert from "../../utils/hooks/useAlert";
import {MediaType} from "../../api/model/media/MediaType";

function Medias() {
    const {t: textMedias} = useTranslation(TranslationPortalFile.MEDIAS);
    const [isLoading, setLoading] = useState(false);
    const history = useHistory();
    const alert = useAlert();
    const {setSearchResult, ...search} = useSearch(Media, MediaSearchQuery);
    const [isShowModalCreateMedia, setShowModalCreateMedia] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const newSearchResult = await session.restMedia.search(search.searchQuery);
                setSearchResult(newSearchResult);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    if (e.statusCode !== HttpStatusCode.NOT_FOUND) {
                        alert.failToLoad("medias", e.message);
                    }
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [search.searchQuery, setSearchResult, alert]);

    const handleCreate = (refresh?: boolean) => {
        setShowModalCreateMedia(false);
        if (refresh) {
            search.changePage(1);
        }
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.MEDIAS}>
                <RestrictedContent allowedTo={Authorization.MEDIAS.update}>
                    <MainContentHeaderAction action={Action.NEW} onClick={() => setShowModalCreateMedia(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <ContentBlock>
                        <SearchToolbar searchBar={{placeholder: textMedias("search.placeholder"), value: search.searchQuery.query, onSubmit: search.changeQuery}} canHideFilters={true}>
                            <SearchField label={textMedias(`field.${MediaField.TYPE}`)}>
                                <RadioButtons
                                    id={SearchParamsField.TYPE}
                                    options={[
                                        {label: textMedias("type.all"), value: ""},
                                        ...MediaType.values().map(({name}) => ({label: textMedias(`type.${name}`), value: name}))
                                    ]}
                                    value={search.searchQuery.type}
                                    onChange={(value) => search.changeParam(SearchParamsField.TYPE, value)}
                                />
                            </SearchField>
                        </SearchToolbar>
                        <Loadable loading={isLoading}>
                            {!!search.searchResult.elements.length ?
                                <>
                                    <ContentBlockRow>
                                        <ContentBlockRowElementRight>
                                            <Pagination {...search.searchResult.getPagination(search.changePage)}/>
                                        </ContentBlockRowElementRight>
                                    </ContentBlockRow>
                                    <div className="media-cards">
                                        {search.searchResult.elements.map((media) =>
                                            <MediaCard
                                                key={media.id}
                                                media={media}
                                                onClick={(e) => onClickLink(e, media.getRoute(), history)}
                                            />
                                        )}
                                    </div>
                                    <ContentBlockRow>
                                        <ContentBlockRowElementRight>
                                            <Pagination {...search.searchResult.getPagination(search.changePage)}/>
                                        </ContentBlockRowElementRight>
                                    </ContentBlockRow>
                                </> :
                                <SearchError query={search.searchQuery.query}/>
                            }
                        </Loadable>
                    </ContentBlock>
                </LayoutRows>
                <ModalCreateMedia active={isShowModalCreateMedia} onClose={handleCreate}/>
            </MainContent>
        </Wrapper>
    );
}

export default Medias;
