import {
    ButtonLinkCancel,
    ButtonValidate,
    FieldBlock,
    Form,
    FormLayoutRows,
    FormValidationType,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    RadioButtons
} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {CategorizerConfigEntry} from "../../../api/model/categorizer/CategorizerConfigEntry";
import {CategorizerConfigEntryField} from "../../../api/model/categorizer/CategorizerConfigEntryField";
import {CategorizerConfigType} from "../../../api/model/categorizer/CategorizerConfigType";
import {CategorizerConfigPrivacy} from "../../../api/model/categorizer/config/CategorizerConfigPrivacy";
import {CategorizerConfigPrivacyField} from "../../../api/model/categorizer/config/CategorizerConfigPrivacyField";
import {CategorizerConfigPrivacyMode} from "../../../api/model/categorizer/config/CategorizerConfigPrivacyMode";
import {TranslationPortalFile} from "../../../utils/constants";
import {CategorizerConfigEntryInfo} from "../../snippet";

type ModalEditCategorizerConfigPrivacyProps = {
    initEntry: CategorizerConfigEntry<CategorizerConfigPrivacy> | undefined;
    onSubmit: (entry: CategorizerConfigEntry<CategorizerConfigPrivacy>) => void;
    onClose: () => void;
};

const ModalEditCategorizerConfigPrivacy: FunctionComponent<ModalEditCategorizerConfigPrivacyProps> = ({initEntry, onSubmit, onClose}) => {
    const {t: textCategorizer} = useTranslation(TranslationPortalFile.CATEGORIZER);
    const [entry, setEntry] = useState<CategorizerConfigEntry<CategorizerConfigPrivacy>>(CategorizerConfigEntry.forType(CategorizerConfigType.PRIVACY));
    const FORM_ID = "form-edit-categorizer-config-privacy";

    useEffect(() => {
        if (initEntry) {
            setEntry(initEntry);
        }
    }, [initEntry]);

    const handleChange = (field: CategorizerConfigEntryField, value: any) => {
        setEntry((prevState) => new CategorizerConfigEntry<CategorizerConfigPrivacy>({...prevState, [field]: value}));
    };

    const handleChangeConfig = (field: CategorizerConfigPrivacyField, value: any) => {
        handleChange(CategorizerConfigEntryField.CONFIG, new CategorizerConfigPrivacy({...entry.config, [field]: value}));
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmit(entry);
    };

    return (
        <ModalNew onClose={onClose} active={!!initEntry}>
            <ModalHeader>
                <ModalHeaderTitle title={textCategorizer("modal.edit_configuration", {type: textCategorizer(`config_type.${CategorizerConfigType.PRIVACY.name}`)})}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <CategorizerConfigEntryInfo currentEntry={entry}/>
                        <FieldBlock label={textCategorizer(`field.${CategorizerConfigEntryField.CONFIG}.${CategorizerConfigPrivacyField.SAFETY}`)}>
                            <RadioButtons
                                id={CategorizerConfigPrivacyField.SAFETY}
                                value={entry.config.safety}
                                options={CategorizerConfigPrivacyMode.values().map((it) => ({label: textCategorizer(`privacy_mode.${it.name}`), value: it.name}))}
                                onChange={(value) => handleChangeConfig(CategorizerConfigPrivacyField.SAFETY, value)}
                            />
                        </FieldBlock>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalEditCategorizerConfigPrivacy;
