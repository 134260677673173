import {FunctionComponent, useState} from "react";
import {useTranslation} from "react-i18next";
import {Action, Color, ContentBlock, Icon, IconTooltip, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TranslationLibFile} from "@sirdata/ui-lib";
import {SirdataListStack} from "../../../../api/model/cmp/list/sirdata-list-v2/SirdataListStack";
import {SirdataListPurpose} from "../../../../api/model/cmp/list/sirdata-list-v2/SirdataListPurpose";
import {TranslationPortalFile} from "../../../../utils/constants";
import {GlobalVendorList} from "../../../../api/model/cmp/list/global-vendor-list/GlobalVendorList";
import {GlobalVendorListField} from "../../../../api/model/cmp/list/global-vendor-list/GlobalVendorListField";
import {sortNumbers} from "../../../../common/utils/helper";
import {RestrictedContent} from "../../../../common/component/widget";
import {Authorization} from "../../../../api/model/account/Authorization";
import {detectChanges} from "../../../../common/utils/portal";

type CmpSirdataListV2StacksEditableRowProps = {
    stack: SirdataListStack;
    globalVendorList: GlobalVendorList;
    extraPurposes: SirdataListPurpose[];
    currentVersionStack?: SirdataListStack;
    onEdit: () => void;
};

const CmpSirdataListV2StacksEditableRow: FunctionComponent<CmpSirdataListV2StacksEditableRowProps> = ({stack, globalVendorList, extraPurposes, currentVersionStack, onEdit}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textCmpSirdataList} = useTranslation(TranslationPortalFile.CMP_SIRDATA_LIST_V2);
    const [isOpenDetails, setOpenDetails] = useState(false);

    return (
        <>
            <TableRow onClick={() => setOpenDetails((prevState) => !prevState)}>
                <TableColumn>
                    <Icon {...(isOpenDetails ? Action.COLLAPSE.icon : Action.EXPAND.icon)}/>
                </TableColumn>
                <TableColumn>{stack.id}</TableColumn>
                <TableColumn>
                    {stack.name}
                    {(!currentVersionStack || detectChanges(stack, currentVersionStack)) &&
                        <IconTooltip
                            icon={{name: "warning", colorIcon: Color.ORANGE}}
                            text={textCmpSirdataList("tooltip.warning")}
                        />
                    }
                </TableColumn>
                <TableColumn>{stack.description}</TableColumn>
                <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                    <RestrictedContent allowedTo={Authorization.CMP_SIRDATA_LIST.update}>
                        <IconTooltip
                            icon={Action.EDIT.icon}
                            text={textCommon(Action.EDIT.labelKey)}
                            onClick={onEdit}
                        />
                    </RestrictedContent>
                </TableActionColumn>
            </TableRow>
            {isOpenDetails &&
                <TableRow>
                    <TableColumn colSpan={5}>
                        <ContentBlock>
                            {(!!stack.purposes.length || !!stack.specialFeatures.length || !!stack.extraPurposes.length) &&
                                <>
                                    <span>{textCmpSirdataList("stack_purposes")}</span>
                                    <ul>
                                        {sortNumbers(stack.purposes).map((id) =>
                                            <li key={id}>
                                                <b>{textCmpSirdataList("purpose_with_id", {id: id})}</b>: {globalVendorList.getName(GlobalVendorListField.PURPOSES, id)}
                                            </li>
                                        )}
                                        {sortNumbers(stack.specialFeatures).map((id) =>
                                            <li key={id}>
                                                <b>{textCmpSirdataList("special_feature_with_id", {id: id})}</b>: {globalVendorList.getName(GlobalVendorListField.SPECIAL_FEATURES, id)}
                                            </li>
                                        )}
                                        {sortNumbers(stack.extraPurposes).map((id) =>
                                            <li key={id}>
                                                <b>{textCmpSirdataList("extra_purpose_with_id", {id: id})}</b>: {extraPurposes.find((it) => it.id === id)?.name || ""}
                                            </li>
                                        )}
                                    </ul>
                                </>
                            }
                            {!!stack.requiredPurposes.length &&
                                <>
                                    <span>{textCmpSirdataList("stack_required_purposes")}</span>
                                    <ul>
                                        {sortNumbers(stack.requiredPurposes).map((id) =>
                                            <li key={id}>
                                                <b>{textCmpSirdataList("purpose_with_id", {id: id})}</b>: {globalVendorList.getName(GlobalVendorListField.PURPOSES, id)}
                                            </li>
                                        )}
                                    </ul>
                                </>
                            }
                        </ContentBlock>
                    </TableColumn>
                </TableRow>
            }
        </>
    );
};

export default CmpSirdataListV2StacksEditableRow;
