import {Action, IconTooltip, InputTextNumber, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TranslationLibFile} from "@sirdata/ui-lib";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {PricingStep} from "../../../../api/model/partner/pricing/PricingStep";
import {PricingStepField} from "../../../../api/model/partner/pricing/PricingStepField";
import {Formatter} from "../../../../common/utils/Formatter";
import {Currency} from "../../../../api/model/currency/Currency";

type PricingStepRowProps = {
    step: PricingStep;
    onChange: (step: PricingStep) => void;
    onRemove: () => void;
}

const PricingStepRow: FunctionComponent<PricingStepRowProps> = ({step, onChange, onRemove}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const [pricingStep, setPricingStep] = useState<PricingStep>(step);

    useEffect(() => {
        setPricingStep(step);
    }, [step]);

    return (
        <TableRow>
            <TableColumn styles={TableColumnStyle.NO_VERTICAL_PADDING}>
                <InputTextNumber
                    value={step.limit}
                    minValue={0}
                    onChange={(value) => onChange(new PricingStep({...pricingStep, [PricingStepField.LIMIT]: value}))}
                    small
                />
            </TableColumn>
            <TableColumn styles={TableColumnStyle.NO_VERTICAL_PADDING}>
                <InputTextNumber
                    value={Formatter.convertCentsToUnits(step.fee)}
                    minValue={0}
                    onChange={(value) => onChange(new PricingStep({...pricingStep, [PricingStepField.FEE]: Formatter.convertUnitsToCents(value)}))}
                    small
                />
                <span>{Currency.EUR.symbol}</span>
            </TableColumn>
            <TableColumn styles={TableColumnStyle.NO_VERTICAL_PADDING}>
                <InputTextNumber
                    value={step.volume_cost_per_unit && Formatter.convertCentsToUnits(step.volume_cost_per_unit)}
                    minValue={0}
                    onChange={(value) => onChange(new PricingStep({...pricingStep, [PricingStepField.VOLUME_COST_PER_UNIT]: Formatter.convertUnitsToCents(value)}))}
                    small
                />
                <span>{Currency.EUR.symbol}</span>
            </TableColumn>
            <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                <IconTooltip
                    icon={Action.REMOVE.icon}
                    text={textCommon(Action.REMOVE.labelKey)}
                    onClick={onRemove}
                />
            </TableActionColumn>
        </TableRow>
    );
};

export default PricingStepRow;
