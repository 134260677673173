import {Action, Color, ContentBlock, Icon, IconTooltip, TableColumn, TableRow} from "@sirdata/ui-lib";
import React, {FC, useState} from "react";
import {useTranslation} from "react-i18next";
import {Purpose} from "../../../../api/model/cmp/list/global-vendor-list/Purpose";
import {SirdataVendor} from "../../../../api/model/cmp/list/sirdata-list/SirdataVendor";
import {Formatter} from "../../../../common/utils/Formatter";
import {TranslationPortalFile} from "../../../../utils/constants";

type CmpListsSirdataVendorsRowProps = {
    vendor: SirdataVendor;
    purposes: Purpose[];
};

const CmpListsSirdataVendorRow: FC<CmpListsSirdataVendorsRowProps> = ({vendor, purposes}) => {
    const {t: textCmpLists} = useTranslation(TranslationPortalFile.CMP_LISTS);
    const [isOpenDetails, setOpenDetails] = useState(false);

    const getPurposeName = (id: number) => {
        return purposes.find((it) => it.id === id)?.name || "";
    };

    return (
        <>
            <TableRow onClick={() => setOpenDetails((prevState) => !prevState)}>
                <TableColumn>
                    <Icon {...(isOpenDetails ? Action.COLLAPSE.icon : Action.EXPAND.icon)}/>
                </TableColumn>
                <TableColumn>{vendor.id}</TableColumn>
                <TableColumn>
                    <span>{vendor.name}</span>
                    {vendor.premium &&
                        <IconTooltip
                            icon={{name: "card_membership", outlined: true, colorIcon: Color.GREEN}}
                            text={textCmpLists("sirdata.vendors.tooltip.premium_vendor")}
                        />
                    }
                </TableColumn>
                <TableColumn>{vendor.policyUrl}</TableColumn>
                <TableColumn>{vendor.deletedDate ? Formatter.formatUTCDate(vendor.deletedDate, Formatter.DATETIME_FORMAT) : ""}</TableColumn>
            </TableRow>
            {isOpenDetails &&
                <TableRow>
                    <TableColumn colSpan={5}>
                        <ContentBlock>
                            {!!vendor.purposes.length &&
                                <>
                                    <span>{textCmpLists("sirdata.vendor_consent")}</span>
                                    <ul>
                                        {vendor.purposes.map((id) =>
                                            <li key={id}>
                                                <b>{textCmpLists("purpose_with_id", {id: id})}</b>: {getPurposeName(id)}
                                            </li>
                                        )}
                                    </ul>
                                </>
                            }
                            {!!vendor.legIntPurposes.length &&
                                <>
                                    <span>{textCmpLists("sirdata.vendor_legitimate_interest")}</span>
                                    <ul>
                                        {vendor.legIntPurposes.map((id) =>
                                            <li key={id}>
                                                <b>{textCmpLists("purpose_with_id", {id: id})}</b>: {getPurposeName(id)}
                                            </li>
                                        )}
                                    </ul>
                                </>
                            }
                        </ContentBlock>
                    </TableColumn>
                </TableRow>
            }
        </>
    );
};

export default CmpListsSirdataVendorRow;
