import {Action, Box, BoxProps, ContentBlock, FieldBlock, Form, FormLayoutColumns, FormLayoutRows, FormValidationType, InputDate, InputDateType, InputText, LayoutRows, Loadable, Textarea} from "@sirdata/ui-lib";
import React, {FormEvent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {CmpVersion} from "../../api/model/cmp/version/CmpVersion";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {MainContentHeader, MainContentHeaderAction} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import {Module} from "../../utils/Module";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {detectChanges} from "../../common/utils/portal";
import {CmpVersionField} from "../../api/model/cmp/version/CmpVersionField";
import useAlert from "../../utils/hooks/useAlert";

function CmpVersionsDetails() {
    const {t: textCmpVersions} = useTranslation(TranslationPortalFile.CMP_VERSIONS);
    const {id} = useParams<{ id: string }>();
    const alert = useAlert();

    const [isLoading, setLoading] = useState(true);
    const [isUnsavedChanges, setUnsavedChanges] = useState(false);

    const FORM_ID = "form-edit-cmp-version";
    const [cmpVersion, setCmpVersion] = useState<CmpVersion>(new CmpVersion());
    const [initCmpVersion, setInitCmpVersion] = useState<CmpVersion>(new CmpVersion());

    useEffect(() => {
        (async function () {
            try {
                const version = await session.restCmpVersion.get(id);
                setCmpVersion(version);
                setInitCmpVersion(new CmpVersion(version));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    if (e.statusCode === HttpStatusCode.NOT_FOUND) {
                        session.emit(SirdataApiEvent.eventNotFound);
                    } else {
                        alert.failToLoad("version", e.message);
                    }
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [id, alert]);

    useEffect(() => {
        setUnsavedChanges(detectChanges(cmpVersion, initCmpVersion));
    }, [cmpVersion, initCmpVersion]);

    const handleChangeText = (field: CmpVersionField.TEXT_FR | CmpVersionField.TEXT_EN, value: string) => {
        setCmpVersion((prevState) => new CmpVersion({...prevState, [field]: value.replaceAll("\n", "\n<br/>")}));
    };

    const handleSave = async (e: FormEvent) => {
        e.preventDefault();
        try {
            await session.restCmpVersion.update(cmpVersion);
            setInitCmpVersion(new CmpVersion(cmpVersion));
            alert.updateWithSuccess("version");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("version", e.message);
            }
        }
    };

    return (
        <Wrapper>
            <MainHeader preventUnsaved={isUnsavedChanges}/>
            <MainContentHeader module={Module.CMP_VERSIONS} element={initCmpVersion.toContentElement()} preventUnsaved={isUnsavedChanges}>
                <RestrictedContent allowedTo={Authorization.CMP_VERSIONS.update}>
                    <MainContentHeaderAction action={Action.SAVE} form={FORM_ID} disabled={!isUnsavedChanges}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <Form id={FORM_ID} onSubmit={handleSave} validationType={FormValidationType.CUSTOM}>
                    <LayoutRows>
                        <Loadable loading={isLoading}>
                            <ContentBlock>
                                <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                    <FormLayoutRows>
                                        <FormLayoutColumns columns={4}>
                                            <FormLayoutColumns columns={2}>
                                                <FieldBlock label={textCmpVersions(`field.${CmpVersionField.VERSION}`)}>
                                                    <InputText
                                                        value={cmpVersion.version}
                                                        onChange={() => {}}
                                                        disabled
                                                    />
                                                </FieldBlock>
                                                <FieldBlock label={textCmpVersions(`field.${CmpVersionField.DATE}`)} required>
                                                    <InputDate
                                                        type={InputDateType.DATE}
                                                        value={cmpVersion.date}
                                                        onChange={(value) => setCmpVersion((prevState) =>  new CmpVersion({...prevState, [CmpVersionField.DATE]: value}))}
                                                    />
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                        </FormLayoutColumns>
                                        <FormLayoutColumns>
                                            <FieldBlock label={textCmpVersions(`field.${CmpVersionField.TEXT_FR}`)} required>
                                                <Textarea
                                                    value={cmpVersion.text_fr.replaceAll("<br/>", "")}
                                                    rows={10}
                                                    onChange={(value) => handleChangeText(CmpVersionField.TEXT_FR, value)}
                                                />
                                            </FieldBlock>
                                            <FieldBlock label={textCmpVersions(`field.${CmpVersionField.TEXT_EN}`)} required>
                                                <Textarea
                                                    value={cmpVersion.text_en.replaceAll("<br/>", "")}
                                                    rows={10}
                                                    onChange={(value) => handleChangeText(CmpVersionField.TEXT_EN, value)}
                                                />
                                            </FieldBlock>
                                        </FormLayoutColumns>
                                    </FormLayoutRows>
                                </Box>
                            </ContentBlock>
                        </Loadable>
                    </LayoutRows>
                </Form>
            </MainContent>
        </Wrapper>
    );
}

export default CmpVersionsDetails;
