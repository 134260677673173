import {FC, useState} from "react";
import {useTranslation} from "react-i18next";
import {ContentBlock, SearchError, SearchToolbar, Table} from "@sirdata/ui-lib";
import {PAGE_SIZE, TranslationPortalFile} from "../../../utils/constants";
import {CmpSirdataListV2PurposesRow} from "../../../component/snippet";
import {SirdataListPurposeField} from "../../../api/model/cmp/list/sirdata-list-v2/SirdataListPurposeField";
import {SirdataListVersionField} from "../../../api/model/cmp/list/sirdata-list-v2/SirdataListVersionField";
import {SirdataListVersion} from "../../../api/model/cmp/list/sirdata-list-v2/SirdataListVersion";

type CmpSirdataListV2PurposesProps = {
    sirdataList: SirdataListVersion;
};

const CmpSirdataListV2Purposes: FC<CmpSirdataListV2PurposesProps> = ({sirdataList}) => {
    const {t: textCmpSirdataList} = useTranslation(TranslationPortalFile.CMP_SIRDATA_LIST_V2);
    const [query, setQuery] = useState("");

    const filteredPurposes = sirdataList.purposes.filter((purpose) => purpose.name.toLowerCase().includes(query.toLowerCase()));

    return (
        <ContentBlock header={{title: {label: textCmpSirdataList(`field.${SirdataListVersionField.PURPOSES}`)}}}>
            <SearchToolbar
                searchBar={{
                    value: query,
                    placeholder: textCmpSirdataList(`search.placeholder.${SirdataListVersionField.PURPOSES}`),
                    onSubmit: setQuery
                }}
            />
            {!!filteredPurposes.length ?
                <Table
                    columns={[
                        {width: 5, label: textCmpSirdataList(`field.purpose.${SirdataListPurposeField.ID}`)},
                        {width: 30, label: textCmpSirdataList(`field.purpose.${SirdataListPurposeField.NAME}`)},
                        {width: 65, label: textCmpSirdataList(`field.purpose.${SirdataListPurposeField.DESCRIPTION}`)}
                    ]}
                    pagination={PAGE_SIZE}
                >
                    {filteredPurposes.map((purpose) =>
                        <CmpSirdataListV2PurposesRow key={purpose.id} purpose={purpose}/>
                    )}
                </Table> :
                <SearchError query={query}/>
            }
        </ContentBlock>
    );
};

export default CmpSirdataListV2Purposes;
