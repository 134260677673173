import {
    ButtonLinkCancel,
    ButtonValidate,
    FieldBlock,
    Form,
    FormLayoutColumns,
    FormLayoutRows,
    FormValidationType,
    InputDomain,
    InputText,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    ToggleSwitch
} from "@sirdata/ui-lib";
import {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {PartnerDomain} from "../../../api/model/partner/domain/PartnerDomain";
import {PartnerDomainField} from "../../../api/model/partner/domain/PartnerDomainField";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import {SelectPartner, SelectService} from "../../snippet";

type ModalEditPartnerDomainProps = {
    domain?: PartnerDomain;
    onClose: (refresh: boolean) => void;
};

const ModalEditPartnerDomain: FunctionComponent<ModalEditPartnerDomainProps> = ({domain, onClose}) => {
    const {t: textPartnerDomains} = useTranslation(TranslationPortalFile.PARTNER_DOMAINS);
    const [partnerDomain, setPartnerDomain] = useState<PartnerDomain>(new PartnerDomain());
    const alert = useAlert();
    const FORM_ID = "form-edit-partner-domain";

    useEffect(() => {
        if (!!domain) {
            setPartnerDomain(new PartnerDomain(domain));
        }
    }, [domain]);

    const handleChange = (field: PartnerDomainField, value: any) => {
        setPartnerDomain((prevState) => new PartnerDomain({...prevState, [field]: value}));
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        try {
            await session.restPartnerDomain.update(partnerDomain);
            alert.updateWithSuccess("domain");
            onClose(true);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("domain", e.message);
            }
        }
    };

    return (
        <ModalNew active={!!domain} onClose={() => onClose(false)}>
            <ModalHeader>
                <ModalHeaderTitle title={textPartnerDomains("modal.title_edit")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FormLayoutColumns>
                            <FieldBlock label={textPartnerDomains(`field.${PartnerDomainField.DOMAIN_NAME}`)} required>
                                <InputDomain
                                    value={partnerDomain.domain_name}
                                    onChange={(value) => handleChange(PartnerDomainField.DOMAIN_NAME, value)}
                                    disabled={!!partnerDomain.id}
                                />
                            </FieldBlock>
                            <FieldBlock label={textPartnerDomains(`field.${PartnerDomainField.ID_PARTNER}`)} required>
                                <SelectPartner
                                    value={partnerDomain.id_partner}
                                    onChange={(partner) => handleChange(PartnerDomainField.ID_PARTNER, partner?.id || 0)}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FormLayoutColumns>
                            <FieldBlock label={textPartnerDomains(`field.${PartnerDomainField.TARGET_SERVICE}`)}>
                                <SelectService
                                    value={partnerDomain.target_service}
                                    services={PartnerDomain.targetServices()}
                                    onChange={(service) => handleChange(PartnerDomainField.TARGET_SERVICE, service?.name)}
                                    clearable
                                />
                            </FieldBlock>
                            <FieldBlock name={PartnerDomainField.AVAILABLE} label={textPartnerDomains(`field.${PartnerDomainField.AVAILABLE}`)}>
                                <ToggleSwitch
                                    checked={partnerDomain.available}
                                    onChange={(value) => handleChange(PartnerDomainField.AVAILABLE, value)}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FieldBlock label={textPartnerDomains(`field.${PartnerDomainField.OVERRIDE_TARGET_API_SERVICE_APPLICATION_NAME}`)}>
                            <InputText
                                value={partnerDomain.override_target_api_service_application_name}
                                onChange={(value) => handleChange(PartnerDomainField.OVERRIDE_TARGET_API_SERVICE_APPLICATION_NAME, value)}
                            />
                        </FieldBlock>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={() => onClose(false)}/>
                <ButtonValidate form={FORM_ID}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalEditPartnerDomain;
