import {
    ButtonLinkCancel,
    ButtonValidate,
    FieldBlock,
    Form,
    FormLayoutRows,
    FormValidationType,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    Textarea
} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {CategorizerConfigEntry} from "../../../api/model/categorizer/CategorizerConfigEntry";
import {CategorizerConfigEntryField} from "../../../api/model/categorizer/CategorizerConfigEntryField";
import {CategorizerConfigType} from "../../../api/model/categorizer/CategorizerConfigType";
import {CategorizerConfigLinks} from "../../../api/model/categorizer/config/CategorizerConfigLinks";
import {CategorizerConfigLinksField} from "../../../api/model/categorizer/config/CategorizerConfigLinksField";
import {TranslationPortalFile} from "../../../utils/constants";
import {CategorizerConfigEntryInfo} from "../../snippet";
import {CategorizerConfigEntryParams} from "../../../api/model/categorizer/CategorizerConfigEntryParams";

type ModalAddCategorizerConfigLinksProps = {
    params: CategorizerConfigEntryParams;
    active: boolean;
    onSubmit: (entry: CategorizerConfigEntry<CategorizerConfigLinks>) => void;
    onClose: () => void;
};

const ModalAddCategorizerConfigLinks: FunctionComponent<ModalAddCategorizerConfigLinksProps> = ({params, active, onSubmit, onClose}) => {
    const {t: textCategorizer} = useTranslation(TranslationPortalFile.CATEGORIZER);
    const [entry, setEntry] = useState<CategorizerConfigEntry<CategorizerConfigLinks>>(CategorizerConfigEntry.forType(CategorizerConfigType.LINKS, params));
    const FORM_ID = "form-add-categorizer-config-links";

    useEffect(() => {
        if (active) {
            setEntry(CategorizerConfigEntry.forType(CategorizerConfigType.LINKS, params));
        }
    }, [active, params]);

    const handleChange = (field: CategorizerConfigEntryField, value: any) => {
        setEntry((prevState) => new CategorizerConfigEntry<CategorizerConfigLinks>({...prevState, [field]: value}));
    };

    const handleChangeConfig = (field: CategorizerConfigLinksField, value: any) => {
        handleChange(CategorizerConfigEntryField.CONFIG, new CategorizerConfigLinks({...entry.config, [field]: value}));
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmit(entry);
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textCategorizer("modal.add_new_configuration", {type: textCategorizer(`config_type.${CategorizerConfigType.LINKS.name}`)})}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <CategorizerConfigEntryInfo currentEntry={entry}/>
                        <FieldBlock label={textCategorizer(`field.${CategorizerConfigEntryField.CONFIG}.${CategorizerConfigLinksField.CSS_QUERY}`)}>
                            <Textarea
                                value={entry.config.css_query}
                                rows={5}
                                onChange={(value) => handleChangeConfig(CategorizerConfigLinksField.CSS_QUERY, value)}
                            />
                        </FieldBlock>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalAddCategorizerConfigLinks;
