import {Action, ActionsMenu, InputCheckbox, Slider, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TranslationLibFile} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {Authorization} from "../../../../api/model/account/Authorization";
import {CategoryGroupLinkKeyword} from "../../../../api/model/audience/category/CategoryGroupLinkKeyword";
import {CategoryGroupLinkKeywordSource} from "../../../../api/model/audience/category/CategoryGroupLinkKeywordSource";
import {User} from "../../../../api/model/user/User";
import {RestrictedContent} from "../../../../common/component/widget";
import {Formatter} from "../../../../common/utils/Formatter";
import {LabelUser} from "../../index";

type CategoryGroupLinkKeywordRowProps = {
    link: CategoryGroupLinkKeyword;
    isSelected: boolean;
    owner?: User;
    onChangeThreshold: (link: CategoryGroupLinkKeyword) => void;
    onSelect: () => void;
    onRemove: () => void;
};

const CategoryGroupLinkKeywordRow: FunctionComponent<CategoryGroupLinkKeywordRowProps> = ({link, isSelected, owner, onChangeThreshold, onSelect, onRemove}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);

    const isUpdatable = (): boolean => {
        return link.source === CategoryGroupLinkKeywordSource.DIRECT;
    };

    return (
        <TableRow>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                {isUpdatable() &&
                    <InputCheckbox checked={isSelected} onChange={onSelect} disabled={!isUpdatable()}/>
                }
            </TableColumn>
            <TableColumn onClick={isUpdatable() ? onSelect : undefined}>{link.keyword}</TableColumn>
            <TableColumn styles={TableColumnStyle.NO_VERTICAL_PADDING}>
                <Slider
                    value={link.threshold}
                    minValue={CategoryGroupLinkKeyword.MIN_THRESHOLD}
                    maxValue={CategoryGroupLinkKeyword.MAX_THRESHOLD}
                    step={CategoryGroupLinkKeyword.THRESHOLD_STEP}
                    disabled={!isUpdatable()}
                    onChange={(value) => onChangeThreshold(new CategoryGroupLinkKeyword({...link, threshold: value}))}
                />
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                <LabelUser user={owner}/>
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                {Formatter.formatDate(link.last_update)}
            </TableColumn>
            <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                {isUpdatable() &&
                <RestrictedContent allowedTo={Authorization.KEYWORDS.update}>
                    <ActionsMenu
                        iconTooltip={{icon: Action.MORE.icon, text: textCommon(Action.MORE.labelKey)}}
                        items={[
                            {label: textCommon(Action.REMOVE.labelKey), onClick: onRemove, critical: true, separator: true}
                        ]}
                    />
                </RestrictedContent>
                }
            </TableActionColumn>
        </TableRow>
    );
};

export default CategoryGroupLinkKeywordRow;
