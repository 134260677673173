import {FC, useState} from "react";
import {useTranslation} from "react-i18next";
import {ContentBlock, SearchError, SearchToolbar, Table} from "@sirdata/ui-lib";
import {PAGE_SIZE, TranslationPortalFile} from "../../../utils/constants";
import {CmpSirdataListV2StacksRow} from "../../../component/snippet";
import {GlobalVendorList} from "../../../api/model/cmp/list/global-vendor-list/GlobalVendorList";
import {SirdataListStackField} from "../../../api/model/cmp/list/sirdata-list-v2/SirdataListStackField";
import {SirdataListVersionField} from "../../../api/model/cmp/list/sirdata-list-v2/SirdataListVersionField";
import {SirdataListVersion} from "../../../api/model/cmp/list/sirdata-list-v2/SirdataListVersion";

type CmpSirdataListV2StacksProps = {
    sirdataList: SirdataListVersion;
    globalVendorList: GlobalVendorList;
};

const CmpSirdataListV2Stacks: FC<CmpSirdataListV2StacksProps> = ({sirdataList, globalVendorList}) => {
    const {t: textCmpSirdataList} = useTranslation(TranslationPortalFile.CMP_SIRDATA_LIST_V2);
    const [query, setQuery] = useState("");

    const filteredStacks = sirdataList.stacks.filter((stack) => stack.name.toLowerCase().includes(query.toLowerCase()));

    return (
        <ContentBlock header={{title: {label: textCmpSirdataList(`field.${SirdataListVersionField.STACKS}`)}}}>
            <SearchToolbar
                searchBar={{
                    value: query,
                    placeholder: textCmpSirdataList(`search.placeholder.${SirdataListVersionField.STACKS}`),
                    onSubmit: setQuery
                }}
            />
            {!!filteredStacks.length ?
                <Table
                    columns={[
                        {width: 5},
                        {width: 5, label: textCmpSirdataList(`field.stack.${SirdataListStackField.ID}`)},
                        {width: 30, label: textCmpSirdataList(`field.stack.${SirdataListStackField.NAME}`)},
                        {width: 60, label: textCmpSirdataList(`field.stack.${SirdataListStackField.DESCRIPTION}`)}
                    ]}
                    pagination={PAGE_SIZE}
                >
                    {filteredStacks.map((stack) =>
                        <CmpSirdataListV2StacksRow
                            key={stack.id}
                            stack={stack}
                            globalVendorList={globalVendorList}
                            extraPurposes={sirdataList.purposes}
                        />
                    )}
                </Table> :
                <SearchError query={query}/>
            }
        </ContentBlock>
    );
};

export default CmpSirdataListV2Stacks;
