import React, {FunctionComponent, ReactElement, useState} from "react";
import {Module} from "../../utils/Module";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {TranslationPortalFile} from "../../utils/constants";
import {Action, Box, BoxRadius, BoxSpacing, ButtonSize, ButtonStyle, Icon, IconTooltip, ModalConfirmMessage, TranslationLibFile, TranslationLibKey} from "@sirdata/ui-lib";
import {Formatter} from "../../common/utils/Formatter";
import {onClickLink} from "../../utils/helper";
import {MainContentHeaderAction} from "./index";
import {ContentElement} from "../../api/interface/ContentElement";

type MainContentHeaderProps = {
    module: Module;
    element?: ContentElement;
    preventUnsaved?: boolean;
    children?: ReactElement<typeof MainContentHeaderAction> | ReactElement<typeof MainContentHeaderAction>[];
};

const MainContentHeader: FunctionComponent<MainContentHeaderProps> = ({module, element, preventUnsaved, children}) => {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const history = useHistory();
    const [goTo, setGoTo] = useState("");

    const handleLink = (e: any, path: string) => {
        if (preventUnsaved) {
            setGoTo(path);
        } else {
            onClickLink(e, path, history);
        }
    };

    const buildInfo = () => {
        if (!element || !(!!element.lastUpdated || !!element.createdAt)) {
            return <></>;
        }

        const infos = [];
        if (!!element.createdAt) {
            infos.push(t("info.created_at", {date: Formatter.formatDate(element.createdAt, Formatter.DATETIME_FORMAT)}));
        }
        if (!!element.lastUpdated && element.lastUpdated.getTime() !== element.createdAt?.getTime()) {
            infos.push(t("info.last_updated_at", {date: Formatter.formatDate(element.lastUpdated, Formatter.DATETIME_FORMAT)}));
        }

        return (
            <div className="tools__infos__item">
                <IconTooltip
                    icon={{name: "info", outlined: true}}
                    text={infos.join("<br/>")}
                />
            </div>
        );
    };

    return (
        <div className="main-content-header">
            <div className="main-content-header__content">
                <div className="main-content-header__breadcrumb">
                    <div className="breadcrumb__item">
                        <Box radius={BoxRadius.XS} spacing={BoxSpacing.XSMALL}>
                            <Icon {...module.icon} cssClass="breadcrumb__item__icon"/>
                        </Box>
                        {!!element ?
                            <span className="breadcrumb__item__link" onClick={(e) => handleLink(e, module.path)}>{t(`modules.${module.label}`)}</span> :
                            <span>{t(`modules.${module.label}`)}</span>
                        }
                    </div>
                    {!!element &&
                        <div className="breadcrumb__item">
                            <span>{element.id && `#${element.id} - `}{element.title}</span>
                            {!!element.url &&
                                <IconTooltip
                                    icon={Action.OPEN.icon}
                                    text={textCommon(Action.OPEN.labelKey)}
                                    cssClass="breadcrumb__item__action"
                                    onClick={() => window.open(element?.url, "_blank")}
                                />
                            }
                        </div>
                    }
                </div>
                <div className="main-content-header__tools">
                    {!!element &&
                        <div className="tools__infos">
                            {buildInfo()}
                            {!!element.status &&
                                <div className="tools__infos__item">
                                    {element.status.icon.name === "circle" ?
                                        <i className="tools__infos__item__bullet" style={{backgroundColor: element.status.icon.colorIcon}}/> :
                                        <Icon {...element.status.icon}/>
                                    }
                                    <span>{t(`status.${element.status.name}`)}</span>
                                </div>
                            }
                        </div>
                    }
                    <div className="tools__actions">
                        {children}
                    </div>
                </div>
            </div>
            <ModalConfirmMessage
                active={!!goTo}
                cancel={{onClick: () => setGoTo(""), size: ButtonSize.BIG, style: ButtonStyle.PRIMARY_MIDNIGHT, children: textCommon(Action.CANCEL.labelKey)}}
                confirm={{onClick: () => history.push(goTo), size: ButtonSize.BIG, style: ButtonStyle.DEFAULT_MIDNIGHT, children: textCommon(Action.LEAVE.labelKey)}}
                message={textCommon(TranslationLibKey.MESSAGE_WARNING_UNSAVED_CHANGES)}
            />
        </div>
    );
};

export default MainContentHeader;
