import React, {FunctionComponent} from "react";
import {Route, RouteProps} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import {Module} from "../../utils/Module";

interface PrivateModuleProps extends RouteProps {
    component: FunctionComponent;
    detailsComponent?: FunctionComponent;
}

class PrivateModule extends Route<PrivateModuleProps> {
    render() {
        const {path, authorization, component, detailsComponent}: any = this.props;
        const module = new Module("", path, {name: ""}, authorization);

        return (
            <>
                <PrivateRoute exact path={module.path} component={component} requiredAuthorization={module.authorization?.name}/>
                {!!detailsComponent &&
                    <PrivateRoute exact path={module.pathWithId} component={detailsComponent} requiredAuthorization={module.authorization?.name}/>
                }
            </>
        );
    };
}

export default PrivateModule;
