import {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Action, ContentBlock, ContentBlockAction, SearchError, SearchToolbar, Table} from "@sirdata/ui-lib";
import {PAGE_SIZE, TranslationPortalFile} from "../../../utils/constants";
import {RestrictedContent} from "../../../common/component/widget";
import {Authorization} from "../../../api/model/account/Authorization";
import {sortItems} from "../../../common/utils/helper";
import {CmpSirdataListV2StacksEditableRow} from "../../../component/snippet";
import {GlobalVendorList} from "../../../api/model/cmp/list/global-vendor-list/GlobalVendorList";
import {SirdataListLanguage} from "../../../api/model/cmp/list/sirdata-list-v2/SirdataListLanguage";
import {SirdataListStack} from "../../../api/model/cmp/list/sirdata-list-v2/SirdataListStack";
import {SirdataListStackField} from "../../../api/model/cmp/list/sirdata-list-v2/SirdataListStackField";
import {SirdataListVersionField} from "../../../api/model/cmp/list/sirdata-list-v2/SirdataListVersionField";
import ModalCreateCmpSirdataListV2Stack from "../../modal/cmp-sirdata-list-v2/ModalCreateCmpSirdataListV2Stack";
import ModalEditCmpSirdataListV2Stack from "../../modal/cmp-sirdata-list-v2/ModalEditCmpSirdataListV2Stack";
import {SirdataListVersion} from "../../../api/model/cmp/list/sirdata-list-v2/SirdataListVersion";
import ModalTranslateCmpSirdataListV2Stacks from "../../modal/cmp-sirdata-list-v2/ModalTranslateCmpSirdataListV2Stacks";

type CmpSirdataListV2StacksEditableProps = {
    sirdataList: SirdataListVersion;
    globalVendorList: GlobalVendorList;
    listLanguage: SirdataListLanguage;
    onChange: () => void;
};

const CmpSirdataListV2StacksEditable: FC<CmpSirdataListV2StacksEditableProps> = ({sirdataList, globalVendorList, listLanguage, onChange}) => {
    const {t: textCmpSirdataList} = useTranslation(TranslationPortalFile.CMP_SIRDATA_LIST_V2);
    const [query, setQuery] = useState("");
    const [filteredStacks, setFilteredStacks] = useState<SirdataListStack[]>([]);
    const [isShowModalCreateCmpSirdataListV2Stack, setShowModalCreateCmpSirdataListV2Stack] = useState(false);
    const [isShowModalTranslateCmpSirdataListV2Stacks, setShowModalTranslateCmpSirdataListV2Stacks] = useState(false);
    const [activeEditStack, setActiveEditStack] = useState<SirdataListStack>();

    useEffect(() => {
        const sortedStacks = sortItems(listLanguage.stacks, SirdataListStackField.ID, true);
        const filteredStacks = sortedStacks.filter((stack) => stack.name.toLowerCase().includes(query.toLowerCase()));
        setFilteredStacks(filteredStacks);
    }, [listLanguage.stacks, query]);

    return (
        <ContentBlock
            header={{
                title: {label: textCmpSirdataList(`field.${SirdataListVersionField.STACKS}`)},
                actions: (
                    <RestrictedContent allowedTo={Authorization.CMP_SIRDATA_LIST.update}>
                        <ContentBlockAction action={new Action(textCmpSirdataList("action.translate"), {name: "language"})} onClick={() => setShowModalTranslateCmpSirdataListV2Stacks(true)}/>
                        <ContentBlockAction action={Action.ADD} onClick={() => setShowModalCreateCmpSirdataListV2Stack(true)}/>
                    </RestrictedContent>
                )
            }}
        >
            <SearchToolbar
                searchBar={{
                    value: query,
                    placeholder: textCmpSirdataList(`search.placeholder.${SirdataListVersionField.STACKS}`),
                    onSubmit: setQuery
                }}
            />
            {!!filteredStacks.length ?
                <Table
                    columns={[
                        {width: 5},
                        {width: 5, label: textCmpSirdataList(`field.stack.${SirdataListStackField.ID}`)},
                        {width: 25, label: textCmpSirdataList(`field.stack.${SirdataListStackField.NAME}`)},
                        {width: 60, label: textCmpSirdataList(`field.stack.${SirdataListStackField.DESCRIPTION}`)},
                        {width: 5}
                    ]}
                    pagination={PAGE_SIZE}
                >
                    {filteredStacks.map((stack) =>
                        <CmpSirdataListV2StacksEditableRow
                            key={stack.id}
                            stack={stack}
                            globalVendorList={globalVendorList}
                            extraPurposes={listLanguage.purposes}
                            currentVersionStack={sirdataList.stacks.find((it) => it.id === stack.id)}
                            onEdit={() => setActiveEditStack(stack)}
                        />
                    )}
                </Table> :
                <SearchError query={query}/>
            }
            <ModalCreateCmpSirdataListV2Stack
                globalVendorList={globalVendorList}
                extraPurposes={listLanguage.purposes}
                active={isShowModalCreateCmpSirdataListV2Stack}
                onClose={async (refresh) => {
                    setShowModalCreateCmpSirdataListV2Stack(false);
                    refresh && onChange();
                }}
            />
            <ModalEditCmpSirdataListV2Stack
                initStack={activeEditStack}
                globalVendorList={globalVendorList}
                extraPurposes={listLanguage.purposes}
                onClose={async (refresh) => {
                    setActiveEditStack(undefined);
                    refresh && onChange();
                }}
            />
            <ModalTranslateCmpSirdataListV2Stacks
                active={isShowModalTranslateCmpSirdataListV2Stacks}
                onClose={async (refresh) => {
                    setShowModalTranslateCmpSirdataListV2Stacks(false);
                    refresh && onChange();
                }}
            />
        </ContentBlock>
    );
};

export default CmpSirdataListV2StacksEditable;
