import {ApiModel} from "../../../common/api/model/ApiModel";
import {ApiProfile} from "./ApiProfile";

export class AuthorizationDetails extends ApiModel {
    id: number = 0;
    authorization_set: string[] = [];
    private _profiles: ApiProfile[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    set profiles(list: ApiProfile[]) {
        let profiles = [];
        for (let a of list) {
            profiles.push(new ApiProfile(a));
        }
        this._profiles = profiles;
    }

    get profiles(): ApiProfile[] {
        return this._profiles;
    }

}
