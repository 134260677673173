import {FC, useState} from "react";
import {useTranslation} from "react-i18next";
import {ContentBlock, SearchError, SearchToolbar, Table} from "@sirdata/ui-lib";
import {PAGE_SIZE, TranslationPortalFile} from "../../../utils/constants";
import {CmpSirdataListV2VendorsRow} from "../../../component/snippet";
import {GlobalVendorList} from "../../../api/model/cmp/list/global-vendor-list/GlobalVendorList";
import {SirdataListVendorField} from "../../../api/model/cmp/list/sirdata-list-v2/SirdataListVendorField";
import {SirdataListVersionField} from "../../../api/model/cmp/list/sirdata-list-v2/SirdataListVersionField";
import {SirdataListVersion} from "../../../api/model/cmp/list/sirdata-list-v2/SirdataListVersion";

type CmpSirdataListV2VendorsProps = {
    sirdataList: SirdataListVersion;
    globalVendorList: GlobalVendorList;
};

const CmpSirdataListV2Vendors: FC<CmpSirdataListV2VendorsProps> = ({sirdataList, globalVendorList}) => {
    const {t: textCmpSirdataList} = useTranslation(TranslationPortalFile.CMP_SIRDATA_LIST_V2);
    const [query, setQuery] = useState("");

    const filteredVendors = sirdataList.vendors.filter((vendor) => vendor.name.toLowerCase().includes(query.toLowerCase()));

    return (
        <ContentBlock header={{title: {label: textCmpSirdataList(`field.${SirdataListVersionField.VENDORS}`)}}}>
            <SearchToolbar
                searchBar={{
                    value: query,
                    placeholder: textCmpSirdataList(`search.placeholder.${SirdataListVersionField.VENDORS}`),
                    onSubmit: setQuery
                }}
            />
            {!!filteredVendors.length ?
                <Table
                    columns={[
                        {width: 3},
                        {width: 5, label: textCmpSirdataList(`field.vendor.${SirdataListVendorField.ID}`)},
                        {width: 25, label: textCmpSirdataList(`field.vendor.${SirdataListVendorField.NAME}`)},
                        {width: 40, label: textCmpSirdataList(`field.vendor.${SirdataListVendorField.POLICY_URL}`)},
                        {width: 25, label: textCmpSirdataList(`field.vendor.${SirdataListVendorField.DELETED_DATE}`)}
                    ]}
                    pagination={PAGE_SIZE}
                >
                    {filteredVendors.map((vendor) =>
                        <CmpSirdataListV2VendorsRow
                            key={vendor.id}
                            vendor={vendor}
                            globalVendorList={globalVendorList}
                            extraPurposes={sirdataList.purposes}
                        />
                    )}
                </Table> :
                <SearchError query={query}/>
            }
        </ContentBlock>
    );
};

export default CmpSirdataListV2Vendors;
