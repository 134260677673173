import {FunctionComponent} from "react";
import {TableColumn, TableRow} from "@sirdata/ui-lib";
import {SirdataListPurpose} from "../../../../api/model/cmp/list/sirdata-list-v2/SirdataListPurpose";

type CmpSirdataListV2PurposesRowProps = {
    purpose: SirdataListPurpose;
};

const CmpSirdataListV2PurposesRow: FunctionComponent<CmpSirdataListV2PurposesRowProps> = ({purpose}) => {
    return (
        <TableRow>
            <TableColumn>{purpose.id}</TableColumn>
            <TableColumn>{purpose.name}</TableColumn>
            <TableColumn>{purpose.description}</TableColumn>
        </TableRow>
    );
};

export default CmpSirdataListV2PurposesRow;
