import {Rest} from "../../common/api/rest/Rest";
import {SirdataListLanguage} from "../model/cmp/list/sirdata-list-v2/SirdataListLanguage";
import {RestService} from "../interface/RestService";
import {SearchResult} from "../interface/SearchResult";
import {SirdataListStack} from "../model/cmp/list/sirdata-list-v2/SirdataListStack";
import {SirdataListPurpose} from "../model/cmp/list/sirdata-list-v2/SirdataListPurpose";

export class RestCmpSirdataListLanguage extends Rest implements RestService<SirdataListLanguage> {
    rootPath = "/console-api/cmp/list/sirdata/language";

    search(params: any): Promise<SearchResult<SirdataListLanguage>> {
        return new Promise<SearchResult<SirdataListLanguage>>(() => {}); // Not necessary
    }

    list(): Promise<SirdataListLanguage[]> {
        return this._client.get(`${this.rootPath}`, SirdataListLanguage) as Promise<SirdataListLanguage[]>;
    }

    create(listLanguage: SirdataListLanguage): Promise<SirdataListLanguage> {
        return this._client.post(`${this.rootPath}`, listLanguage, SirdataListLanguage) as Promise<SirdataListLanguage>;
    }

    get(language: string): Promise<SirdataListLanguage> {
        return this._client.get(`${this.rootPath}/${language}`, SirdataListLanguage) as Promise<SirdataListLanguage>;
    }

    update(listLanguage: SirdataListLanguage): Promise<SirdataListLanguage> {
        return this._client.put(`${this.rootPath}/${listLanguage.language}`, listLanguage, SirdataListLanguage) as Promise<SirdataListLanguage>;
    }

    upsertStack(language: string, stack: SirdataListStack): Promise<SirdataListLanguage> {
        return this._client.patch(`${this.rootPath}/${language}/stack`, stack, SirdataListLanguage) as Promise<SirdataListLanguage>;
    }

    upsertPurpose(language: string, purpose: SirdataListPurpose): Promise<SirdataListLanguage> {
        return this._client.patch(`${this.rootPath}/${language}/purpose`, purpose, SirdataListLanguage) as Promise<SirdataListLanguage>;
    }

    delete(language: string) {
        // Not necessary
    }
}
