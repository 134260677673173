import {Table} from "@sirdata/ui-lib";
import React, {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {CmpListsSirdataStackRow} from "../index";
import {GlobalVendorList} from "../../../api/model/cmp/list/global-vendor-list/GlobalVendorList";
import {SirdataStack} from "../../../api/model/cmp/list/sirdata-list/SirdataStack";
import {SirdataStackField} from "../../../api/model/cmp/list/sirdata-list/SirdataStackField";
import {PAGE_SIZE, TranslationPortalFile} from "../../../utils/constants";
import {SearchResult} from "../../../api/interface/SearchResult";

type SirdataListStacksProps = {
    stacks: SirdataStack[];
    globalVendorList: GlobalVendorList;
};

const SirdataListStacks: FC<SirdataListStacksProps> = ({stacks, globalVendorList}) => {
    const {t: textCmpLists} = useTranslation(TranslationPortalFile.CMP_LISTS);
    const [searchResult, setSearchResult] = useState<SearchResult<SirdataStack>>(new SearchResult(SirdataStack));

    useEffect(() => {
        setSearchResult(SearchResult.buildFromList(SirdataStack, stacks, 0, PAGE_SIZE));
    }, [stacks, globalVendorList]);

    const handleChangePage = (page: number) => {
        setSearchResult(SearchResult.buildFromList(SirdataStack, stacks, page - 1, PAGE_SIZE));
    };

    return (
        <Table
            columns={[
                {width: 5},
                {width: 5, label: textCmpLists(`sirdata.field.stack.${SirdataStackField.ID}`)},
                {width: 20, label: textCmpLists(`sirdata.field.stack.${SirdataStackField.NAME}`)},
                {width: 70, label: textCmpLists(`sirdata.field.stack.${SirdataStackField.DESCRIPTION}`)}
            ]}
            pagination={searchResult.getPagination(handleChangePage)}
        >
            {searchResult.elements.map((stack) =>
                <CmpListsSirdataStackRow
                    key={stack.id}
                    stack={stack}
                    globalVendorList={globalVendorList}
                />
            )}
        </Table>
    );
};

export default SirdataListStacks;
