import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {Action, IconTooltip, TranslationLibFile} from "@sirdata/ui-lib";
import {TagCategoryType} from "../index";
import {Category} from "../../../api/model/audience/category/Category";
import clsx from "clsx";
import {CategoryType} from "../../../api/model/audience/category/CategoryType";

type TagCategoryRowProps = {
    category: Category;
    isHighlighted?: boolean;
    onRemove?: () => void;
};

const TagCategoryRow: FunctionComponent<TagCategoryRowProps> = ({category, isHighlighted, onRemove}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);

    return (
        <div className={clsx("element-list__tag-row", {"element-list__tag-row--highlighted": isHighlighted})}>
            <span className="element-list__tag-row__infos">
                <TagCategoryType type={CategoryType.getByName(category.type)}/>
                <span>{category.name} ({category.id})</span>
            </span>
            {onRemove && <IconTooltip icon={Action.REMOVE.icon} text={textCommon(Action.REMOVE.labelKey)} onClick={onRemove}/>}
        </div>
    );
};

export default TagCategoryRow;
