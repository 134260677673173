import {ApiModel} from "../../../../common/api/model/ApiModel";

export class RevenueImportResponse extends ApiModel {
    count_deleted  : number = 0;
    count_imported : number = 0;
    total : number = 0;
    error : string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
