import {
    Action,
    AlertSeverity,
    Box,
    BoxProps,
    ButtonLink,
    ContentBlock,
    ElementList,
    ElementListSize,
    FieldBlock,
    FlexContentDirection,
    FlexContentSpacing,
    Form,
    FormLayoutColumns,
    FormLayoutRows,
    FormLayoutSeparator,
    FormValidationType,
    InputAutocomplete,
    InputDate,
    InputDateType,
    InputText,
    LayoutColumns,
    LayoutRows,
    Loadable,
    RadioButtons,
    Tabs,
    Textarea,
    TranslationLibFile
} from "@sirdata/ui-lib";
import React, {FormEvent, useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {DataSelection} from "../../api/model/audience/data-selection/DataSelection";
import {DataSelectionContent} from "../../api/model/audience/data-selection/DataSelectionContent";
import {DataSelectionContentField} from "../../api/model/audience/data-selection/DataSelectionContentField";
import {DATA_SELECTION_THEMES} from "../../api/model/audience/data-selection/DataSelectionTheme";
import {Media} from "../../api/model/media/Media";
import {PortalContentField} from "../../api/model/portal-content/PortalContentField";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {MainContentHeader, MainContentHeaderAction, MediaPicker, SelectStatus, TagWithAction} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import {slugify} from "../../common/utils/string";
import {Module} from "../../utils/Module";
import {Status} from "../../utils/Status";
import {FormLayoutMessage, MainHeader} from "../../common/component/snippet";
import ModalConfirmDelete from "../../component/modal/ModalConfirmDelete";
import {Locale} from "../../common/utils/Locale";
import {detectChanges} from "../../common/utils/portal";
import {PORTAL_CONTENT_STATUSES} from "../../api/model/portal-content/PortalContentStatus";
import useAlert from "../../utils/hooks/useAlert";
import {Formatter} from "../../common/utils/Formatter";
import {MediaType} from "../../api/model/media/MediaType";
import {TranslationCommonFile} from "../../common/utils/constants";
import useFormValidator from "../../utils/hooks/useFormValidator";

function AudienceDataSelectionsDetails() {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textMain} = useTranslation(TranslationCommonFile.MAIN);
    const {t: textPortalContent} = useTranslation(TranslationPortalFile.PORTAL_CONTENT);
    const {t: textAudienceDataSelections} = useTranslation(TranslationPortalFile.AUDIENCE_DATA_SELECTIONS);
    const {t: textTaxonomyTags} = useTranslation(TranslationPortalFile.TAXONOMY_TAGS);
    const {id} = useParams<{ id: string }>();
    const history = useHistory();
    const [isLoading, setLoading] = useState(true);
    const [isUnsavedChanges, setUnsavedChanges] = useState(false);
    const [isActiveDelete, setActiveDelete] = useState(false);

    const FORM_ID = "form-edit-data-selection";
    const [dataSelection, setDataSelection] = useState<DataSelection>(new DataSelection());
    const [initDataSelection, setInitDataSelection] = useState<DataSelection>(new DataSelection());
    const [tags, setTags] = useState<string[]>([]);
    const {setErrors, setShowErrors, ...formValidator} = useFormValidator<DataSelectionContentField>();

    const alert = useAlert();

    const loadTags = useCallback(async () => {
        try {
            setTags(await session.restList.getTaxonomyTags());
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToLoad("tags", e.message);
            }
        }
    }, [alert]);

    useEffect(() => {
        (async () => {
            try {
                const dataSelection = await session.restPortalContent.get(DataSelection, +id);
                if (dataSelection.status === Status.DELETED.name) {
                    session.emit(SirdataApiEvent.eventNotFound);
                }
                const newDataSelection = new DataSelection({
                    ...dataSelection,
                    start_date: dataSelection.start_date ? Formatter.convertUTCToDate(dataSelection.start_date).format(Formatter.API_DATETIME_FORMAT) : undefined,
                    end_date: dataSelection.end_date ? Formatter.convertUTCToDate(dataSelection.end_date).format(Formatter.API_DATETIME_FORMAT) : undefined
                });
                setDataSelection(newDataSelection);
                setInitDataSelection(new DataSelection(newDataSelection));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    if (e.statusCode === HttpStatusCode.NOT_FOUND) {
                        session.emit(SirdataApiEvent.eventNotFound);
                    } else {
                        alert.failToLoad("data selection", e.message);
                    }
                }
            } finally {
                setLoading(false);
                await loadTags();
            }
        })();
    }, [id, alert, loadTags]);

    useEffect(() => {
        setUnsavedChanges(detectChanges(dataSelection, initDataSelection));
    }, [dataSelection, initDataSelection]);

    useEffect(() => {
        setErrors((prevState) => ({
            ...prevState,
            [DataSelectionContentField.MAIN_IMAGE_ID]: !dataSelection.content.main_image_id,
            [DataSelectionContentField.PREVIEW_IMAGE_ID]: !dataSelection.content.preview_image_id
        }));
    }, [setErrors, dataSelection]);

    const handleChangeContent = (field: DataSelectionContentField, value: any) => {
        const newDataSelectionContent = new DataSelectionContent({...dataSelection.content, [field]: value});
        const newDataSelection = new DataSelection({...dataSelection});
        newDataSelection.content = newDataSelectionContent;
        setDataSelection(newDataSelection);
    };

    const handleSaveCapture = () => {
        setShowErrors(true);
    };

    const handleSave = async (e: FormEvent) => {
        e.preventDefault();
        try {
            const newDataSelection = new DataSelection({
                ...dataSelection,
                start_date: Formatter.convertDateToUTC(dataSelection.start_date),
                end_date: dataSelection.end_date ? Formatter.convertDateToUTC(dataSelection.end_date) : undefined
            });
            await session.restPortalContent.update(DataSelection, newDataSelection);
            setDataSelection(dataSelection);
            setInitDataSelection(new DataSelection(dataSelection));
            alert.updateWithSuccess("data selection");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("data selection", e.message);
            }
        } finally {
            setShowErrors(false);
        }
    };

    const handleDeleteDataSelection = async () => {
        if (!isActiveDelete) return;
        try {
            await session.restPortalContent.delete(dataSelection.id);
            history.push(Module.AUDIENCE_DATA_SELECTIONS.path);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToDelete("data selection", e.message);
            }
        } finally {
            setActiveDelete(false);
        }
    };

    const handleChangeImage = (name: DataSelectionContentField.MAIN_IMAGE_ID | DataSelectionContentField.PREVIEW_IMAGE_ID, media?: Media) => {
        const newDataSelection = new DataSelection({...dataSelection});
        newDataSelection.content[name] = media?.id;
        setDataSelection(newDataSelection);
    };

    const handleRemoveAllTags = () => {
        const newDataSelectionContent = new DataSelectionContent({...dataSelection.content, tags: []});
        const newDataSelection = new DataSelection({...dataSelection});
        newDataSelection.content = newDataSelectionContent;
        setDataSelection(newDataSelection);
    };

    const handleTag = (tag: string) => {
        let newTags = [...dataSelection.content.tags];
        if (newTags.find((it) => it === tag)) {
            newTags = newTags.filter((it) => it !== tag);
        } else {
            newTags = [...newTags, tag];
        }
        const newDataSelectionContent = new DataSelectionContent({...dataSelection.content, tags: newTags});
        const newDataSelection = new DataSelection({...dataSelection});
        newDataSelection.content = newDataSelectionContent;
        setDataSelection(newDataSelection);
    };

    return (
        <Wrapper>
            <MainHeader preventUnsaved={isUnsavedChanges}/>
            <MainContentHeader module={Module.AUDIENCE_DATA_SELECTIONS} element={initDataSelection.toContentElement()} preventUnsaved={isUnsavedChanges}>
                <RestrictedContent allowedTo={Authorization.AUDIENCE_DATA_SELECTIONS.update}>
                    <MainContentHeaderAction action={Action.SAVE} form={FORM_ID} disabled={!isUnsavedChanges}/>
                    <MainContentHeaderAction action={Action.DELETE} onClick={() => setActiveDelete(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <Form id={FORM_ID} onSubmitCapture={handleSaveCapture} onSubmit={handleSave} validationType={FormValidationType.CUSTOM}>
                    <LayoutRows>
                        <LayoutColumns>
                            <Loadable loading={isLoading}>
                                <ContentBlock header={{title: {label: textAudienceDataSelections("section.information")}}}>
                                    <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                        <FormLayoutRows>
                                            <FormLayoutColumns columns={4}>
                                                <FieldBlock label={textPortalContent(`field.${PortalContentField.STATUS}`)}>
                                                    <SelectStatus
                                                        value={dataSelection.status}
                                                        statuses={PORTAL_CONTENT_STATUSES.filter((it) => it.name !== Status.DELETED.name)}
                                                        onChange={(status) => setDataSelection((prevState) => new DataSelection({...prevState, [PortalContentField.STATUS]: status!.name}))}
                                                    />
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                            <FormLayoutColumns>
                                                <FieldBlock label={textPortalContent(`field.${PortalContentField.TITLE}`)} required>
                                                    <InputText
                                                        value={dataSelection.title}
                                                        onChange={(value) => setDataSelection((prevState) => new DataSelection({...prevState, [PortalContentField.TITLE]: value}))}
                                                    />
                                                </FieldBlock>
                                                <FieldBlock label={textPortalContent(`field.${PortalContentField.STRING_IDENTIFIER}`)} required>
                                                    <InputText
                                                        value={dataSelection.string_identifier}
                                                        onChange={(value) => setDataSelection((prevState) => new DataSelection({...prevState, [PortalContentField.STRING_IDENTIFIER]: slugify(value)}))}
                                                    />
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                            <FormLayoutColumns>
                                                <FieldBlock label={textPortalContent(`field.${PortalContentField.START_DATE}`)} required>
                                                    <InputDate
                                                        type={InputDateType.DATETIME_LOCAL}
                                                        value={dataSelection.start_date}
                                                        onChange={(value) => setDataSelection((prevState) => new DataSelection({...prevState, [PortalContentField.START_DATE]: value}))}
                                                    />
                                                </FieldBlock>
                                                <FieldBlock label={textPortalContent(`field.${PortalContentField.END_DATE}`)}>
                                                    <InputDate
                                                        type={InputDateType.DATETIME_LOCAL}
                                                        value={dataSelection.end_date}
                                                        onChange={(value) => setDataSelection((prevState) => new DataSelection({...prevState, [PortalContentField.END_DATE]: value}))}
                                                    />
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                            <FieldBlock label={textAudienceDataSelections(`field.${DataSelectionContentField.THEME}`)} required>
                                                <RadioButtons
                                                    id={DataSelectionContentField.THEME}
                                                    value={dataSelection.content.theme}
                                                    options={DATA_SELECTION_THEMES.map((it) => ({value: it, label: textAudienceDataSelections(`theme.${it}`)}))}
                                                    onChange={(value) => handleChangeContent(DataSelectionContentField.THEME, value)}
                                                />
                                            </FieldBlock>
                                            <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                                                <FieldBlock
                                                    label={textAudienceDataSelections(`field.${DataSelectionContentField.TAGS}`)}
                                                    actions={dataSelection.content.tags.length && <ButtonLink onClick={handleRemoveAllTags}>{textCommon(Action.REMOVE_ALL.labelKey)}</ButtonLink>}
                                                >
                                                    <InputAutocomplete
                                                        placeholder={textTaxonomyTags("search.placeholder")}
                                                        options={tags.filter((tag) => !dataSelection.content.tags.includes(tag)).map((tag) => ({label: tag, value: tag}))}
                                                        onChange={(option) => handleTag(option?.value as string)}
                                                    />
                                                </FieldBlock>
                                                {!!dataSelection.content.tags.length &&
                                                    <ElementList size={ElementListSize.SMALL} inline>
                                                        {dataSelection.content.tags.map((tag) =>
                                                            <TagWithAction key={tag} value={tag} onDelete={() => handleTag(tag)} active={true}/>
                                                        )}
                                                    </ElementList>
                                                }
                                            </FormLayoutRows>
                                        </FormLayoutRows>
                                    </Box>
                                </ContentBlock>
                            </Loadable>
                            <Loadable loading={isLoading}>
                                <ContentBlock header={{title: {label: textAudienceDataSelections("section.content")}}}>
                                    <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                        <FormLayoutRows>
                                            <FormLayoutColumns>
                                                <FieldBlock
                                                    label={textAudienceDataSelections(`field.${DataSelectionContentField.MAIN_IMAGE_ID}`)}
                                                    tooltip={textAudienceDataSelections(`tooltip.${DataSelectionContentField.MAIN_IMAGE_ID}`)}
                                                    content={{direction: FlexContentDirection.COLUMN}}
                                                    required
                                                >
                                                    <MediaPicker
                                                        allowedType={MediaType.IMAGE}
                                                        mediaId={dataSelection.content.main_image_id}
                                                        onSelect={(media) => handleChangeImage(DataSelectionContentField.MAIN_IMAGE_ID, media)}
                                                        onRemove={() => handleChangeImage(DataSelectionContentField.MAIN_IMAGE_ID)}
                                                    />
                                                    {formValidator.isError(DataSelectionContentField.MAIN_IMAGE_ID) &&
                                                        <FormLayoutMessage message={t("message.error.field_required")} severity={AlertSeverity.DANGER} small/>
                                                    }
                                                </FieldBlock>
                                                <FieldBlock
                                                    label={textAudienceDataSelections(`field.${DataSelectionContentField.PREVIEW_IMAGE_ID}`)}
                                                    tooltip={textAudienceDataSelections(`tooltip.${DataSelectionContentField.PREVIEW_IMAGE_ID}`)}
                                                    content={{direction: FlexContentDirection.COLUMN}}
                                                    required
                                                >
                                                    <MediaPicker
                                                        allowedType={MediaType.IMAGE}
                                                        mediaId={dataSelection.content.preview_image_id}
                                                        onSelect={(media) => handleChangeImage(DataSelectionContentField.PREVIEW_IMAGE_ID, media)}
                                                        onRemove={() => handleChangeImage(DataSelectionContentField.PREVIEW_IMAGE_ID)}
                                                    />
                                                    {formValidator.isError(DataSelectionContentField.PREVIEW_IMAGE_ID) &&
                                                        <FormLayoutMessage message={t("message.error.field_required")} severity={AlertSeverity.DANGER} small/>
                                                    }
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                            <FormLayoutSeparator/>
                                            <Tabs headers={Locale.values().map((locale) => ({label: textMain(`language.${locale}`)}))}>
                                                <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                                                    <FieldBlock label={textAudienceDataSelections("field.name")} required>
                                                        <InputText
                                                            value={dataSelection.content.name_fr}
                                                            onChange={(value) => handleChangeContent(DataSelectionContentField.NAME_FRENCH, value)}
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock label={textAudienceDataSelections("field.description")}>
                                                        <Textarea
                                                            value={dataSelection.content.description_fr}
                                                            rows={5}
                                                            onChange={(value) => handleChangeContent(DataSelectionContentField.DESCRIPTION_FRENCH, value)}
                                                            isExpandable
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutRows>
                                                <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                                                    <FieldBlock label={textAudienceDataSelections("field.name")} required>
                                                        <InputText
                                                            value={dataSelection.content.name_en}
                                                            onChange={(value) => handleChangeContent(DataSelectionContentField.NAME_ENGLISH, value)}
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock label={textAudienceDataSelections("field.description")}>
                                                        <Textarea
                                                            value={dataSelection.content.description_en}
                                                            rows={5}
                                                            onChange={(value) => handleChangeContent(DataSelectionContentField.DESCRIPTION_ENGLISH, value)}
                                                            isExpandable
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutRows>
                                            </Tabs>
                                        </FormLayoutRows>
                                    </Box>
                                </ContentBlock>
                            </Loadable>
                        </LayoutColumns>
                    </LayoutRows>
                </Form>
                <ModalConfirmDelete
                    active={isActiveDelete}
                    entity="data selection"
                    confirm={handleDeleteDataSelection}
                    cancel={() => setActiveDelete(false)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default AudienceDataSelectionsDetails;
