import {Action, Box, BoxProps, ContentBlock, FieldBlock, FlexContentLayout, FlexContentSpacing, FormLayoutColumns, FormLayoutRows, InputText, LayoutRows, Loadable, Tabs, TagStyle, Textarea} from "@sirdata/ui-lib";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {DataleaksQuestion} from "../../api/model/dataleaks/question/DataleaksQuestion";
import {DataleaksQuestionField} from "../../api/model/dataleaks/question/DataleaksQuestionField";
import {DataleaksQuestionTexts} from "../../api/model/dataleaks/question/DataleaksQuestionTexts";
import {DataleaksQuestionTextsField} from "../../api/model/dataleaks/question/DataleaksQuestionTextsField";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {TranslationCommonFile} from "../../common/utils/constants";
import {Locale} from "../../common/utils/Locale";
import {detectChanges} from "../../common/utils/portal";
import {MainContentHeader, MainContentHeaderAction, Tag} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import {DataleaksAnswer} from "../../utils/dataleaks/DataleaksAnswer";
import useAlert from "../../utils/hooks/useAlert";
import {Module} from "../../utils/Module";

function DataleaksQuestionsDetails() {
    const {t: textMain} = useTranslation(TranslationCommonFile.MAIN);
    const {t: textDataleaksAudits} = useTranslation(TranslationPortalFile.DATALEAKS_AUDITS);
    const {t: textDataleaksQuestions} = useTranslation(TranslationPortalFile.DATALEAKS_QUESTIONS);
    const {id} = useParams<{ id: string }>();
    const alert = useAlert();
    const [isLoading, setLoading] = useState(true);
    const [question, setQuestion] = useState<DataleaksQuestion>(new DataleaksQuestion());
    const [initQuestion, setInitQuestion] = useState<DataleaksQuestion>(new DataleaksQuestion());
    const [isUnsavedChanged, setUnsavedChanges] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const newQuestion = await session.restDataleaksQuestion.get(+id);
                setQuestion(newQuestion);
                setInitQuestion(new DataleaksQuestion(newQuestion));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    if (e.statusCode === HttpStatusCode.NOT_FOUND) {
                        session.emit(SirdataApiEvent.eventNotFound);
                    } else {
                        alert.failToLoad("question", e.message);
                    }
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [id, alert]);

    useEffect(() => {
        setUnsavedChanges(detectChanges(question, initQuestion));
    }, [question, initQuestion]);

    const handleSave = async () => {
        try {
            const updatedQuestion = await session.restDataleaksQuestion.update(question);
            setInitQuestion(updatedQuestion);
            alert.updateWithSuccess("question");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("question", e.message);
            }
        }
    };

    const handleChange = (field: DataleaksQuestionField, value: any) => {
        setQuestion((prevState) => new DataleaksQuestion({...prevState, [field]: value}));
    };

    const handleChangeText = (locale: Locale, field: DataleaksQuestionTextsField, value: string) => {
        const newTexts = new Map<Locale, DataleaksQuestionTexts>(question.texts);
        const newTextsForLocale = new DataleaksQuestionTexts({...newTexts.get(locale), [field]: value});
        newTexts.set(locale, newTextsForLocale);
        handleChange(DataleaksQuestionField.TEXTS, newTexts);
    };

    const getText = (locale: Locale, field: DataleaksQuestionTextsField): string => {
        const texts = question.texts.get(locale);
        if (!texts) return "";
        return texts[field];
    };

    const hasEmptyFields = () => {
        return Locale.values().some((locale) => {
            const textsForLocale = question.texts.get(locale);
            return !textsForLocale || !(textsForLocale.title && textsForLocale.short_title && textsForLocale.text_success && textsForLocale.text_failed);
        });
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.DATALEAKS_QUESTIONS} element={initQuestion.toContentElement()} preventUnsaved={isUnsavedChanged}>
                <RestrictedContent allowedTo={Authorization.DATALEAKS.update}>
                    <MainContentHeaderAction action={Action.SAVE} onClick={handleSave} disabled={!isUnsavedChanged || hasEmptyFields()}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <FormLayoutColumns layout={FlexContentLayout.TWO_COLUMNS_WIDE_RIGHT}>
                        <Loadable loading={isLoading}>
                            <ContentBlock header={{title: {label: textDataleaksQuestions("section.information")}}}>
                                <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                    <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                                        <FieldBlock label={textDataleaksQuestions(`field.${DataleaksQuestionField.REGULATIONS}`)} content={{noFullWidth: true}}>
                                            {question.regulations.map((regulation) =>
                                                <Tag key={regulation} label={textDataleaksQuestions(`regulation.${regulation}`)} style={TagStyle.PRIMARY_MIDNIGHT_LIGHT}/>
                                            )}
                                        </FieldBlock>
                                        <FieldBlock label={textDataleaksQuestions(`field.${DataleaksQuestionField.TRAIT}`)} content={{noFullWidth: true}}>
                                            <Tag label={textDataleaksQuestions(`trait.${question.trait}`)} style={TagStyle.PRIMARY_OCEAN}/>
                                        </FieldBlock>
                                        <FieldBlock label={textDataleaksQuestions("field.good_answer")} content={{noFullWidth: true}}>
                                            <Tag label={textDataleaksAudits(`answer.${question.reverse_answer ? DataleaksAnswer.NO : DataleaksAnswer.YES}`)} style={TagStyle.PRIMARY_CYAN_LIGHT}/>
                                        </FieldBlock>
                                    </FormLayoutRows>
                                </Box>
                            </ContentBlock>
                        </Loadable>
                        <Loadable loading={isLoading}>
                            <ContentBlock header={{title: {label: textDataleaksQuestions("section.texts")}}}>
                                <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                    <Tabs headers={Locale.values().map((locale) => ({label: textMain(`language.${locale}`)}))}>
                                        {Locale.values().map((locale) =>
                                            <FormLayoutRows key={`${locale}`} spacing={FlexContentSpacing.MEDIUM}>
                                                <FieldBlock
                                                    label={textDataleaksQuestions(`field.${DataleaksQuestionField.TEXTS}.${DataleaksQuestionTextsField.TITLE}`)}
                                                    required
                                                >
                                                    <InputText
                                                        value={getText(locale, DataleaksQuestionTextsField.TITLE)}
                                                        onChange={(value) => handleChangeText(locale, DataleaksQuestionTextsField.TITLE, value)}
                                                    />
                                                </FieldBlock>
                                                <FieldBlock
                                                    label={textDataleaksQuestions(`field.${DataleaksQuestionField.TEXTS}.${DataleaksQuestionTextsField.SHORT_TITLE}`)}
                                                    required
                                                >
                                                    <InputText
                                                        value={getText(locale, DataleaksQuestionTextsField.SHORT_TITLE)}
                                                        onChange={(value) => handleChangeText(locale, DataleaksQuestionTextsField.SHORT_TITLE, value)}
                                                    />
                                                </FieldBlock>
                                                <FieldBlock label={textDataleaksQuestions(`field.${DataleaksQuestionField.TEXTS}.${DataleaksQuestionTextsField.DESCRIPTION}`)}>
                                                    <Textarea
                                                        value={getText(locale, DataleaksQuestionTextsField.DESCRIPTION)}
                                                        rows={5}
                                                        onChange={(value) => handleChangeText(locale, DataleaksQuestionTextsField.DESCRIPTION, value)}
                                                    />
                                                </FieldBlock>
                                                <FieldBlock label={textDataleaksQuestions(`field.${DataleaksQuestionField.TEXTS}.${DataleaksQuestionTextsField.DESCRIPTION_LEGAL}`)}>
                                                    <Textarea
                                                        value={getText(locale, DataleaksQuestionTextsField.DESCRIPTION_LEGAL)}
                                                        rows={5}
                                                        onChange={(value) => handleChangeText(locale, DataleaksQuestionTextsField.DESCRIPTION_LEGAL, value)}
                                                    />
                                                </FieldBlock>
                                                <FieldBlock
                                                    label={textDataleaksQuestions(`field.${DataleaksQuestionField.TEXTS}.${DataleaksQuestionTextsField.TEXT_SUCCESS}`)}
                                                    required
                                                >
                                                    <Textarea
                                                        value={getText(locale, DataleaksQuestionTextsField.TEXT_SUCCESS)}
                                                        rows={5}
                                                        onChange={(value) => handleChangeText(locale, DataleaksQuestionTextsField.TEXT_SUCCESS, value)}
                                                    />
                                                </FieldBlock>
                                                <FieldBlock
                                                    label={textDataleaksQuestions(`field.${DataleaksQuestionField.TEXTS}.${DataleaksQuestionTextsField.TEXT_FAILED}`)}
                                                    required
                                                >
                                                    <Textarea
                                                        value={getText(locale, DataleaksQuestionTextsField.TEXT_FAILED)}
                                                        rows={5}
                                                        onChange={(value) => handleChangeText(locale, DataleaksQuestionTextsField.TEXT_FAILED, value)}
                                                    />
                                                </FieldBlock>
                                            </FormLayoutRows>
                                        )}
                                    </Tabs>
                                </Box>
                            </ContentBlock>
                        </Loadable>
                    </FormLayoutColumns>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default DataleaksQuestionsDetails;
