import {
    Action,
    Button,
    ButtonSize,
    ButtonStyle,
    FieldBlock,
    FormLayoutButtons,
    FormLayoutColumns,
    FormLayoutRows,
    InputTextNumber,
    Loader,
    ModalActions,
    ModalContent,
    ModalDescription,
    ModalDescriptionAlignment,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    Select,
    SelectAutocomplete,
    TranslationLibFile,
    UploadPanel
} from "@sirdata/ui-lib";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {Authorization} from "../../../api/model/account/Authorization";
import {Category} from "../../../api/model/audience/category/Category";
import {CategoryField} from "../../../api/model/audience/category/CategoryField";
import {CategoryType} from "../../../api/model/audience/category/CategoryType";
import {CategoryPanel} from "../../../api/model/audience/category/panel/CategoryPanel";
import {CategoryPanelField} from "../../../api/model/audience/category/panel/CategoryPanelField";
import {CategoryPanelType} from "../../../api/model/audience/category/panel/CategoryPanelType";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {sortItems} from "../../../common/utils/helper";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";

type ModalImportCategoryPanelProps = {
    active: boolean;
    onClose: () => void;
    category?: Category;
};

enum CategoryPanelImportStep {
    CONFIGURE_IMPORT,
    IMPORT_DONE,
}

const ModalImportCategoryPanel: FunctionComponent<ModalImportCategoryPanelProps> = ({active, onClose, category}) => {
    const alert = useAlert();
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textCategoryPanels} = useTranslation(TranslationPortalFile.CATEGORY_PANELS);
    const [isLoading, setLoading] = useState(true);
    const [isLoadingImport, setLoadingImport] = useState(false);
    const [isLoadingPopulate, setLoadingPopulate] = useState(false);
    const [isShowModalPopulate, setShowModalPopulate] = useState(false);

    const [activeStep, setActiveStep] = useState<CategoryPanelImportStep>(CategoryPanelImportStep.CONFIGURE_IMPORT);
    const [categories, setCategories] = useState<Category[]>([]);
    const [categoryPanel, setCategoryPanel] = useState<CategoryPanel>(new CategoryPanel());
    const [fileName, setFileName] = useState<string>("");

    const PARSING_HEADER_ERROR = "parse error on line 1";

    useEffect(() => {
        (async function () {
            try {
                if (!category) {
                    let categories = await session.getCategoriesByType(CategoryType.MODELING);
                    categories = sortItems(categories, CategoryField.NAME);
                    setCategories(categories);
                }
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("modeling categories", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [category, alert]);

    useEffect(() => {
        const newCategoryPanel = new CategoryPanel();
        if (category) {
            newCategoryPanel.category_id = category.id;
            newCategoryPanel.population = category.population || 0;
        }
        setCategoryPanel(newCategoryPanel);
        if (!active) {
            setFileName("");
            setActiveStep(CategoryPanelImportStep.CONFIGURE_IMPORT);
        }
    }, [active, category]);

    useEffect(() => {
        (async function () {
            try {
                if (activeStep !== CategoryPanelImportStep.IMPORT_DONE) return;
                const account = await session.getAccount();
                setShowModalPopulate(account.hasAuthorization(Authorization.MODELING_ROLLBACK.name));
            } catch (e) {
            }
        })();
    }, [activeStep]);

    const handleImport = async () => {
        setLoadingImport(true);

        try {
            await session.restCategory.updatePopulation(categoryPanel.category_id, categoryPanel.population);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("category's population", e.message);
                setLoadingImport(false);
                return;
            }
        }

        try {
            await session.restModeling.importPanel(categoryPanel);
            alert.actionWithSuccess("panel imported");
            setActiveStep(CategoryPanelImportStep.IMPORT_DONE);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                if (e.message.includes(PARSING_HEADER_ERROR)) {
                    alert.failTo("import panel", textCategoryPanels("message.malformed_header"));
                } else {
                    alert.failTo("import panel", e.message);
                }
                onClose();
            }
        } finally {
            setLoadingImport(false);
        }
    };

    const handlePopulate = async () => {
        setLoadingPopulate(true);

        try {
            await session.restModeling.populateCategories(categoryPanel.category_id);
            alert.actionWithSuccess("category populated");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failTo("populate category", e.message);
            }
        } finally {
            setLoadingPopulate(false);
            setShowModalPopulate(false);
        }
    };

    const handleRemoveFile = () => {
        setCategoryPanel((prevState) => new CategoryPanel({...prevState, file: undefined, contentType: ""}));
        setFileName("");
    };

    const handleImportFile = (file?: File) => {
        if (!file) return;
        setFileName(file.name);

        const newCategoryPanel = new CategoryPanel({...categoryPanel, contentType: file.type});
        const reader = new FileReader();
        reader.onload = ({target}) => {
            if (!!target?.result && target?.result instanceof ArrayBuffer) {
                newCategoryPanel.file = target.result;
                setCategoryPanel(newCategoryPanel);
            }
        };
        reader.readAsArrayBuffer(file);
    };

    return (
        <>
            {activeStep === CategoryPanelImportStep.CONFIGURE_IMPORT &&
                <ModalNew onClose={onClose} active={active} loading={isLoading}>
                    <ModalHeader>
                        <ModalHeaderTitle title={textCategoryPanels("title")}/>
                    </ModalHeader>
                    <ModalContent>
                        <FormLayoutRows>
                            {!category &&
                                <FieldBlock label={textCategoryPanels(`field.${CategoryPanelField.CATEGORY_ID}`)} required>
                                    <SelectAutocomplete
                                        value={categoryPanel.category_id}
                                        options={categories.map((category) => ({value: category.id, label: `${category.id} - ${category.name}`}))}
                                        onChange={(option) => setCategoryPanel((prevState) => new CategoryPanel({
                                            ...prevState,
                                            [CategoryPanelField.CATEGORY_ID]: option?.value
                                        }))}
                                    />
                                </FieldBlock>
                            }
                            <FormLayoutColumns>
                                <FieldBlock label={textCategoryPanels(`field.${CategoryPanelField.TYPE}`)} required>
                                    <Select
                                        value={categoryPanel.type}
                                        options={CategoryPanelType.values().map((it) => ({label: textCategoryPanels(`panel_name.${it.name}`), value: it.name}))}
                                        onChange={(option) => setCategoryPanel((prevState) => new CategoryPanel({...prevState, [CategoryPanelField.TYPE]: option?.value}))}
                                    />
                                </FieldBlock>
                                <FieldBlock label={textCategoryPanels(`field.${CategoryPanelField.POPULATION}`)} required>
                                    <InputTextNumber
                                        value={categoryPanel.population}
                                        minValue={0}
                                        onChange={(value) => setCategoryPanel((prevState) => new CategoryPanel({...prevState, [CategoryPanelField.POPULATION]: value}))}
                                    />
                                </FieldBlock>
                            </FormLayoutColumns>
                            <FieldBlock label={textCategoryPanels(`field.${CategoryPanelField.FILE}`)} required>
                                <UploadPanel
                                    fileName={fileName}
                                    placeholder={textCategoryPanels(`placeholder.${CategoryPanelField.FILE}`)}
                                    onChange={handleImportFile}
                                    onRemove={handleRemoveFile}
                                    acceptType={[".csv", ".xlsx", ".xls"]}
                                />
                            </FieldBlock>
                        </FormLayoutRows>
                    </ModalContent>
                    <ModalActions>
                        <Button
                            size={ButtonSize.BIG}
                            style={ButtonStyle.PRIMARY_GREEN}
                            onClick={handleImport}
                            disabled={categoryPanel.hasEmptyField()}
                            icon={Action.IMPORT.icon}
                            loading={isLoadingImport}
                        >
                            {textCommon(Action.IMPORT.labelKey)}
                        </Button>
                    </ModalActions>
                </ModalNew>
            }
            {activeStep === CategoryPanelImportStep.IMPORT_DONE &&
                <ModalNew active={isShowModalPopulate}>
                    <ModalContent>
                        {isLoadingPopulate ?
                            <FormLayoutRows>
                                <Loader/>
                                <ModalDescription alignment={ModalDescriptionAlignment.CENTER}>
                                    <span dangerouslySetInnerHTML={{__html: textCategoryPanels("message.populate_in_progress")}}/>
                                </ModalDescription>
                            </FormLayoutRows> :
                            <FormLayoutRows>
                                <ModalDescription alignment={ModalDescriptionAlignment.CENTER}>
                                    <span dangerouslySetInnerHTML={{__html: textCategoryPanels("message.populate_category")}}/>
                                </ModalDescription>
                                <FormLayoutButtons>
                                    <Button onClick={() => setShowModalPopulate(false)} size={ButtonSize.BIG} style={ButtonStyle.DEFAULT_MIDNIGHT}>
                                        {textCategoryPanels("action.do_not_populate")}
                                    </Button>
                                    <Button onClick={handlePopulate} size={ButtonSize.BIG} style={ButtonStyle.PRIMARY_MIDNIGHT}>{textCategoryPanels("action.populate_category")}</Button>
                                </FormLayoutButtons>
                            </FormLayoutRows>
                        }
                    </ModalContent>
                </ModalNew>
            }
        </>
    );
};

export default ModalImportCategoryPanel;
