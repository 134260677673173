import {Rest} from "../../common/api/rest/Rest";
import {RestService} from "../interface/RestService";
import {SearchResult} from "../interface/SearchResult";
import {SirdataListVersion} from "../model/cmp/list/sirdata-list-v2/SirdataListVersion";

export class RestCmpSirdataListVersion extends Rest implements RestService<SirdataListVersion> {
    rootPath = "/console-api/cmp/list/sirdata/version";

    search(params: any): Promise<SearchResult<SirdataListVersion>> {
        return new Promise<SearchResult<SirdataListVersion>>(() => {}); // Not necessary
    }

    list(): Promise<SirdataListVersion[]> {
        return this._client.get(`${this.rootPath}`, SirdataListVersion) as Promise<SirdataListVersion[]>;
    }

    create(): Promise<SirdataListVersion> {
        return this._client.post(`${this.rootPath}`, undefined, SirdataListVersion) as Promise<SirdataListVersion>;
    }

    get(version: number, language?: string): Promise<SirdataListVersion> {
        return this._client.get(`${this.rootPath}/${version}?lang=${language || ""}`, SirdataListVersion) as Promise<SirdataListVersion>;
    }

    getLatestVersion(language?: string): Promise<SirdataListVersion> {
        return this._client.get(`${this.rootPath}/LATEST?lang=${language || ""}`, SirdataListVersion) as Promise<SirdataListVersion>;
    }

    update(listVersion: SirdataListVersion): Promise<SirdataListVersion> {
        return new Promise<SirdataListVersion>(() => {}); // Not necessary
    }

    delete(version: number, language?: string) {
        // Not necessary
    }
}
