import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    Action,
    Alert,
    AlertSeverity,
    ButtonLink,
    ButtonLinkCancel,
    ButtonValidate,
    ElementList,
    ElementListSize,
    FieldBlock,
    FlexContentSpacing,
    FormFieldMessageType,
    FormLayoutRows,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    SearchIds,
    SearchIdsResult,
    TranslationLibFile
} from "@sirdata/ui-lib";
import {session} from "../../../api/ApiSession";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {TranslationPortalFile} from "../../../utils/constants";
import {Segment} from "../../../api/model/audience/segment/Segment";
import {SelectSegment, TagSegmentRow} from "../../snippet";
import useAlert from "../../../utils/hooks/useAlert";

type ModalSelectSegmentsProps = {
    active: boolean;
    preSelectedSegments: Segment[];
    title?: string;
    onSubmit: (values: Segment[]) => void;
    onClose: () => void;
};

const ModalSelectSegments: FunctionComponent<ModalSelectSegmentsProps> = ({active, preSelectedSegments, title, onSubmit, onClose}) => {
    const alert = useAlert();
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textSegments} = useTranslation(TranslationPortalFile.SEGMENTS);

    const [segments, setSegments] = useState<Segment[]>([]);
    const [selectedSegments, setSelectedSegments] = useState<Segment[]>([]);
    const [searchIdResult, setSearchIdResult] = useState<SearchIdsResult>();
    const [highlightedIds, setHighlightedIds] = useState<number[]>();

    useEffect(() => {
        (async () => {
            try {
                setSegments(await session.getSegments());
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("segments", e.message);
                }
            }
        })();
    }, [alert]);

    useEffect(() => {
        if (!active) return;
        setSelectedSegments([...preSelectedSegments]);
    }, [active, preSelectedSegments]);

    const handleAddSegments = (items: Segment[]) => {
        setSelectedSegments((prevSelected) => ([...items, ...prevSelected]));
        setHighlightedIds(items.map((it) => it.id));
        setTimeout(() => {
            setHighlightedIds([]);
        }, 1000);
    };

    const handleAddSegmentsByIds = (result: SearchIdsResult) => {
        handleAddSegments(result.matchedItems);
        setSearchIdResult(result);
        setTimeout(() => {
            setSearchIdResult(undefined);
        }, 10000);
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={title || textSegments("modal.select.title")}/>
            </ModalHeader>
            <ModalContent>
                <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                    <FormLayoutRows spacing={FlexContentSpacing.XSMALL}>
                        <SelectSegment
                            value={undefined}
                            onChange={(segment) => segment && handleAddSegments([segment])}
                            excludedSegments={selectedSegments}
                        />
                        <SearchIds items={segments} selectedItems={selectedSegments} onSubmit={handleAddSegmentsByIds}/>
                        {searchIdResult?.messages.map((message) =>
                            <Alert
                                key={message.message}
                                text={message.message}
                                severity={message.type === FormFieldMessageType.ERROR ? AlertSeverity.DANGER : message.type === FormFieldMessageType.WARNING ? AlertSeverity.WARNING : AlertSeverity.SUCCESS}
                            />
                        )}
                    </FormLayoutRows>
                    <FieldBlock
                        label={textSegments("modal.select.selected", {count: selectedSegments.length})}
                        actions={<ButtonLink onClick={() => setSelectedSegments([])}>{textCommon(Action.REMOVE_ALL.labelKey)}</ButtonLink>}
                    >
                        <ElementList placeholder={textSegments("modal.select.search_result")} size={ElementListSize.BIG}>
                            {selectedSegments.map((item) =>
                                <TagSegmentRow
                                    key={item.id}
                                    segment={item}
                                    isHighlighted={highlightedIds?.some((id) => item.id === id)}
                                    onRemove={() => setSelectedSegments((prevState) => prevState.filter((it) => it.id !== item.id))}
                                />
                            )}
                        </ElementList>
                    </FieldBlock>
                </FormLayoutRows>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate onClick={() => onSubmit(selectedSegments)}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalSelectSegments;
