import {ApiModel} from "../../../../common/api/model/ApiModel";

export class CmpWebinarInfo extends ApiModel {
    title: string = "";
    description: string = "";
    cover_url: string = "";
    youtube_id: string = "";
    announcement: string = "";
    is_main_language: boolean = false;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    hasEmptyField(): boolean {
        return !(!!this.title && !!this.description && !!this.youtube_id);
    }

    matchQuery(query: string): boolean {
        return `${this.title} ${this.description} ${this.announcement}`.toLowerCase().includes(query.toLowerCase());
    }
}
