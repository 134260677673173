import {
    Action,
    Box,
    BoxProps,
    ContentBlock,
    ElementList,
    ElementListSize,
    FieldBlock,
    FlexContentLayout,
    FlexContentSpacing,
    FormLayoutColumns,
    FormLayoutRows,
    FormLayoutSeparator,
    IconEdit,
    InputNumber,
    InputText,
    InputTextNumber,
    LayoutColumns,
    LayoutRows,
    Loadable,
    SelectAutocomplete,
    Slider,
    TagStyle,
    Textarea,
    ToggleSwitch
} from "@sirdata/ui-lib";
import copy from "copy-to-clipboard";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import "react-phone-number-input/style.css";
import {useHistory, useParams} from "react-router-dom";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {Segment} from "../../api/model/audience/segment/Segment";
import {SegmentDataType} from "../../api/model/audience/segment/SegmentDataType";
import {SegmentField} from "../../api/model/audience/segment/SegmentField";
import {CurrencyType} from "../../api/model/currency/Currency";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {ApiService} from "../../api/model/ApiService";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {Formatter} from "../../common/utils/Formatter";
import {detectChanges} from "../../common/utils/portal";
import ModalConfirmDelete from "../../component/modal/ModalConfirmDelete";
import ModalConfirmMessage from "../../component/modal/ModalConfirmMessage";
import ModalDuplicateSegment from "../../component/modal/segments/ModalDuplicateSegment";
import ModalEditTags from "../../component/modal/taxonomy-tags/ModalEditTags";
import {
    IframeBlock,
    MainContentHeader,
    MainContentHeaderAction,
    SegmentAreaCodesLinks,
    SegmentCategoryLinks,
    SegmentExternalLinks,
    SelectPartner,
    SelectStatus,
    Tag,
    TagSegmentDataType
} from "../../component/snippet";
import {HandleSaveRef} from "../../utils/audience/HandleSaveRef";
import {SegmentTypeDefaultValue} from "../../utils/audience/SegmentTypeDefaultValue";
import {TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import {Module} from "../../utils/Module";
import {Status} from "../../utils/Status";
import {Looker} from "../../utils/Looker";

export enum SegmentsDetailsSubModule {
    SEGMENT = "segment",
    LINKS_AREA_CODES = "links_area_codes",
    LINKS_CATEGORIES = "links_categories",
    LINKS_EXTERNAL = "links_external",
    TAGS = "tags"
}

const SEGMENT_DETAILS_SUB_MODULES: SegmentsDetailsSubModule[] = [
    SegmentsDetailsSubModule.SEGMENT,
    SegmentsDetailsSubModule.LINKS_AREA_CODES,
    SegmentsDetailsSubModule.LINKS_CATEGORIES,
    SegmentsDetailsSubModule.LINKS_EXTERNAL,
    SegmentsDetailsSubModule.TAGS
];

type SegmentsDetailsUnsavedChanges = {
    [SegmentsDetailsSubModule.SEGMENT]?: boolean;
    [SegmentsDetailsSubModule.LINKS_AREA_CODES]?: boolean;
    [SegmentsDetailsSubModule.LINKS_CATEGORIES]?: boolean;
    [SegmentsDetailsSubModule.LINKS_EXTERNAL]?: boolean;
    [SegmentsDetailsSubModule.TAGS]?: boolean;
};

function SegmentsDetails() {
    const {t: textSegments} = useTranslation(TranslationPortalFile.SEGMENTS);
    const {t: textTags} = useTranslation(TranslationPortalFile.TAXONOMY_TAGS);
    const {id} = useParams<{ id: string }>();
    const history = useHistory();
    const alert = useAlert();
    const linksAreaCodesRef = useRef<HandleSaveRef>(null);
    const linksCategoriesRef = useRef<HandleSaveRef>(null);
    const linksExternalRef = useRef<HandleSaveRef>(null);
    const searchTimeout: any = useRef<any>();

    const [isLoading, setLoading] = useState(true);
    const [isActiveDelete, setActiveDelete] = useState(false);
    const [isActiveDisable, setActiveDisable] = useState(false);
    const [isShowModalDuplicateSegment, setShowModalDuplicateSegment] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState<SegmentsDetailsUnsavedChanges>({});
    const [segment, setSegment] = useState<Segment>(new Segment());
    const [initSegment, setInitSegment] = useState<Segment>(new Segment());

    const [tiers, setTiers] = useState<string[][]>([[], [], [], [], []]);
    const [segmentTags, setSegmentTags] = useState<string[]>([]);
    const [initSegmentTags, setInitSegmentTags] = useState<string[]>([]);
    const [currencyRates, setCurrencyRates] = useState(new Map<string, number>());

    const [isShowModalEditTags, setShowModalEditTags] = useState(false);

    const maxValuePrice = 250;
    const customerPriceIndexMin = 0;
    const customerPriceIndexMax = 3;

    useEffect(() => {
        (async () => {
            try {
                const segment = await session.restSegment.get(+id);
                if (segment.hasScoring() && !segment.percentile) {
                    const dataType = SegmentDataType.getByName(segment.data_type);
                    const defaultValues = SegmentTypeDefaultValue.getByDataType(dataType);
                    if (!!defaultValues?.percentile) {
                        segment.percentile = `${defaultValues.percentile}`;
                    }
                }
                setSegment(segment);
                setInitSegment(new Segment(segment));

                let segmentTiers: string[][] = [[], [], [], [], []];
                segmentTiers[0] = await session.restSegment.getTiersOptions(1);
                if (!!segment.tiers1) {
                    segmentTiers[1] = await session.restSegment.getTiersOptions(2, segment.tiers1);
                }
                if (!!segment.tiers2) {
                    segmentTiers[2] = await session.restSegment.getTiersOptions(3, segment.tiers1, segment.tiers2);
                }
                if (!!segment.tiers3) {
                    segmentTiers[3] = await session.restSegment.getTiersOptions(4, segment.tiers1, segment.tiers2, segment.tiers3);
                }
                setTiers(segmentTiers);

                const currencies = await session.restCurrency.list();
                setCurrencyRates(() => {
                    const currencyRates = new Map<string, number>();
                    for (const currency of currencies) {
                        currencyRates.set(currency.currency, currency.exchange_rate);
                    }
                    return currencyRates;
                });

                const account = await session.getAccount();
                if (account.hasAuthorization(Authorization.TAGS.name)) {
                    const tags = await session.restSegmentLinkTag.getTags(segment);
                    tags.sort();
                    setSegmentTags(tags);
                    setInitSegmentTags([...tags]);
                }
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    if (e.statusCode === HttpStatusCode.NOT_FOUND) {
                        session.emit(SirdataApiEvent.eventNotFound);
                    } else {
                        alert.failToLoad("segment", e.message);
                    }
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [id, alert]);

    useEffect(() => {
        setUnsavedChanges((prevState) => ({
            ...prevState,
            segment: detectChanges(segment, initSegment),
            tags: segmentTags.join() !== initSegmentTags.join()
        }));
    }, [segment, initSegment, segmentTags, initSegmentTags]);

    const handleChangeStatus = (status?: Status) => {
        if (!isActiveDisable && status === Status.INACTIVE) {
            setActiveDisable(true);
            return;
        }
        setSegment((prevState) => new Segment({...prevState, [SegmentField.ACTIVE]: status === Status.ACTIVE}));
        setActiveDisable(false);
    };

    const handleChangePrice = (value: number) => {
        const newSegment = new Segment(segment);
        newSegment.price = value;
        const rateUsd = currencyRates.get(CurrencyType.USD);
        if (rateUsd) {
            let usdPrice = Formatter.roundNumber(newSegment.price * rateUsd, 5);
            newSegment.price_usd = Math.min(usdPrice, maxValuePrice);
        }
        const rateGbp = currencyRates.get(CurrencyType.GBP);
        if (rateGbp) {
            let gbpPrice = Formatter.roundNumber(newSegment.price * rateGbp, 5);
            newSegment.price_gbp = Math.min(gbpPrice, maxValuePrice);
        }
        setSegment(newSegment);
    };

    const handleChangeTier = async (field: SegmentField.TIERS1 | SegmentField.TIERS2 | SegmentField.TIERS3 | SegmentField.TIERS4 | SegmentField.TIERS5, value: string) => {
        const newSegment = new Segment({...segment});
        switch (field) {
            case SegmentField.TIERS1:
                newSegment.tiers1 = value;
                newSegment.tiers2 = "";
                newSegment.tiers3 = "";
                newSegment.tiers4 = "";
                newSegment.tiers5 = "";
                break;
            case SegmentField.TIERS2:
                newSegment.tiers2 = value;
                newSegment.tiers3 = "";
                newSegment.tiers4 = "";
                newSegment.tiers5 = "";
                break;
            case SegmentField.TIERS3:
                newSegment.tiers3 = value;
                newSegment.tiers4 = "";
                newSegment.tiers5 = "";
                break;
            case SegmentField.TIERS4:
                newSegment.tiers4 = value;
                newSegment.tiers5 = "";
                break;
        }
        setSegment(newSegment);

        if (value.length < 3) return;

        if (searchTimeout.current) {
            clearTimeout(searchTimeout.current);
            searchTimeout.current = undefined;
        }
        searchTimeout.current = setTimeout(async () => {
            try {
                let segmentTiers = [...tiers];
                switch (field) {
                    case SegmentField.TIERS1:
                        segmentTiers[1] = await session.restSegment.getTiersOptions(2, value);
                        break;
                    case SegmentField.TIERS2:
                        segmentTiers[2] = await session.restSegment.getTiersOptions(3, segment.tiers1, value);
                        break;
                    case SegmentField.TIERS3:
                        segmentTiers[3] = await session.restSegment.getTiersOptions(4, segment.tiers1, segment.tiers2, value);
                        break;
                }
                setTiers(segmentTiers);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("existing tiers", e.message);
                }
            }
            searchTimeout.current = undefined;
        }, 500);
    };

    const handleChangeTags = (tags: string[]) => {
        setSegmentTags(tags);
        setShowModalEditTags(false);
    };

    const handleSave = async () => {
        try {
            if (unsavedChanges.segment) await session.restSegment.update(segment);
            if (unsavedChanges.links_area_codes) linksAreaCodesRef.current?.handleSave();
            if (unsavedChanges.links_categories) linksCategoriesRef.current?.handleSave();
            if (unsavedChanges.links_external) linksExternalRef.current?.handleSave();
            if (unsavedChanges.tags) await session.restSegmentLinkTag.updateTagsForSegment(segment, segmentTags);

            setInitSegment(new Segment(segment));
            setInitSegmentTags([...segmentTags]);
            alert.updateWithSuccess("segment");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("segment", e.message);
            }
        }
    };

    const handleDelete = async () => {
        if (!isActiveDelete) return;
        try {
            await session.restSegment.delete(segment.id);
            history.push(Module.SEGMENTS.path);
            alert.deleteWithSuccess("segment");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToDelete("segment", e.message);
            }
        } finally {
            setActiveDelete(false);
        }
    };

    const handleCopyName = () => {
        copy(`${segment.id} | ${segment.name}`);
        alert.copied("segment name");
    };

    const handleInsights = () => {
        Looker.goToDashboard(112, `Segment+ID=${segment.id}&Segment+Name=&Date=yesterday&Country=FR`);
    };

    const hasUnsavedChanges = () => {
        return SEGMENT_DETAILS_SUB_MODULES.some((module) => unsavedChanges[module]);
    };

    const handleUnsavedChanges = useCallback((module: SegmentsDetailsSubModule, hasChanges: boolean) => {
        setUnsavedChanges((prevState) => ({...prevState, [module]: hasChanges}));
    }, []);

    return (
        <Wrapper>
            <MainHeader preventUnsaved={hasUnsavedChanges()}/>
            <MainContentHeader module={Module.SEGMENTS} element={initSegment.toContentElement()} preventUnsaved={hasUnsavedChanges()}>
                <MainContentHeaderAction action={new Action(textSegments("actions.insights"), {name: "query_stats"})} onClick={handleInsights}/>
                <MainContentHeaderAction action={Action.COPY} label={"Segment Name"} onClick={handleCopyName}/>
                <RestrictedContent allowedTo={Authorization.SEGMENTS.update}>
                    <MainContentHeaderAction action={Action.SAVE} onClick={handleSave} disabled={segment.hasEmptyField() || !hasUnsavedChanges()}/>
                    <MainContentHeaderAction action={Action.DUPLICATE} onClick={() => setShowModalDuplicateSegment(true)}/>
                    <MainContentHeaderAction action={Action.DELETE} onClick={() => setActiveDelete(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <Loadable loading={isLoading}>
                        <IframeBlock
                            src={Looker.getDashboardIframeUrl(190, `ID=${segment.id}&Country=FR`)}
                            title="segment-iframe"
                        />
                    </Loadable>
                    <LayoutColumns>
                        <Loadable loading={isLoading}>
                            <ContentBlock header={{title: {label: textSegments("section.information")}}}>
                                <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                    <FormLayoutRows>
                                        <FormLayoutColumns>
                                            <FormLayoutColumns columns={2}>
                                                <FieldBlock name={SegmentField.ACTIVE} label={textSegments("field.status")}>
                                                    <SelectStatus
                                                        value={segment.active ? Status.ACTIVE.name : Status.INACTIVE.name}
                                                        statuses={Status.getActiveStatuses()}
                                                        onChange={handleChangeStatus}
                                                    />
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                            <FieldBlock label={textSegments(`field.${SegmentField.DATA_TYPE}`)} content={{noFullWidth: true}}>
                                                <TagSegmentDataType type={SegmentDataType.getByName(segment.data_type)}/>
                                            </FieldBlock>
                                        </FormLayoutColumns>
                                        <FieldBlock label={textSegments(`field.${SegmentField.NAME}`)} required>
                                            <InputText
                                                value={segment.name}
                                                onChange={(value) => setSegment((prevState) => new Segment({...prevState, [SegmentField.NAME]: value}))}
                                            />
                                        </FieldBlock>
                                        <FieldBlock label={textSegments(`field.${SegmentField.DESCRIPTION}`)} required>
                                            <Textarea
                                                value={segment.description}
                                                onChange={(value) => setSegment((prevState) => new Segment({...prevState, [SegmentField.DESCRIPTION]: value}))}
                                                rows={3}
                                            />
                                        </FieldBlock>
                                        <FormLayoutRows inline spacing={FlexContentSpacing.MEDIUM}>
                                            <FieldBlock label={textSegments(`field.${SegmentField.TIERS1}`)} required>
                                                <SelectAutocomplete
                                                    value={segment.tiers1}
                                                    options={tiers[0].map((tier) => ({label: tier, value: tier}))}
                                                    onChange={(option) => handleChangeTier(SegmentField.TIERS1, option?.value as string)}
                                                    disabled={!tiers[0].length}
                                                    creatable
                                                />
                                            </FieldBlock>
                                            <FieldBlock label={textSegments(`field.${SegmentField.TIERS2}`)}>
                                                <SelectAutocomplete
                                                    value={segment.tiers2}
                                                    options={tiers[1].map((tier) => ({label: tier, value: tier}))}
                                                    onChange={(option) => handleChangeTier(SegmentField.TIERS2, option?.value as string)}
                                                    disabled={!segment.tiers1}
                                                    clearable
                                                    creatable
                                                />
                                            </FieldBlock>
                                            <FieldBlock label={textSegments(`field.${SegmentField.TIERS3}`)}>
                                                <SelectAutocomplete
                                                    value={segment.tiers3}
                                                    options={tiers[2].map((tier) => ({label: tier, value: tier}))}
                                                    onChange={(option) => handleChangeTier(SegmentField.TIERS3, option?.value as string)}
                                                    disabled={!segment.tiers2}
                                                    clearable
                                                    creatable
                                                />
                                            </FieldBlock>
                                            <FieldBlock label={textSegments(`field.${SegmentField.TIERS4}`)}>
                                                <SelectAutocomplete
                                                    value={segment.tiers4}
                                                    options={tiers[3].map((tier) => ({label: tier, value: tier}))}
                                                    onChange={(option) => handleChangeTier(SegmentField.TIERS4, option?.value as string)}
                                                    disabled={!segment.tiers3}
                                                    clearable
                                                    creatable
                                                />
                                            </FieldBlock>
                                            <FieldBlock label={textSegments(`field.${SegmentField.TIERS5}`)}>
                                                <SelectAutocomplete
                                                    value={segment.tiers5}
                                                    options={tiers[4].map((tier) => ({label: tier, value: tier}))}
                                                    onChange={(option) => handleChangeTier(SegmentField.TIERS5, option?.value as string)}
                                                    disabled={!segment.tiers4}
                                                    clearable
                                                    creatable
                                                />
                                            </FieldBlock>
                                            <FieldBlock label={textSegments(`field.${SegmentField.TIERS_NAME}`)} required>
                                                <InputText
                                                    value={segment.tiers_name}
                                                    onChange={(value) => setSegment((prevState) => new Segment({...prevState, [SegmentField.TIERS_NAME]: value}))}
                                                />
                                            </FieldBlock>
                                        </FormLayoutRows>
                                    </FormLayoutRows>
                                </Box>
                            </ContentBlock>
                        </Loadable>
                        <Loadable loading={isLoading}>
                            <ContentBlock header={{title: {label: textSegments("section.settings")}}}>
                                <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                    <FormLayoutRows>
                                        <FormLayoutColumns layout={FlexContentLayout.TWO_COLUMNS_WIDE_LEFT}>
                                            <FieldBlock label={textSegments(`field.${SegmentField.PARTNER_ID}`)}>
                                                <SelectPartner
                                                    value={segment.partner_id}
                                                    onChange={(partner) => setSegment((prevState) => new Segment({...prevState, [SegmentField.PARTNER_ID]: partner?.id || 0}))}
                                                    service={ApiService.AUDIENCE}
                                                />
                                            </FieldBlock>
                                            <FieldBlock name={SegmentField.PRIVATE} label={textSegments("field.public")}>
                                                <ToggleSwitch
                                                    checked={!segment.private}
                                                    onChange={(value) => setSegment((prevState) => new Segment({...prevState, [SegmentField.PRIVATE]: !value}))}
                                                />
                                            </FieldBlock>
                                        </FormLayoutColumns>
                                        <FormLayoutSeparator/>
                                        <FormLayoutColumns columns={4}>
                                            <FieldBlock label={textSegments("field.retention_in_days")} required>
                                                <InputNumber
                                                    value={segment.expiration_day}
                                                    minValue={0}
                                                    onChange={(value) => setSegment((prevState) => new Segment({...prevState, [SegmentField.EXPIRATION_DAY]: value}))}
                                                />
                                            </FieldBlock>
                                            <FieldBlock label={textSegments(`field.${SegmentField.CUSTOMER_PRICE_INDEX}`)}>
                                                <InputTextNumber
                                                    value={segment.customer_price_index}
                                                    minValue={customerPriceIndexMin}
                                                    maxValue={customerPriceIndexMax}
                                                    onChange={(value) => setSegment((prevState) => new Segment({...prevState, [SegmentField.CUSTOMER_PRICE_INDEX]: parseFloat(value.toFixed(2))}))}
                                                />
                                            </FieldBlock>
                                            {segment.hasScoring() &&
                                                <FieldBlock
                                                    label={textSegments(`field.${SegmentField.PERCENTILE}`)}
                                                    tooltip={textSegments(`tooltip.${SegmentField.PERCENTILE}`)}
                                                >
                                                    <Slider
                                                        value={segment.percentile ? +segment.percentile : Segment.MIN_PERCENTILE}
                                                        onChange={(value) => setSegment((prevState) => new Segment({...prevState, [SegmentField.PERCENTILE]: `${value || ""}`}))}
                                                        unit="%"
                                                        minValue={Segment.MIN_PERCENTILE}
                                                        maxValue={Segment.MAX_PERCENTILE}
                                                        step={Segment.PERCENTILE_STEP}
                                                        reverseRange={true}
                                                    />
                                                </FieldBlock>
                                            }
                                            <FieldBlock name={SegmentField.COOKIELESS} label={textSegments(`field.${SegmentField.COOKIELESS}`)}>
                                                <ToggleSwitch
                                                    checked={segment.cookieless}
                                                    onChange={(value) => setSegment((prevState) => new Segment({...prevState, [SegmentField.COOKIELESS]: value}))}
                                                />
                                            </FieldBlock>
                                        </FormLayoutColumns>
                                        <FormLayoutSeparator/>
                                        <FormLayoutColumns>
                                            <FieldBlock label={textSegments("field.price_eur")} required>
                                                <InputTextNumber
                                                    value={Formatter.convertCentsToUnits(segment.price)}
                                                    minValue={0}
                                                    maxValue={Formatter.convertCentsToUnits(maxValuePrice)}
                                                    onChange={(value) => handleChangePrice(Formatter.convertUnitsToCents(value))}
                                                />
                                                <span>{textSegments("field.currency.eur")}</span>
                                            </FieldBlock>
                                            <FieldBlock label={textSegments(`field.${SegmentField.PRICE_USD}`)}>
                                                <InputTextNumber
                                                    value={Formatter.convertCentsToUnits(segment.price_usd)}
                                                    minValue={0}
                                                    maxValue={Formatter.convertCentsToUnits(maxValuePrice)}
                                                    onChange={(value) => handleChangePrice(Formatter.convertUnitsToCents(value))}
                                                    disabled
                                                />
                                                <span>{textSegments("field.currency.usd")}</span>
                                            </FieldBlock>
                                            <FieldBlock label={textSegments(`field.${SegmentField.PRICE_GBP}`)}>
                                                <InputTextNumber
                                                    value={Formatter.convertCentsToUnits(segment.price_gbp)}
                                                    minValue={0}
                                                    maxValue={Formatter.convertCentsToUnits(maxValuePrice)}
                                                    onChange={(value) => handleChangePrice(Formatter.convertUnitsToCents(value))}
                                                    disabled
                                                />
                                                <span>{textSegments("field.currency.gbp")}</span>
                                            </FieldBlock>
                                        </FormLayoutColumns>
                                        <RestrictedContent allowedTo={Authorization.TAGS.name}>
                                            <FormLayoutSeparator/>
                                            <FieldBlock
                                                label={textSegments("field.tags")}
                                                actions={
                                                    <RestrictedContent allowedTo={Authorization.SEGMENTS.update}>
                                                        <IconEdit onClick={() => setShowModalEditTags(true)}/>
                                                    </RestrictedContent>
                                                }
                                            >
                                                <ElementList placeholder={textTags("placeholder.no_tag_selected")} size={ElementListSize.SMALL} inline>
                                                    {segmentTags.map((tag) =>
                                                        <Tag key={tag} label={tag} style={TagStyle.PRIMARY_MIDNIGHT_LIGHT}/>
                                                    )}
                                                </ElementList>
                                            </FieldBlock>
                                        </RestrictedContent>
                                        <FormLayoutSeparator/>
                                        <FieldBlock label={textSegments(`field.${SegmentField.COMMENT}`)}>
                                            <Textarea
                                                value={segment.comment}
                                                onChange={(value) => setSegment((prevState) => new Segment({...prevState, [SegmentField.COMMENT]: value}))}
                                                rows={3}
                                            />
                                        </FieldBlock>
                                    </FormLayoutRows>
                                </Box>
                            </ContentBlock>
                        </Loadable>
                    </LayoutColumns>
                    <LayoutColumns>
                        <SegmentExternalLinks
                            segment={segment}
                            handleSaveRef={linksExternalRef}
                            onChange={handleUnsavedChanges}
                        />
                        {segment.data_type !== SegmentDataType.LOCATION.name ?
                            <SegmentCategoryLinks
                                segmentId={segment.id}
                                segmentType={SegmentDataType.getByName(segment.data_type)}
                                percentile={segment.percentile}
                                handleSaveRef={linksCategoriesRef}
                                onChange={handleUnsavedChanges}
                            /> :
                            <SegmentAreaCodesLinks
                                segmentId={segment.id}
                                handleSaveRef={linksAreaCodesRef}
                                onChange={handleUnsavedChanges}
                            />
                        }
                    </LayoutColumns>
                </LayoutRows>
                <ModalEditTags
                    initTags={segmentTags}
                    active={isShowModalEditTags}
                    onSubmit={handleChangeTags}
                    onClose={() => setShowModalEditTags(false)}
                />
                <ModalDuplicateSegment
                    active={isShowModalDuplicateSegment}
                    source={segment}
                    onClose={() => setShowModalDuplicateSegment(false)}
                />
                <ModalConfirmDelete
                    active={isActiveDelete}
                    entity="segment"
                    confirm={handleDelete}
                    cancel={() => setActiveDelete(false)}
                />
                <ModalConfirmMessage
                    active={isActiveDisable}
                    message={textSegments("message.confirm_segment_disable")}
                    confirm={() => handleChangeStatus(Status.INACTIVE)}
                    confirmAction={Action.DEACTIVATE.labelKey}
                    cancel={() => setActiveDisable(false)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default SegmentsDetails;
