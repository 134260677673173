import {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ButtonLinkCancel, ButtonValidate, FieldBlock, Form, FormLayoutRows, FormValidationType, InputText, InputUrl, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, ToggleSwitch} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../../utils/constants";
import {SirdataListVendorField} from "../../../api/model/cmp/list/sirdata-list-v2/SirdataListVendorField";
import {SirdataListVendor} from "../../../api/model/cmp/list/sirdata-list-v2/SirdataListVendor";
import {CmpSirdataListV2PurposesCheckboxes} from "../../snippet";
import {Purpose} from "../../../api/model/cmp/list/global-vendor-list/Purpose";
import {session} from "../../../api/ApiSession";
import useAlert from "../../../utils/hooks/useAlert";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {SirdataListPurpose} from "../../../api/model/cmp/list/sirdata-list-v2/SirdataListPurpose";

type ModalEditCmpSirdataListV2VendorProps = {
    initVendor: SirdataListVendor | undefined;
    purposes: Purpose[];
    extraPurposes: SirdataListPurpose[];
    onClose: (refresh?: boolean) => void;
};

const ModalEditCmpSirdataListV2Vendor: FunctionComponent<ModalEditCmpSirdataListV2VendorProps> = ({initVendor, purposes, extraPurposes, onClose}) => {
    const alert = useAlert();
    const {t: textCmpSirdataList} = useTranslation(TranslationPortalFile.CMP_SIRDATA_LIST_V2);
    const [isSubmitting, setSubmitting] = useState(false);
    const [vendor, setVendor] = useState<SirdataListVendor>(new SirdataListVendor());
    const [selectedPurposes, setSelectedPurposes] = useState<number[]>([]);
    const [selectedLegIntPurposes, setSelectedLegIntPurposes] = useState<number[]>([]);
    const [selectedExtraPurposes, setSelectedExtraPurposes] = useState<number[]>([]);
    const [selectedLegIntExtraPurposes, setSelectedLegIntExtraPurposes] = useState<number[]>([]);
    const FORM_ID = "form-edit-cmp-sirdata-list-v2-vendor";

    useEffect(() => {
        if (!!initVendor) {
            setVendor(initVendor);
            setSelectedPurposes(initVendor.purposes);
            setSelectedLegIntPurposes(initVendor.legIntPurposes);
            setSelectedExtraPurposes(initVendor.extraPurposes);
            setSelectedLegIntExtraPurposes(initVendor.legIntExtraPurposes);
        }
    }, [initVendor]);

    const handleChange = (field: SirdataListVendorField, value: any) => {
        setVendor((prevState) => new SirdataListVendor({...prevState, [field]: value}));
    };

    const handleTogglePurposes = (purposes: number[]) => {
        setSelectedPurposes(purposes);
        handleChange(SirdataListVendorField.PURPOSES, purposes);

        if (selectedLegIntPurposes.some((id) => purposes.includes(id))) {
            const updatedLegIntPurposes = selectedLegIntPurposes.filter((id) => !purposes.includes(id));
            setSelectedLegIntPurposes(updatedLegIntPurposes);
            handleChange(SirdataListVendorField.LEG_INT_PURPOSES, updatedLegIntPurposes);
        }
    };

    const handleToggleLegIntPurposes = (legIntPurposes: number[]) => {
        setSelectedLegIntPurposes(legIntPurposes);
        handleChange(SirdataListVendorField.LEG_INT_PURPOSES, legIntPurposes);

        if (selectedPurposes.some((id) => legIntPurposes.includes(id))) {
            const updatedPurposes = selectedPurposes.filter((id) => !legIntPurposes.includes(id));
            setSelectedPurposes(updatedPurposes);
            handleChange(SirdataListVendorField.PURPOSES, updatedPurposes);
        }
    };

    const handleToggleExtraPurposes = (extraPurposes: number[]) => {
        setSelectedExtraPurposes(extraPurposes);
        handleChange(SirdataListVendorField.EXTRA_PURPOSES, extraPurposes);

        if (selectedLegIntExtraPurposes.some((id) => extraPurposes.includes(id))) {
            const updatedLegIntExtraPurposes = selectedLegIntExtraPurposes.filter((id) => !extraPurposes.includes(id));
            setSelectedLegIntExtraPurposes(updatedLegIntExtraPurposes);
            handleChange(SirdataListVendorField.LEG_INT_EXTRA_PURPOSES, updatedLegIntExtraPurposes);
        }
    };

    const handleToggleLegIntExtraPurposes = (legIntExtraPurposes: number[]) => {
        setSelectedLegIntExtraPurposes(legIntExtraPurposes);
        handleChange(SirdataListVendorField.LEG_INT_EXTRA_PURPOSES, legIntExtraPurposes);

        if (selectedExtraPurposes.some((id) => legIntExtraPurposes.includes(id))) {
            const updatedExtraPurposes = selectedExtraPurposes.filter((id) => !legIntExtraPurposes.includes(id));
            setSelectedExtraPurposes(updatedExtraPurposes);
            handleChange(SirdataListVendorField.EXTRA_PURPOSES, updatedExtraPurposes);
        }
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            await session.restCmpSirdataListVendor.update(vendor);
            alert.updateWithSuccess("vendor");
            onClose(true);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("vendor", e.message);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <ModalNew onClose={() => onClose(false)} active={!!initVendor}>
            <ModalHeader>
                <ModalHeaderTitle title={textCmpSirdataList("edit_vendor")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FieldBlock label={textCmpSirdataList(`field.vendor.${SirdataListVendorField.NAME}`)} required>
                            <InputText
                                value={vendor.name}
                                onChange={(value) => handleChange(SirdataListVendorField.NAME, value)}
                            />
                        </FieldBlock>
                        <FieldBlock label={textCmpSirdataList(`field.vendor.${SirdataListVendorField.POLICY_URL}`)} required>
                            <InputUrl
                                value={vendor.policyUrl}
                                placeholder={textCmpSirdataList(`placeholder.${SirdataListVendorField.POLICY_URL}`)}
                                onChange={(value) => handleChange(SirdataListVendorField.POLICY_URL, value)}
                            />
                        </FieldBlock>
                        <FieldBlock label={textCmpSirdataList(`field.vendor.${SirdataListVendorField.PURPOSES}`)}>
                            <CmpSirdataListV2PurposesCheckboxes
                                purposes={purposes}
                                selectedPurposes={selectedPurposes}
                                onChange={handleTogglePurposes}
                            />
                        </FieldBlock>
                        <FieldBlock
                            label={textCmpSirdataList(`field.vendor.${SirdataListVendorField.LEG_INT_PURPOSES}`)}
                            tooltip={textCmpSirdataList(`tooltip.vendor.${SirdataListVendorField.LEG_INT_PURPOSES}`)}
                        >
                            <CmpSirdataListV2PurposesCheckboxes
                                purposes={purposes}
                                selectedPurposes={selectedLegIntPurposes}
                                onChange={handleToggleLegIntPurposes}
                                disabledPurposeIds={[1]}
                            />
                        </FieldBlock>
                        <FieldBlock label={textCmpSirdataList(`field.vendor.${SirdataListVendorField.EXTRA_PURPOSES}`)}>
                            <CmpSirdataListV2PurposesCheckboxes
                                purposes={extraPurposes}
                                selectedPurposes={selectedExtraPurposes}
                                onChange={handleToggleExtraPurposes}
                            />
                        </FieldBlock>
                        <FieldBlock label={textCmpSirdataList(`field.vendor.${SirdataListVendorField.LEG_INT_EXTRA_PURPOSES}`)}>
                            <CmpSirdataListV2PurposesCheckboxes
                                purposes={extraPurposes}
                                selectedPurposes={selectedLegIntExtraPurposes}
                                onChange={handleToggleLegIntExtraPurposes}
                            />
                        </FieldBlock>
                        <ToggleSwitch
                            name={SirdataListVendorField.PREMIUM}
                            label={textCmpSirdataList(`field.vendor.${SirdataListVendorField.PREMIUM}`)}
                            checked={vendor.premium}
                            onChange={(value) => handleChange(SirdataListVendorField.PREMIUM, value)}
                        />
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={() => onClose(false)}/>
                <ButtonValidate form={FORM_ID} loading={isSubmitting}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalEditCmpSirdataListV2Vendor;
