import {ScrollToTop} from "@sirdata/ui-lib";
import {createBrowserHistory} from "history";
import React, {FunctionComponent, Suspense, useEffect, useState} from "react";
import {Redirect, Route, Router, Switch} from "react-router-dom";
import {CompatRouter} from "react-router-dom-v5-compat";
import {session} from "./api/ApiSession";
import {Authorization} from "./api/model/account/Authorization";
import {SirdataApiEvent} from "./common/api/CommonApiClient";
import {Error403, Error404, Error500} from "./common/screen";
import {pathError403, pathError404, pathError500, pathHome, pathLogin} from "./common/utils/constants";
import PrivateModule from "./component/guard/PrivateModule";
import PrivateRoute from "./component/guard/PrivateRoute";
import ModalModules from "./component/modal/ModalModules";
import ModalTicketing from "./component/modal/ModalTicketing";
import {
    AdsAccess,
    AdsAccessDetails,
    ApiDocumentations,
    AudienceDataSelections,
    AudienceDataSelectionsDetails,
    AuthorizationsPartner,
    AuthorizationsUser,
    Categories,
    CategoriesDetails,
    Categorizer,
    CategorizerAdvancedSearch,
    CategorizerDetails,
    CmpConfigurations,
    CmpConfigurationsDetails,
    CmpConsentManagementPlatforms,
    CmpGlobalVendorList,
    CmpGoogleACProviderList,
    CmpNetworks,
    CmpSirdataList,
    CmpSirdataListV2,
    CmpTranslations,
    CmpVersions,
    CmpVersionsDetails,
    CmpWebinars,
    CmpWebinarsDetails,
    CustomerCountings,
    CustomerCountingsDetails,
    CustomerOrders,
    CustomerOrdersDetails,
    CustomerStorages,
    CustomerStoragesDetails,
    DataleaksAudits,
    DataleaksAuditsDetails,
    DataleaksAuditsReport,
    DataleaksQuestions,
    DataleaksQuestionsDetails,
    DataleaksStatistics,
    DataleaksVersions,
    DataleaksVersionsDetails,
    Deals,
    DealsDetails,
    Distributions,
    DistributionsDetails,
    DistributionsLicenses,
    HelperDomains,
    HelperDomainsDetails,
    Home,
    Keywords,
    Licenses,
    LicensesDetails,
    Login,
    Medias,
    MediasDetails,
    Notifications,
    NotificationsDetails,
    OrganizationGroups,
    OrganizationGroupsDetails,
    Organizations,
    OrganizationsDetails,
    PartnerDomains,
    Partners,
    PartnersConnect,
    PartnersDetails,
    PortalSettings,
    PortalSettingsDetails,
    Pricings,
    PricingsDetails,
    RevenuesImports,
    Seats,
    SeatsDetails,
    Segments,
    SegmentsDetails,
    SgtmContainers,
    SgtmContainersDetails,
    TaxonomyTags,
    TaxonomyTagsDetails,
    UserProfiles,
    UserProfilesDetails,
    Users,
    UsersDetails
} from "./screen";
import {pathCategorizerAdvancedSearch, pathDataleaksReport, pathPartnersConnect} from "./utils/constants";
import {Module} from "./utils/Module";
import {PortalContextProvider} from "./common/context/PortalContext";
import {PortalSetting} from "./common/api/model/portal-setting/PortalSetting";

const history = createBrowserHistory();

const App: FunctionComponent = () => {
    const [portalSetting, setPortalSetting] = useState<PortalSetting>(new PortalSetting());

    useEffect(() => {
        (async () => {
            try {
                setPortalSetting(await session.getPortalSetting());
            } catch (e) {
            }
        })();
    }, []);

    session.on(SirdataApiEvent.eventNotFound, () => {
        history.replace(pathError404);
    });

    return (
        <React.StrictMode>
            <Suspense fallback={<></>}>
                <PortalContextProvider portalSetting={portalSetting}>
                    <Router history={history}>
                        <CompatRouter>
                            <ScrollToTop/>
                            <Switch>
                                <Route exact path={pathError403} component={Error403}/>
                                <Route exact path={pathError404} component={Error404}/>
                                <Route exact path={pathError500} component={Error500}/>

                                <Route exact path="/" component={() => <Redirect to={pathLogin}/>}/>
                                <Route exact path={pathLogin} component={Login}/>
                                <PrivateRoute exact path={pathHome} component={Home} requiredAuthorization={undefined}/>
                                <PrivateRoute exact path={pathCategorizerAdvancedSearch} component={CategorizerAdvancedSearch} requiredAuthorization={Authorization.CATEGORIZER_ADMIN.name}/>
                                <PrivateRoute exact path={pathDataleaksReport} component={DataleaksAuditsReport} requiredAuthorization={Authorization.DATALEAKS.name}/>
                                <PrivateRoute exact path={pathPartnersConnect} component={PartnersConnect} requiredAuthorization={Authorization.LOG_AS_PARTNER.name}/>

                                <PrivateModule {...Module.ADS} component={AdsAccess} detailsComponent={AdsAccessDetails}/>
                                <PrivateModule {...Module.API_DOCUMENTATIONS} component={ApiDocumentations} detailsComponent={undefined}/>
                                <PrivateModule {...Module.AUDIENCE_DATA_SELECTIONS} component={AudienceDataSelections} detailsComponent={AudienceDataSelectionsDetails}/>
                                <PrivateModule {...Module.AUTHORIZATIONS_PARTNER} component={AuthorizationsPartner} detailsComponent={undefined}/>
                                <PrivateModule {...Module.AUTHORIZATIONS_USER} component={AuthorizationsUser} detailsComponent={undefined}/>
                                <PrivateModule {...Module.CATEGORIES} component={Categories} detailsComponent={CategoriesDetails}/>
                                <PrivateModule {...Module.CATEGORIZER} component={Categorizer} detailsComponent={CategorizerDetails}/>
                                <PrivateModule {...Module.CMP_CONFIGURATIONS} component={CmpConfigurations} detailsComponent={CmpConfigurationsDetails}/>
                                <PrivateModule {...Module.CMP_CONSENT_MANAGEMENT_PLATFORMS} component={CmpConsentManagementPlatforms} detailsComponent={undefined}/>
                                <PrivateModule {...Module.CMP_GLOBAL_VENDOR_LIST} component={CmpGlobalVendorList} detailsComponent={undefined}/>
                                <PrivateModule {...Module.CMP_GOOGLE_AC_PROVIDER_LIST} component={CmpGoogleACProviderList} detailsComponent={undefined}/>
                                <PrivateModule {...Module.CMP_NETWORKS} component={CmpNetworks} detailsComponent={undefined}/>
                                <PrivateModule {...Module.CMP_SIRDATA_LIST} component={CmpSirdataList} detailsComponent={undefined}/>
                                <PrivateModule {...Module.CMP_SIRDATA_LIST_V2} component={CmpSirdataListV2} detailsComponent={undefined}/>
                                <PrivateModule {...Module.CMP_TRANSLATIONS} component={CmpTranslations} detailsComponent={undefined}/>
                                <PrivateModule {...Module.CMP_VERSIONS} component={CmpVersions} detailsComponent={CmpVersionsDetails}/>
                                <PrivateModule {...Module.CMP_WEBINARS} component={CmpWebinars} detailsComponent={CmpWebinarsDetails}/>
                                <PrivateModule {...Module.CUSTOMER_COUNTINGS} component={CustomerCountings} detailsComponent={CustomerCountingsDetails}/>
                                <PrivateModule {...Module.CUSTOMER_ORDERS} component={CustomerOrders} detailsComponent={CustomerOrdersDetails}/>
                                <PrivateModule {...Module.CUSTOMER_STORAGES} component={CustomerStorages} detailsComponent={CustomerStoragesDetails}/>
                                <PrivateModule {...Module.DATALEAKS_QUESTIONS} component={DataleaksQuestions} detailsComponent={DataleaksQuestionsDetails}/>
                                <PrivateModule {...Module.DATALEAKS_STATISTICS} component={DataleaksStatistics} detailsComponent={undefined}/>
                                <PrivateModule {...Module.DATALEAKS_VERSIONS} component={DataleaksVersions} detailsComponent={DataleaksVersionsDetails}/>
                                <PrivateModule {...Module.DATALEAKS} component={DataleaksAudits} detailsComponent={DataleaksAuditsDetails}/>
                                <PrivateModule {...Module.DEALS} component={Deals} detailsComponent={DealsDetails}/>
                                <PrivateModule {...Module.DISTRIBUTIONS} component={Distributions} detailsComponent={DistributionsDetails}/>
                                <PrivateModule {...Module.DISTRIBUTIONS_LICENSES} component={DistributionsLicenses} detailsComponent={undefined}/>
                                <PrivateModule {...Module.HELPER_DOMAINS} component={HelperDomains} detailsComponent={HelperDomainsDetails}/>
                                <PrivateModule {...Module.KEYWORDS} component={Keywords} detailsComponent={undefined}/>
                                <PrivateModule {...Module.LICENSES} component={Licenses} detailsComponent={LicensesDetails}/>
                                <PrivateModule {...Module.MEDIAS} component={Medias} detailsComponent={MediasDetails}/>
                                <PrivateModule {...Module.NOTIFICATIONS} component={Notifications} detailsComponent={NotificationsDetails}/>
                                <PrivateModule {...Module.ORGANIZATION_GROUPS} component={OrganizationGroups} detailsComponent={OrganizationGroupsDetails}/>
                                <PrivateModule {...Module.ORGANIZATIONS} component={Organizations} detailsComponent={OrganizationsDetails}/>
                                <PrivateModule {...Module.PARTNER_DOMAINS} component={PartnerDomains} detailsComponent={undefined}/>
                                <PrivateModule {...Module.PARTNERS} component={Partners} detailsComponent={PartnersDetails}/>
                                <PrivateModule {...Module.PORTAL_SETTINGS} component={PortalSettings} detailsComponent={PortalSettingsDetails}/>
                                <PrivateModule {...Module.PRICINGS} component={Pricings} detailsComponent={PricingsDetails}/>
                                <PrivateModule {...Module.REVENUES_IMPORTS} component={RevenuesImports} detailsComponent={undefined}/>
                                <PrivateModule {...Module.SEATS} component={Seats} detailsComponent={SeatsDetails}/>
                                <PrivateModule {...Module.SEGMENTS} component={Segments} detailsComponent={SegmentsDetails}/>
                                <PrivateModule {...Module.SGTM_CONTAINERS} component={SgtmContainers} detailsComponent={SgtmContainersDetails}/>
                                <PrivateModule {...Module.TAXONOMY_TAGS} component={TaxonomyTags} detailsComponent={TaxonomyTagsDetails}/>
                                <PrivateModule {...Module.USER_PROFILES} component={UserProfiles} detailsComponent={UserProfilesDetails}/>
                                <PrivateModule {...Module.USERS} component={Users} detailsComponent={UsersDetails}/>

                                <Route component={() => <Redirect to={pathError404}/>}/>
                            </Switch>
                        </CompatRouter>
                    </Router>
                    <ModalModules/>
                    <ModalTicketing/>
                </PortalContextProvider>
            </Suspense>
        </React.StrictMode>
    );
};

export default App;
