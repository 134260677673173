export enum SgtmContainerField {
    ID = "id",
    PARTNER_ID = "partner_id",
    NAME = "name",
    CONTAINER_CONFIG_ID = "container_config_id",
    DEFAULT_PROPERTIES = "default_properties",
    OVERRIDE_PROPERTIES_PER_HOST_LIST = "override_properties_per_host_list",
    INSTANCE_HOSTS = "instance_hosts",
    HELPER_ACTIVE = "helper_active",
    STATUS = "status",
    HOST_DETAILS = "host_details",
    INSTANCE_STATUS = "instance_status",
    CREATION_TS = "creation_ts",
    LAST_UPDATE_TS = "last_update_ts"
}
