import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {LICENSE_INFO_VALUE_TYPES, PartnerLicenseInfo} from "../../../api/model/partner/license/PartnerLicenseInfo";
import {PartnerLicenseInfoValueType} from "../../../api/model/partner/license/PartnerLicenseInfoValueType";
import {
    ButtonLinkCancel,
    ButtonValidate,
    FieldBlock,
    Form,
    FormLayoutColumns,
    FormLayoutRows,
    FormValidationType,
    InputText,
    InputTextNumber,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    RadioButtons,
    Textarea
} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../../utils/constants";
import {PartnerLicenseInfoField} from "../../../api/model/partner/license/PartnerLicenseInfoField";

type ModalAddLicenseInfoProps = {
    active: boolean;
    onSubmit: (license: PartnerLicenseInfo) => void;
    onClose: () => void;
};

const ModalAddLicenseInfo: FunctionComponent<ModalAddLicenseInfoProps> = ({active, onSubmit, onClose}) => {
    const {t: textLicenses} = useTranslation(TranslationPortalFile.LICENSES);
    const [info, setInfo] = useState<PartnerLicenseInfo>(new PartnerLicenseInfo());
    const FORM_ID = "form-add-license-info";

    useEffect(() => {
        if (active) {
            setInfo(new PartnerLicenseInfo());
        }
    }, [active]);

    const handleChange = (field: PartnerLicenseInfoField, value: any) => {
        setInfo((prevState) => new PartnerLicenseInfo({...prevState, [field]: value}));
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmit(info);
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textLicenses("modal.info.title_add")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FieldBlock label={textLicenses(`field.${PartnerLicenseInfoField.VALUE_TYPE}`)}>
                            <RadioButtons
                                id={PartnerLicenseInfoField.VALUE_TYPE}
                                value={info.valueType}
                                options={LICENSE_INFO_VALUE_TYPES.map((item) => ({label: item, value: item}))}
                                onChange={(value) => handleChange(PartnerLicenseInfoField.VALUE_TYPE, value)}
                            />
                        </FieldBlock>
                        <FormLayoutColumns>
                            <FieldBlock label={textLicenses(`field.${PartnerLicenseInfoField.KEY}`)} required>
                                <InputText
                                    value={info.key}
                                    onChange={(value) => handleChange(PartnerLicenseInfoField.KEY, value)}
                                />
                            </FieldBlock>
                            {info.valueType === PartnerLicenseInfoValueType.STRING &&
                                <FieldBlock label={textLicenses(`field.${PartnerLicenseInfoField.VALUE}`)} required>
                                    <InputText
                                        value={info.value}
                                        onChange={(value) => handleChange(PartnerLicenseInfoField.VALUE, value)}
                                    />
                                </FieldBlock>
                            }
                            {info.valueType === PartnerLicenseInfoValueType.NUMBER &&
                                <FieldBlock label={textLicenses(`field.${PartnerLicenseInfoField.VALUE}`)} required>
                                    <InputTextNumber
                                        value={info.value}
                                        minValue={0}
                                        onChange={(value) => handleChange(PartnerLicenseInfoField.VALUE, value)}
                                    />
                                </FieldBlock>
                            }
                        </FormLayoutColumns>
                        {info.valueType === PartnerLicenseInfoValueType.OBJECT &&
                            <FieldBlock label={textLicenses(`field.${PartnerLicenseInfoField.VALUE}`)} required>
                                <Textarea
                                    value={info.value}
                                    rows={10}
                                    onChange={(value) => handleChange(PartnerLicenseInfoField.VALUE, value)}
                                />
                            </FieldBlock>
                        }
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalAddLicenseInfo;
