import {Action, Alert, AlertSeverity, Box, BoxProps, CodeBlock, CodeBlockLanguage, ContentBlock, ElementList, ElementListSize, FieldBlock, FlexContentDirection, FormLayoutColumns, FormLayoutRows, FormLayoutSeparator, IconTooltip, LayoutColumns, LayoutRows, Loadable} from "@sirdata/ui-lib";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import "react-phone-number-input/style.css";
import {useParams} from "react-router-dom";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {Segment} from "../../api/model/audience/segment/Segment";
import {SegmentDataType} from "../../api/model/audience/segment/SegmentDataType";
import {CustomerCounting} from "../../api/model/customer/counting/CustomerCounting";
import {CustomerCountingField} from "../../api/model/customer/counting/CustomerCountingField";
import {CustomerCountingSegmentFilterType} from "../../api/model/customer/counting/segment-filter/CustomerCountingSegmentFilterType";
import {Partner} from "../../api/model/partner/Partner";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {ApiService} from "../../api/model/ApiService";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {Formatter} from "../../common/utils/Formatter";
import ModalConfirmMessage from "../../component/modal/ModalConfirmMessage";
import {MainContentHeader, MainContentHeaderAction, SelectPartner, TagSegmentDataType, TagSegmentRow} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import {Module} from "../../utils/Module";

function CustomerCountingsDetails() {
    const {t: textCustomerCountings} = useTranslation(TranslationPortalFile.CUSTOMER_COUNTINGS);
    const alert = useAlert();
    const {id} = useParams<{ id: string }>();

    const [isLoading, setLoading] = useState(true);
    const [counting, setCounting] = useState<CustomerCounting>(new CustomerCounting());
    const [partner, setPartner] = useState<Partner>();
    const [selectedSegments, setSelectedSegments] = useState<Segment[]>([]);
    const [isShowModalConfirmRetryCounting, setShowModalConfirmRetryCounting] = useState(false);

    useEffect(() => {
        (async function () {
            try {
                const newCounting = await session.restCustomerCounting.get(id);
                setCounting(newCounting);

                const newPartner = await session.restPartner.get(newCounting.partner_id);
                setPartner(newPartner);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    if (e.statusCode === HttpStatusCode.NOT_FOUND) {
                        session.emit(SirdataApiEvent.eventNotFound);
                    } else {
                        alert.failToLoad("counting", e.message);
                    }
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [id, alert]);

    useEffect(() => {
        (async function () {
            try {
                const segments = await session.getSegments();

                const countingSegmentIds = counting.segment_filter.getSegmentIds();
                setSelectedSegments(segments.filter(({id}) => countingSegmentIds.includes(id)));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("segments", e.message);
                }
            }
        })();
    }, [counting, alert]);

    const handleExecuteCounting = async () => {
        if (!isShowModalConfirmRetryCounting) return;
        try {
            await session.restCustomerCounting.executeJob(counting);
            alert.actionWithSuccess("counting executed");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failTo("execute counting", e.message);
            }
        } finally {
            setShowModalConfirmRetryCounting(false);
        }
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.CUSTOMER_COUNTINGS} element={counting.toContentElement()}>
                <RestrictedContent allowedTo={Authorization.CUSTOMER_COUNTINGS.update}>
                    {counting.canBeRelaunched() &&
                        <MainContentHeaderAction
                            action={new Action(textCustomerCountings("action.retry_counting"), {name: "replay"})}
                            onClick={() => setShowModalConfirmRetryCounting(true)}
                        />
                    }
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <LayoutColumns>
                        <ContentBlock header={{title: {label: textCustomerCountings("section.information")}}}>
                            <Loadable loading={isLoading}>
                                <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                    <FormLayoutRows>
                                        <FieldBlock
                                            label={textCustomerCountings(`field.${CustomerCountingField.STORAGE}`)}
                                            actions={
                                                <IconTooltip
                                                    key={"link_to_storage"}
                                                    icon={Action.OPEN.icon}
                                                    text={textCustomerCountings("action.view_storage")}
                                                    onClick={() => window.open(`${Module.CUSTOMER_STORAGES.buildRoute(counting.storage_id)}`, "_blank")}
                                                />
                                            }
                                        >
                                            {counting.storage.name}
                                        </FieldBlock>
                                        <FieldBlock label={textCustomerCountings(`field.${CustomerCountingField.PARTNER_ID}`)}>
                                            <SelectPartner
                                                value={partner?.id}
                                                onChange={undefined}
                                                service={ApiService.CUSTOMER}
                                                disabled
                                            />
                                        </FieldBlock>
                                        <FieldBlock label={textCustomerCountings("field.distinct_mapping_column_matched")}>
                                            {Formatter.formatNumber(counting.table.distinct_mapping_column_values)}
                                        </FieldBlock>
                                        {!!counting.dimension_indexes.length &&
                                        <>
                                            <FormLayoutSeparator/>
                                            <FormLayoutColumns>
                                                {counting.dimension_indexes.map((nameIndex, index) =>
                                                    <FieldBlock key={`counting-dimension-index-${index.toString()}`} label={textCustomerCountings("field.column", {value: index + 1})}>
                                                        {counting.storage.table.column_names[nameIndex]}
                                                    </FieldBlock>
                                                )}
                                            </FormLayoutColumns>
                                        </>
                                        }
                                    </FormLayoutRows>
                                </Box>
                            </Loadable>
                        </ContentBlock>
                        <ContentBlock header={{title: {label: textCustomerCountings("section.counting_filtering")}}}>
                            <Loadable loading={isLoading}>
                                <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                    <FormLayoutRows>
                                        <FieldBlock label={textCustomerCountings("field.counting_type")}>
                                            {textCustomerCountings(`types.${counting.segment_filter.getType().name}`)}
                                        </FieldBlock>
                                        {counting.segment_filter.getType() === CustomerCountingSegmentFilterType.SEGMENT &&
                                        <FieldBlock label={textCustomerCountings("field.segments")} content={{direction: FlexContentDirection.COLUMN}}>
                                            <ElementList size={ElementListSize.BIG}>
                                                {selectedSegments.map((segment) =>
                                                    <TagSegmentRow key={segment.id} segment={segment}/>
                                                )}
                                            </ElementList>
                                            <Alert text={textCustomerCountings("message.segments_sort_tooltip")}/>
                                        </FieldBlock>
                                        }
                                        {counting.segment_filter.getType() === CustomerCountingSegmentFilterType.SEGMENT_TYPE &&
                                            <FieldBlock label={textCustomerCountings("field.segment_types")}>
                                                <ElementList inline>
                                                    {SegmentDataType.values().filter((it) => counting.segment_filter.data_type.includes(it.name)).map((item) =>
                                                        <TagSegmentDataType key={item.name} type={item}/>
                                                    )}
                                                </ElementList>
                                            </FieldBlock>
                                        }
                                    </FormLayoutRows>
                                </Box>
                            </Loadable>
                        </ContentBlock>
                    </LayoutColumns>
                    {!!counting.error_code &&
                    <ContentBlock header={{title: {label: textCustomerCountings("section.error")}}}>
                        <Loadable loading={isLoading}>
                            <Alert text={textCustomerCountings("message.error_occurred")} severity={AlertSeverity.DANGER}/>
                            <CodeBlock
                                header={textCustomerCountings(`error_code.${counting.error_code}`)}
                                language={CodeBlockLanguage.JSON}
                                code={counting.error_message}
                                copiable
                                wrapLongLines
                            />
                        </Loadable>
                    </ContentBlock>
                    }
                </LayoutRows>
                <ModalConfirmMessage
                    active={isShowModalConfirmRetryCounting}
                    message={textCustomerCountings("message.confirm_retry_counting")}
                    confirm={handleExecuteCounting}
                    confirmAction={textCustomerCountings("action.retry_counting")}
                    cancel={() => setShowModalConfirmRetryCounting(false)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default CustomerCountingsDetails;
