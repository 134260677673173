import {
    Action,
    ActionsMenu,
    Box,
    BoxProps,
    ContentBlock,
    ContentBlockAction,
    ElementList,
    ElementListSize,
    FieldBlock,
    FlexContentLayout,
    Form,
    FormLayoutColumns,
    FormLayoutRows,
    FormValidationType,
    InputNumber,
    InputText,
    LayoutColumns,
    LayoutRows,
    Loadable,
    Table,
    TableActionColumn,
    TableColumn,
    TableColumnStyle,
    TableRow,
    Tag,
    TagSize,
    TagStyle,
    ToggleSwitch,
    TranslationLibFile,
    Wrapper
} from "@sirdata/ui-lib";
import React, {FormEvent, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Authorization} from "../../api/model/account/Authorization";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent} from "../../common/component/widget";
import {LabelPartner, MainContentHeader, MainContentHeaderAction, SelectPartner, SelectStatus} from "../../component/snippet";
import {Module} from "../../utils/Module";
import {session} from "../../api/ApiSession";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import useAlert from "../../utils/hooks/useAlert";
import {AdsAccess} from "../../api/model/audience/ads/AdsAccess";
import {TranslationPortalFile} from "../../utils/constants";
import {AdsAccessField} from "../../api/model/audience/ads/AdsAccessField";
import {Status} from "../../utils/Status";
import {detectChanges} from "../../common/utils/portal";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {Partner} from "../../api/model/partner/Partner";
import {AdsAccessConfigurationField} from "../../api/model/audience/ads/AdsAccessConfigurationField";
import {AdsAccessConfiguration} from "../../api/model/audience/ads/AdsAccessConfiguration";
import {ApiAuthorization} from "../../api/model/auth/ApiAuthorization";
import {ApiService} from "../../api/model/ApiService";
import {sortItems} from "../../common/utils/helper";
import {ApiAuthorizationField} from "../../api/model/auth/ApiAuthorizationField";
import {PartnerField} from "../../api/model/partner/PartnerField";
import ModalAddSharedTaxonomyPartners from "../../component/modal/ads-access/ModalAddSharedTaxonomyPartners";
import ModalAddGlobalTaxonomyPartners from "../../component/modal/ads-access/ModalAddGlobalTaxonomyPartners";
import ModalConfirmDelete from "../../component/modal/ModalConfirmDelete";
import ModalConfirmRemove from "../../component/modal/ModalConfirmRemove";

function AdsAccessDetails() {
    const alert = useAlert();
    const history = useHistory();
    const {id} = useParams<{id: string}>();
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textAdsAccess} = useTranslation(TranslationPortalFile.ADS_ACCESS);
    const {t: textPartners} = useTranslation(TranslationPortalFile.PARTNERS);
    const [isLoading, setLoading] = useState(true);
    const [isUnsavedChanges, setUnsavedChanges] = useState(false);

    const FORM_ID = "form-edit-ads-access";
    const [access, setAccess] = useState<AdsAccess>(new AdsAccess());
    const [initAccess, setInitAccess] = useState<AdsAccess>(new AdsAccess());
    const [accessAuthorizations, setAccessAuthorizations] = useState<ApiAuthorization[]>([]);
    const [sharedTaxonomyPartners, setSharedTaxonomyPartners] = useState<Partner[]>([]);
    const [globalTaxonomyPartners, setGlobalTaxonomyPartners] = useState<Partner[]>([]);
    const [isShowModalDelete, setShowModalDelete] = useState(false);
    const [activeModalAddTaxonomyPartners, setActiveModalAddTaxonomyPartners] = useState<AdsAccessConfigurationField.SHARED_TAXONOMY | AdsAccessConfigurationField.GLOBAL_TAXONOMY>();
    const [activePartnerToRemove, setActivePartnerToRemove] = useState<{field: AdsAccessConfigurationField.SHARED_TAXONOMY | AdsAccessConfigurationField.GLOBAL_TAXONOMY; partner: Partner}>();

    useEffect(() => {
        (async () => {
            try {
                const access = await session.restAdsAccess.get(+id);
                setAccess(access);
                setInitAccess(new AdsAccess(access));

                const authorizations = access.feature_access.map((it) =>
                    new ApiAuthorization({
                        [ApiAuthorizationField.NAME]: it,
                        [ApiAuthorizationField.SERVICE]: ApiService.LIVE_TARGETING.name
                    })
                );
                setAccessAuthorizations(authorizations);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    if (e.statusCode === HttpStatusCode.NOT_FOUND) {
                        session.emit(SirdataApiEvent.eventNotFound);
                    } else {
                        alert.failToLoad("access", e.message);
                    }
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [id, alert]);

    useEffect(() => {
        (async () => {
            try {
                const partners = await session.getPartners();
                const sharedTaxonomyPartners = partners.filter((partner) => access.configuration.shared_taxonomy?.includes(partner.id));
                setSharedTaxonomyPartners(sharedTaxonomyPartners);

                const globalTaxonomyPartners = partners.filter((partner) => access.configuration.global_taxonomy?.includes(partner.id));
                setGlobalTaxonomyPartners(globalTaxonomyPartners);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("partners", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [access, alert]);

    useEffect(() => {
        setUnsavedChanges(detectChanges(access, initAccess));
    }, [access, initAccess]);

    const handleChange = (field: AdsAccessField, value: any) => {
        setAccess((prevState) => new AdsAccess({...prevState, [field]: value}));
    };

    const handleChangeConfiguration = (field: AdsAccessConfigurationField, value: any) => {
        handleChange(AdsAccessField.CONFIGURATION, new AdsAccessConfiguration({
            ...access.configuration,
            [field]: value
        }));
    };

    const doUpdateTaxonomyPartners = async (field: AdsAccessConfigurationField.SHARED_TAXONOMY | AdsAccessConfigurationField.GLOBAL_TAXONOMY, partnerIds: number[]) => {
        try {
            let newAccess: AdsAccess;
            switch (field) {
                case AdsAccessConfigurationField.SHARED_TAXONOMY:
                    newAccess = await session.restAdsAccess.updateSharedTaxonomy(access.id, partnerIds);
                    break;
                case AdsAccessConfigurationField.GLOBAL_TAXONOMY:
                    newAccess = await session.restAdsAccess.updateGlobalTaxonomy(access.id, partnerIds);
                    break;
            }
            setAccess((prevState) => new AdsAccess({
                ...prevState,
                [AdsAccessField.CONFIGURATION]: new AdsAccessConfiguration({
                    ...prevState.configuration,
                    [field]: newAccess.configuration[field]
                })
            }));
            setInitAccess((prevState) => new AdsAccess({
                ...prevState,
                [AdsAccessField.CONFIGURATION]: new AdsAccessConfiguration({
                    ...prevState.configuration,
                    [field]: newAccess.configuration[field]
                })
            }));
            alert.updateWithSuccess(textAdsAccess(`field.${AdsAccessField.CONFIGURATION}.${field}`));
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate(textAdsAccess(`field.${AdsAccessField.CONFIGURATION}.${field}`), e.message);
            }
        }
    };

    const handleAddTaxonomyPartners = async (field: AdsAccessConfigurationField.SHARED_TAXONOMY | AdsAccessConfigurationField.GLOBAL_TAXONOMY, partners: Partner[]) => {
        const partnerIds = Array.from(new Set([
            ...access.configuration[field] || [],
            ...partners.map((partner) => partner.id)
        ]));
        await doUpdateTaxonomyPartners(field, partnerIds);
        setActiveModalAddTaxonomyPartners(undefined);
    };

    const handleRemoveTaxonomyPartner = async () => {
        if (!activePartnerToRemove) {
            return;
        }

        const taxonomyPartners = access.configuration[activePartnerToRemove.field] || [];
        const partnerIds = taxonomyPartners.filter((id) => id !== activePartnerToRemove.partner.id);
        await doUpdateTaxonomyPartners(activePartnerToRemove.field, partnerIds);
        setActivePartnerToRemove(undefined);
    };

    const handleSave = async (e: FormEvent) => {
        e.preventDefault();
        try {
            await session.restAdsAccess.update(access);
            setInitAccess(new AdsAccess(access));
            alert.updateWithSuccess("access");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("access", e.message);
            }
        }
    };

    const handleDelete = async () => {
        if (!isShowModalDelete) {
            return;
        }
        try {
            await session.restAdsAccess.delete(access.id);
            history.push(Module.ADS.path);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToDelete("access", e.message);
            }
        } finally {
            setShowModalDelete(false);
        }
    };

    return (
        <Wrapper>
            <MainHeader preventUnsaved={isUnsavedChanges}/>
            <MainContentHeader module={Module.ADS} element={initAccess.toContentElement()} preventUnsaved={isUnsavedChanges}>
                <MainContentHeaderAction action={new Action(textAdsAccess("action.view_partner"), {name: "person"})} onClick={() => window.open(Module.PARTNERS.buildRoute(access.partner_id), "_blank")}/>
                <RestrictedContent allowedTo={Authorization.ADS.update}>
                    <MainContentHeaderAction action={Action.SAVE} form={FORM_ID} disabled={!isUnsavedChanges}/>
                    <MainContentHeaderAction action={Action.DELETE} onClick={() => setShowModalDelete(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <Form id={FORM_ID} onSubmit={handleSave} validationType={FormValidationType.CUSTOM}>
                    <LayoutRows>
                        <LayoutColumns>
                            <LayoutRows>
                                <Loadable loading={isLoading}>
                                    <ContentBlock header={{title: {label: textAdsAccess("section.information")}}}>
                                        <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                            <FormLayoutRows>
                                                <FormLayoutColumns columns={4}>
                                                    <FieldBlock label={textAdsAccess("field.status")}>
                                                        <SelectStatus
                                                            value={access.active ? Status.ACTIVE.name : Status.INACTIVE.name}
                                                            statuses={Status.getActiveStatuses()}
                                                            onChange={(status) => handleChange(AdsAccessField.ACTIVE, status === Status.ACTIVE)}
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                                <FormLayoutColumns layout={FlexContentLayout.TWO_COLUMNS_WIDE_RIGHT}>
                                                    <FieldBlock label={textAdsAccess(`field.${AdsAccessField.ID}`)} required>
                                                        <InputNumber
                                                            value={access.id}
                                                            onChange={() => {}}
                                                            disabled
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock label={textAdsAccess(`field.${AdsAccessField.NAME}`)} required>
                                                        <InputText
                                                            value={access.name}
                                                            onChange={(value) => handleChange(AdsAccessField.NAME, value)}
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                                <FieldBlock label={textAdsAccess(`field.${AdsAccessField.PARTNER_ID}`)} required>
                                                    <SelectPartner
                                                        value={access.partner_id}
                                                        onChange={undefined}
                                                        disabled
                                                    />
                                                </FieldBlock>
                                                <FormLayoutColumns columns={3}>
                                                    <FieldBlock
                                                        name={AdsAccessField.COLLECT_DATA}
                                                        label={textAdsAccess(`field.${AdsAccessField.COLLECT_DATA}`)}
                                                    >
                                                        <ToggleSwitch
                                                            checked={access.collect_data}
                                                            onChange={(value) => handleChange(AdsAccessField.COLLECT_DATA, value)}
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock
                                                        name={AdsAccessField.PUBLIC_TAXONOMY}
                                                        label={textAdsAccess(`field.${AdsAccessField.PUBLIC_TAXONOMY}`)}
                                                    >
                                                        <ToggleSwitch
                                                            checked={access.public_taxonomy}
                                                            onChange={(value) => handleChange(AdsAccessField.PUBLIC_TAXONOMY, value)}
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                                <FieldBlock label={textAdsAccess(`field.${AdsAccessField.FEATURE_ACCESS}`)}>
                                                    <ElementList placeholder={textAdsAccess("placeholder.no_authorization_granted")} size={ElementListSize.BIG}>
                                                        {sortItems(accessAuthorizations, ApiAuthorizationField.NAME).map((authorization) =>
                                                            <Tag
                                                                key={authorization.name}
                                                                size={TagSize.MEDIUM}
                                                                style={TagStyle.PRIMARY_MIDNIGHT_LIGHT}
                                                                label={authorization.label}
                                                            />
                                                        )}
                                                    </ElementList>
                                                </FieldBlock>
                                            </FormLayoutRows>
                                        </Box>
                                    </ContentBlock>
                                </Loadable>
                            </LayoutRows>
                            <LayoutRows>
                                <Loadable loading={isLoading}>
                                    <ContentBlock header={{title: {label: textAdsAccess("section.configuration")}}}>
                                        <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                            <FormLayoutRows>
                                                <FormLayoutColumns>
                                                    <FieldBlock label={textAdsAccess(`field.${AdsAccessField.CONFIGURATION}.${AdsAccessConfigurationField.SEGMENT_TAXONOMY_ID}`)}>
                                                        <InputNumber
                                                            value={access.configuration.segment_taxonomy_id}
                                                            onChange={(value) => handleChangeConfiguration(AdsAccessConfigurationField.SEGMENT_TAXONOMY_ID, value)}
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock label={textAdsAccess(`field.${AdsAccessField.CONFIGURATION}.${AdsAccessConfigurationField.CATEGORY_TAXONOMY_ID}`)}>
                                                        <InputNumber
                                                            value={access.configuration.category_taxonomy_id}
                                                            onChange={(value) => handleChangeConfiguration(AdsAccessConfigurationField.CATEGORY_TAXONOMY_ID, value)}
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                                <FieldBlock
                                                    name={`${AdsAccessField.CONFIGURATION}.${AdsAccessConfigurationField.ORIGIN_IGNORED}`}
                                                    label={textAdsAccess(`field.${AdsAccessField.CONFIGURATION}.${AdsAccessConfigurationField.ORIGIN_IGNORED}`)}
                                                >
                                                    <ToggleSwitch
                                                        checked={access.configuration.origin_ignored}
                                                        onChange={(value) => handleChangeConfiguration(AdsAccessConfigurationField.ORIGIN_IGNORED, value)}
                                                    />
                                                </FieldBlock>
                                            </FormLayoutRows>
                                        </Box>
                                    </ContentBlock>
                                </Loadable>
                                <Loadable loading={isLoading}>
                                    <ContentBlock
                                        header={{
                                            title: {label: textAdsAccess(`field.${AdsAccessField.CONFIGURATION}.${AdsAccessConfigurationField.SHARED_TAXONOMY}`)},
                                            actions: (
                                                <RestrictedContent allowedTo={Authorization.ADS.update}>
                                                    <ContentBlockAction action={Action.ADD} onClick={() => setActiveModalAddTaxonomyPartners(AdsAccessConfigurationField.SHARED_TAXONOMY)}/>
                                                </RestrictedContent>
                                            )
                                        }}
                                    >
                                        <Table
                                            columns={[
                                                {width: 20, label: textPartners(`field.${PartnerField.ID}`)},
                                                {width: 75, label: textPartners("field.name")},
                                                {width: 5}
                                            ]}
                                        >
                                            {sharedTaxonomyPartners.map((partner) =>
                                                <TableRow key={partner.id} onClick={() => window.open(partner.getRoute(), "_blank")}>
                                                    <TableColumn>
                                                        <LabelPartner partner={partner.id}/>
                                                    </TableColumn>
                                                    <TableColumn>{partner.fullName}</TableColumn>
                                                    <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                                                        <RestrictedContent allowedTo={Authorization.ADS.update}>
                                                            <ActionsMenu
                                                                iconTooltip={{
                                                                    icon: Action.EDIT.icon,
                                                                    text: textCommon(Action.EDIT.labelKey)
                                                                }}
                                                                items={[{
                                                                    label: textCommon(Action.REMOVE.labelKey),
                                                                    critical: true,
                                                                    onClick: () => setActivePartnerToRemove({
                                                                        field: AdsAccessConfigurationField.SHARED_TAXONOMY,
                                                                        partner: partner
                                                                    })
                                                                }]}
                                                            />
                                                        </RestrictedContent>
                                                    </TableActionColumn>
                                                </TableRow>
                                            )}
                                        </Table>
                                    </ContentBlock>
                                </Loadable>
                                <Loadable loading={isLoading}>
                                    <ContentBlock
                                        header={{
                                            title: {label: textAdsAccess(`field.${AdsAccessField.CONFIGURATION}.${AdsAccessConfigurationField.GLOBAL_TAXONOMY}`)},
                                            actions: (
                                                <RestrictedContent allowedTo={Authorization.ADS.update}>
                                                    <ContentBlockAction action={Action.ADD} onClick={() => setActiveModalAddTaxonomyPartners(AdsAccessConfigurationField.GLOBAL_TAXONOMY)}/>
                                                </RestrictedContent>
                                            )
                                        }}
                                    >
                                        <Table
                                            columns={[
                                                {width: 20, label: textPartners(`field.${PartnerField.ID}`)},
                                                {width: 75, label: textPartners("field.name")},
                                                {width: 5}
                                            ]}
                                        >
                                            {globalTaxonomyPartners.map((partner) =>
                                                <TableRow key={partner.id} onClick={() => window.open(partner.getRoute(), "_blank")}>
                                                    <TableColumn>
                                                        <LabelPartner partner={partner.id}/>
                                                    </TableColumn>
                                                    <TableColumn>{partner.fullName}</TableColumn>
                                                    <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                                                        <RestrictedContent allowedTo={Authorization.ADS.update}>
                                                            <ActionsMenu
                                                                iconTooltip={{
                                                                    icon: Action.EDIT.icon,
                                                                    text: textCommon(Action.EDIT.labelKey)
                                                                }}
                                                                items={[{
                                                                    label: textCommon(Action.REMOVE.labelKey),
                                                                    critical: true,
                                                                    onClick: () => setActivePartnerToRemove({
                                                                        field: AdsAccessConfigurationField.GLOBAL_TAXONOMY,
                                                                        partner: partner
                                                                    })
                                                                }]}
                                                            />
                                                        </RestrictedContent>
                                                    </TableActionColumn>
                                                </TableRow>
                                            )}
                                        </Table>
                                    </ContentBlock>
                                </Loadable>
                            </LayoutRows>
                        </LayoutColumns>
                    </LayoutRows>
                </Form>
                <ModalConfirmDelete
                    active={isShowModalDelete}
                    entity={"access"}
                    confirm={handleDelete}
                    cancel={() => setShowModalDelete(false)}
                />
                <ModalAddSharedTaxonomyPartners
                    active={activeModalAddTaxonomyPartners === AdsAccessConfigurationField.SHARED_TAXONOMY}
                    onSubmit={(partners) => handleAddTaxonomyPartners(AdsAccessConfigurationField.SHARED_TAXONOMY, partners)}
                    onClose={() => setActiveModalAddTaxonomyPartners(undefined)}
                    selectedPartnerIds={access.configuration.shared_taxonomy || []}
                />
                <ModalAddGlobalTaxonomyPartners
                    active={activeModalAddTaxonomyPartners === AdsAccessConfigurationField.GLOBAL_TAXONOMY}
                    onSubmit={(partners) => handleAddTaxonomyPartners(AdsAccessConfigurationField.GLOBAL_TAXONOMY, partners)}
                    onClose={() => setActiveModalAddTaxonomyPartners(undefined)}
                    selectedPartnerIds={access.configuration.global_taxonomy || []}
                />
                <ModalConfirmRemove
                    active={!!activePartnerToRemove}
                    confirm={handleRemoveTaxonomyPartner}
                    cancel={() => setActivePartnerToRemove(undefined)}
                    entity={`partner ${activePartnerToRemove?.partner.fullName}`}
                />
            </MainContent>
        </Wrapper>
    );
}

export default AdsAccessDetails;
