import {ApiModel} from "../../../../common/api/model/ApiModel";
import {Status} from "../../../../utils/Status";
import {CountryCode, CountryIsoCode} from "../../../../common/api/model/Country";
import {SegmentLinkAreaCodeType} from "./SegmentLinkAreaCodeType";

export class SegmentLinkAreaCodes extends ApiModel {
    id: number = 0;
    active: boolean = true;
    iso_country: CountryIsoCode = CountryCode.FRANCE.code;
    type: string = SegmentLinkAreaCodeType.POSTAL_CODE.name;
    _codes: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    get codes(): string {
        return this._codes;
    }

    set codes(codes: string) {
        this._codes = codes.split(",").filter((v, i, s) => s.indexOf(v) === i).join(",");
    }

    getCodes(): string[] {
        if (!this._codes) return [];
        return this.codes.split(",");
    }

    setCodes(codes: string[]) {
        this.codes = codes.join(",");
    }

    getJsonParameters(): {} {
        return {
            active: this.active,
            iso_country: this.iso_country,
            type: this.type,
            codes: this.codes
        };
    }

    getStatus(): Status {
        return this.active ? Status.ACTIVE : Status.INACTIVE;
    }
}
