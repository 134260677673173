import {ApiModel} from "../../../../common/api/model/ApiModel";
import {SegmentDataType} from "./SegmentDataType";
import {Status} from "../../../../utils/Status";
import {ContentElement} from "../../../interface/ContentElement";
import {Origin} from "../../../../common/api/model/Origin";
import {Module} from "../../../../utils/Module";

export class Segment extends ApiModel {
    id: number = 0;
    partner_id: number = 0;
    name: string = SegmentDataType.INTENT.prefix;
    description: string = "";
    active: boolean = false;
    cookieless: boolean = false;
    customer_price_index: number = 1;
    private: boolean = false;
    data_type: string = SegmentDataType.INTENT.name;
    tiers1: string = "";
    tiers2: string = "";
    tiers3: string = "";
    tiers4: string = "";
    tiers5: string = "";
    tiers_name: string = "";
    price: number = 0;
    price_usd: number = 0;
    price_gbp: number = 0;
    percentile?: string;
    expiration_day: number = SegmentDataType.INTENT.expirationDay;
    comment: string = "";
    created: string = "";
    platform_id: string = "";

    static MIN_PERCENTILE = 10;
    static MAX_PERCENTILE = 90;
    static PERCENTILE_STEP = 10;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            name: this.name,
            partner_id: !!this.partner_id ? this.partner_id : undefined,
            description: this.description,
            active: this.active,
            cookieless: this.cookieless,
            customer_price_index: this.customer_price_index,
            private: this.private,
            data_type: this.data_type,
            tiers1: this.tiers1,
            tiers2: this.tiers2,
            tiers3: this.tiers3,
            tiers4: this.tiers4,
            tiers5: this.tiers5,
            tiers_name: this.tiers_name,
            price: this.price,
            price_usd: this.price_usd,
            price_gbp: this.price_gbp,
            expiration_day: this.expiration_day,
            comment: this.comment
        };
    }

    getRoute(): string {
        return Module.SEGMENTS.buildRoute(this.id);
    }

    isNew(): boolean {
        const days = (new Date().getTime() - new Date(this.created).getTime()) / (1000 * 60 * 60 * 24);
        return days < 30;
    }

    hasEmptyField(): boolean {
        return !this.price || !this.tiers1 || !this.tiers_name || !this.name || !this.description || !this.expiration_day;
    };

    getStatus(): Status {
        return this.active ? Status.ACTIVE : Status.INACTIVE;
    }

    hasScoring(): boolean {
        return [SegmentDataType.INFERRED, SegmentDataType.INTENT, SegmentDataType.INTEREST].map((it) => it.name).includes(this.data_type);
    }

    toContentElement(): ContentElement {
        const element = new ContentElement(this.id, `${this.name}`);
        element.status = this.getStatus();
        element.createdAt = new Date(this.created);
        if (this.active && !this.private) {
            element.url = `${Origin.AUDIENCE.url}/user/segment/${this.id}`;
        }
        return element;
    }
}
