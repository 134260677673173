import {
    Action,
    Alert,
    AlertSeverity,
    ButtonLink,
    ButtonLinkCancel,
    ButtonValidate,
    ElementList,
    ElementListSize,
    FieldBlock,
    FlexContentSpacing,
    Form,
    FormFieldMessageType,
    FormLayoutRows,
    FormValidationType,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    SearchIds,
    SearchIdsResult,
    TranslationLibFile
} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Segment} from "../../../api/model/audience/segment/Segment";
import {DealTargetingOriginField} from "../../../api/model/deal/DealTargetingOriginField";
import {TranslationPortalFile} from "../../../utils/constants";
import {SelectSegment, TagSegmentRow} from "../../snippet";

type ModalEditDealTargetingOriginUserCentricProps = {
    active: boolean;
    segments: Segment[];
    initialSegments: Segment[];
    onSubmit: (segments: Segment[]) => void;
    onClose: () => void;
};

const ModalEditDealTargetingOriginUserCentric: FunctionComponent<ModalEditDealTargetingOriginUserCentricProps> = ({active, segments, initialSegments, onSubmit, onClose}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textDeals} = useTranslation(TranslationPortalFile.DEALS);
    const [selectedSegments, setSelectedSegments] = useState<Segment[]>([]);
    const [searchIdResult, setSearchIdResult] = useState<SearchIdsResult>();
    const [highlightedIds, setHighlightedIds] = useState<number[]>();
    const FORM_ID = "form-edit-deal-targeting-origin-user-centric";

    useEffect(() => {
        if (active) {
            setSelectedSegments([...initialSegments]);
        }
    }, [active, initialSegments]);

    const handleAddSegments = (segments: Segment[]) => {
        setSelectedSegments([...segments, ...selectedSegments]);
        setHighlightedIds(segments.map((it) => it.id));
        setTimeout(() => setHighlightedIds([]), 1000);
    };

    const handleAddSegmentsByIds = (result: SearchIdsResult) => {
        handleAddSegments(result.matchedItems);
        setSearchIdResult(result);
        setTimeout(() => setSearchIdResult(undefined), 10000);
    };

    const handleRemoveSegment = (segment: Segment) => {
        setSelectedSegments((prevState) => prevState.filter((it) => it.id !== segment.id));
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmit(selectedSegments);
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textDeals(`targeting_origin.${DealTargetingOriginField.USER_CENTRIC}.title_edit`)}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                        <FormLayoutRows spacing={FlexContentSpacing.XSMALL}>
                            <SelectSegment
                                value={undefined}
                                excludedSegments={selectedSegments}
                                onChange={(segment) => segment && handleAddSegments([segment])}
                            />
                            <SearchIds items={segments} selectedItems={selectedSegments} onSubmit={handleAddSegmentsByIds}/>
                            {searchIdResult?.messages.map((message) =>
                                <Alert
                                    key={message.message}
                                    text={message.message}
                                    severity={message.type === FormFieldMessageType.ERROR ? AlertSeverity.DANGER : message.type === FormFieldMessageType.WARNING ? AlertSeverity.WARNING : AlertSeverity.SUCCESS}
                                />
                            )}
                        </FormLayoutRows>
                        <FieldBlock
                            label={textDeals(`targeting_origin.${DealTargetingOriginField.USER_CENTRIC}.selected`, {count: selectedSegments.length})}
                            actions={<ButtonLink onClick={() => setSelectedSegments([])}>{textCommon(Action.REMOVE_ALL.labelKey)}</ButtonLink>}
                        >
                            <ElementList placeholder={textDeals(`targeting_origin.${DealTargetingOriginField.USER_CENTRIC}.search_result`)} size={ElementListSize.BIG}>
                                {selectedSegments.map((item) =>
                                    <TagSegmentRow
                                        key={item.id}
                                        segment={item}
                                        isHighlighted={highlightedIds?.some((id) => item.id === id)}
                                        onRemove={() => handleRemoveSegment(item)}
                                    />
                                )}
                            </ElementList>
                        </FieldBlock>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalEditDealTargetingOriginUserCentric;
