import {Action, ContentBlock, ContentBlockAction, ElementList, ElementListSize, Tag as LibTag, TagSize, TagStyle} from "@sirdata/ui-lib";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {Authorization} from "../../../api/model/account/Authorization";
import {ApiProfile} from "../../../api/model/auth/ApiProfile";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {RestrictedContent} from "../../../common/component/widget";
import {TranslationPortalFile} from "../../../utils/constants";
import ModalEditProfiles from "../../modal/authorizations/ModalEditProfiles";
import {sortItems} from "../../../common/utils/helper";
import {ApiProfileField} from "../../../api/model/auth/ApiProfileField";
import useAlert from "../../../utils/hooks/useAlert";

const UserProfiles: FunctionComponent<{ userId: number }> = ({userId}) => {
    const alert = useAlert();
    const {t: textUsers} = useTranslation(TranslationPortalFile.USERS);
    const [isShowModalEditProfiles, setShowModalEditProfiles] = useState(false);
    const [userProfiles, setUserProfiles] = useState<ApiProfile[]>([]);

    useEffect(() => {
        (async function () {
            try {
                const details = await session.restUserAuthorization.getDetails(userId);
                setUserProfiles(details.profiles);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("user's profiles", e.message);
                }
            }
        })();
    }, [userId, alert]);

    return (
        <ContentBlock
            header={{
                title: {label: textUsers("section.profiles")},
                actions: (
                    <RestrictedContent allowedTo={Authorization.USERS.update}>
                        <ContentBlockAction action={Action.EDIT} onClick={() => setShowModalEditProfiles(true)}/>
                    </RestrictedContent>
                )
            }}
        >
            <ElementList placeholder={textUsers("placeholder.no_profile_assigned")} size={ElementListSize.BIG}>
                {sortItems(userProfiles, ApiProfileField.NAME).map((profile) =>
                    <LibTag
                        key={profile.id}
                        size={TagSize.MEDIUM}
                        style={TagStyle.PRIMARY_MIDNIGHT_LIGHT}
                        label={profile.name}
                    />
                )}
            </ElementList>
            <ModalEditProfiles
                userId={userId}
                userProfiles={userProfiles}
                onSubmit={(profiles) => {
                    setUserProfiles(profiles);
                    setShowModalEditProfiles(false);
                }}
                onClose={() => setShowModalEditProfiles(false)}
                active={isShowModalEditProfiles}
            />
        </ContentBlock>
    );
};

export default UserProfiles;
