import {useCallback, useEffect, useState} from "react";
import {SearchParams} from "../SearchParams";
import {SearchQuery} from "../../api/interface/SearchQuery";
import {SearchResult} from "../../api/interface/SearchResult";
import {useSearchParams} from "react-router-dom-v5-compat";
import {ApiModel} from "../../common/api/model/ApiModel";
import {PAGE_SIZE} from "../constants";
import {SearchParamsField} from "../SearchParamsField";

function useSearch<ELEMENT_TYPE extends ApiModel, QUERY_TYPE extends SearchQuery, RESULT_TYPE extends SearchResult<ELEMENT_TYPE>>(
    ElementModel: new () => ELEMENT_TYPE, QueryModel?: new () => QUERY_TYPE, ResultModel?: new (o?: {}) => RESULT_TYPE) {

    const [searchParams, setSearchParams] = useSearchParams();
    const [searchQuery, setSearchQuery] = useState<SearchQuery>(
        (QueryModel ? new QueryModel() : new SearchQuery()).withSearchParams(new SearchParams(searchParams))
    );
    const [searchResult, setSearchResult] = useState<SearchResult<ELEMENT_TYPE>>(
        ResultModel ? new ResultModel() : new SearchResult(ElementModel)
    );

    useEffect(() => {
        let searchQuery = (QueryModel ? new QueryModel() : new SearchQuery());
        searchQuery = searchQuery.withSearchParams(new SearchParams(searchParams));
        setSearchQuery(searchQuery);
    }, [QueryModel, searchParams]);

    const buildSearchResult = useCallback((items: ELEMENT_TYPE[], resetPage?: boolean) => {
        items = searchQuery.sortItems(items);
        const result = SearchResult.buildFromList(ElementModel, items, resetPage ? 0 : searchQuery.page, PAGE_SIZE);
        setSearchResult(ResultModel ? new ResultModel(result) : result);
    }, [ElementModel, ResultModel, searchQuery]);

    const changeParam = (name: string, value: any) => {
        setSearchParams((prev) => new SearchParams(prev).with({[name]: value}));
    };

    const changeQuery = (query: string) => {
        changeParam(SearchParamsField.QUERY, query);
    };

    const changePage = (page: number) => {
        changeParam(SearchParamsField.PAGE, page - 1);
    };

    const changeSortOrder = useCallback((field: string, reverseOrder: boolean = false) => {
        setSearchParams((prev) => new SearchParams(prev)
            .withString(SearchParamsField.SORT_BY, field)
            .withBoolean(SearchParamsField.REVERSE_ORDER, reverseOrder));
    }, [setSearchParams]);

    const clearFilters = () => {
        setSearchParams((prev) => new SearchParams()
            .withString(SearchParamsField.QUERY, prev.get(SearchParamsField.QUERY) || undefined));
    };

    return {
        searchQuery: searchQuery as QUERY_TYPE,
        searchResult: searchResult as RESULT_TYPE,
        buildSearchResult: buildSearchResult,
        setSearchResult,
        changeParam,
        changeQuery,
        changePage,
        changeSortOrder,
        clearFilters
    };
}

export default useSearch;
