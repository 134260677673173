import {FC} from "react";
import {useTranslation} from "react-i18next";
import {Color, ContentBlock, IconTooltip, Table, TableColumn, TableRow} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../../utils/constants";
import {SirdataListVersion} from "../../../api/model/cmp/list/sirdata-list-v2/SirdataListVersion";
import {SirdataListVendor} from "../../../api/model/cmp/list/sirdata-list-v2/SirdataListVendor";
import {SirdataListVersionField} from "../../../api/model/cmp/list/sirdata-list-v2/SirdataListVersionField";
import {SirdataListVendorField} from "../../../api/model/cmp/list/sirdata-list-v2/SirdataListVendorField";
import {CmpSirdataListV2EntityState} from "../index";

type CmpSirdataListV2VendorsNewVersionProps = {
    sirdataList: SirdataListVersion;
    listVendors: SirdataListVendor[];
};

const CmpSirdataListV2VendorsNewVersion: FC<CmpSirdataListV2VendorsNewVersionProps> = ({sirdataList, listVendors}) => {
    const {t: textCmpSirdataList} = useTranslation(TranslationPortalFile.CMP_SIRDATA_LIST_V2);
    const filteredVendors = listVendors.filter((vendor) => !!vendor.getState(sirdataList));

    return (
        <ContentBlock header={{title: {label: textCmpSirdataList(`field.${SirdataListVersionField.VENDORS}`)}}}>
            <Table
                columns={[
                    {width: 5},
                    {width: 5, label: textCmpSirdataList(`field.vendor.${SirdataListVendorField.ID}`)},
                    {width: 90, label: textCmpSirdataList(`field.vendor.${SirdataListVendorField.NAME}`)}
                ]}
            >
                {filteredVendors.map((vendor) =>
                    <TableRow key={vendor.id}>
                        <TableColumn>
                            <CmpSirdataListV2EntityState state={vendor.getState(sirdataList)}/>
                        </TableColumn>
                        <TableColumn>{vendor.id}</TableColumn>
                        <TableColumn>
                            {vendor.name}
                            {vendor.premium &&
                                <IconTooltip
                                    icon={{name: "card_membership", outlined: true, colorIcon: Color.GREEN}}
                                    text={textCmpSirdataList(`field.vendor.${SirdataListVendorField.PREMIUM}`)}
                                />
                            }
                        </TableColumn>
                    </TableRow>
                )}
            </Table>
        </ContentBlock>
    );
};

export default CmpSirdataListV2VendorsNewVersion;
