import {FunctionComponent} from "react";
import {Vendor} from "../../api/model/cmp/list/global-vendor-list/Vendor";
import {GoogleACProvider} from "../../api/model/cmp/list/google-ac-provider-list/GoogleACProvider";
import {SirdataVendor} from "../../api/model/cmp/list/sirdata-list/SirdataVendor";
import {CmpPartnerType} from "./CmpPartnerType";
import {ApiModel} from "../../common/api/model/ApiModel";
import {SvgIabChip, SvgSirdataChip} from "@sirdata/ui-lib";

export class CmpPartner extends ApiModel {
    id: number = 0;
    name: string = "";
    type: CmpPartnerType = CmpPartnerType.IAB;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    static create(partner: Vendor | GoogleACProvider | SirdataVendor, type: CmpPartnerType): CmpPartner {
        const cmpPartner = new CmpPartner();
        cmpPartner.id = partner.id;
        cmpPartner.name = partner.name;
        cmpPartner.type = type;
        return cmpPartner;
    }

    get isIABVendor(): boolean {
        return this.type === CmpPartnerType.IAB;
    }

    get isGoogleProvider(): boolean {
        return this.type === CmpPartnerType.Google;
    }

    get isSirdataVendor(): boolean {
        return this.type === CmpPartnerType.Sirdata;
    }

    get logo(): string | FunctionComponent {
        if (this.isIABVendor) {
            return SvgIabChip;
        } else if (this.isGoogleProvider) {
            return "/images/logos/google.png";
        } else {
            return SvgSirdataChip;
        }
    }
}
