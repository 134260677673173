import {Color, IconTooltip, TableActionColumn, TableColumn, TableColumnStyle, TableRow} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {Segment} from "../../../../api/model/audience/segment/Segment";
import {SegmentLinkExternal} from "../../../../api/model/audience/segment/SegmentLinkExternal";
import {Formatter} from "../../../../common/utils/Formatter";
import {TranslationPortalFile} from "../../../../utils/constants";
import {onClickLink} from "../../../../utils/helper";
import {Status} from "../../../../utils/Status";
import {LabelStatus, ValueCopier} from "../../index";

type DistributionSegmentRowProps = {
    link: SegmentLinkExternal;
    segment?: Segment;
};

const DistributionSegmentRow: FunctionComponent<DistributionSegmentRowProps> = ({link, segment}) => {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textDistribution} = useTranslation(TranslationPortalFile.DISTRIBUTIONS);
    const history = useHistory();

    if (!segment) return <></>;

    return (
        <TableRow onClick={(e) => onClickLink(e, new Segment({id: link.segment_id}).getRoute(), history)}>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                <LabelStatus status={link.getStatus()}/>
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{link.segment_id}</TableColumn>
            <TableColumn>
                <span>{segment.name}</span>
                {segment.private &&
                    <IconTooltip icon={{name: "lock", colorIcon: Color.OCEAN}} text={t(`status.${Status.PRIVATE.name}`)}/>
                }
            </TableColumn>
            <TableActionColumn styles={TableColumnStyle.ALIGN_CENTER}>
                <ValueCopier value={link.value} tooltip={textDistribution("action.copy_platform_id")}/>
            </TableActionColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                {Formatter.formatDate(link.last_update, Formatter.DATETIME_FORMAT)}
            </TableColumn>
        </TableRow>
    );
};

export default DistributionSegmentRow;
