import {FC} from "react";
import {FlexContent, FlexContentDirection, FlexContentSpacing, InputCheckbox, Tooltip} from "@sirdata/ui-lib";
import {Purpose} from "../../../api/model/cmp/list/global-vendor-list/Purpose";
import {sortNumbers} from "../../../common/utils/helper";
import {SirdataListPurpose} from "../../../api/model/cmp/list/sirdata-list-v2/SirdataListPurpose";

type CmpSirdataListV2PurposesCheckboxesProps = {
    purposes: Purpose[] | SirdataListPurpose[];
    selectedPurposes: number[];
    onChange: (purposes: number[]) => void;
    disabledPurposeIds?: number[];
};

const CmpSirdataListV2PurposesCheckboxes: FC<CmpSirdataListV2PurposesCheckboxesProps> = ({purposes, selectedPurposes, onChange, disabledPurposeIds}) => {
    const handleSelectPurpose = (purposeId: number) => {
        const purposes = new Set(selectedPurposes);
        purposes.has(purposeId) ? purposes.delete(purposeId) : purposes.add(purposeId);

        const sortedPurposes = sortNumbers(Array.from(purposes));
        onChange(sortedPurposes);
    };

    const generateId = (id: number) => {
        const chars = "abcdefghijklmnopqrstuvwxyz";
        const length = 5;

        let inputId = `purpose-${id}-`;
        for (let i = 0; i < length; i++) {
            inputId = inputId + chars[Math.floor(Math.random() * chars.length)];
        }
        return inputId;
    };

    return (
        <FlexContent
            direction={FlexContentDirection.ROW}
            spacing={FlexContentSpacing.SMALL}
            cssClass="purposes-checkboxes"
            allowWrap
        >
            {purposes.map((purpose) => {
                const checkboxId = generateId(purpose.id);

                return (
                    <Tooltip key={purpose.id} text={purpose.name}>
                        <div key={purpose.id} className="purposes-checkboxes__item">
                            <InputCheckbox
                                id={checkboxId}
                                name={checkboxId}
                                checked={selectedPurposes.includes(purpose.id)}
                                onChange={() => handleSelectPurpose(purpose.id)}
                                disabled={disabledPurposeIds?.some((it) => it === purpose.id)}
                            />
                            <label htmlFor={checkboxId} className="purposes-checkboxes__item__label">
                                {purpose.id}
                            </label>
                        </div>
                    </Tooltip>
                );
            })}
        </FlexContent>
    );
};

export default CmpSirdataListV2PurposesCheckboxes;
