import {Action, Color, IconTooltip, TableColumn, TableColumnStyle, TableRow} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {SegmentDataType} from "../../../../api/model/audience/segment/SegmentDataType";
import {CustomerCounting} from "../../../../api/model/customer/counting/CustomerCounting";
import {TranslationPortalFile} from "../../../../utils/constants";
import {onClickLink} from "../../../../utils/helper";
import {LabelStatus, TagSegmentDataType} from "../../index";
import {Module} from "../../../../utils/Module";

type CustomerCountingRowProps = {
    filter: CustomerCounting;
};

const CustomerCountingRow: FunctionComponent<CustomerCountingRowProps> = ({filter}) => {
    const {t: textCustomerCountings} = useTranslation(TranslationPortalFile.CUSTOMER_COUNTINGS);
    const history = useHistory();

    const formatSegmentFilter = () => {
        if (filter.segment_filter.data_type?.length) {
            return filter.segment_filter.data_type.map((it) => <TagSegmentDataType key={it} type={SegmentDataType.getByName(it)}/>);
        }
        if (filter.segment_filter.segment.length || filter.segment_filter.segment_modeling.length) {
            const countSegment = filter.segment_filter.segment?.length || 0;
            const countSegmentModeling = filter.segment_filter.segment_modeling?.length || 0;
            return textCustomerCountings("segments_count", {count: countSegment + countSegmentModeling});
        }
        if (filter.segment_filter.tiers1) {
            return filter.segment_filter.tiers1.join(",");
        }
        if (filter.segment_filter.tiers2) {
            return filter.segment_filter.tiers2.join(",");
        }
    };

    return (
        <TableRow onClick={(e) => onClickLink(e, filter.getRoute(), history)}>
            <TableColumn>
                <LabelStatus status={filter.getStatus()}/>
            </TableColumn>
            <TableColumn>
                {filter.id}
            </TableColumn>
            <TableColumn styles={TableColumnStyle.FIXED_WIDTH}>
                {filter.name}
                {!!filter.error_code &&
                    <IconTooltip
                        icon={{name: "error", colorIcon: Color.DANGER_MAIN}}
                        text={textCustomerCountings(`error_code.${filter.error_code}`)}
                    />
                }
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                {filter.storage_id}
                <IconTooltip
                    icon={Action.OPEN.icon}
                    text={textCustomerCountings("action.view_storage")}
                    onClick={() => window.open(Module.CUSTOMER_STORAGES.buildRoute(filter.storage_id), "_blank")}
                />
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                {!!filter.dimension_indexes.length && filter.dimension_indexes.join(", ")}
            </TableColumn>
            <TableColumn>
                {formatSegmentFilter()}
            </TableColumn>
        </TableRow>
    );
};

export default CustomerCountingRow;
