import {
    Action,
    Alert,
    AlertSeverity,
    ButtonLink,
    ButtonLinkCancel,
    ButtonValidate,
    ElementList,
    ElementListSize,
    FieldBlock,
    FlexContentSpacing,
    Form,
    FormFieldMessageType,
    FormLayoutRows,
    FormValidationType,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    SearchIds,
    SearchIdsResult,
    TranslationLibFile
} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Category} from "../../../api/model/audience/category/Category";
import {DealTargetingOriginField} from "../../../api/model/deal/DealTargetingOriginField";
import {TranslationPortalFile} from "../../../utils/constants";
import {SelectCategory, TagCategoryRow} from "../../snippet";

type ModalEditDealTargetingOriginContextualProps = {
    active: boolean;
    categories: Category[];
    initialCategories: Category[];
    onSubmit: (categories: Category[]) => void;
    onClose: () => void;
};

const ModalEditDealTargetingOriginContextual: FunctionComponent<ModalEditDealTargetingOriginContextualProps> = ({active, categories, initialCategories, onSubmit, onClose}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textDeals} = useTranslation(TranslationPortalFile.DEALS);
    const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);
    const [searchIdResult, setSearchIdResult] = useState<SearchIdsResult>();
    const [highlightedIds, setHighlightedIds] = useState<number[]>();
    const FORM_ID = "form-edit-deal-targeting-origin-contextual";

    useEffect(() => {
        if (active) {
            setSelectedCategories([...initialCategories]);
        }
    }, [active, initialCategories]);

    const handleAddCategories = (categories: Category[]) => {
        setSelectedCategories([...categories, ...selectedCategories]);
        setHighlightedIds(categories.map((it) => it.id));
        setTimeout(() => setHighlightedIds([]), 1000);
    };

    const handleAddCategoriesByIds = (result: SearchIdsResult) => {
        handleAddCategories(result.matchedItems);
        setSearchIdResult(result);
        setTimeout(() => setSearchIdResult(undefined), 10000);
    };

    const handleRemoveCategory = (category: Category) => {
        setSelectedCategories((prevState) => prevState.filter((it) => it.id !== category.id));
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmit(selectedCategories);
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textDeals(`targeting_origin.${DealTargetingOriginField.CONTEXTUAL}.title_edit`)}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                        <FormLayoutRows spacing={FlexContentSpacing.XSMALL}>
                            <SelectCategory
                                value={undefined}
                                excludedCategories={selectedCategories}
                                onChange={(category) => category && handleAddCategories([category])}
                            />
                            <SearchIds items={categories} selectedItems={selectedCategories} onSubmit={handleAddCategoriesByIds}/>
                            {searchIdResult?.messages.map((message) =>
                                <Alert
                                    key={message.message}
                                    text={message.message}
                                    severity={message.type === FormFieldMessageType.ERROR ? AlertSeverity.DANGER : message.type === FormFieldMessageType.WARNING ? AlertSeverity.WARNING : AlertSeverity.SUCCESS}
                                />
                            )}
                        </FormLayoutRows>
                        <FieldBlock
                            label={textDeals(`targeting_origin.${DealTargetingOriginField.CONTEXTUAL}.selected`, {count: selectedCategories.length})}
                            actions={<ButtonLink onClick={() => setSelectedCategories([])}>{textCommon(Action.REMOVE_ALL.labelKey)}</ButtonLink>}
                        >
                            <ElementList placeholder={textDeals(`targeting_origin.${DealTargetingOriginField.CONTEXTUAL}.search_result`)} size={ElementListSize.BIG}>
                                {selectedCategories.map((item) =>
                                    <TagCategoryRow
                                        key={item.id}
                                        category={item}
                                        isHighlighted={highlightedIds?.some((id) => item.id === id)}
                                        onRemove={() => handleRemoveCategory(item)}
                                    />
                                )}
                            </ElementList>
                        </FieldBlock>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalEditDealTargetingOriginContextual;
