import {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Box, BoxRadius, ButtonLinkCancel, ButtonValidate, Checkbox, ContentBlock, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, Paragraph} from "@sirdata/ui-lib";
import {CmpCloudflareEndpoint} from "../../../api/model/cmp/cloudflare/CmpCloudflareEndpoint";
import {usePreprod} from "../../../common/utils/helper";
import {TranslationPortalFile} from "../../../utils/constants";

export type ModalFlushCmpConfigurationsProps = {
    active: boolean;
    onSubmit: (cmpCloudflareEndpoints: CmpCloudflareEndpoint[]) => void;
    onClose: () => void;
};

const ModalFlushCmpConfigurations: FunctionComponent<ModalFlushCmpConfigurationsProps> = ({active, onSubmit, onClose}) => {
    const {t: textCmpConfigurations} = useTranslation(TranslationPortalFile.CMP_CONFIGURATIONS);
    const [isLoading, setLoading] = useState(false);

    const handleSubmit = () => {
        setLoading(true);
        onSubmit(CmpCloudflareEndpoint.values());
    };

    useEffect(() => {
        if (active) {
            setLoading(false);
        }
    }, [active]);

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textCmpConfigurations("modal.flush_cmp_configurations.title")}/>
            </ModalHeader>
            <ModalContent>
                <ContentBlock>
                    <Paragraph>{textCmpConfigurations("modal.flush_cmp_configurations.description")}</Paragraph>
                    <Box radius={BoxRadius.MD}>
                        {CmpCloudflareEndpoint.values().map((endpoint) =>
                            <Checkbox
                                key={endpoint.name}
                                name={endpoint.name}
                                label={endpoint.getUrl(usePreprod)}
                                checked
                                onChange={() => {}}
                                disabled
                            />
                        )}
                    </Box>
                </ContentBlock>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate onClick={handleSubmit} loading={isLoading}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalFlushCmpConfigurations;
