import {
    ButtonLinkCancel,
    ButtonValidate,
    FieldBlock,
    Form,
    FormLayoutColumns,
    FormLayoutRows,
    FormValidationType,
    InputDate,
    InputDateType,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    TagStyle
} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {PartnerLicenseLinkPartner} from "../../../api/model/partner/license/PartnerLicenseLinkPartner";
import {TranslationPortalFile} from "../../../utils/constants";
import {SelectStatus, Tag} from "../../snippet";
import {PartnerLicenseLinkPartnerField} from "../../../api/model/partner/license/PartnerLicenseLinkPartnerField";
import {Status} from "../../../utils/Status";
import {session} from "../../../api/ApiSession";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import useAlert from "../../../utils/hooks/useAlert";

export type ModalEditLicenseLinkPartnerProps = {
    initLink: PartnerLicenseLinkPartner | undefined;
    onClose: (refresh: boolean) => void;
};

const ModalEditLicenseLinkPartner: FunctionComponent<ModalEditLicenseLinkPartnerProps> = ({initLink, onClose}) => {
    const alert = useAlert();
    const {t: textLicenses} = useTranslation(TranslationPortalFile.LICENSES);
    const [linkPartner, setLinkPartner] = useState<PartnerLicenseLinkPartner>(new PartnerLicenseLinkPartner());
    const FORM_ID = "form-edit-license-link-partner";

    useEffect(() => {
        if (initLink) {
            setLinkPartner(initLink);
        }
    }, [initLink]);

    const handleChange = (field: PartnerLicenseLinkPartnerField, value: any) => {
        setLinkPartner((prevState) => new PartnerLicenseLinkPartner({...prevState, [field]: value}));
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        try {
            if (linkPartner.active) {
                await session.restPartnerLicense.activate(linkPartner.license.id, linkPartner.partner_id, linkPartner.expires_at);
            } else {
                await session.restPartnerLicense.deactivate(linkPartner.license.id, linkPartner.partner_id);
            }
            alert.updateWithSuccess("license");
            onClose(true);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("license", e.message);
            }
        }
    };

    return (
        <ModalNew onClose={() => onClose(false)} active={!!initLink}>
            <ModalHeader>
                <ModalHeaderTitle title={textLicenses("modal.link.title_edit", {id: linkPartner.partner_id})}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FieldBlock label={textLicenses(`field.link_partner.${PartnerLicenseLinkPartnerField.LICENSE}`)} content={{noFullWidth: true}}>
                            <Tag label={linkPartner.license.name} style={TagStyle.PRIMARY_MIDNIGHT_LIGHT}/>
                        </FieldBlock>
                        <FormLayoutColumns>
                            <FieldBlock label={textLicenses("field.link_partner.status")}>
                                <SelectStatus
                                    value={linkPartner.active ? Status.ACTIVE.name : Status.INACTIVE.name}
                                    statuses={Status.getActiveStatuses()}
                                    onChange={(status) => handleChange(PartnerLicenseLinkPartnerField.ACTIVE, status === Status.ACTIVE)}
                                />
                            </FieldBlock>
                            <FieldBlock label={textLicenses(`field.link_partner.${PartnerLicenseLinkPartnerField.EXPIRES_AT}`)}>
                                <InputDate
                                    type={InputDateType.DATETIME_LOCAL}
                                    value={linkPartner.expires_at}
                                    onChange={(value) => handleChange(PartnerLicenseLinkPartnerField.EXPIRES_AT, value)}
                                    disabled={!linkPartner.active}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={() => onClose(false)}/>
                <ButtonValidate form={FORM_ID}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalEditLicenseLinkPartner;
