import {SelectAutocomplete} from "@sirdata/ui-lib";
import React, {FunctionComponent, useEffect, useState} from "react";
import {session} from "../../../api/ApiSession";
import {Partner} from "../../../api/model/partner/Partner";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {abbreviate} from "../../../common/utils/string";
import useAlert from "../../../utils/hooks/useAlert";
import {ApiService} from "../../../api/model/ApiService";

type SelectPartnerProps = {
    value: string | number | undefined;
    onChange: ((partner?: Partner) => void) | undefined;
    service?: ApiService;
    excludedPartners?: Partner[];
    onInput?: (e: React.FormEvent<HTMLInputElement>) => void;
    onInvalid?: (e: React.FormEvent<HTMLInputElement>) => void;
    small?: boolean;
    disabled?: boolean;
};

const SelectPartner: FunctionComponent<SelectPartnerProps> = ({value, onChange, service, excludedPartners, ...rest}) => {
    const alert = useAlert();
    const [isLoading, setLoading] = useState(true);
    const [partners, setPartners] = useState<Partner[]>([]);
    const [filteredPartners, setFilteredPartners] = useState<Partner[]>([]);

    useEffect(() => {
        (async () => {
            try {
                setPartners(await session.getPartnersByService(service));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("partners", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [service, alert]);

    useEffect(() => {
        if (!!excludedPartners?.length) {
            const newFilteredPartners = partners.filter((partner) => !excludedPartners?.some((it) => it.id === partner.id));
            setFilteredPartners(newFilteredPartners);
        } else {
            setFilteredPartners(partners);
        }
    }, [partners, excludedPartners]);

    const getPartnerName = (partner: Partner) => {
        let company = abbreviate(partner.company || "[UNKNOWN]", 30);
        if (partner.fullName && partner.fullName !== partner.company) {
            return `${company} (${abbreviate(partner.fullName, 30)})`;
        }
        return company;
    };

    return (
        <SelectAutocomplete
            value={value}
            options={filteredPartners.map((it) => ({
                value: it.id,
                label: `${it.id} - ${getPartnerName(it)}`,
                partner: it
            }))}
            onChange={(option) => onChange && onChange(option?.partner)}
            isLoading={isLoading}
            clearable
            {...rest}
        />
    );
};

export default SelectPartner;
