import {ButtonLinkCancel, ButtonValidate, FieldBlock, Form, FormLayoutColumns, FormLayoutRows, FormValidationType, InputDate, InputDateType, InputText, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, Select} from "@sirdata/ui-lib";
import {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {Notification} from "../../../api/model/notifications/Notification";
import {NotificationContent} from "../../../api/model/notifications/NotificationContent";
import {NotificationField} from "../../../api/model/notifications/NotificationField";
import {NOTIFICATION_TYPES, NotificationType} from "../../../api/model/notifications/NotificationType";
import {Locale} from "../../../common/utils/Locale";
import {TranslationPortalFile} from "../../../utils/constants";
import {SelectService} from "../../snippet";
import {ApiService} from "../../../api/model/ApiService";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import useAlert from "../../../utils/hooks/useAlert";
import {useHistory} from "react-router-dom";
import {NotificationContentField} from "../../../api/model/notifications/NotificationContentField";

export type ModalCreateNotificationProps = {
    active: boolean;
    onClose: () => void;
};

const ModalCreateNotification: FunctionComponent<ModalCreateNotificationProps> = ({active, onClose}) => {
    const alert = useAlert();
    const history = useHistory();
    const {t: textNotifications} = useTranslation(TranslationPortalFile.NOTIFICATIONS);
    const [notification, setNotification] = useState<Notification>(new Notification());
    const FORM_ID = "form-create-notification";

    useEffect(() => {
        if (active) {
            setNotification(new Notification({[NotificationField.SERVICE]: ApiService.ACCOUNT.name}));
        }
    }, [active]);

    const handleChange = (field: NotificationField, value: any) => {
        setNotification((prevState) => new Notification({...prevState, [field]: value}));
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        try {
            notification.user_id = session.userId;
            Locale.values().forEach((locale) => {
                notification.locales.set(locale, new NotificationContent({
                    [NotificationContentField.CALL_TO_ACTION]: textNotifications(`default.call_to_action.${locale}`)
                }));
            });
            const newNotification = await session.restNotification.create(notification);
            history.push(newNotification.getRoute());
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToCreate("notification", e.message);
            }
        }
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textNotifications("create_notification")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FieldBlock label={textNotifications(`field.${NotificationField.NAME}`)} required>
                            <InputText
                                value={notification.name}
                                onChange={(value) => handleChange(NotificationField.NAME, value)}
                            />
                        </FieldBlock>
                        <FormLayoutColumns>
                            <FieldBlock label={textNotifications(`field.${NotificationField.SERVICE}`)} required>
                                <SelectService
                                    value={notification.service}
                                    services={ApiService.portals()}
                                    onChange={(service) => handleChange(NotificationField.SERVICE, `${service?.name || ""}`)}
                                />
                            </FieldBlock>
                            <FieldBlock label={textNotifications(`field.${NotificationField.TYPE}`)} required>
                                <Select
                                    value={notification.type}
                                    options={NOTIFICATION_TYPES.map((it) => ({value: it, label: textNotifications(`type.${it}`)}))}
                                    onChange={(option) => handleChange(NotificationField.TYPE, option?.value as NotificationType)}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FormLayoutColumns columns={2}>
                            <FieldBlock label={textNotifications(`field.${NotificationField.EXPIRES_AT}`)} required>
                                <InputDate
                                    type={InputDateType.DATETIME_LOCAL}
                                    value={notification.expires_at}
                                    onChange={(value) => handleChange(NotificationField.EXPIRES_AT, value)}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalCreateNotification;
