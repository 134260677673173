import {
    Accordion,
    AccordionItem,
    AccordionItemContent,
    FieldBlock,
    FlexContent,
    FlexContentAlignment,
    FlexContentDirection,
    FlexContentLayout,
    FlexContentMobileDirection,
    FlexContentSpacing,
    FormLayoutColumns,
    FormLayoutRows,
    RadioButtons,
    Select,
    TagStyle,
    ToggleSwitch
} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {CmpConfigField} from "../../../api/model/cmp/config/CmpConfigField";
import {CmpConfigSettings} from "../../../api/model/cmp/config/CmpConfigSettings";
import {CmpConfigSettingsField} from "../../../api/model/cmp/config/CmpConfigSettingsField";
import {CmpConfigThemeField} from "../../../api/model/cmp/config/theme/CmpConfigThemeField";
import {CmpConfigThemeModeField} from "../../../api/model/cmp/config/theme/CmpConfigThemeModeField";
import {CmpConfigThemeToolbarField} from "../../../api/model/cmp/config/theme/CmpConfigThemeToolbarField";
import {CMP_CONFIG_THEME_TOOLBAR_POSITIONS, CmpConfigThemeToolbarPosition} from "../../../api/model/cmp/config/theme/CmpConfigThemeToolbarPosition";
import {CMP_CONFIG_THEME_TOOLBAR_SIZES, CmpConfigThemeToolbarSize} from "../../../api/model/cmp/config/theme/CmpConfigThemeToolbarSize";
import {CmpConfigThemeToolbarStyle} from "../../../api/model/cmp/config/theme/CmpConfigThemeToolbarStyle";
import {TranslationPortalFile} from "../../../utils/constants";
import {CmpConfigurationPreview, CmpConfigurationThemeImages, Tag} from "../index";
import {CmpConfig} from "../../../api/model/cmp/config/CmpConfig";
import {CmpConfigThemeMode} from "../../../api/model/cmp/config/theme/CmpConfigThemeMode";
import {CmpConfigThemeToolbar} from "../../../api/model/cmp/config/theme/CmpConfigThemeToolbar";
import {CmpConfigTheme} from "../../../api/model/cmp/config/theme/CmpConfigTheme";

type CmpConfigurationSettingsImagesAndButtonProps = {
    partnerId: number;
    cmpConfig: CmpConfig;
    settings: CmpConfigSettings;
    onChange: (settings: CmpConfigSettings) => void;
};

const CmpConfigurationSettingsImagesAndButton: FunctionComponent<CmpConfigurationSettingsImagesAndButtonProps> = ({partnerId, cmpConfig, settings, onChange}) => {
    const {t: textCmpConfigurations} = useTranslation(TranslationPortalFile.CMP_CONFIGURATIONS);

    const handleChangeSettings = (field: CmpConfigSettingsField, value: any) => {
        onChange(new CmpConfigSettings({...settings, [field]: value}));
    };

    const handleChangeSettingsTheme = (field: CmpConfigThemeField, value: any) => {
        handleChangeSettings(CmpConfigSettingsField.THEME, new CmpConfigTheme({...settings.theme, [field]: value}));
    };

    const handleChangeSettingsThemeToolbar = (field: CmpConfigThemeToolbarField, value: any) => {
        handleChangeSettingsTheme(CmpConfigThemeField.TOOLBAR, new CmpConfigThemeToolbar({...settings.theme.toolbar, [field]: value}));
    };

    const handleChangeSettingsThemeModeImage = (mode: CmpConfigThemeField.LIGHT_MODE | CmpConfigThemeField.DARK_MODE, field: CmpConfigThemeModeField, newImage: string = "", applyBothMode?: boolean) => {
        const newConfigSettings = new CmpConfigSettings(settings);
        if (applyBothMode) {
            newConfigSettings.theme.lightMode[field] = newImage;
            newConfigSettings.theme.darkMode[field] = newImage;
        } else {
            newConfigSettings.theme[mode][field] = newImage;
        }
        onChange(newConfigSettings);
    };

    return (
        <FlexContent
            direction={FlexContentDirection.ROW}
            alignment={FlexContentAlignment.START}
            spacing={FlexContentSpacing.LARGE_PLUS}
            layout={FlexContentLayout.TWO_COLUMNS_WIDE_RIGHT}
            mobileDirection={FlexContentMobileDirection.COLUMN}
        >
            <Accordion>
                <AccordionItem heading={<Tag label={textCmpConfigurations("section.images")} icon={{name: "wallpaper"}} style={TagStyle.DEFAULT_OCEAN}/>} startOpen>
                    <AccordionItemContent>
                        <FormLayoutRows>
                            <FieldBlock label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeModeField.LOGO}`)}>
                                <CmpConfigurationThemeImages
                                    theme={settings.theme}
                                    field={CmpConfigThemeModeField.LOGO}
                                    onChange={handleChangeSettingsThemeModeImage}
                                    onDelete={handleChangeSettingsThemeModeImage}
                                    partnerId={partnerId}
                                    maxFileSize={CmpConfigThemeMode.LOGO_MAX_FILE_SIZE}
                                />
                            </FieldBlock>
                            <FieldBlock label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeModeField.SKIN}`)}>
                                <CmpConfigurationThemeImages
                                    theme={settings.theme}
                                    field={CmpConfigThemeModeField.SKIN}
                                    onChange={handleChangeSettingsThemeModeImage}
                                    onDelete={handleChangeSettingsThemeModeImage}
                                    partnerId={partnerId}
                                    maxFileSize={CmpConfigThemeMode.SKIN_MAX_FILE_SIZE}
                                />
                            </FieldBlock>
                            <FieldBlock label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeModeField.WATERMARK}`)}>
                                <CmpConfigurationThemeImages
                                    theme={settings.theme}
                                    field={CmpConfigThemeModeField.WATERMARK}
                                    onChange={handleChangeSettingsThemeModeImage}
                                    onDelete={handleChangeSettingsThemeModeImage}
                                    partnerId={partnerId}
                                />
                            </FieldBlock>
                            <FieldBlock
                                name={`${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.WHITE_LABEL}`}
                                label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.WHITE_LABEL}`)}
                            >
                                <ToggleSwitch
                                    label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.WHITE_LABEL}_label`)}
                                    checked={settings.theme.whiteLabel}
                                    onChange={(value) => handleChangeSettingsTheme(CmpConfigThemeField.WHITE_LABEL, value)}
                                />
                            </FieldBlock>
                        </FormLayoutRows>
                    </AccordionItemContent>
                </AccordionItem>
                <AccordionItem heading={<Tag label={textCmpConfigurations("section.redisplay_button")} icon={{name: "picture_in_picture_alt"}} style={TagStyle.DEFAULT_OCEAN}/>}>
                    <AccordionItemContent>
                        <FormLayoutRows>
                            <ToggleSwitch
                                name={CmpConfigThemeToolbarField.ACTIVE}
                                label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.TOOLBAR}.${CmpConfigThemeToolbarField.ACTIVE}`)}
                                checked={settings.theme.toolbar.active}
                                onChange={(value) => handleChangeSettingsThemeToolbar(CmpConfigThemeToolbarField.ACTIVE, value)}
                            />
                            <FieldBlock label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.TOOLBAR}.${CmpConfigThemeToolbarField.STYLE}`)}>
                                <Select
                                    value={settings.theme.toolbar.style}
                                    options={CmpConfigThemeToolbarStyle.values().map((it) => ({
                                        value: it.name,
                                        label: textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.TOOLBAR}.${CmpConfigThemeToolbarField.STYLE}_${it.name}`)
                                    }))}
                                    onChange={(option) => handleChangeSettingsThemeToolbar(CmpConfigThemeToolbarField.STYLE, option?.value)}
                                />
                            </FieldBlock>
                            <FormLayoutColumns columns={3}>
                                <FieldBlock label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.TOOLBAR}.${CmpConfigThemeToolbarField.SIZE}`)}>
                                    <RadioButtons
                                        id={CmpConfigThemeToolbarField.SIZE}
                                        value={settings.theme.toolbar.size}
                                        options={CMP_CONFIG_THEME_TOOLBAR_SIZES.map((it) => ({
                                            value: it,
                                            label: textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.TOOLBAR}.${CmpConfigThemeToolbarField.SIZE}_${it}`)
                                        }))}
                                        onChange={(value) => handleChangeSettingsThemeToolbar(CmpConfigThemeToolbarField.SIZE, value as CmpConfigThemeToolbarSize)}
                                    />
                                </FieldBlock>
                                <FieldBlock label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.TOOLBAR}.${CmpConfigThemeToolbarField.POSITION}`)}>
                                    <RadioButtons
                                        id={CmpConfigThemeToolbarField.POSITION}
                                        value={settings.theme.toolbar.position}
                                        options={CMP_CONFIG_THEME_TOOLBAR_POSITIONS.map((it) => ({
                                            value: it,
                                            label: textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.TOOLBAR}.${CmpConfigThemeToolbarField.POSITION}_${it}`)
                                        }))}
                                        onChange={(value) => handleChangeSettingsThemeToolbar(CmpConfigThemeToolbarField.POSITION, value as CmpConfigThemeToolbarPosition)}
                                    />
                                </FieldBlock>
                            </FormLayoutColumns>
                        </FormLayoutRows>
                    </AccordionItemContent>
                </AccordionItem>
            </Accordion>
            <CmpConfigurationPreview cmpConfig={cmpConfig}/>
        </FlexContent>
    );
};

export default CmpConfigurationSettingsImagesAndButton;
