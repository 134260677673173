import {
    ButtonLinkCancel,
    ButtonValidate,
    FieldBlock,
    FlexContentDirection,
    Form,
    FormLayoutRows,
    FormValidationType,
    InputTextNumber,
    ModalActions,
    ModalContent,
    ModalDescription,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    ToggleSwitch
} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {Authorization} from "../../../api/model/account/Authorization";
import {Currency} from "../../../api/model/currency/Currency";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {RestrictedContent} from "../../../common/component/widget";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";

export type ModalEditCurrencyRatesCategoriesProps = {
    active: boolean;
    onClose: () => void;
};

const ModalEditCurrencyRatesCategories: FunctionComponent<ModalEditCurrencyRatesCategoriesProps> = ({active, onClose}) => {
    const alert = useAlert();
    const {t: textCategories} = useTranslation(TranslationPortalFile.CATEGORIES);
    const [isLoading, setLoading] = useState(true);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [isRefreshPrices, setRefreshPrices] = useState(false);
    const FORM_ID = "form-edit-currency-rates-categories";

    useEffect(() => {
        if (!active) return;

        (async () => {
            try {
                setCurrencies(await session.restCurrency.list());
                setRefreshPrices(false);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("currencies", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [active, alert]);

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (isRefreshPrices) {
            try {
                await session.restCategory.refreshPrices();
                alert.updateWithSuccess("category's prices");
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToUpdate("category's prices", e.message);
                }
            }
        }

        onClose();
    };

    return (
        <ModalNew onClose={onClose} active={active} loading={isLoading}>
            <ModalHeader>
                <ModalHeaderTitle title={textCategories("modal.currency_rates.title")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <ModalDescription>{textCategories("modal.currency_rates.description")}</ModalDescription>
                        {currencies.map((item) =>
                            <FieldBlock
                                key={item.id}
                                label={textCategories(`field.currency.${item.currency.toLowerCase()}`)}
                                content={{direction: FlexContentDirection.COLUMN}}
                                inline
                            >
                                <InputTextNumber
                                    value={item.exchange_rate}
                                    onChange={() => {}}
                                    minValue={0}
                                    disabled
                                />
                            </FieldBlock>
                        )}
                        <RestrictedContent allowedTo={Authorization.CATEGORIES.update}>
                            <ToggleSwitch
                                name="refreshPrices"
                                checked={isRefreshPrices}
                                onChange={setRefreshPrices}
                                label={textCategories("field.refresh_prices")}
                            />
                        </RestrictedContent>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalEditCurrencyRatesCategories;
