import {Box, BoxRadius, BoxSpacing, FieldBlock, FlexContent, FlexContentDirection, FlexContentSpacing, FormLayoutRows, FormLayoutTitle, InputText, ToggleSwitch} from "@sirdata/ui-lib";
import {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {CmpConfigSettingsField} from "../../../api/model/cmp/config/CmpConfigSettingsField";
import {CmpConfigExternalField} from "../../../api/model/cmp/config/CmpConfigExternalField";
import {CmpConfigField} from "../../../api/model/cmp/config/CmpConfigField";
import {CmpConfigExternal} from "../../../api/model/cmp/config/CmpConfigExternal";

type CmpConfigurationExternalProps = {
    external: CmpConfigExternal;
    onChangeExternal: (external: CmpConfigExternal) => void;
};

type CmpConfigurationExternalItem = {
    field: CmpConfigExternalField;
    title: string;
    logo: string;
}

const EXTERNAL_ITEMS: CmpConfigurationExternalItem[] = [
    {field: CmpConfigExternalField.GOOGLE_ANALYTICS, title: "Google Consent Mode", logo: "/images/logos/google-consent-mode.png"},
    {field: CmpConfigExternalField.GOOGLE_AD_SENSE, title: "Google AdSense", logo: "/images/logos/google-ad-sense.png"},
    {field: CmpConfigExternalField.GOOGLE_AD_MANAGER, title: "Google Ad Manager", logo: "/images/logos/google-ad-manager.png"},
    {field: CmpConfigExternalField.GTM_DATA_LAYER, title: "Google Tag Manager", logo: "/images/logos/google-tag-manager.png"},
    {field: CmpConfigExternalField.SHOPIFY, title: "Shopify Customer Privacy", logo: "/images/logos/shopify.png"},
    {field: CmpConfigExternalField.MICROSOFT_UET, title: "Microsoft Consent Mode (UET)", logo: "/images/logos/microsoft.png"},
    {field: CmpConfigExternalField.CONSENT_MODE_FROM_TCF, title: "TCF/Google Consent Mode", logo: "/images/logos/google-ads.png"}
];

const CmpConfigurationExternal: FunctionComponent<CmpConfigurationExternalProps> = ({external, onChangeExternal}) => {
    const {t: textCmpConfigurations} = useTranslation(TranslationPortalFile.CMP_CONFIGURATIONS);

    return (
        <FlexContent
            direction={FlexContentDirection.COLUMN}
            spacing={FlexContentSpacing.SMALL}
            cssClass="cmp-add-ons"
        >
            {EXTERNAL_ITEMS.map((item) => (
                <Box
                    key={item.field}
                    radius={BoxRadius.MD}
                    spacing={BoxSpacing.MEDIUM}
                    cssClass={"cmp-add-ons__item"}
                >
                    <div className={"cmp-add-ons__item__image-container"}>
                        <img src={item.logo} alt={""}/>
                    </div>
                    <FormLayoutRows>
                        <FormLayoutTitle>{item.title}</FormLayoutTitle>
                        {item.field === CmpConfigExternalField.GTM_DATA_LAYER ?
                            <FieldBlock label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.EXTERNAL}.${CmpConfigExternalField.GTM_DATA_LAYER}`)} inline>
                                <InputText
                                    placeholder={"dataLayer"}
                                    value={external.gtmDataLayer}
                                    onChange={(value) => onChangeExternal(new CmpConfigExternal({...external, [CmpConfigExternalField.GTM_DATA_LAYER]: value}))}
                                />
                            </FieldBlock> :
                            <ToggleSwitch
                                name={item.field}
                                label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.EXTERNAL}.${item.field}`)}
                                checked={Boolean(external[item.field])}
                                onChange={(value) => onChangeExternal(new CmpConfigExternal({...external, [item.field]: value}))}
                            />
                        }
                    </FormLayoutRows>
                </Box>
            ))}
        </FlexContent>
    );
};

export default CmpConfigurationExternal;
