import {Rest} from "../../common/api/rest/Rest";
import {DeleteRevenueResponse} from "../model/revenue/DeleteRevenueResponse";
import {RevenueImportRequest} from "../model/revenue/import/RevenueImportRequest";
import {RevenueImportResponse} from "../model/revenue/import/RevenueImportResponse";
import {RevenueImportSearchQuery} from "../model/revenue/import/RevenueImportSearchQuery";
import {RevenueImportSearchResult} from "../model/revenue/import/RevenueImportSearchResult";
import {RevenuePlatform} from "../model/revenue/RevenuePlatform";

export class RestRevenuesImport extends Rest {
    rootPath = "/console-api/revenue";

    list(params: RevenueImportSearchQuery): Promise<RevenueImportSearchResult> {
        return this._client.get(params.owner !== "" ? `${this.rootPath}/import/user/${params.owner}` : `${this.rootPath}/import/user`, RevenueImportSearchResult, {queryParams: params.getJsonParameters()}) as Promise<RevenueImportSearchResult>;
    }

    create(importRequest: RevenueImportRequest): Promise<RevenueImportResponse> {
        return this._client.post(`${this.rootPath}${importRequest.getApiRoute()}`, importRequest.file_buffer, RevenueImportResponse) as Promise<RevenueImportResponse>;
    }

    delete(importId: string): Promise<DeleteRevenueResponse> {
        return this._client.delete(`${this.rootPath}/import/uuid/${importId}/revenue`) as Promise<DeleteRevenueResponse>;
    }

    reupload(importId: string): Promise<RevenueImportResponse> {
        return this._client.post(`${this.rootPath}/import/uuid/${importId}/re-upload`, undefined, RevenueImportResponse) as Promise<RevenueImportResponse>;
    }

    downloadTemplateFile(): Promise<Blob> {
        return this._client.get(`${this.rootPath}/import/template`) as Promise<Blob>;
    }

    getPlatforms(): Promise<RevenuePlatform[]> {
        return this._client.get(`${this.rootPath}/platform`, RevenuePlatform) as Promise<RevenuePlatform[]>;
    }
}
