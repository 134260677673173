import {Action, ActionsMenu, TableActionColumn, TableColumn, TableColumnStyle, TableRow} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {Authorization} from "../../../../api/model/account/Authorization";
import {Partner} from "../../../../api/model/partner/Partner";
import {RestrictedContent} from "../../../../common/component/widget";
import {TranslationCommonFile} from "../../../../common/utils/constants";
import {Formatter} from "../../../../common/utils/Formatter";
import {PARTNER_CONNECT_ORIGINS} from "../../../../screen/partners/PartnersConnect";
import {TranslationPortalFile} from "../../../../utils/constants";
import {onClickLink} from "../../../../utils/helper";
import {LabelService, LabelStatus, LabelUser} from "../../index";

type PartnerRowProps = {
    partner: Partner;
}

const PartnerRow: FunctionComponent<PartnerRowProps> = ({partner}) => {
    const {t: textPartners} = useTranslation(TranslationPortalFile.PARTNERS);
    const {t: textMain} = useTranslation(TranslationCommonFile.MAIN);
    const history = useHistory();

    return (
        <TableRow onClick={(e) => onClickLink(e, partner.getRoute(), history)}>
            <TableColumn>
                <LabelStatus status={partner.getStatus()}/>
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{partner.id}</TableColumn>
            <TableColumn styles={TableColumnStyle.HIDE_SCREEN_MEDIUM}>{partner.company}</TableColumn>
            <TableColumn>{partner.fullName}</TableColumn>
            <TableColumn styles={[TableColumnStyle.FIXED_WIDTH, TableColumnStyle.HIDE_SCREEN_MEDIUM]}>{partner.email}</TableColumn>
            <TableColumn styles={[TableColumnStyle.HIDE_SCREEN_MEDIUM]}>
                {Formatter.formatDate(partner.created_at, Formatter.DATETIME_FORMAT)}
            </TableColumn>
            <TableColumn styles={[TableColumnStyle.HIDE_SCREEN_MEDIUM]}>
                <LabelService service={partner.creation_service}/>
            </TableColumn>
            <TableColumn styles={[TableColumnStyle.ALIGN_CENTER, TableColumnStyle.HIDE_SCREEN_MEDIUM]}>
                {!!partner.user && <LabelUser user={partner.user.substring(0, 3).toUpperCase()}/>}
            </TableColumn>
            <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                <RestrictedContent allowedTo={Authorization.LOG_AS_PARTNER.name}>
                    <ActionsMenu
                        iconTooltip={{icon: Action.OPEN.icon, text: textPartners("actions.go_to_partner_area")}}
                        items={
                            PARTNER_CONNECT_ORIGINS.map((origin) => (
                                {label: textMain(`menu.origins.${origin.name}`), onClick: () => window.open(partner.getPartnerAreaRoute(origin), "_blank")}
                            ))
                        }
                    />
                </RestrictedContent>
            </TableActionColumn>
        </TableRow>
    );
};

export default PartnerRow;
