import {Action, ButtonLinkCancel, ButtonValidate, ElementList, ElementListSize, FlexContentSpacing, Form, FormLayoutRows, FormValidationType, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, SelectAutocomplete, Tag as LibTag, TagSize, TagStyle} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {ApiAuthorization} from "../../../api/model/auth/ApiAuthorization";
import {session} from "../../../api/ApiSession";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import useAlert from "../../../utils/hooks/useAlert";

export type ModalEditUserAuthorizationsProps = {
    userId: number;
    userAuthorizations: ApiAuthorization[];
    onSubmit: (authorizations: ApiAuthorization[]) => void;
    onClose: () => void;
    active: boolean;
};

const ModalEditUserAuthorizations: FunctionComponent<ModalEditUserAuthorizationsProps> = ({userId, userAuthorizations, onSubmit, onClose, active}) => {
    const alert = useAlert();
    const {t: textUsers} = useTranslation(TranslationPortalFile.USERS);
    const [authorizations, setAuthorizations] = useState<ApiAuthorization[]>([]);
    const [selectedAuthorizations, setSelectedAuthorizations] = useState<ApiAuthorization[]>([]);
    const FORM_ID = "form-edit-user-authorizations";

    useEffect(() => {
        if (!active) return;

        setSelectedAuthorizations([...userAuthorizations]);

        (async () => {
            try {
                setAuthorizations(await session.restUserAuthorization.list());
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("authorizations", e.message);
                }
            }
        })();
    }, [userAuthorizations, active, alert]);

    const handleAddAuthorization = (authorization: ApiAuthorization) => {
        setSelectedAuthorizations((prevState) => ([...prevState, authorization]));
    };

    const handleRemoveAuthorization = (authorization: ApiAuthorization) => {
        setSelectedAuthorizations((prevState) => prevState.filter((it) => it.name !== authorization.name));
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        try {
            const authorizationsToRemove = userAuthorizations.filter((authorization) => !selectedAuthorizations.some((it) => it.name === authorization.name));
            for (let authorization of authorizationsToRemove) {
                await session.restUserAuthorization.remove(userId, authorization.name);
            }
            const authorizationsToAdd = selectedAuthorizations.filter((authorization) => !userAuthorizations.some((it) => it.name === authorization.name));
            await session.restUserAuthorization.add(userId, authorizationsToAdd.map((it) => it.name));

            alert.updateWithSuccess("user's authorizations");
            onSubmit(selectedAuthorizations);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("user's authorizations", e.message);
            }
        }
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textUsers("section.authorizations")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmitCapture={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                        <SelectAutocomplete
                            options={authorizations.filter((it) => !selectedAuthorizations.some((authorization) => authorization.name === it.name)).map((it) =>
                                ({label: it.label, value: it.name, authorization: it}))
                            }
                            onChange={(option) => option && handleAddAuthorization(option.authorization as ApiAuthorization)}
                            closeMenuOnSelect={false}
                        />
                        <ElementList size={ElementListSize.BIG}>
                            {selectedAuthorizations.map((authorization) =>
                                <LibTag
                                    key={authorization.name}
                                    size={TagSize.MEDIUM}
                                    style={userAuthorizations.some((it) => it.name === authorization.name) ? TagStyle.PRIMARY_MIDNIGHT_LIGHT : TagStyle.DEFAULT_MIDNIGHT_LIGHT}
                                    label={authorization.label}
                                    icon={{...Action.REMOVE.icon, onClick: () => handleRemoveAuthorization(authorization)}}
                                    reverse
                                />
                            )}
                        </ElementList>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalEditUserAuthorizations;
