import {Action, ContentBlock, ContentBlockAction, Loadable, SearchToolbar, Table, TableColumnStyle} from "@sirdata/ui-lib";
import copy from "copy-to-clipboard";
import React, {FC, RefObject, useCallback, useEffect, useImperativeHandle, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {Authorization} from "../../../api/model/account/Authorization";
import {Category} from "../../../api/model/audience/category/Category";
import {SegmentDataType} from "../../../api/model/audience/segment/SegmentDataType";
import {SegmentLinkCategory} from "../../../api/model/audience/segment/SegmentLinkCategory";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {RestrictedContent} from "../../../common/component/widget";
import {copyApiModelArray} from "../../../common/utils/helper";
import {detectChanges} from "../../../common/utils/portal";
import {SegmentsDetailsSubModule} from "../../../screen/segments/SegmentsDetails";
import {HandleSaveRef} from "../../../utils/audience/HandleSaveRef";
import {SegmentTypeDefaultValue} from "../../../utils/audience/SegmentTypeDefaultValue";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import useSearch from "../../../utils/hooks/useSearch";
import ModalAddSegmentLinksCategory from "../../modal/segments/ModalAddSegmentLinksCategory";
import {SegmentLinkCategoryRow} from "../index";

type SegmentCategoryLinksProps = {
    segmentId: number;
    segmentType?: SegmentDataType;
    percentile?: string;
    handleSaveRef: RefObject<HandleSaveRef>;
    onChange: (module: SegmentsDetailsSubModule, hasChanges: boolean) => void;
};

const SegmentCategoryLinks: FC<SegmentCategoryLinksProps> = ({segmentId, segmentType, percentile, handleSaveRef, onChange}) => {
    const alert = useAlert();
    const {t: textSegments} = useTranslation(TranslationPortalFile.SEGMENTS);
    const {t: textCategories} = useTranslation(TranslationPortalFile.CATEGORIES);
    const [isLoading, setLoading] = useState(true);
    const [isMounted, setMounted] = useState(false);
    const [currentPercentile, setCurrentPercentile] = useState<string>();

    const [categoryLinks, setCategoryLinks] = useState<SegmentLinkCategory[]>([]);
    const [initCategoryLinks, setInitCategoryLinks] = useState<SegmentLinkCategory[]>([]);
    const {buildSearchResult, ...search} = useSearch(SegmentLinkCategory);

    const [isShowModalAddSegmentLinksCategory, setShowModalAddSegmentLinksCategory] = useState(false);

    const updateCategoryLinks = useCallback((newCategoryLinks: SegmentLinkCategory[]) => {
        setCategoryLinks(newCategoryLinks);
    }, []);

    const handleSaveLinksCategory = async (linksCategory?: SegmentLinkCategory[]) => {
        if (!linksCategory) {
            linksCategory = categoryLinks;
        }
        setLoading(true);
        try {
            const newCategoryLinks = await session.restSegmentLinkCategory.update(segmentId, linksCategory);
            setCategoryLinks(newCategoryLinks);
            setInitCategoryLinks(copyApiModelArray(SegmentLinkCategory, newCategoryLinks));
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("categories", e.message);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isMounted || !segmentId) return;
        (async function () {
            try {
                const newCategoryLinks = await session.restSegmentLinkCategory.list(segmentId);
                setCategoryLinks(newCategoryLinks);
                setInitCategoryLinks(copyApiModelArray(SegmentLinkCategory, newCategoryLinks));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("categories", e.message);
                }
            } finally {
                setLoading(false);
                setMounted(true);
            }
        })();
    }, [isMounted, segmentId, alert]);

    useEffect(() => {
        onChange(SegmentsDetailsSubModule.LINKS_CATEGORIES, detectChanges(categoryLinks, initCategoryLinks));
    }, [onChange, categoryLinks, initCategoryLinks]);

    useEffect(() => {
        if (!percentile || currentPercentile === percentile) return;
        setCurrentPercentile(percentile);
        if (categoryLinks.length && initCategoryLinks.length) {
            const newCategoryLinks = categoryLinks.map((link) => new SegmentLinkCategory({...link, percentile: percentile}));
            updateCategoryLinks(newCategoryLinks);
        }
    }, [percentile, currentPercentile, initCategoryLinks.length, categoryLinks, updateCategoryLinks]);

    useEffect(() => {
        let currentCategoryLinks = (categoryLinks || []);
        if (search.searchQuery.query) {
            currentCategoryLinks = currentCategoryLinks.filter((it) => `${it.category_id} ${it.category_name}`.toLowerCase().includes(search.searchQuery.query.toLowerCase()));
        }
        buildSearchResult(currentCategoryLinks);
    }, [categoryLinks, search.searchQuery, buildSearchResult]);

    useImperativeHandle(handleSaveRef, () => ({
        handleSave: handleSaveLinksCategory
    }));

    const handleCopyCategoryIds = () => {
        const categoryIdsAsString = categoryLinks.map((item) => item.category_id).sort().join(",");
        copy(categoryIdsAsString);
        alert.copied("category IDs");
    };

    const handleAddLinksCategory = async (categories: Category[]) => {
        let volumes = new Map<string, number>();
        try {
            const result = await session.restSegmentLinkCategory.getCategoryVolumes(segmentId);
            volumes = new Map(Object.entries(result));
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToLoad("categories' volumes", e.message);
            }
        }

        const defaultValues = SegmentTypeDefaultValue.getByDataType(segmentType);
        const links = categories.map((item) => {
            return new SegmentLinkCategory({
                segment_id: segmentId,
                category_id: item.id,
                category_name: item.name,
                category_code_name: item.code_name,
                category_type: item.type,
                volume: volumes.get(item.id.toString()) || 0,
                min_score: defaultValues?.minScore,
                expiration_day: defaultValues?.expirationTime,
                percentile: !!percentile ? percentile : undefined
            });
        });
        await handleSaveLinksCategory([...links, ...categoryLinks]);
        search.changePage(1);
        setShowModalAddSegmentLinksCategory(false);
    };

    const handleDeleteCategoryLink = (link: SegmentLinkCategory) => {
        const newCategoryLinks = categoryLinks.filter((it) => it.category_id !== link.category_id);
        setCategoryLinks(newCategoryLinks);
        search.changePage(1);
    };

    return (
        <Loadable loading={isLoading}>
            <ContentBlock
                header={{title: {label: textSegments("section.categories")}, actions: [
                    <ContentBlockAction
                        key="action-copy-category-ids"
                        action={Action.COPY}
                        label={"Category IDs"}
                        onClick={handleCopyCategoryIds}
                    />,
                    <RestrictedContent key="action-add-segment-link-category" allowedTo={Authorization.SEGMENTS.update}>
                        <ContentBlockAction
                            action={Action.ADD}
                            onClick={() => setShowModalAddSegmentLinksCategory(true)}
                        />
                    </RestrictedContent>
                ]}}
            >
                <SearchToolbar searchBar={{placeholder: textCategories("search.placeholder"), value: search.searchQuery.query, onSubmit: search.changeQuery}}/>
                <Table
                    columns={[
                        {width: 15, label: textSegments("field.id"), styles: TableColumnStyle.ALIGN_CENTER},
                        {width: 50, label: textSegments("field.name"), styles: TableColumnStyle.FIXED_WIDTH},
                        {width: 10, label: textSegments("field.size"), styles: TableColumnStyle.ALIGN_CENTER},
                        {width: 10, label: textSegments("field.min_score"), styles: [TableColumnStyle.ALIGN_CENTER, TableColumnStyle.NOWRAP]},
                        {width: 5}
                    ]}
                    pagination={search.searchResult.getPagination(search.changePage)}
                >
                    {search.searchResult.elements.map((link) =>
                        <SegmentLinkCategoryRow
                            key={link.category_id}
                            link={link}
                            onRemove={handleDeleteCategoryLink}
                            showCategoryType={segmentType?.name === SegmentDataType.INFERRED.name}
                        />
                    )}
                </Table>
                <ModalAddSegmentLinksCategory
                    active={isShowModalAddSegmentLinksCategory}
                    segmentType={segmentType}
                    currentLinks={categoryLinks}
                    onSubmit={handleAddLinksCategory}
                    onClose={() => setShowModalAddSegmentLinksCategory(false)}
                />
            </ContentBlock>
        </Loadable>
    );
};

export default SegmentCategoryLinks;
