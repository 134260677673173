import {
    ButtonLinkCancel,
    ButtonValidate,
    FieldBlock,
    Form,
    FormLayoutColumns,
    FormValidationType,
    InputText,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew
} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {session} from "../../../api/ApiSession";
import {Distribution} from "../../../api/model/audience/distribution/Distribution";
import {DistributionField} from "../../../api/model/audience/distribution/DistributionField";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";

type ModalCreateDistributionProps = {
    active: boolean;
    onClose: () => void;
};

const ModalCreateDistribution: FunctionComponent<ModalCreateDistributionProps> = ({active, onClose}) => {
    const {t: textDistributions} = useTranslation(TranslationPortalFile.DISTRIBUTIONS);
    const [distribution, setDistribution] = useState<Distribution>(new Distribution());
    const alert = useAlert();
    const history = useHistory();
    const FORM_ID = "form-create-distribution";

    const handleChange = (field: DistributionField, value: any) => {
        setDistribution((prevState) => new Distribution({...prevState, [field]: value}));
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        try {
            const newDistribution = await session.restDistribution.create(distribution);
            history.push(newDistribution.getRoute());
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToCreate("distribution", e.message);
            }
        }
    };

    return (
        <ModalNew onClose={onClose} active={active} loading={false}>
            <ModalHeader>
                <ModalHeaderTitle title={textDistributions("modal.create_distribution")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutColumns>
                        <FieldBlock label={textDistributions(`field.${DistributionField.NAME}`)} required>
                            <InputText
                                value={distribution.name}
                                onChange={(value) => handleChange(DistributionField.NAME, value)}
                            />
                        </FieldBlock>
                        <FieldBlock label={textDistributions(`field.${DistributionField.LABEL}`)}>
                            <InputText
                                value={distribution.label}
                                onChange={(value) => handleChange(DistributionField.LABEL, value)}
                            />
                        </FieldBlock>
                    </FormLayoutColumns>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalCreateDistribution;
