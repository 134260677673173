import {FC} from "react";
import {useTranslation} from "react-i18next";
import {ContentBlock, Table, TableColumn, TableRow} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../../utils/constants";
import {SirdataListVersion} from "../../../api/model/cmp/list/sirdata-list-v2/SirdataListVersion";
import {SirdataListLanguage} from "../../../api/model/cmp/list/sirdata-list-v2/SirdataListLanguage";
import {SirdataListVersionField} from "../../../api/model/cmp/list/sirdata-list-v2/SirdataListVersionField";
import {SirdataListStackField} from "../../../api/model/cmp/list/sirdata-list-v2/SirdataListStackField";
import {CmpSirdataListV2EntityState} from "../index";

type CmpSirdataListV2StacksNewVersionProps = {
    sirdataList: SirdataListVersion;
    listLanguage: SirdataListLanguage;
};

const CmpSirdataListV2StacksNewVersion: FC<CmpSirdataListV2StacksNewVersionProps> = ({sirdataList, listLanguage}) => {
    const {t: textCmpSirdataList} = useTranslation(TranslationPortalFile.CMP_SIRDATA_LIST_V2);
    const filteredStacks = listLanguage.stacks.filter((stack) => !!stack.getState(sirdataList));

    return (
        <ContentBlock header={{title: {label: textCmpSirdataList(`field.${SirdataListVersionField.STACKS}`)}}}>
            <Table
                columns={[
                    {width: 5},
                    {width: 5, label: textCmpSirdataList(`field.stack.${SirdataListStackField.ID}`)},
                    {width: 90, label: textCmpSirdataList(`field.stack.${SirdataListStackField.NAME}`)}
                ]}
            >
                {filteredStacks.map((stack) =>
                    <TableRow key={stack.id}>
                        <TableColumn>
                            <CmpSirdataListV2EntityState state={stack.getState(sirdataList)}/>
                        </TableColumn>
                        <TableColumn>{stack.id}</TableColumn>
                        <TableColumn>{stack.name}</TableColumn>
                    </TableRow>
                )}
            </Table>
        </ContentBlock>
    );
};

export default CmpSirdataListV2StacksNewVersion;
