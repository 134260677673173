import {LayoutRows, Loadable, SearchField, SearchToolbar, Select, Tabs} from "@sirdata/ui-lib";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {CmpConfigLanguage} from "../../api/model/cmp/config/CmpConfigLanguage";
import {GlobalVendorList} from "../../api/model/cmp/list/global-vendor-list/GlobalVendorList";
import {SirdataListField} from "../../api/model/cmp/list/sirdata-list/SirdataListField";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, Wrapper} from "../../common/component/widget";
import {MainContentHeader, SirdataListStacks, SirdataListVendors} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import {Module} from "../../utils/Module";
import {SirdataListVersion} from "../../api/model/cmp/list/sirdata-list-v2/SirdataListVersion";

function CmpSirdataList() {
    const {t: textCmpLists} = useTranslation(TranslationPortalFile.CMP_LISTS);
    const {t: textCmpConfigurations} = useTranslation(TranslationPortalFile.CMP_CONFIGURATIONS);
    const alert = useAlert();

    const [isLoading, setLoading] = useState(true);
    const [globalVendorList, setGlobalVendorList] = useState<GlobalVendorList>(new GlobalVendorList());
    const [sirdataList, setSirdataList] = useState<SirdataListVersion>(new SirdataListVersion());
    const [language, setLanguage] = useState<CmpConfigLanguage>(CmpConfigLanguage.ENGLISH);

    useEffect(() => {
        (async function () {
            try {
                const sirdataList = await session.restCmpList.getSirdataList(language);
                setSirdataList(sirdataList);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("Sirdata List", e.message);
                }
            } finally {
                setLoading(false);
            }

            try {
                setGlobalVendorList(await session.restCmpList.getGlobalVendorList(language));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("Global Vendor List", e.message);
                }
            }
        })();
    }, [language, alert, textCmpLists]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.CMP_SIRDATA_LIST}/>
            <MainContent>
                <LayoutRows>
                    <SearchToolbar>
                        <SearchField label={textCmpLists("filter.language")}>
                            <Select
                                value={language.name}
                                options={CmpConfigLanguage.values().map((it) => ({value: it.name, label: textCmpConfigurations(`language.${it.name}`), language: it}))}
                                onChange={(option) => option && setLanguage(option.language)}
                            />
                        </SearchField>
                    </SearchToolbar>
                    <Loadable loading={isLoading}>
                        <Tabs
                            headers={[
                                {label: textCmpLists(`sirdata.field.${SirdataListField.VENDORS}`)},
                                {label: textCmpLists(`sirdata.field.${SirdataListField.STACKS}`)}
                            ]}
                        >
                            <SirdataListVendors
                                vendors={sirdataList.vendors}
                                purposes={globalVendorList.purposes}
                            />
                            <SirdataListStacks
                                stacks={sirdataList.stacks}
                                globalVendorList={globalVendorList}
                            />
                        </Tabs>
                    </Loadable>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default CmpSirdataList;
