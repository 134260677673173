export enum PartnerBillingInfoField {
    PARTNER_ID = "partner_id",
    COMPANY_NAME = "company_name",
    CONTACT_LASTNAME = "contact_lastname",
    CONTACT_FIRSTNAME = "contact_firstname",
    ADDRESS = "address",
    POSTAL_CODE = "postal_code",
    CITY = "city",
    COUNTRY_ISO = "country_iso",
    VAT = "vat",
    IBAN = "iban",
    BIC = "bic",
    BUSINESS_ID = "business_id",
    PAYMENT_METHOD = "payment_method",
    PAYMENT_TYPE = "payment_type",
    SMALL_BUSINESS = "small_business",
    AXONAUT_ID = "axonaut_id"
}
