import {Action, Box, BoxProps, ContentBlock, ElementList, ElementListSize, FieldBlock, Form, FormLayoutColumns, FormLayoutRows, FormValidationType, IconEdit, LayoutColumns, LayoutRows, Loadable, Select, TagStyle, Textarea} from "@sirdata/ui-lib";
import React, {FormEvent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import {session} from "../../api/ApiSession";
import {Category} from "../../api/model/audience/category/Category";
import {CategoryType} from "../../api/model/audience/category/CategoryType";
import {Segment} from "../../api/model/audience/segment/Segment";
import {TaxonomyTag} from "../../api/model/taxonomy/tag/TaxonomyTag";
import {TaxonomyTagField} from "../../api/model/taxonomy/tag/TaxonomyTagField";
import {TaxonomyTagLinkCategoryGroup} from "../../api/model/taxonomy/tag/TaxonomyTagLinkCategoryGroup";
import {TaxonomyTagLinkSegment} from "../../api/model/taxonomy/tag/TaxonomyTagLinkSegment";
import {TAXONOMY_TAG_PURPOSES} from "../../api/model/taxonomy/tag/TaxonomyTagPurpose";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {Language} from "../../common/api/model/Language";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, Wrapper} from "../../common/component/widget";
import {TranslationCommonFile} from "../../common/utils/constants";
import {detectChanges} from "../../common/utils/portal";
import ModalSelectCategories from "../../component/modal/categories/ModalSelectCategories";
import ModalConfirmDelete from "../../component/modal/ModalConfirmDelete";
import ModalSelectSegments from "../../component/modal/segments/ModalSelectSegments";
import {MainContentHeader, MainContentHeaderAction, Tag, TagCategoryRow, TagSegmentRow} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import {Module} from "../../utils/Module";
import useAlert from "../../utils/hooks/useAlert";

function TaxonomyTagsDetails() {
    const {t: textTags} = useTranslation(TranslationPortalFile.TAXONOMY_TAGS);
    const {t: textMain} = useTranslation(TranslationCommonFile.MAIN);
    const {id: tagName} = useParams<{ id: string }>();
    const history = useHistory();
    const alert = useAlert();

    const [isLoading, setLoading] = useState(true);
    const [isLoadingLinks, setLoadingLinks] = useState(true);
    const [isUnsavedChanges, setUnsavedChanges] = useState(false);
    const [isActiveDelete, setActiveDelete] = useState(false);
    const [isShowModalEditLinksSegments, setShowModalEditLinksSegments] = useState(false);
    const [isShowModalEditLinksCategories, setShowModalEditLinksCategories] = useState(false);

    const FORM_ID = "form-edit-taxonomy-tag";
    const [tag, setTag] = useState<TaxonomyTag>(new TaxonomyTag());
    const [initTag, setInitTag] = useState<TaxonomyTag>(new TaxonomyTag());

    const [segmentTagLinks, setSegmentTagLinks] = useState<TaxonomyTagLinkSegment[]>([]);
    const [initSegmentTagLinks, setInitSegmentTagLinks] = useState<TaxonomyTagLinkSegment[]>([]);
    const [selectedSegments, setSelectedSegments] = useState<Segment[]>([]);

    const [categoryGroupTagLinks, setCategoryGroupTagLinks] = useState<TaxonomyTagLinkCategoryGroup[]>([]);
    const [initCategoryGroupTagLinks, setInitCategoryGroupTagLinks] = useState<TaxonomyTagLinkCategoryGroup[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);

    useEffect(() => {
        (async () => {
            try {
                const tag = await session.restTaxonomyTag.get(tagName);
                setTag(tag);
                setInitTag(new TaxonomyTag({...tag}));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    if (e.statusCode === HttpStatusCode.NOT_FOUND) {
                        session.emit(SirdataApiEvent.eventNotFound);
                    } else {
                        alert.failToLoad("tag", e.message);
                    }
                }
            } finally {
                setLoading(false);
            }
            try {
                const segmentLinks = await session.restTaxonomyTag.getSegments(tagName);
                setSegmentTagLinks(segmentLinks);
                setInitSegmentTagLinks([...segmentLinks]);
                const categoryLinks = await session.restTaxonomyTag.getCategoryGroups(tagName);
                setCategoryGroupTagLinks(categoryLinks);
                setInitCategoryGroupTagLinks([...categoryLinks]);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("linked audiences", e.message);
                }
            } finally {
                setLoadingLinks(false);
            }
        })();
    }, [tagName, alert]);

    useEffect(() => {
        (async () => {
            try {
                const segments = await session.getSegments();
                setSelectedSegments(segments.filter(({id}) => segmentTagLinks.some((link) => link.id_segment === id)));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("segments", e.message);
                }
            }
        })();
    }, [segmentTagLinks, alert]);

    useEffect(() => {
        (async () => {
            try {
                const categories = await session.getCategoriesByType(CategoryType.CONTEXTUAL);
                setSelectedCategories(categories.filter(({id_group}) => categoryGroupTagLinks.some((link) => link.category_group_id === id_group)));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("categories", e.message);
                }
            }
        })();
    }, [categoryGroupTagLinks, alert]);

    useEffect(() => {
        setUnsavedChanges(detectChanges(tag, initTag) || detectChanges(segmentTagLinks, initSegmentTagLinks) || detectChanges(categoryGroupTagLinks, initCategoryGroupTagLinks));
    }, [tag, initTag, segmentTagLinks, initSegmentTagLinks, categoryGroupTagLinks, initCategoryGroupTagLinks]);

    const handleSave = async (e: FormEvent) => {
        e.preventDefault();
        if (detectChanges(tag, initTag)) {
            try {
                await session.restTaxonomyTag.update(tag);
                setInitTag(new TaxonomyTag(tag));
                alert.updateWithSuccess("tag");
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToUpdate("tag", e.message);
                }
            }
        }

        if (detectChanges(segmentTagLinks, initSegmentTagLinks) || detectChanges(categoryGroupTagLinks, initCategoryGroupTagLinks)) {
            setLoadingLinks(true);
            try {
                for (let link of segmentTagLinks.filter(({id_segment}) => initSegmentTagLinks.findIndex((it) => it.id_segment === id_segment) === -1)) {
                    await session.restSegmentLinkTag.create(link);
                }
                for (let link of initSegmentTagLinks.filter(({id_segment}) => segmentTagLinks.findIndex((it) => it.id_segment === id_segment) === -1)) {
                    await session.restSegmentLinkTag.delete(link);
                }

                for (let link of categoryGroupTagLinks.filter(({category_group_id}) => initCategoryGroupTagLinks.findIndex((it) => it.category_group_id === category_group_id) === -1)) {
                    await session.restCategoryGroupLinkTag.create(link);
                }
                for (let link of initCategoryGroupTagLinks.filter(({category_group_id}) => categoryGroupTagLinks.findIndex((it) => it.category_group_id === category_group_id) === -1)) {
                    await session.restCategoryGroupLinkTag.delete(link);
                }
                alert.updateWithSuccess("audiences");

                setInitSegmentTagLinks([...segmentTagLinks]);
                setInitCategoryGroupTagLinks([...categoryGroupTagLinks]);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToUpdate("audiences", e.message);
                }
            } finally {
                setLoadingLinks(false);
            }
        }
    };

    const handleDelete = async () => {
        if (!isActiveDelete) return;
        try {
            await session.restTaxonomyTag.delete(tag);
            history.push(Module.TAXONOMY_TAGS.path);
            alert.deleteWithSuccess("tag");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToDelete("tag", e.message);
            }
        } finally {
            setActiveDelete(false);
        }
    };

    const handleSegmentLinks = (segments: Segment[]) => {
        setSegmentTagLinks(segments.map((segment) => new TaxonomyTagLinkSegment({id_segment: segment.id, tag_name: tag.name})));
        setShowModalEditLinksSegments(false);
    };

    const handleCategoryLinks = (categories: Category[]) => {
        setCategoryGroupTagLinks(categories.map((category) => new TaxonomyTagLinkCategoryGroup({category_group_id: category.id_group, tag_name: tag.name})));
        setShowModalEditLinksCategories(false);
    };

    return (
        <Wrapper>
            <MainHeader preventUnsaved={isUnsavedChanges}/>
            <MainContentHeader module={Module.TAXONOMY_TAGS} element={initTag.toContentElement()} preventUnsaved={isUnsavedChanges}>
                <MainContentHeaderAction action={Action.SAVE} form={FORM_ID} disabled={!isUnsavedChanges}/>
                <MainContentHeaderAction action={Action.DELETE} onClick={() => setActiveDelete(true)}/>
            </MainContentHeader>
            <MainContent>
                <Form id={FORM_ID} onSubmit={handleSave} validationType={FormValidationType.CUSTOM}>
                    <LayoutRows>
                        <LayoutColumns>
                            <Loadable loading={isLoading}>
                                <ContentBlock header={{title: {label: textTags("section.information")}}}>
                                    <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                        <FormLayoutRows>
                                            <FormLayoutColumns>
                                                <FieldBlock label={textTags(`field.${TaxonomyTagField.NAME}`)} content={{noFullWidth: true}}>
                                                    <Tag label={tag.name} style={TagStyle.PRIMARY_MIDNIGHT_LIGHT}/>
                                                </FieldBlock>
                                                <FieldBlock label={textTags(`field.${TaxonomyTagField.LANGUAGE}`)} required>
                                                    <Select
                                                        value={tag.language}
                                                        options={Language.values().map((language) => ({label: textMain(`language.${language.name}`), value: language.name}))}
                                                        onChange={(option) => setTag((prevState) => new TaxonomyTag({...prevState, [TaxonomyTagField.LANGUAGE]: `${option?.value || ""}`}))}
                                                    />
                                                </FieldBlock>
                                                <FieldBlock label={textTags(`field.${TaxonomyTagField.PURPOSE}`)} required>
                                                    <Select
                                                        value={tag.purpose}
                                                        options={TAXONOMY_TAG_PURPOSES.map((purpose) => ({label: textTags(`purpose.${purpose}`), value: purpose}))}
                                                        onChange={(option) => setTag((prevState) => new TaxonomyTag({...prevState, [TaxonomyTagField.PURPOSE]: `${option?.value || ""}`}))}
                                                    />
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                            <FieldBlock label={textTags(`field.${TaxonomyTagField.DESCRIPTION}`)}>
                                                <Textarea
                                                    value={tag.description}
                                                    rows={3}
                                                    onChange={(value) => setTag((prevState) => new TaxonomyTag({...prevState, [TaxonomyTagField.DESCRIPTION]: value}))}
                                                />
                                            </FieldBlock>
                                        </FormLayoutRows>
                                    </Box>
                                </ContentBlock>
                            </Loadable>
                            <Loadable loading={isLoadingLinks}>
                                <ContentBlock header={{title: {label: textTags("section.audiences")}}}>
                                    <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                        <FormLayoutRows>
                                            <FieldBlock
                                                label={textTags("audiences.segment.title")}
                                                actions={<IconEdit onClick={() => setShowModalEditLinksSegments(true)}/>}
                                            >
                                                <ElementList placeholder={textTags("audiences.segment.list_empty")} size={ElementListSize.BIG}>
                                                    {selectedSegments.map((segment) => (
                                                        <TagSegmentRow key={segment.id} segment={segment}/>
                                                    ))}
                                                </ElementList>
                                            </FieldBlock>
                                            <FieldBlock
                                                label={textTags("audiences.category.title")}
                                                actions={<IconEdit onClick={() => setShowModalEditLinksCategories(true)}/>}
                                            >
                                                <ElementList placeholder={textTags("audiences.category.list_empty")} size={ElementListSize.BIG}>
                                                    {selectedCategories.map((category) => (
                                                        <TagCategoryRow key={category.id} category={category}/>
                                                    ))}
                                                </ElementList>
                                            </FieldBlock>
                                        </FormLayoutRows>
                                    </Box>
                                </ContentBlock>
                            </Loadable>
                        </LayoutColumns>
                    </LayoutRows>
                </Form>
                <ModalConfirmDelete
                    active={isActiveDelete}
                    entity="tag"
                    confirm={handleDelete}
                    cancel={() => setActiveDelete(false)}
                />
                <ModalSelectSegments
                    active={isShowModalEditLinksSegments}
                    preSelectedSegments={selectedSegments}
                    title={textTags("modal.edit_linked_segments")}
                    onSubmit={handleSegmentLinks}
                    onClose={() => setShowModalEditLinksSegments(false)}
                />
                <ModalSelectCategories
                    active={isShowModalEditLinksCategories}
                    preSelectedCategories={selectedCategories}
                    type={CategoryType.CONTEXTUAL}
                    title={textTags("modal.edit_linked_categories")}
                    onSubmit={handleCategoryLinks}
                    onClose={() => setShowModalEditLinksCategories(false)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default TaxonomyTagsDetails;
