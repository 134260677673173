import {
    ButtonLinkCancel,
    ButtonValidate,
    FieldBlock,
    Form,
    FormLayoutColumns,
    FormLayoutRows,
    FormValidationType,
    InputText,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    Select
} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {session} from "../../../api/ApiSession";
import {ApiService} from "../../../api/model/ApiService";
import {PortalContentField} from "../../../api/model/portal-content/PortalContentField";
import {PortalSetting} from "../../../api/model/portal-setting/PortalSetting";
import {PortalSettingContentField} from "../../../api/model/portal-setting/PortalSettingContentField";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {TranslationCommonFile} from "../../../common/utils/constants";
import {Formatter} from "../../../common/utils/Formatter";
import {slugify} from "../../../common/utils/string";
import {Theme} from "../../../common/utils/Theme";
import {SelectService} from "../../snippet";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import {PortalSettingContent} from "../../../api/model/portal-setting/PortalSettingContent";

export type ModalCreatePortalSettingProps = {
    active: boolean;
    onClose: () => void;
};

const ModalCreatePortalSetting: FunctionComponent<ModalCreatePortalSettingProps> = ({active, onClose}) => {
    const {t: textPortalSettings} = useTranslation(TranslationPortalFile.PORTAL_SETTINGS);
    const {t: textPortalContent} = useTranslation(TranslationPortalFile.PORTAL_CONTENT);
    const {t: textMain} = useTranslation(TranslationCommonFile.MAIN);
    const [portalSetting, setPortalSetting] = useState<PortalSetting>(new PortalSetting());
    const alert = useAlert();
    const history = useHistory();
    const FORM_ID = "form-create-portal-settings";

    useEffect(() => {
        if (active) {
            setPortalSetting(new PortalSetting({[PortalContentField.SERVICE]: ApiService.ACCOUNT.name}));
        }
    }, [active]);

    const handleChange = (field: PortalContentField, value: any) => {
        setPortalSetting((prevState) => new PortalSetting({...prevState, [field]: value}));
    };

    const handleChangeContent = (field: PortalSettingContentField, value: any) => {
        handleChange(PortalContentField.CONTENT, new PortalSettingContent({
            ...portalSetting.content,
            [PortalSettingContentField.THEME]: value
        }));
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        try {
            portalSetting.user_id = session.userId;
            portalSetting.string_identifier = slugify(portalSetting.title);
            portalSetting.start_date = Formatter.convertDateToUTC(new Date());
            const newPortalSetting = await session.restPortalContent.create(PortalSetting, portalSetting);
            history.push(newPortalSetting.getRoute());
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToCreate("portal setting", e.message);
            }
        }
    };

    return (
        <ModalNew active={active} onClose={onClose}>
            <ModalHeader>
                <ModalHeaderTitle title={textPortalSettings("create_portal_settings")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FieldBlock label={textPortalContent(`field.${PortalContentField.TITLE}`)} required>
                            <InputText
                                value={portalSetting.title}
                                onChange={(value) => handleChange(PortalContentField.TITLE, value)}
                            />
                        </FieldBlock>
                        <FormLayoutColumns>
                            <FieldBlock label={textPortalContent(`field.${PortalContentField.SERVICE}`)} required>
                                <SelectService
                                    value={portalSetting.service}
                                    services={ApiService.portals()}
                                    onChange={(service) => handleChange(PortalContentField.SERVICE, service?.name || "")}
                                />
                            </FieldBlock>
                            <FieldBlock label={textPortalSettings(`field.${PortalSettingContentField.THEME}`)} required>
                                <Select
                                    value={portalSetting.content.theme}
                                    options={Theme.values().map((theme) => ({
                                        value: theme.name,
                                        label: textMain(`${PortalSettingContentField.THEME}.${theme.name}`)
                                    }))}
                                    onChange={(option) => handleChangeContent(PortalSettingContentField.THEME, option?.value)}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalCreatePortalSetting;
