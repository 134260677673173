import {Action, ContentBlock, ContentBlockAction, Paragraph, Table, TableColumnStyle} from "@sirdata/ui-lib";
import React, {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {CategorizerConfigEnrichmentRow} from "..";
import {session} from "../../../api/ApiSession";
import {Authorization} from "../../../api/model/account/Authorization";
import {CategoryGroup} from "../../../api/model/audience/category/CategoryGroup";
import {CategorizerConfigEntry} from "../../../api/model/categorizer/CategorizerConfigEntry";
import {CategorizerConfigEntryField} from "../../../api/model/categorizer/CategorizerConfigEntryField";
import {CategorizerConfigType} from "../../../api/model/categorizer/CategorizerConfigType";
import {CategorizerConfigEnrichment} from "../../../api/model/categorizer/config/CategorizerConfigEnrichment";
import {CategorizerConfigEnrichmentField} from "../../../api/model/categorizer/config/CategorizerConfigEnrichmentField";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {RestrictedContent} from "../../../common/component/widget";
import {CategorizerConfigEntriesProps} from "../../../utils/categorizer/CategorizerConfigEntriesProps";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import ModalEditCategorizerConfigEnrichment from "../../modal/categorizer/ModalEditCategorizerConfigEnrichment";
import ModalAddCategorizerConfigEnrichment from "../../modal/categorizer/ModalAddCategorizerConfigEnrichment";
import {CategorizerConfigEntryGroup} from "../../../api/model/categorizer/CategorizerConfigEntryGroup";
import {CategorizerConfigEntryGroupField} from "../../../api/model/categorizer/CategorizerConfigEntryGroupField";

const CategorizerConfigEnrichmentEntries: FC<CategorizerConfigEntriesProps<CategorizerConfigEnrichment>> = ({configs, params, onSubmit, onDelete, editable}) => {
    const {t: textCategorizer} = useTranslation(TranslationPortalFile.CATEGORIZER);
    const alert = useAlert();
    const history = useHistory();
    const [categoryGroups, setCategoryGroups] = useState<CategoryGroup[]>([]);
    const [activeEditCategorizerConfigEntry, setActiveEditCategorizerConfigEntry] = useState<CategorizerConfigEntry<CategorizerConfigEnrichment>>();
    const [isShowModalAddCategorizerConfigEnrichment, setShowModalAddCategorizerConfigEnrichment] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                setCategoryGroups(await session.getCategoryGroups());
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("category groups", e.message);
                }
            }
        })();
    }, [alert]);

    const hasConfig = (): boolean => {
        return configs.some((config) => config.match(params));
    };

    const handleClickEnrichmentRow = (entry: CategorizerConfigEntry<CategorizerConfigEnrichment>) => {
        if (entry.match(params) || editable) {
            setActiveEditCategorizerConfigEntry(entry);
            return;
        }
        const group = new CategorizerConfigEntryGroup({
            [CategorizerConfigEntryGroupField.DOMAIN]: entry.domain,
            [CategorizerConfigEntryGroupField.HOST]: entry.host,
            [CategorizerConfigEntryGroupField.PATH]: entry.path
        });
        history.push(group.getRoute());
    };

    return (
        <ContentBlock
            header={{
                title: {label: textCategorizer(`config_type.${CategorizerConfigType.ENRICHMENT.name}`), icon: {name: "category", outlined: true}},
                actions: [
                    !hasConfig() &&
                    <RestrictedContent key="add_config_enrichment" allowedTo={Authorization.CATEGORIZER.update}>
                        <ContentBlockAction action={Action.ADD} onClick={() => setShowModalAddCategorizerConfigEnrichment(true)}/>
                    </RestrictedContent>
                ]
            }}
        >
            <Paragraph>{textCategorizer(`config_type.description.${CategorizerConfigType.ENRICHMENT.name}`)}</Paragraph>
            <Table
                columns={[
                    {width: 15, label: textCategorizer(`field.${CategorizerConfigEntryField.DOMAIN}`), styles: TableColumnStyle.FIXED_WIDTH},
                    {width: 15, label: textCategorizer(`field.${CategorizerConfigEntryField.HOST}`), styles: TableColumnStyle.FIXED_WIDTH},
                    {width: 15, label: textCategorizer(`field.${CategorizerConfigEntryField.PATH}`), styles: TableColumnStyle.FIXED_WIDTH},
                    {width: 10, label: textCategorizer(`field.${CategorizerConfigEntryField.CONFIG}.${CategorizerConfigEnrichmentField.MODE}`), styles: TableColumnStyle.ALIGN_CENTER},
                    {label: textCategorizer(`field.${CategorizerConfigEntryField.CONFIG}.categories`)},
                    {width: 10, label: textCategorizer(`field.${CategorizerConfigEntryField.CONFIG}.${CategorizerConfigEnrichmentField.PROPAGATE_POINTS}`), styles: TableColumnStyle.ALIGN_CENTER},
                    {width: 2}
                ]}
            >
                {configs.map((entry) =>
                    <CategorizerConfigEnrichmentRow
                        key={entry.id}
                        entry={entry}
                        isEditable={entry.match(params) || !!editable}
                        onClick={() => handleClickEnrichmentRow(entry)}
                        onDelete={() => onDelete(entry)}
                        categoryGroups={categoryGroups}
                    />
                )}
            </Table>
            <ModalAddCategorizerConfigEnrichment
                params={params}
                active={isShowModalAddCategorizerConfigEnrichment}
                onSubmit={(entry) => {
                    onSubmit(entry);
                    setShowModalAddCategorizerConfigEnrichment(false);
                }}
                onClose={() => setShowModalAddCategorizerConfigEnrichment(false)}
            />
            <ModalEditCategorizerConfigEnrichment
                initEntry={activeEditCategorizerConfigEntry}
                onSubmit={(entry) => {
                    onSubmit(entry);
                    setActiveEditCategorizerConfigEntry(undefined);
                }}
                onClose={() => setActiveEditCategorizerConfigEntry(undefined)}
            />
        </ContentBlock>
    );
};

export default CategorizerConfigEnrichmentEntries;
