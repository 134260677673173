import {
    Action,
    Alert,
    Box,
    BoxProps,
    ButtonLink,
    ContentBlock,
    FieldBlock,
    FlexContent,
    FlexContentAlignment,
    FlexContentDirection,
    FlexContentLayout,
    FormLayoutColumns,
    FormLayoutRows,
    FormLayoutSeparator,
    FormLayoutTitle,
    InputNumber,
    InputText,
    InputTextNumber,
    LayoutColumns,
    LayoutRows,
    Loadable,
    Select,
    Table,
    ToggleOption,
    ToggleSwitch
} from "@sirdata/ui-lib";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {MainContentHeader, MainContentHeaderAction, PartnerPricings, PricingStepRow, SelectService, SelectStatus} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import {Module} from "../../utils/Module";
import {Pricing} from "../../api/model/partner/pricing/Pricing";
import {detectChanges} from "../../common/utils/portal";
import {PricingField} from "../../api/model/partner/pricing/PricingField";
import {PRICING_BILLING_PERIODS, PricingBillingPeriod} from "../../api/model/partner/pricing/PricingBillingPeriod";
import {PRICING_STATUSES} from "../../api/model/partner/pricing/PricingStatus";
import {PricingStep} from "../../api/model/partner/pricing/PricingStep";
import ModalConfirmDelete from "../../component/modal/ModalConfirmDelete";
import {PricingStepField} from "../../api/model/partner/pricing/PricingStepField";
import {Status} from "../../utils/Status";
import {PRICING_DIVIDER_UNITS} from "../../api/model/partner/pricing/PricingDividerUnit";
import useAlert from "../../utils/hooks/useAlert";
import {Formatter} from "../../common/utils/Formatter";
import {Currency} from "../../api/model/currency/Currency";

function PricingsDetails() {
    const {t: textPricings} = useTranslation(TranslationPortalFile.PRICINGS);
    const {id} = useParams<{ id: string }>();
    const history = useHistory();
    const alert = useAlert();
    const [isLoading, setLoading] = useState(true);
    const [isUnsavedChanges, setUnsavedChanges] = useState(false);
    const [isActiveDelete, setActiveDelete] = useState(false);

    const [pricing, setPricing] = useState<Pricing>(new Pricing());
    const [initPricing, setInitPricing] = useState<Pricing>(new Pricing());

    useEffect(() => {
        (async function () {
            try {
                const pricing = await session.restPricing.get(+id);
                setPricing(pricing);
                setInitPricing(new Pricing(pricing));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    if (e.statusCode === HttpStatusCode.NOT_FOUND) {
                        session.emit(SirdataApiEvent.eventNotFound);
                    } else {
                        alert.failToLoad("rate card", e.message);
                    }
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [id, alert]);

    useEffect(() => {
        setUnsavedChanges(detectChanges(pricing, initPricing));
    }, [pricing, initPricing]);

    const handleChangeCostType = (value: boolean) => {
        const newPricing = new Pricing(pricing);
        newPricing.step_price_is_per_unit = !value;
        setPricing(newPricing);
    };

    const handleSetAsDefault = async () => {
        try {
            pricing.default = true;

            await session.restPricing.update(pricing);
            setInitPricing(new Pricing(pricing));
            alert.updateWithSuccess("rate card");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("rate card", e.message);
            }
        }
    };

    const handleSave = async () => {
        try {
            if (pricing.step_price_is_per_unit) {
                pricing.volume_cost_per_unit = 0;
                pricing.steps_price = pricing.steps_price.filter((it) => it.fee || it.volume_cost_per_unit);
            } else {
                pricing.steps_price = [];
            }

            await session.restPricing.update(pricing);
            setInitPricing(new Pricing(pricing));
            alert.updateWithSuccess("rate card");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("rate card", e.message);
            }
        }
    };

    const handleDelete = async () => {
        if (!isActiveDelete) return;
        try {
            await session.restPricing.delete(pricing.id);
            history.push(Module.PRICINGS.path);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToDelete("rate card", e.message);
            }
        } finally {
            setActiveDelete(false);
        }
    };

    const handleAddPricingStep = () => {
        const newPricing = new Pricing(pricing);
        newPricing.steps_price = [...newPricing.steps_price || [], new PricingStep()];
        setPricing(newPricing);
    };

    const handleChangePricingStep = (index: number, step: PricingStep) => {
        const newPricing = new Pricing(pricing);
        newPricing.steps_price = newPricing.steps_price.map((it, idx) => (index === idx) ? step : it);
        setPricing(newPricing);
    };

    const handleDeletePricingStep = (stepIndex: number) => {
        const newPricing = new Pricing(pricing);
        let newPricingSteps = [...newPricing.steps_price];
        newPricingSteps.splice(stepIndex, 1);
        newPricing.steps_price = newPricingSteps;
        setPricing(newPricing);
    };

    const hasEmptyField = () => {
        if (pricing.step_price_is_per_unit) {
            if (!pricing.steps_price?.length || pricing.hasInvalidSteps()) return true;
        } else {
            if (pricing.volume_cost_per_unit === undefined) {
                return true;
            }
        }

        return !pricing.name;
    };

    return (
        <Wrapper>
            <MainHeader preventUnsaved={isUnsavedChanges}/>
            <MainContentHeader module={Module.PRICINGS} element={initPricing.toContentElement()} preventUnsaved={isUnsavedChanges}>
                <RestrictedContent allowedTo={Authorization.PRICINGS.update}>
                    <MainContentHeaderAction
                        action={new Action(textPricings("actions.use_by_default_for_product", {product: textPricings(`api_service.${pricing.api_service}`)}), {name: "verified"})}
                        onClick={handleSetAsDefault}
                        disabled={pricing.default || hasEmptyField() || isUnsavedChanges}
                    />
                    <MainContentHeaderAction action={Action.SAVE} onClick={handleSave} disabled={hasEmptyField() || !isUnsavedChanges}/>
                    <MainContentHeaderAction action={Action.DELETE} onClick={() => setActiveDelete(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <LayoutColumns>
                        <Loadable loading={isLoading}>
                            <ContentBlock header={{title: {label: textPricings("section.information")}}}>
                                <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                    <FormLayoutRows>
                                        <FormLayoutColumns columns={4}>
                                            <FieldBlock label={textPricings(`field.${PricingField.STATUS}`)}>
                                                <SelectStatus
                                                    value={pricing.status}
                                                    statuses={PRICING_STATUSES.filter((it) => it !== Status.DELETED)}
                                                    onChange={(status) => setPricing((prevState) => new Pricing({...prevState, [PricingField.STATUS]: status!.name}))}
                                                />
                                            </FieldBlock>
                                        </FormLayoutColumns>
                                        <FormLayoutColumns layout={FlexContentLayout.TWO_COLUMNS_WIDE_RIGHT}>
                                            <FieldBlock label={textPricings(`field.${PricingField.API_SERVICE}`)}>
                                                <SelectService
                                                    value={pricing.api_service}
                                                    services={Pricing.apiServices()}
                                                    onChange={undefined}
                                                    disabled
                                                />
                                            </FieldBlock>
                                            <FieldBlock label={textPricings(`field.${PricingField.NAME}`)} required>
                                                <InputText
                                                    value={pricing.name}
                                                    onChange={(value) => setPricing(new Pricing({...pricing, [PricingField.NAME]: value}))}
                                                />
                                            </FieldBlock>
                                        </FormLayoutColumns>
                                        <FieldBlock label={textPricings(`field.${PricingField.DESCRIPTION}`)}>
                                            <InputText
                                                value={pricing.description}
                                                onChange={(value) => setPricing(new Pricing({...pricing, [PricingField.DESCRIPTION]: value}))}
                                            />
                                        </FieldBlock>
                                        <FormLayoutSeparator/>
                                        <FormLayoutTitle>{textPricings("section.billing")}</FormLayoutTitle>
                                        <FieldBlock label={textPricings(`field.${PricingField.INVOICE_LABEL}`)}>
                                            <InputText
                                                value={pricing.invoice_label}
                                                onChange={(value) => setPricing(new Pricing({...pricing, [PricingField.INVOICE_LABEL]: value}))}
                                            />
                                        </FieldBlock>
                                        <FormLayoutColumns columns={2} layout={FlexContentLayout.TWO_COLUMNS_WIDE_LEFT}>
                                            <FormLayoutColumns layout={FlexContentLayout.TWO_COLUMNS_WIDE_LEFT}>
                                                <FieldBlock label={textPricings(`field.${PricingField.BILLING_PERIOD}`)}>
                                                    <Select
                                                        value={pricing.billing_period}
                                                        options={PRICING_BILLING_PERIODS.map((it) => ({value: it, label: textPricings(`billing_period.${it}`)}))}
                                                        onChange={(option) => setPricing((prevState) => new Pricing({...prevState, [PricingField.BILLING_PERIOD]: option?.value as PricingBillingPeriod}))}
                                                    />
                                                </FieldBlock>
                                                <FieldBlock label={textPricings(`field.${PricingField.BILLING_DAY}`)}>
                                                    <InputNumber
                                                        value={pricing.billing_day}
                                                        minValue={1}
                                                        maxValue={31}
                                                        disabled={pricing.billing_period !== PricingBillingPeriod.MONTH}
                                                        onChange={(value) => setPricing((prevState) => new Pricing({...prevState, [PricingField.BILLING_DAY]: value}))}
                                                    />
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                        </FormLayoutColumns>
                                        <FieldBlock label={textPricings(`field.${PricingField.BARTER_AGREEMENT}`)} name={PricingField.BARTER_AGREEMENT}>
                                            <ToggleSwitch
                                                label={textPricings(`field.${PricingField.BARTER_AGREEMENT}_text`)}
                                                checked={pricing.barter_agreement}
                                                onChange={(value) => setPricing((prevState) => new Pricing({...prevState, [PricingField.BARTER_AGREEMENT]: value}))}
                                            />
                                        </FieldBlock>
                                        <FormLayoutSeparator/>
                                        <FormLayoutColumns columns={3}>
                                            <FieldBlock label={textPricings(`field.${PricingField.AXONAUT_PRODUCT_ID}`)}>
                                                <InputNumber
                                                    value={pricing.axonaut_product_id}
                                                    onChange={(value) => setPricing((prevState) => new Pricing({...prevState, [PricingField.AXONAUT_PRODUCT_ID]: value}))}
                                                />
                                            </FieldBlock>
                                        </FormLayoutColumns>
                                    </FormLayoutRows>
                                </Box>
                            </ContentBlock>
                        </Loadable>
                        <Loadable loading={isLoading}>
                            <ContentBlock header={{title: {label: textPricings("section.settings")}}}>
                                <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                    <FormLayoutRows>
                                        <Alert text={textPricings("message.prices_mention")}/>
                                        <FormLayoutColumns columns={3}>
                                            <FieldBlock label={textPricings(`field.${PricingField.FEE}`)} tooltip={textPricings(`tooltip.${PricingField.FEE}`)}>
                                                <InputTextNumber
                                                    value={Formatter.convertCentsToUnits(pricing.fee)}
                                                    minValue={0}
                                                    onChange={(value) => setPricing((prevState) => new Pricing({...prevState, [PricingField.FEE]: Formatter.convertUnitsToCents(value)}))}
                                                />
                                                <span>{Currency.EUR.symbol}</span>
                                            </FieldBlock>
                                        </FormLayoutColumns>
                                        <FormLayoutSeparator/>
                                        <FormLayoutTitle>{textPricings("section.prices_on_volume")}</FormLayoutTitle>
                                        <FormLayoutColumns>
                                            <FieldBlock label={textPricings("field.volume_price_type.title")}>
                                                <ToggleOption
                                                    firstOption={textPricings(`field.volume_price_type.${PricingField.VOLUME_COST_PER_UNIT}`)}
                                                    secondOption={textPricings(`field.volume_price_type.${PricingField.STEP_PRICE_IS_PER_UNIT}`)}
                                                    isFirstOption={!pricing.step_price_is_per_unit}
                                                    onClick={handleChangeCostType}
                                                />
                                            </FieldBlock>
                                            <FieldBlock label={textPricings("field.divider_unit")}>
                                                <Select
                                                    value={pricing.divider_unit}
                                                    options={PRICING_DIVIDER_UNITS.map((it) => ({value: it.value, label: textPricings(`divider_unit.${it.name}`)}))}
                                                    onChange={(option) => setPricing((prevState) => new Pricing({...prevState, [PricingField.DIVIDER_UNIT]: option ? +option?.value : 0}))}
                                                />
                                            </FieldBlock>
                                        </FormLayoutColumns>
                                        {pricing.step_price_is_per_unit ?
                                            <>
                                                <ToggleSwitch
                                                    name={PricingField.ONLY_LAST_STEP_PRICE_BILLABLE}
                                                    label={textPricings(`field.${PricingField.ONLY_LAST_STEP_PRICE_BILLABLE}`)}
                                                    checked={pricing.only_last_step_price_billable}
                                                    onChange={(value) => setPricing((prevState) => new Pricing({...prevState, [PricingField.ONLY_LAST_STEP_PRICE_BILLABLE]: value}))}
                                                />
                                                <FieldBlock
                                                    label={textPricings("field.volume_price_type.price_levels")}
                                                    content={{direction: FlexContentDirection.COLUMN}}
                                                    error={pricing.hasInvalidSteps() ? textPricings("message.invalid_pricing_steps") : undefined}
                                                >
                                                    <Table
                                                        columns={[
                                                            {width: 30, label: textPricings(`field.step.${PricingStepField.LIMIT}`)},
                                                            {width: 30, label: textPricings(`field.step.${PricingStepField.FEE}`)},
                                                            {width: 30, label: textPricings(`field.step.${PricingStepField.VOLUME_COST_PER_UNIT}`)},
                                                            {width: 10}
                                                        ]}
                                                    >
                                                        {pricing.steps_price.map((step, index) =>
                                                            <PricingStepRow
                                                                key={`pricing-step-row-${index.toString()}`}
                                                                step={step}
                                                                onChange={(step) => handleChangePricingStep(index, step)}
                                                                onRemove={() => handleDeletePricingStep(index)}
                                                            />
                                                        )}
                                                    </Table>
                                                    {
                                                        (!pricing.steps_price.length || !!pricing.steps_price[pricing.steps_price.length - 1].limit) &&
                                                        <FlexContent direction={FlexContentDirection.COLUMN} alignment={FlexContentAlignment.END}>
                                                            <ButtonLink
                                                                icon={{name: "add_circle"}}
                                                                onClick={handleAddPricingStep}
                                                                reverseUnderline
                                                            >
                                                                {textPricings("add_step")}
                                                            </ButtonLink>
                                                        </FlexContent>
                                                    }
                                                </FieldBlock>
                                            </> :
                                            <FormLayoutColumns columns={3}>
                                                <FieldBlock label={textPricings("field.volume_cost_per_unit")}>
                                                    <InputTextNumber
                                                        value={Formatter.convertCentsToUnits(pricing.volume_cost_per_unit)}
                                                        minValue={0}
                                                        onChange={(value) => setPricing((prevState) => new Pricing({...prevState, [PricingField.VOLUME_COST_PER_UNIT]: Formatter.convertUnitsToCents(value)}))}
                                                    />
                                                    <span>{Currency.EUR.symbol}</span>
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                        }
                                    </FormLayoutRows>
                                </Box>
                            </ContentBlock>
                        </Loadable>
                    </LayoutColumns>
                    <Loadable loading={isLoading}>
                        <PartnerPricings pricingId={pricing.id}/>
                    </Loadable>
                </LayoutRows>
                <ModalConfirmDelete
                    active={isActiveDelete}
                    entity="rate card"
                    confirm={handleDelete}
                    cancel={() => setActiveDelete(false)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default PricingsDetails;
