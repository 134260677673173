import {History} from "history";
import {QueryString} from "../common/api/model/QueryString";

export const goToLoginGoogle = (loginPath: string, goTo: string = "") => {
    const clientId = "694961167927-5jbc70glk6g2h0d0smqafp8m0ctd8dpk.apps.googleusercontent.com";
    const redirectUri = `${window.location.origin}${loginPath}`;
    const queryString = QueryString.build({
        "scope": "email profile openid",
        "access_type": "offline",
        "prompt": "consent",
        "response_type": "code",
        "client_id": clientId,
        "redirect_uri": redirectUri,
        "state": goTo // the state param is the only way to passthrough goTo
    });
    window.location.assign(`https://accounts.google.com/o/oauth2/v2/auth?${queryString}`);
};

export const onClickLink = (e: any, path: string, history: History) => {
    if (e.ctrlKey || e.metaKey) {
        window.open(path, "_blank");
    } else if (history) {
        history.push(path);
    } else {
        window.open(path, "_top");
    }
};
