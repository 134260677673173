import {Color, IconTooltip, TableColumn, TableColumnStyle, TableRow} from "@sirdata/ui-lib";
import {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {CustomerStorage} from "../../../../api/model/customer/storage/CustomerStorage";
import {TranslationPortalFile} from "../../../../utils/constants";
import {onClickLink} from "../../../../utils/helper";
import {LabelPartner, LabelStatus} from "../../index";

type CustomerStorageRowProps = {
    storage: CustomerStorage;
};

const CustomerStorageRow: FunctionComponent<CustomerStorageRowProps> = ({storage}) => {
    const {t: textCustomerStorages} = useTranslation(TranslationPortalFile.CUSTOMER_STORAGES);
    const history = useHistory();

    return (
        <TableRow onClick={(e) => onClickLink(e, storage.getRoute(), history)}>
            <TableColumn>
                <LabelStatus status={storage.getStatus()}/>
            </TableColumn>
            <TableColumn>
                {storage.id}
            </TableColumn>
            <TableColumn styles={TableColumnStyle.FIXED_WIDTH}>
                <span>{storage.name}</span>
                {!!storage.error_code &&
                    <IconTooltip
                        icon={{name: "error", colorIcon: Color.DANGER_MAIN}}
                        text={textCustomerStorages(`error_code.${storage.error_code}`)}
                    />
                }
            </TableColumn>
            <TableColumn>
                <LabelPartner partner={storage.partner_id}/>
            </TableColumn>
        </TableRow>
    );
};

export default CustomerStorageRow;
