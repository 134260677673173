import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {PartnerSite} from "../../../api/model/partner/PartnerSite";
import {
    AlertSeverity,
    ButtonLinkCancel,
    ButtonValidate,
    FieldBlock,
    FlexContentDirection,
    FlexContentLayout,
    Form,
    FormLayoutColumns,
    FormValidationType,
    InputNumber,
    InputText,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew
} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../../utils/constants";
import {PartnerSiteField} from "../../../api/model/partner/PartnerSiteField";
import {FormLayoutMessage} from "../../../common/component/snippet";
import {session} from "../../../api/ApiSession";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import useAlert from "../../../utils/hooks/useAlert";
import useFormValidator from "../../../utils/hooks/useFormValidator";

export type ModalEditPartnerSiteProps = {
    initSite: PartnerSite | undefined;
    existingSites: PartnerSite[];
    onClose: (refresh: boolean) => void;
};

const ModalEditPartnerSite: FunctionComponent<ModalEditPartnerSiteProps> = ({initSite, existingSites, onClose}) => {
    const {t: textPartners} = useTranslation(TranslationPortalFile.PARTNERS);
    const [site, setSite] = useState<PartnerSite>(new PartnerSite());
    const alert = useAlert();
    const FORM_ID = "form-edit-partner-site";
    const {setErrors, setShowErrors, ...formValidator} = useFormValidator<PartnerSiteField>();

    useEffect(() => {
        if (initSite) {
            setSite(initSite);
            setShowErrors(false);
        }
    }, [initSite, setShowErrors]);

    useEffect(() => {
        setErrors((prevState) => ({
            ...prevState,
            [PartnerSiteField.NAME]: existingSites.some((it) => it.name === site.name)
        }));
    }, [setErrors, site, existingSites]);

    const handleChange = (field: PartnerSiteField, value: any) => {
        setSite((prevState) => new PartnerSite({...prevState, [field]: value}));
    };

    const handleSubmitCapture = () => {
        setShowErrors(true);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (formValidator.hasErrors()) {
            return;
        }
        try {
            await session.restPartnerSite.update(site);
            alert.updateWithSuccess("site");
            onClose(true);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("site", e.message);
            }
        } finally {
            setShowErrors(false);
        }
    };

    return (
        <ModalNew onClose={() => onClose(false)} active={!!initSite}>
            <ModalHeader>
                <ModalHeaderTitle title={textPartners("edit_site")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmitCapture={handleSubmitCapture} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutColumns layout={FlexContentLayout.TWO_COLUMNS_WIDE_RIGHT}>
                        <FieldBlock label={textPartners(`field.site.${PartnerSiteField.SITE_ID}`)}>
                            <InputNumber
                                value={site.site_id}
                                onChange={() => {}}
                                disabled
                            />
                        </FieldBlock>
                        <FieldBlock
                            label={textPartners(`field.site.${PartnerSiteField.NAME}`)}
                            content={{direction: FlexContentDirection.COLUMN}}
                            required
                        >
                            <InputText
                                value={site.name}
                                onChange={(value) => handleChange(PartnerSiteField.NAME, value)}
                            />
                            {formValidator.isError(PartnerSiteField.NAME) &&
                                <FormLayoutMessage message={textPartners("message.existing_site_name")} severity={AlertSeverity.DANGER} small/>
                            }
                        </FieldBlock>
                    </FormLayoutColumns>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={() => onClose(false)}/>
                <ButtonValidate form={FORM_ID}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalEditPartnerSite;
