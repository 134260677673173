import {FunctionComponent, useState} from "react";
import {useTranslation} from "react-i18next";
import {Action, ContentBlock, Icon, TableColumn, TableRow} from "@sirdata/ui-lib";
import {SirdataListStack} from "../../../../api/model/cmp/list/sirdata-list-v2/SirdataListStack";
import {SirdataListPurpose} from "../../../../api/model/cmp/list/sirdata-list-v2/SirdataListPurpose";
import {TranslationPortalFile} from "../../../../utils/constants";
import {GlobalVendorList} from "../../../../api/model/cmp/list/global-vendor-list/GlobalVendorList";
import {GlobalVendorListField} from "../../../../api/model/cmp/list/global-vendor-list/GlobalVendorListField";
import {sortNumbers} from "../../../../common/utils/helper";

type CmpSirdataListV2StacksRowProps = {
    stack: SirdataListStack;
    globalVendorList: GlobalVendorList;
    extraPurposes: SirdataListPurpose[];
};

const CmpSirdataListV2StacksRow: FunctionComponent<CmpSirdataListV2StacksRowProps> = ({stack, globalVendorList, extraPurposes}) => {
    const {t: textCmpSirdataList} = useTranslation(TranslationPortalFile.CMP_SIRDATA_LIST_V2);
    const [isOpenDetails, setOpenDetails] = useState(false);

    return (
        <>
            <TableRow onClick={() => setOpenDetails((prevState) => !prevState)}>
                <TableColumn>
                    <Icon {...(isOpenDetails ? Action.COLLAPSE.icon : Action.EXPAND.icon)}/>
                </TableColumn>
                <TableColumn>{stack.id}</TableColumn>
                <TableColumn>{stack.name}</TableColumn>
                <TableColumn>{stack.description}</TableColumn>
            </TableRow>
            {isOpenDetails &&
                <TableRow>
                    <TableColumn colSpan={4}>
                        <ContentBlock>
                            {(!!stack.purposes.length || !!stack.specialFeatures.length || !!stack.extraPurposes.length) &&
                                <>
                                    <span>{textCmpSirdataList("stack_purposes")}</span>
                                    <ul>
                                        {sortNumbers(stack.purposes).map((id) =>
                                            <li key={id}>
                                                <b>{textCmpSirdataList("purpose_with_id", {id: id})}</b>: {globalVendorList.getName(GlobalVendorListField.PURPOSES, id)}
                                            </li>
                                        )}
                                        {sortNumbers(stack.specialFeatures).map((id) =>
                                            <li key={id}>
                                                <b>{textCmpSirdataList("special_feature_with_id", {id: id})}</b>: {globalVendorList.getName(GlobalVendorListField.SPECIAL_FEATURES, id)}
                                            </li>
                                        )}
                                        {sortNumbers(stack.extraPurposes).map((id) =>
                                            <li key={id}>
                                                <b>{textCmpSirdataList("extra_purpose_with_id", {id: id})}</b>: {extraPurposes.find((it) => it.id === id)?.name || ""}
                                            </li>
                                        )}
                                    </ul>
                                </>
                            }
                            {!!stack.requiredPurposes.length &&
                                <>
                                    <span>{textCmpSirdataList("stack_required_purposes")}</span>
                                    <ul>
                                        {sortNumbers(stack.requiredPurposes).map((id) =>
                                            <li key={id}>
                                                <b>{textCmpSirdataList("purpose_with_id", {id: id})}</b>: {globalVendorList.getName(GlobalVendorListField.PURPOSES, id)}
                                            </li>
                                        )}
                                    </ul>
                                </>
                            }
                        </ContentBlock>
                    </TableColumn>
                </TableRow>
            }
        </>
    );
};

export default CmpSirdataListV2StacksRow;
