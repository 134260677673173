import {ButtonLinkCancel, ButtonValidate, FieldBlock, Form, FormLayoutColumns, FormLayoutRows, FormValidationType, InputDate, InputDateType, InputText, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, Textarea} from "@sirdata/ui-lib";
import {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {session} from "../../../api/ApiSession";
import {CmpVersion} from "../../../api/model/cmp/version/CmpVersion";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {TranslationPortalFile} from "../../../utils/constants";
import {CmpVersionField} from "../../../api/model/cmp/version/CmpVersionField";
import useAlert from "../../../utils/hooks/useAlert";
import {Formatter} from "../../../common/utils/Formatter";

export type ModalCreateCmpVersionProps = {
    active: boolean;
    onClose: () => void;
};

const ModalCreateCmpVersion: FunctionComponent<ModalCreateCmpVersionProps> = ({active, onClose}) => {
    const alert = useAlert();
    const {t: textCmpVersions} = useTranslation(TranslationPortalFile.CMP_VERSIONS);
    const history = useHistory();
    const [cmpVersion, setCmpVersion] = useState<CmpVersion>(new CmpVersion());
    const FORM_ID = "form-create-cmp-version";

    useEffect(() => {
        if (active) {
            setCmpVersion(new CmpVersion());
        }
    }, [active]);

    const handleChange = (field: CmpVersionField, value: any) => {
        setCmpVersion((prevState) => new CmpVersion({...prevState, [field]: value}));
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        try {
            const newCmpVersion = await session.restCmpVersion.create(cmpVersion);
            history.push(newCmpVersion.getRoute());
            onClose();
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToCreate("CMP version", e.message);
            }
        }
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textCmpVersions("modal.title_create")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FormLayoutColumns columns={3}>
                            <FieldBlock label={textCmpVersions(`field.${CmpVersionField.VERSION}`)} required>
                                <InputText
                                    value={cmpVersion.version}
                                    onChange={(value) => handleChange(CmpVersionField.VERSION, value)}
                                />
                            </FieldBlock>
                            <FieldBlock label={textCmpVersions(`field.${CmpVersionField.DATE}`)} required>
                                <InputDate
                                    type={InputDateType.DATE}
                                    value={Formatter.formatDate(cmpVersion.date, Formatter.API_DATE_FORMAT)}
                                    onChange={(value) => handleChange(CmpVersionField.DATE, value)}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FieldBlock label={textCmpVersions(`field.${CmpVersionField.TEXT_FR}`)} required>
                            <Textarea
                                value={cmpVersion.text_fr.replaceAll("<br/>", "")}
                                rows={7}
                                onChange={(value) => handleChange(CmpVersionField.TEXT_FR, value.replaceAll("\n", "\n<br/>"))}
                            />
                        </FieldBlock>
                        <FieldBlock label={textCmpVersions(`field.${CmpVersionField.TEXT_EN}`)} required>
                            <Textarea
                                value={cmpVersion.text_en.replaceAll("<br/>", "")}
                                rows={7}
                                onChange={(value) => handleChange(CmpVersionField.TEXT_EN, value.replaceAll("\n", "\n<br/>"))}
                            />
                        </FieldBlock>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalCreateCmpVersion;
