import {ApiModel} from "../../../../../common/api/model/ApiModel";
import {SirdataListVersion} from "./SirdataListVersion";
import {SirdataListEntityState} from "./SirdataListEntityState";
import {detectChanges} from "../../../../../common/utils/portal";

export class SirdataListStack extends ApiModel {
    id: number = 0;
    name: string = "";
    description: string = "";
    purposes: number[] = [];
    extraPurposes: number[] = [];
    specialFeatures: number[] = [];
    requiredPurposes: number[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            purposes: this.purposes,
            extraPurposes: this.extraPurposes,
            specialFeatures: this.specialFeatures,
            requiredPurposes: this.requiredPurposes
        };
    }

    isValid(): boolean {
        if (!this.name || !this.description) {
            return false;
        }

        if (![...this.purposes, ...this.extraPurposes, ...this.specialFeatures].length) {
            return false;
        }

        return !(this.purposes.some((id) => this.requiredPurposes.includes(id))
            || this.requiredPurposes.some((id) => this.purposes.includes(id)));
    }

    getState(sirdataList: SirdataListVersion): SirdataListEntityState | undefined {
        const stack = sirdataList.stacks.find((it) => it.id === this.id);
        if (!stack) {
            return SirdataListEntityState.ADDED;
        } else if (detectChanges(stack, this)) {
            return SirdataListEntityState.UPDATED;
        }
    }
}
