import {
    Action,
    Alert,
    AlertSeverity,
    ButtonLink,
    ButtonLinkCancel,
    ButtonValidate,
    ElementList,
    ElementListSize,
    FieldBlock,
    FlexContentDirection,
    FlexContentSpacing,
    Form,
    FormLayoutColumns,
    FormLayoutRows,
    FormValidationType,
    InputText,
    InputTextNumber,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    RadioButtons,
    Select,
    SelectAutocomplete,
    Slider,
    ToggleSwitch,
    TranslationLibFile
} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, ReactElement, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {Distribution} from "../../../api/model/audience/distribution/Distribution";
import {DistributionLicense} from "../../../api/model/audience/distribution/license/DistributionLicense";
import {DistributionLicenseCustomField} from "../../../api/model/audience/distribution/license/DistributionLicenseCustomField";
import {DistributionLicenseCustomFieldType} from "../../../api/model/audience/distribution/license/DistributionLicenseCustomFieldType";
import {DistributionLicenseField} from "../../../api/model/audience/distribution/license/DistributionLicenseField";
import {DistributionLicenseSegmentLinkType} from "../../../api/model/audience/distribution/license/DistributionLicenseSegmentLinkType";
import {Segment} from "../../../api/model/audience/segment/Segment";
import {SegmentDataType} from "../../../api/model/audience/segment/SegmentDataType";
import {SegmentField} from "../../../api/model/audience/segment/SegmentField";
import {Currency} from "../../../api/model/currency/Currency";
import {RevenueSeat} from "../../../api/model/revenue/seat/RevenueSeat";
import {RevenueSeatField} from "../../../api/model/revenue/seat/RevenueSeatField";
import {RevenueSeatSearchQuery} from "../../../api/model/revenue/seat/RevenueSeatSearchQuery";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {FormLayoutMessage} from "../../../common/component/snippet";
import {sortItems} from "../../../common/utils/helper";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import useFormValidator from "../../../utils/hooks/useFormValidator";
import {SelectSegment, TagSegmentRow} from "../../snippet";

type ModalEditDistributionLicenseProps = {
    initialValue: DistributionLicense | undefined;
    distributions: Distribution[];
    onClose: (refresh: boolean) => void;
};

const ModalEditDistributionLicense: FunctionComponent<ModalEditDistributionLicenseProps> = ({distributions, initialValue, onClose}) => {
    const alert = useAlert();
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textDistributionsLicenses} = useTranslation(TranslationPortalFile.DISTRIBUTIONS_LICENSES);
    const [isLoadingSeats, setLoadingSeats] = useState(false);
    const [license, setLicense] = useState<DistributionLicense>(new DistributionLicense());
    const [customFields, setCustomFields] = useState<DistributionLicenseCustomField[]>([]);
    const [seats, setSeats] = useState<RevenueSeat[]>([]);
    const [selectedSegments, setSelectedSegments] = useState<Segment[]>([]);
    const FORM_ID = "form-edit-distribution-license";
    const {setErrors, setShowErrors, ...formValidator} = useFormValidator<DistributionLicenseField>();

    useEffect(() => {
        if (!initialValue) {
            setCustomFields([]);
            setLicense(new DistributionLicense());
            return;
        }

        (async () => {
            try {
                setLicense(new DistributionLicense(initialValue));

                let segments = await session.getSegments();
                segments = sortItems(segments, SegmentField.NAME);
                setSelectedSegments(segments.filter((it) => initialValue.segments.includes(it.id)));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("segments", e.message);
                }
            }
        })();
    }, [initialValue, alert]);

    useEffect(() => {
        if (!license.external_map_name) return;

        (async () => {
            const distribution = distributions.find((it) => it.name === license.external_map_name);
            setLoadingSeats(true);
            try {
                const params = new RevenueSeatSearchQuery();
                params.size = 1000;
                if (!distribution || !distribution.platform_name) return;
                params.platform = distribution.platform_name;
                const searchResult = await session.restSeat.search(params);
                setSeats(sortItems(searchResult.seats, RevenueSeatField.BUYER));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("seats", e.message);
                }
            } finally {
                setLoadingSeats(false);
            }
            try {
                const distribution = distributions.find((it) => it.name === license.external_map_name);
                if (!distribution || !distribution.destination_name) {
                    setCustomFields([]);
                    return;
                }
                const customFields = await session.restDistributionLicense.getCustomFields(distribution.destination_name);
                setCustomFields([...customFields]);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("custom fields", e.message);
                }
            }
        })();
    }, [distributions, license.external_map_name, alert]);

    useEffect(() => {
        setErrors((prevState) => ({...prevState, [DistributionLicenseField.SEGMENTS]: license.segment_link_type === DistributionLicenseSegmentLinkType.LIST.name && !selectedSegments.length}));
    }, [setErrors, license.segment_link_type, selectedSegments.length]);

    const handleChange = (field: DistributionLicenseField, value: any) => {
        setLicense((prevState) => new DistributionLicense({...prevState, [field]: value}));
    };

    const handleChangeCustomField = (field: string, value: any) => {
        handleChange(DistributionLicenseField.CUSTOM_FIELDS, {...license.custom_fields, [field]: value});
    };

    const handleAddSegment = (segment: Segment) => {
        setSelectedSegments((prevState) => [segment, ...prevState]);
    };

    const handleRemoveSegment = (segment: Segment) => {
        setSelectedSegments((prevState) => prevState.filter((it) => it.id !== segment.id));
    };

    const handleSubmitCapture = () => {
        setShowErrors(true);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (formValidator.hasErrors()) {
            return;
        }

        license.segments = selectedSegments.map((it) => it.id);
        const distribution = distributions.find((it) => it.name === license.external_map_name);
        if (!distribution || !distribution.destination_name) {
            alert.failToLoad("distribution", "");
            return;
        }
        try {
            await session.restDistributionLicense.update(distribution.destination_name, license);
            alert.updateWithSuccess("distribution license");
            onClose(true);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("distribution license", e.message);
            }
        } finally {
            setShowErrors(false);
        }
    };

    const getComponentFromField = (field: DistributionLicenseCustomField): ReactElement => {
        let value = license[DistributionLicenseField.CUSTOM_FIELDS][field.name];
        if (field.from_seat && !value) {
            const seat = seats.find((seat) => seat.id === license.id_seat);
            if (seat) {
                value = seat[RevenueSeatField.CUSTOM_FIELDS][field.name];
            }
        }
        switch (field.type) {
            case DistributionLicenseCustomFieldType.BOOLEAN:
                return (
                    <FieldBlock name={`custom_field-${field.label}`} label={field.label} required>
                        <ToggleSwitch
                            checked={value}
                            onChange={(value) => handleChangeCustomField(field.name, value)}
                        />
                    </FieldBlock>
                );
            case DistributionLicenseCustomFieldType.NUMBER:
                return (
                    <FieldBlock label={field.label} required>
                        <InputTextNumber
                            value={value}
                            onChange={(value) => handleChangeCustomField(field.name, value)}
                        />
                    </FieldBlock>
                );
            case DistributionLicenseCustomFieldType.SELECT:
                if (!field.values) return <></>;
                return (
                    <FieldBlock label={field.label} required>
                        <Select
                            value={value}
                            onChange={(option) => handleChangeCustomField(field.name, option?.value)}
                            options={field.values.map((o) => ({label: o.replaceAll("_", " "), value: o}))}
                        />
                    </FieldBlock>
                );
            case DistributionLicenseCustomFieldType.TEXT:
            default:
                return (
                    <FieldBlock label={field.label} required>
                        <InputText
                            value={value}
                            onChange={(value) => handleChangeCustomField(field.name, value)}
                        />
                    </FieldBlock>
                );
        }
    };

    return (
        <ModalNew onClose={() => onClose(false)} active={!!initialValue}>
            <ModalHeader>
                <ModalHeaderTitle title={textDistributionsLicenses("modal.edit_license")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmitCapture={handleSubmitCapture} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <Alert text={textDistributionsLicenses("edit_feature_disabled")} severity={AlertSeverity.INFO}/>
                        <FormLayoutColumns>
                            <FieldBlock label={textDistributionsLicenses(`field.${DistributionLicenseField.EXTERNAL_MAP_NAME}`)} required>
                                <SelectAutocomplete
                                    value={license.external_map_name}
                                    options={distributions.map((it) => ({value: it.name, label: it.label}))}
                                    onChange={() => {}}
                                    disabled={!!license.id}
                                />
                            </FieldBlock>
                            <FieldBlock label={textDistributionsLicenses(`field.${DistributionLicenseField.SEAT}.label`)} required>
                                <SelectAutocomplete
                                    value={license.id_seat}
                                    options={seats.map((it) => ({value: it.id, label: `${it.seat_id} (${it.organization?.name})`}))}
                                    onChange={() => {}}
                                    disabled={!license.external_map_name || !!license.id}
                                    isLoading={isLoadingSeats}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FormLayoutColumns>
                            <FieldBlock label={textDistributionsLicenses(`field.${DistributionLicenseField.CURRENCY}.label`)}>
                                <Select
                                    value={license.currency}
                                    options={Currency.values().map((it) => ({value: it.currency, label: textDistributionsLicenses(`field.currency.${it.currency}`)}))}
                                    onChange={(option) => handleChange(DistributionLicenseField.CURRENCY, option?.value)}
                                />
                            </FieldBlock>
                            <FieldBlock label={textDistributionsLicenses(`field.${DistributionLicenseField.PRICE_MEDIA_COST}`)}>
                                <InputTextNumber
                                    value={license.price_media_cost}
                                    onChange={(value) => handleChange(DistributionLicenseField.PRICE_MEDIA_COST, value)}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FormLayoutColumns>
                            <FieldBlock label={textDistributionsLicenses(`field.${DistributionLicenseField.PRICE_CPM}`)}>
                                <InputTextNumber
                                    value={license.price_cpm}
                                    onChange={(value) => handleChange(DistributionLicenseField.PRICE_CPM, value)}
                                />
                            </FieldBlock>
                            <FieldBlock
                                label={textDistributionsLicenses(`field.${DistributionLicenseField.PRICE_CPM_MODIFIER}`)}
                                tooltip={textDistributionsLicenses(`modal.tooltip.${DistributionLicenseField.PRICE_CPM_MODIFIER}`)}
                            >
                                <Slider
                                    value={license.price_cpm_modifier ? license.price_cpm_modifier * 100 : 0}
                                    onChange={(value) => handleChange(DistributionLicenseField.PRICE_CPM_MODIFIER, value / 100)}
                                    minValue={0}
                                    maxValue={100}
                                    unit="%"
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        {customFields.length > 0 &&
                            Array.from({length: Math.ceil(customFields.length / 2)}, (_, i) => i * 2).map((i) =>
                                <FormLayoutColumns key={i}>
                                    {getComponentFromField(customFields[i])}
                                    {customFields[i + 1] && getComponentFromField(customFields[i + 1])}
                                </FormLayoutColumns>
                            )
                        }
                        <FieldBlock label={textDistributionsLicenses(`field.${DistributionLicenseField.SEGMENT_LINK_TYPE}`)}>
                            <RadioButtons
                                id={DistributionLicenseField.SEGMENT_LINK_TYPE}
                                value={license.segment_link_type}
                                options={DistributionLicenseSegmentLinkType.values().map((it) => ({value: it.name, label: it.name}))}
                                onChange={(value) => handleChange(DistributionLicenseField.SEGMENT_LINK_TYPE, value)}
                            />
                        </FieldBlock>
                        {license.segment_link_type === DistributionLicenseSegmentLinkType.DATATYPE.name &&
                            <FieldBlock
                                label={textDistributionsLicenses(`field.${DistributionLicenseField.SEGMENT_LINK_DATATYPE}`)}
                                required={license.segment_link_type === DistributionLicenseSegmentLinkType.DATATYPE.name}
                            >
                                <Select
                                    value={license.segment_link_datatype || SegmentDataType.values()[0].name}
                                    options={SegmentDataType.values().map((it) => ({value: it.name, label: it.name}))}
                                    onChange={(option) => handleChange(DistributionLicenseField.SEGMENT_LINK_DATATYPE, option?.value)}
                                    disabled={license.segment_link_type !== DistributionLicenseSegmentLinkType.DATATYPE.name}
                                    menuPlacement={"top"}
                                />
                            </FieldBlock>
                        }
                        {license.segment_link_type === DistributionLicenseSegmentLinkType.LIST.name &&
                            <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                                <FieldBlock content={{direction: FlexContentDirection.COLUMN}}>
                                    <SelectSegment
                                        value={undefined}
                                        onChange={(segment) => segment && handleAddSegment(segment)}
                                        excludedSegments={selectedSegments}
                                    />
                                    {formValidator.isError(DistributionLicenseField.SEGMENTS) &&
                                        <FormLayoutMessage message={t("message.error.field_required")} small severity={AlertSeverity.DANGER}/>
                                    }
                                </FieldBlock>
                                <FieldBlock
                                    label={textDistributionsLicenses("modal.selected", {count: selectedSegments.length})}
                                    actions={<ButtonLink onClick={() => setSelectedSegments([])}>{textCommon(Action.REMOVE_ALL.labelKey)}</ButtonLink>}
                                >
                                    <ElementList placeholder={textDistributionsLicenses("modal.search_result")} size={ElementListSize.BIG}>
                                        {selectedSegments.map((item) =>
                                            <TagSegmentRow
                                                key={item.id}
                                                segment={item}
                                                onRemove={() => handleRemoveSegment(item)}
                                            />
                                        )}
                                    </ElementList>
                                </FieldBlock>
                            </FormLayoutRows>
                        }
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={() => onClose(false)}/>
                <ButtonValidate form={FORM_ID} disabled/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalEditDistributionLicense;
