import {IconTooltip, TableColumn, TableColumnStyle, TableRow, TagStyle} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {CmpConfig} from "../../../../api/model/cmp/config/CmpConfig";
import {Formatter} from "../../../../common/utils/Formatter";
import {TranslationPortalFile} from "../../../../utils/constants";
import {LabelPartner, LabelStatus, Tag} from "../../index";
import {Status} from "../../../../utils/Status";
import {CmpConfigVersion} from "../../../../api/model/cmp/config/CmpConfigVersion";

type CmpConfigRowProps = {
    item: CmpConfig;
}

const CmpConfigRow: FunctionComponent<CmpConfigRowProps> = ({item}) => {
    const {t: textCmpConfigurations} = useTranslation(TranslationPortalFile.CMP_CONFIGURATIONS);

    return (
        <TableRow onClick={{link: item.getRoute()}}>
            <TableColumn>
                <LabelStatus status={item.getStatus()}/>
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                {item.id}
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                <LabelPartner partner={item.id_partner}/>
            </TableColumn>
            <TableColumn styles={TableColumnStyle.HIDE_SCREEN_SMALL}>
                <span>{item.name}</span>
                {(item.status === Status.INACTIVE.name && !!item.step) &&
                <IconTooltip
                    icon={{name: "schedule", outlined: true}}
                    text={textCmpConfigurations("in_progress")}
                />
                }
            </TableColumn>
            <TableColumn styles={TableColumnStyle.HIDE_SCREEN_MEDIUM}>
                {item.domain}
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                {item.version &&
                    <Tag label={CmpConfigVersion.getByName(item.version)?.label!} style={TagStyle.PRIMARY_OCEAN}/>
                }
            </TableColumn>
            <TableColumn styles={TableColumnStyle.HIDE_SCREEN_MEDIUM}>
                {Formatter.formatDate(item.creation_time, Formatter.DATETIME_FORMAT)}
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                {item.properties.creation_type}
            </TableColumn>
        </TableRow>
    );
};

export default CmpConfigRow;
