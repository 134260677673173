import {Box, BoxRadius, BoxSpacing, FlexContent, FlexContentDirection, FlexContentLayout, FlexContentMobileDirection, FlexContentSpacing, FormLayoutRows, FormLayoutTitle, ToggleSwitch} from "@sirdata/ui-lib";
import {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {CmpConfigCookieWallField} from "../../../api/model/cmp/config/CmpConfigCookieWallField";
import {CmpConfigField} from "../../../api/model/cmp/config/CmpConfigField";
import {CmpConfigSettings} from "../../../api/model/cmp/config/CmpConfigSettings";
import {CmpConfigSettingsField} from "../../../api/model/cmp/config/CmpConfigSettingsField";
import {TranslationPortalFile} from "../../../utils/constants";
import {CmpConfigurationExternal} from "../index";
import {CmpConfigCookieWall} from "../../../api/model/cmp/config/CmpConfigCookieWall";

type CmpConfigurationSettingsAddOnsProps = {
    settings: CmpConfigSettings;
    onChange: (settings: CmpConfigSettings) => void;
};

const CmpConfigurationSettingsAddOns: FunctionComponent<CmpConfigurationSettingsAddOnsProps> = ({settings, onChange}) => {
    const {t: textCmpConfigurations} = useTranslation(TranslationPortalFile.CMP_CONFIGURATIONS);

    const handleChangeSettings = (field: CmpConfigSettingsField, value: any) => {
        onChange(new CmpConfigSettings({...settings, [field]: value}));
    };

    const handleChangeSettingsCookieWall = (field: CmpConfigCookieWallField, value: any) => {
        handleChangeSettings(CmpConfigSettingsField.COOKIEWALL, new CmpConfigCookieWall({...settings.cookieWall, [field]: value}));
    };

    return (
        <FlexContent
            direction={FlexContentDirection.ROW}
            layout={FlexContentLayout.COLUMNS}
            spacing={FlexContentSpacing.SMALL}
            mobileDirection={FlexContentMobileDirection.COLUMN}
        >
            <CmpConfigurationExternal
                external={settings.external}
                onChangeExternal={(external) => handleChangeSettings(CmpConfigSettingsField.EXTERNAL, external)}
            />
            <FlexContent
                direction={FlexContentDirection.COLUMN}
                spacing={FlexContentSpacing.SMALL}
                cssClass="cmp-add-ons"
            >
                <Box
                    radius={BoxRadius.MD}
                    spacing={BoxSpacing.MEDIUM}
                    cssClass={"cmp-add-ons__item"}
                >
                    <div className={"cmp-add-ons__item__image-container"}>
                        <img src={"/images/cmp/config/cookie.png"} alt={""}/>
                    </div>
                    <FormLayoutRows>
                        <FormLayoutTitle>{textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.COOKIEWALL}.${CmpConfigCookieWallField.ACTIVE}`)}</FormLayoutTitle>
                        <ToggleSwitch
                            checked={settings.cookieWall.active}
                            onChange={(value) => handleChangeSettingsCookieWall(CmpConfigCookieWallField.ACTIVE, value)}
                        />
                    </FormLayoutRows>
                </Box>
                <Box
                    radius={BoxRadius.MD}
                    spacing={BoxSpacing.MEDIUM}
                    cssClass={"cmp-add-ons__item"}
                >
                    <div className={"cmp-add-ons__item__image-container"}>
                        <img src={"/images/cmp/config/logo-iab-tcf.png"} alt={""}/>
                    </div>
                    <FormLayoutRows>
                        <FormLayoutTitle>{textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.DISABLE_TCF}`)}</FormLayoutTitle>
                        <ToggleSwitch
                            checked={settings.disableTcf}
                            onChange={(value) => handleChangeSettings(CmpConfigSettingsField.DISABLE_TCF, value)}
                        />
                    </FormLayoutRows>
                </Box>
            </FlexContent>
        </FlexContent>
    );
};

export default CmpConfigurationSettingsAddOns;
