import {Action, ActionsMenu, Color, IconTooltip, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TranslationLibFile} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {Authorization} from "../../../../api/model/account/Authorization";
import {Pricing} from "../../../../api/model/partner/pricing/Pricing";
import {PricingField} from "../../../../api/model/partner/pricing/PricingField";
import {RestrictedContent} from "../../../../common/component/widget";
import {TranslationPortalFile} from "../../../../utils/constants";
import {LabelService, LabelStatus} from "../../index";

type PricingRowProps = {
    pricing: Pricing;
    onSetAsDefault: () => void;
};

const PricingRow: FunctionComponent<PricingRowProps> = ({pricing, onSetAsDefault}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textPricings} = useTranslation(TranslationPortalFile.PRICINGS);

    return (
        <TableRow onClick={{link: pricing.getRoute()}}>
            <TableColumn>
                <LabelStatus status={pricing.getStatus()}/>
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                {pricing.id}
            </TableColumn>
            <TableColumn>
                <LabelService service={pricing.api_service}/>
            </TableColumn>
            <TableColumn>
                <span>{pricing.name}</span>
                {pricing.default &&
                <IconTooltip
                    icon={{name: "verified", colorIcon: Color.GREEN}}
                    text={textPricings(`field.${PricingField.DEFAULT}`, {product: textPricings(`api_service.${pricing.api_service}`)})}
                />
                }
            </TableColumn>
            <TableColumn styles={TableColumnStyle.HIDE_SCREEN_MEDIUM}>{pricing.description}</TableColumn>
            <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                <RestrictedContent allowedTo={Authorization.PRICINGS.update}>
                    {!pricing.default &&
                    <ActionsMenu
                        iconTooltip={{icon: Action.MORE.icon, text: textCommon(Action.MORE.labelKey)}}
                        items={[{label: textPricings("actions.use_by_default_for_product", {product: textPricings(`api_service.${pricing.api_service}`)}), onClick: onSetAsDefault}]}
                    />
                    }
                </RestrictedContent>
            </TableActionColumn>
        </TableRow>
    );
};

export default PricingRow;
