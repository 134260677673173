import {Action, ContentBlock, LayoutRows, Loadable, Paragraph, SearchError, SearchField, SearchToolbar, Table, TableColumnStyle, ToggleSwitch} from "@sirdata/ui-lib";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {CategorizerConfigEntryField} from "../../api/model/categorizer/CategorizerConfigEntryField";
import {CategorizerConfigSearchParamsField, CategorizerConfigSearchQuery} from "../../api/model/categorizer/CategorizerConfigSearchQuery";
import {CategorizerConfigSearchResultItem} from "../../api/model/categorizer/CategorizerConfigSearchResultItem";
import {CategorizerConfigType} from "../../api/model/categorizer/CategorizerConfigType";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import ModalInitCategorizerConfigParams from "../../component/modal/categorizer/ModalInitCategorizerConfigParams";
import {CategorizerConfigEntriesRow, MainContentHeader, MainContentHeaderAction, SelectStatus} from "../../component/snippet";
import {pathCategorizerAdvancedSearch, TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import useSearch from "../../utils/hooks/useSearch";
import {Module} from "../../utils/Module";
import {Status} from "../../utils/Status";

function Categorizer() {
    const {t: textCategorizer} = useTranslation(TranslationPortalFile.CATEGORIZER);
    const alert = useAlert();
    const history = useHistory();

    const [isLoading, setLoading] = useState(true);
    const [domainItems, setDomainItems] = useState<CategorizerConfigSearchResultItem[]>([]);
    const [isShowModalInitCategorizerConfigParams, setShowModalInitCategorizerConfigParams] = useState(false);

    const {buildSearchResult, ...search} = useSearch(CategorizerConfigSearchResultItem, CategorizerConfigSearchQuery);

    useEffect(() => {
        (async () => {
            try {
                setDomainItems(await session.restCategorizerDomain.list());
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("domains", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [alert]);

    useEffect(() => {
        let filteredItems = [...domainItems];
        if (search.searchQuery.query) {
            filteredItems = filteredItems.filter((item) => {
                if (search.searchQuery.strict) {
                    return item.domain === search.searchQuery.query;
                }
                return item.domain?.includes(search.searchQuery.query);
            });
        }
        for (const type of CategorizerConfigType.values()) {
            if (search.searchQuery[type.name] === undefined) continue;
            if (search.searchQuery[type.name] === Status.ACTIVE.name) {
                filteredItems = filteredItems.filter((item) => item.types.includes(type.name));
            } else {
                filteredItems = filteredItems.filter((item) => !item.types.includes(type.name));
            }
        }
        buildSearchResult(filteredItems);
    }, [domainItems, search.searchQuery, buildSearchResult]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.CATEGORIZER}>
                <RestrictedContent allowedTo={Authorization.CATEGORIZER_ADMIN.name}>
                    <MainContentHeaderAction
                        action={new Action(textCategorizer("action.advanced_search"), {name: "mystery"})}
                        onClick={() => history.push(pathCategorizerAdvancedSearch)}
                    />
                </RestrictedContent>
                <RestrictedContent allowedTo={Authorization.CATEGORIZER.update}>
                    <MainContentHeaderAction action={Action.NEW} onClick={() => setShowModalInitCategorizerConfigParams(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <Paragraph>{textCategorizer("description")}</Paragraph>
                    <ContentBlock>
                        <SearchToolbar searchBar={{placeholder: textCategorizer("search.placeholder"), value: search.searchQuery.query, onSubmit: search.changeQuery}}>
                            <SearchField label={textCategorizer("search.strict")} shortWidth>
                                <ToggleSwitch
                                    name={CategorizerConfigSearchParamsField.STRICT}
                                    checked={search.searchQuery.strict}
                                    onChange={(value) => search.changeParam(CategorizerConfigSearchParamsField.STRICT, value)}
                                />
                            </SearchField>
                            {CategorizerConfigType.values().map((type) =>
                                <SearchField label={textCategorizer(`config_type.${type.name}`)} key={type.name}>
                                    <SelectStatus
                                        value={search.searchQuery[type.name] || ""}
                                        statuses={Status.getActiveStatuses()}
                                        onChange={(status) => search.changeParam(type.name, status?.name)}
                                        clearable
                                    />
                                </SearchField>
                            )}
                        </SearchToolbar>
                        <Loadable loading={isLoading}>
                            {!!search.searchResult.elements.length ?
                                <Table
                                    onSort={search.changeSortOrder}
                                    columns={[
                                        {width: 50, label: textCategorizer(`field.${CategorizerConfigEntryField.DOMAIN}`), sort: {field: CategorizerConfigEntryField.DOMAIN, reverseOrder: false}},
                                        {width: 10, label: textCategorizer(`config_type.${CategorizerConfigType.SANITIZER.name}`), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 10, label: textCategorizer(`config_type.${CategorizerConfigType.INTENT_CONFIDENCE.name}`), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 10, label: textCategorizer(`config_type.${CategorizerConfigType.FILTER.name}`), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 10, label: textCategorizer(`config_type.${CategorizerConfigType.ENRICHMENT.name}`), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 10, label: textCategorizer(`config_type.${CategorizerConfigType.URL_CATEGORIZATION.name}`), styles: TableColumnStyle.ALIGN_CENTER}
                                    ]}
                                    pagination={search.searchResult.getPagination(search.changePage)}
                                >
                                    {search.searchResult.elements.map((item) =>
                                        <CategorizerConfigEntriesRow key={item.domain} item={item}/>
                                    )}
                                </Table> :
                                <SearchError query={search.searchQuery.query}/>
                            }
                        </Loadable>
                    </ContentBlock>
                </LayoutRows>
                <ModalInitCategorizerConfigParams active={isShowModalInitCategorizerConfigParams} onClose={() => setShowModalInitCategorizerConfigParams(false)}/>
            </MainContent>
        </Wrapper>
    );
}

export default Categorizer;
