import {Action, ContentBlock, LayoutRows, Loadable, SearchError, SearchField, SearchToolbar, Select, SelectAutocomplete, Table, TableColumnStyle} from "@sirdata/ui-lib";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {session} from "../../api/ApiSession";
import {ConsentManagementPlatform} from "../../api/model/cmp/list/cmp-list/ConsentManagementPlatform";
import {ConsentManagementPlatformField} from "../../api/model/cmp/list/cmp-list/ConsentManagementPlatformField";
import {DataleaksAudit} from "../../api/model/dataleaks/audit/DataleaksAudit";
import {DataleaksAuditCompanyField} from "../../api/model/dataleaks/audit/DataleaksAuditCompanyField";
import {DataleaksAuditField} from "../../api/model/dataleaks/audit/DataleaksAuditField";
import {DataleaksAuditInfosField} from "../../api/model/dataleaks/audit/DataleaksAuditInfosField";
import {DataleaksAuditSearchParamsField, DataleaksAuditSearchQuery} from "../../api/model/dataleaks/audit/DataleaksAuditSearchQuery";
import {DataleaksAuditSearchResult} from "../../api/model/dataleaks/audit/DataleaksAuditSearchResult";
import {DataleaksVersion} from "../../api/model/dataleaks/version/DataleaksVersion";
import {User} from "../../api/model/user/User";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {MainHeader} from "../../common/component/snippet";
import {sortItems} from "../../common/utils/helper";
import {DataleaksAuditRow, MainContentHeader, MainContentHeaderAction, SelectPartner, SelectUser} from "../../component/snippet";
import {pathDataleaksQuestions, pathDataleaksStatistics, pathDataleaksVersions, TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import useSearch from "../../utils/hooks/useSearch";
import {Module} from "../../utils/Module";
import {SearchParamsField} from "../../utils/SearchParamsField";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {Authorization} from "../../api/model/account/Authorization";
import ModalCreateDataleaksAudit from "../../component/modal/dataleaks/ModalCreateDataleaksAudit";

function DataleaksAudits() {
    const alert = useAlert();
    const {t: textDataleaksAudits} = useTranslation(TranslationPortalFile.DATALEAKS_AUDITS);
    const {t: textDataleaksQuestions} = useTranslation(TranslationPortalFile.DATALEAKS_QUESTIONS);
    const {t: textDataleaksStatistics} = useTranslation(TranslationPortalFile.DATALEAKS_STATISTICS);
    const {t: textDataleaksVersions} = useTranslation(TranslationPortalFile.DATALEAKS_VERSIONS);
    const history = useHistory();
    const [isLoading, setLoading] = useState(true);
    const [users, setUsers] = useState<User[]>([]);
    const [versions, setVersions] = useState<DataleaksVersion[]>([]);
    const [cmps, setCmps] = useState<ConsentManagementPlatform[]>([]);
    const {setSearchResult, ...search} = useSearch(DataleaksAudit, DataleaksAuditSearchQuery, DataleaksAuditSearchResult);
    const [isShowModalCreateDataleaksAudit, setShowModalCreateDataleaksAudit] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                setUsers(await session.getUsers());
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("users", e.message);
                }
            }
            try {
                setVersions(await session.restDataleaksVersion.list());
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("versions", e.message);
                }
            }
            try {
                const cmpList = await session.restCmpList.getCmpList();
                setCmps(sortItems(cmpList.cmps, ConsentManagementPlatformField.NAME));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("CMP list", e.message);
                }
            }
        })();
    }, [alert]);

    useEffect(() => {
        (async () => {
            try {
                const newSearchResult = await session.restDataleaksAudit.search(search.searchQuery);
                setSearchResult(newSearchResult);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("audits", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [alert, setSearchResult, search.searchQuery]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.DATALEAKS}>
                <MainContentHeaderAction
                    action={new Action(textDataleaksStatistics("title"), Module.DATALEAKS_STATISTICS.icon)}
                    onClick={() => history.push(pathDataleaksStatistics)}
                />
                <MainContentHeaderAction
                    action={new Action(textDataleaksQuestions("title"), Module.DATALEAKS_QUESTIONS.icon)}
                    onClick={() => history.push(pathDataleaksQuestions)}
                />
                <MainContentHeaderAction
                    action={new Action(textDataleaksVersions("title"), Module.DATALEAKS_VERSIONS.icon)}
                    onClick={() => history.push(pathDataleaksVersions)}
                />
                <RestrictedContent allowedTo={Authorization.DATALEAKS.update}>
                    <MainContentHeaderAction action={Action.NEW} onClick={() => setShowModalCreateDataleaksAudit(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <ContentBlock>
                        <SearchToolbar
                            searchBar={{placeholder: textDataleaksAudits("search.placeholder"), value: search.searchQuery.query, onSubmit: search.changeQuery}}
                            onClearFilters={search.clearFilters}
                            canHideFilters
                        >
                            <SearchField label={textDataleaksAudits(`field.${DataleaksAuditField.PARTNER_ID}`)}>
                                <SelectPartner
                                    value={search.searchQuery.partner_id}
                                    onChange={(partner) => search.changeParam(SearchParamsField.PARTNER, partner?.id)}
                                />
                            </SearchField>
                            <SearchField label={textDataleaksAudits(`field.${DataleaksAuditField.VERSION_ID}`)}>
                                <Select
                                    value={search.searchQuery.version_id}
                                    options={versions.map((it) => ({label: `${it.label}`, value: it.id}))}
                                    onChange={(option) => search.changeParam(DataleaksAuditSearchParamsField.VERSION_ID, option?.value)}
                                    clearable
                                />
                            </SearchField>
                            <SearchField label={textDataleaksAudits(`field.${DataleaksAuditField.USER_ID}`)}>
                                <SelectUser
                                    value={search.searchQuery.user_id}
                                    onChange={(user) => search.changeParam(SearchParamsField.OWNER, user?.id)}
                                />
                            </SearchField>
                            <SearchField label={textDataleaksAudits(`field.${DataleaksAuditField.INFOS}.${DataleaksAuditInfosField.CMP_ID}`)}>
                                <SelectAutocomplete
                                    value={search.searchQuery.cmp_id}
                                    options={cmps.map((it) => ({label: `${it.id} - ${it.name}`, value: it.id}))}
                                    onChange={(option) => search.changeParam(DataleaksAuditSearchParamsField.CMP_ID, option?.value)}
                                    clearable
                                />
                            </SearchField>
                        </SearchToolbar>
                        <Loadable loading={isLoading}>
                            {!!search.searchResult.elements.length ?
                                <Table
                                    columns={[
                                        {width: 5, label: textDataleaksAudits(`field.${DataleaksAuditField.ID}`), sort: {field: DataleaksAuditField.ID, reverseOrder: true}, styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 5, label: textDataleaksAudits(`field.${DataleaksAuditField.PARTNER_ID}`), sort: {field: DataleaksAuditField.PARTNER_ID}, styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 20, label: textDataleaksAudits(`field.${DataleaksAuditField.COMPANY}.${DataleaksAuditCompanyField.NAME}`)},
                                        {width: 30, label: textDataleaksAudits(`field.${DataleaksAuditField.DOMAIN}`), sort: {field: DataleaksAuditField.DOMAIN}},
                                        {width: 10, label: textDataleaksAudits(`field.${DataleaksAuditField.VERSION_ID}`), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 10, label: textDataleaksAudits(`field.${DataleaksAuditField.SCORING}.`)},
                                        {width: 10, label: textDataleaksAudits(`field.${DataleaksAuditField.USER_ID}`), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 10, label: textDataleaksAudits(`field.${DataleaksAuditField.CREATED_AT}`), sort: {field: DataleaksAuditField.CREATED_AT}}
                                    ]}
                                    onSort={search.changeSortOrder}
                                    pagination={search.searchResult.getPagination(search.changePage)}
                                >
                                    {search.searchResult.elements.map((audit) =>
                                        <DataleaksAuditRow key={audit.id} audit={audit} owner={users.find((it) => it.id === audit.user_id)}/>
                                    )}
                                </Table> :
                                <SearchError query={search.searchQuery.query}/>
                            }
                        </Loadable>
                    </ContentBlock>
                </LayoutRows>
                <ModalCreateDataleaksAudit
                    active={isShowModalCreateDataleaksAudit}
                    onClose={() => setShowModalCreateDataleaksAudit(false)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default DataleaksAudits;
