import {Action, ContentBlock, ContentBlockAction, Loadable, SearchError, SearchField, SearchToolbar, SelectMultiple, Table, TableColumnStyle} from "@sirdata/ui-lib";
import React, {FunctionComponent, useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {SearchResult} from "../../../api/interface/SearchResult";
import {Distribution} from "../../../api/model/audience/distribution/Distribution";
import {DistributionSegmentsSearchParamsField, DistributionSegmentsSearchQuery} from "../../../api/model/audience/distribution/DistributionSegmentsSearchQuery";
import {DISTRIBUTION_SEGMENTS_STATUSES} from "../../../api/model/audience/distribution/DistributionSegmentsStatus";
import {DistributionSegmentsStatusUpdate} from "../../../api/model/audience/distribution/DistributionSegmentsStatusUpdate";
import {Segment} from "../../../api/model/audience/segment/Segment";
import {SegmentDataType} from "../../../api/model/audience/segment/SegmentDataType";
import {SegmentLinkExternal} from "../../../api/model/audience/segment/SegmentLinkExternal";
import {SegmentLinkExternalField} from "../../../api/model/audience/segment/SegmentLinkExternalField";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {PAGE_SIZE, TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import useSearch from "../../../utils/hooks/useSearch";
import {SearchParamsField} from "../../../utils/SearchParamsField";
import {Status} from "../../../utils/Status";
import ModalManageDistributionSegments from "../../modal/distributions/ModalManageDistributionSegments";
import {DistributionSegmentRow, SelectStatus} from "../index";

export type DistributionSegmentsProps = {
    distribution: Distribution;
};

const DistributionSegments: FunctionComponent<DistributionSegmentsProps> = ({distribution}) => {
    const {t: textDistributions} = useTranslation(TranslationPortalFile.DISTRIBUTIONS);
    const {t: textSegments} = useTranslation(TranslationPortalFile.SEGMENTS);
    const alert = useAlert();
    const [isLoadingSegments, setLoadingSegments] = useState(true);
    const [segments, setSegments] = useState<Segment[]>([]);
    const [segmentLinks, setSegmentLinks] = useState<SegmentLinkExternal[]>([]);
    const [isShowModalManageSegments, setShowModalManageSegments] = useState(false);
    const {setSearchResult, ...search} = useSearch(SegmentLinkExternal, DistributionSegmentsSearchQuery);

    const getSegment = useCallback((segmentId: number) => {
        return segments.find((it) => it.id === segmentId);
    }, [segments]);

    const loadDistributionSegments = useCallback(async () => {
        if (!distribution.name) return;
        setLoadingSegments(true);
        try {
            const links = await session.restDistribution.getSegments(distribution.name);
            setSegmentLinks(links);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToLoad("distribution's segments", e.message);
            }
        } finally {
            setLoadingSegments(false);
        }
    }, [distribution.name, alert]);

    useEffect(() => {
        (async function () {
            try {
                setSegments(await session.getSegments());
                await loadDistributionSegments();
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("segments", e.message);
                }
            }
        })();
    }, [alert, loadDistributionSegments]);

    useEffect(() => {
        let filteredSegmentLinks = (segmentLinks || []);
        if (search.searchQuery.query) {
            filteredSegmentLinks = filteredSegmentLinks.filter((it) => `${it.segment_id} ${getSegment(it.segment_id)?.name} ${it.value}`.toLowerCase().includes(search.searchQuery.query.toLowerCase()));
        }
        if (!!search.searchQuery.status) {
            filteredSegmentLinks = filteredSegmentLinks.filter((it) => search.searchQuery.status === Status.OPEN.name ? it.active : !it.active);
        }
        if (!!search.searchQuery.data_types.length) {
            const segmentIds = segments.filter((it) => search.searchQuery.data_types.includes(it.data_type)).map((it) => it.id);
            filteredSegmentLinks = filteredSegmentLinks.filter((it) => segmentIds.includes(it.segment_id));
        }
        filteredSegmentLinks = search.searchQuery.sortItems(filteredSegmentLinks);
        setSearchResult(SearchResult.buildFromList(SegmentLinkExternal, filteredSegmentLinks, search.searchQuery.page, PAGE_SIZE));
    }, [segmentLinks, search.searchQuery, getSegment, setSearchResult, segments]);

    const handleSaveSegmentsStatus = async (newDistributionSegmentsStatusUpdate: DistributionSegmentsStatusUpdate) => {
        setShowModalManageSegments(false);
        try {
            await session.restDistribution.updateSegmentsStatus(newDistributionSegmentsStatusUpdate);
            await loadDistributionSegments();
            if (newDistributionSegmentsStatusUpdate.all_segment) {
                alert.updateWithSuccess(textDistributions("message.all_linked_segments", {count: segmentLinks.length}));
            } else {
                alert.updateWithSuccess(textDistributions("message.count_segments", {count: newDistributionSegmentsStatusUpdate.segments.length}));
            }
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("distribution's segments", e.message);
            }
        }
    };

    return (
        <>
            <Loadable loading={isLoadingSegments}>
                <ContentBlock
                    header={{
                        title: {
                            label: search.searchQuery.status === Status.OPEN.name ?
                                textDistributions("section.segments_count_opened", {count: segmentLinks.filter((it) => it.active).length}) :
                                textDistributions("section.segments_count_closed", {count: segmentLinks.filter((it) => !it.active).length})
                        }
                    }}
                >
                    <SearchToolbar
                        searchBar={{placeholder: textDistributions("search.placeholder_segments"), value: search.searchQuery.query, onSubmit: search.changeQuery}}
                        canHideFilters
                        actions={[
                            <ContentBlockAction
                                key="action-manage-segments"
                                action={new Action(textDistributions("action.manage_segments"), Action.EDIT.icon)}
                                onClick={() => setShowModalManageSegments(true)}
                            />
                        ]}
                    >
                        <SearchField label={textSegments("field.status")}>
                            <SelectStatus
                                value={search.searchQuery.status}
                                statuses={DISTRIBUTION_SEGMENTS_STATUSES}
                                onChange={(status) => search.changeParam(SearchParamsField.STATUS, status?.name)}
                            />
                        </SearchField>
                        <SearchField label={textSegments(`field.${DistributionSegmentsSearchParamsField.DATA_TYPES}`)}>
                            <SelectMultiple
                                values={search.searchQuery.data_types}
                                options={SegmentDataType.values().map((type) => ({label: `${textSegments(`datatype.${type.name}`)}`, value: type.name}))}
                                onChange={(options) => search.changeParam(DistributionSegmentsSearchParamsField.DATA_TYPES, options.map((it) => it.value))}
                            />
                        </SearchField>
                    </SearchToolbar>
                    {!!search.searchResult.elements.length ?
                        <Table
                            onSort={search.changeSortOrder}
                            columns={[
                                {width: 5, label: textDistributions("field.status"), styles: TableColumnStyle.ALIGN_CENTER},
                                {width: 5, label: textDistributions(`field.segments.${SegmentLinkExternalField.SEGMENT_ID}`), sort: {field: SegmentLinkExternalField.SEGMENT_ID}, styles: TableColumnStyle.ALIGN_CENTER},
                                {width: 65, label: textDistributions("field.segments.name")},
                                {width: 10, label: textDistributions(`field.segments.${SegmentLinkExternalField.VALUE}`), styles: TableColumnStyle.ALIGN_CENTER},
                                {width: 15, label: textDistributions(`field.segments.${SegmentLinkExternalField.LAST_UPDATE}`), sort: {field: SegmentLinkExternalField.LAST_UPDATE, reverseOrder: true}, styles: TableColumnStyle.ALIGN_CENTER}
                            ]}
                            pagination={search.searchResult.getPagination(search.changePage)}
                        >
                            {search.searchResult.elements.map((link: SegmentLinkExternal) =>
                                <DistributionSegmentRow key={link.segment_id} link={link} segment={getSegment(link.segment_id)}/>
                            )}
                        </Table> :
                        <SearchError query={search.searchQuery.query}/>
                    }
                </ContentBlock>
            </Loadable>
            <ModalManageDistributionSegments
                active={isShowModalManageSegments}
                distribution={distribution}
                onSubmit={handleSaveSegmentsStatus}
                onClose={() => setShowModalManageSegments(false)}
            />
        </>
    );
};

export default DistributionSegments;
