import {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Action, ContentBlock, ContentBlockAction, SearchError, SearchToolbar, Table} from "@sirdata/ui-lib";
import {PAGE_SIZE, TranslationPortalFile} from "../../../utils/constants";
import {RestrictedContent} from "../../../common/component/widget";
import {Authorization} from "../../../api/model/account/Authorization";
import {sortItems} from "../../../common/utils/helper";
import {CmpSirdataListV2PurposesEditableRow} from "../../../component/snippet";
import {SirdataListLanguage} from "../../../api/model/cmp/list/sirdata-list-v2/SirdataListLanguage";
import {SirdataListPurpose} from "../../../api/model/cmp/list/sirdata-list-v2/SirdataListPurpose";
import {SirdataListPurposeField} from "../../../api/model/cmp/list/sirdata-list-v2/SirdataListPurposeField";
import {SirdataListVersionField} from "../../../api/model/cmp/list/sirdata-list-v2/SirdataListVersionField";
import ModalCreateCmpSirdataListV2Purpose from "../../modal/cmp-sirdata-list-v2/ModalCreateCmpSirdataListV2Purpose";
import {SirdataListVersion} from "../../../api/model/cmp/list/sirdata-list-v2/SirdataListVersion";
import ModalTranslateCmpSirdataListV2Purposes from "../../modal/cmp-sirdata-list-v2/ModalTranslateCmpSirdataListV2Purposes";

type CmpSirdataListV2PurposesEditableProps = {
    sirdataList: SirdataListVersion;
    listLanguage: SirdataListLanguage;
    onChange: () => void;
};

const CmpSirdataListV2PurposesEditable: FC<CmpSirdataListV2PurposesEditableProps> = ({sirdataList, listLanguage, onChange}) => {
    const {t: textCmpSirdataList} = useTranslation(TranslationPortalFile.CMP_SIRDATA_LIST_V2);
    const [query, setQuery] = useState("");
    const [filteredPurposes, setFilteredPurposes] = useState<SirdataListPurpose[]>([]);
    const [isShowModalCreateCmpSirdataListV2Purpose, setShowModalCreateCmpSirdataListV2Purpose] = useState(false);
    const [isShowModalTranslateCmpSirdataListV2Purposes, setShowModalTranslateCmpSirdataListV2Purposes] = useState(false);

    useEffect(() => {
        const sortedPurposes = sortItems(listLanguage.purposes, SirdataListPurposeField.ID, true);
        const filteredPurposes = sortedPurposes.filter((purpose) => purpose.name.toLowerCase().includes(query.toLowerCase()));
        setFilteredPurposes(filteredPurposes);
    }, [listLanguage.purposes, query]);

    return (
        <ContentBlock
            header={{
                title: {label: textCmpSirdataList(`field.${SirdataListVersionField.PURPOSES}`)},
                actions: (
                    <RestrictedContent allowedTo={Authorization.CMP_SIRDATA_LIST.update}>
                        <ContentBlockAction action={new Action(textCmpSirdataList("action.translate"), {name: "language"})} onClick={() => setShowModalTranslateCmpSirdataListV2Purposes(true)}/>
                        <ContentBlockAction action={Action.ADD} onClick={() => setShowModalCreateCmpSirdataListV2Purpose(true)}/>
                    </RestrictedContent>
                )
            }}
        >
            <SearchToolbar
                searchBar={{
                    value: query,
                    placeholder: textCmpSirdataList(`search.placeholder.${SirdataListVersionField.PURPOSES}`),
                    onSubmit: setQuery
                }}
            />
            {!!filteredPurposes.length ?
                <Table
                    columns={[
                        {width: 5, label: textCmpSirdataList(`field.purpose.${SirdataListPurposeField.ID}`)},
                        {width: 30, label: textCmpSirdataList(`field.purpose.${SirdataListPurposeField.NAME}`)},
                        {width: 65, label: textCmpSirdataList(`field.purpose.${SirdataListPurposeField.DESCRIPTION}`)}
                    ]}
                    pagination={PAGE_SIZE}
                >
                    {filteredPurposes.map((purpose) =>
                        <CmpSirdataListV2PurposesEditableRow
                            key={purpose.id}
                            purpose={purpose}
                            currentVersionPurpose={sirdataList.purposes.find((it) => it.id === purpose.id)}
                        />
                    )}
                </Table> :
                <SearchError query={query}/>
            }
            <ModalCreateCmpSirdataListV2Purpose
                active={isShowModalCreateCmpSirdataListV2Purpose}
                onClose={async (refresh) => {
                    setShowModalCreateCmpSirdataListV2Purpose(false);
                    refresh && onChange();
                }}
            />
            <ModalTranslateCmpSirdataListV2Purposes
                active={isShowModalTranslateCmpSirdataListV2Purposes}
                onClose={async (refresh) => {
                    setShowModalTranslateCmpSirdataListV2Purposes(false);
                    refresh && onChange();
                }}
            />
        </ContentBlock>
    );
};

export default CmpSirdataListV2PurposesEditable;
