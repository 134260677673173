import {FunctionComponent, useState} from "react";
import {useTranslation} from "react-i18next";
import {Action, ActionsMenu, Color, ContentBlock, Icon, IconTooltip, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TranslationLibFile} from "@sirdata/ui-lib";
import {SirdataListVendor} from "../../../../api/model/cmp/list/sirdata-list-v2/SirdataListVendor";
import {SirdataListPurpose} from "../../../../api/model/cmp/list/sirdata-list-v2/SirdataListPurpose";
import {TranslationPortalFile} from "../../../../utils/constants";
import {Formatter} from "../../../../common/utils/Formatter";
import {SirdataListVendorField} from "../../../../api/model/cmp/list/sirdata-list-v2/SirdataListVendorField";
import {RestrictedContent} from "../../../../common/component/widget";
import {Authorization} from "../../../../api/model/account/Authorization";
import {GlobalVendorList} from "../../../../api/model/cmp/list/global-vendor-list/GlobalVendorList";
import {GlobalVendorListField} from "../../../../api/model/cmp/list/global-vendor-list/GlobalVendorListField";
import {detectChanges} from "../../../../common/utils/portal";
import {sortNumbers} from "../../../../common/utils/helper";

type CmpSirdataListV2VendorsEditableRowProps = {
    vendor: SirdataListVendor;
    globalVendorList: GlobalVendorList;
    extraPurposes: SirdataListPurpose[];
    currentVersionVendor?: SirdataListVendor;
    onEdit: () => void;
    onDelete: () => void;
    onRestore: () => void;
};

const CmpSirdataListV2VendorsEditableRow: FunctionComponent<CmpSirdataListV2VendorsEditableRowProps> = ({vendor, globalVendorList, extraPurposes, currentVersionVendor, onEdit, onDelete, onRestore}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textCmpSirdataList} = useTranslation(TranslationPortalFile.CMP_SIRDATA_LIST_V2);
    const [isOpenDetails, setOpenDetails] = useState(false);

    return (
        <>
            <TableRow onClick={() => setOpenDetails((prevState) => !prevState)}>
                <TableColumn>
                    <Icon {...(isOpenDetails ? Action.COLLAPSE.icon : Action.EXPAND.icon)}/>
                </TableColumn>
                <TableColumn>{vendor.id}</TableColumn>
                <TableColumn>
                    {vendor.name}
                    {vendor.premium &&
                        <IconTooltip
                            icon={{name: "card_membership", outlined: true, colorIcon: Color.GREEN}}
                            text={textCmpSirdataList(`field.vendor.${SirdataListVendorField.PREMIUM}`)}
                        />
                    }
                    {(!currentVersionVendor || detectChanges(vendor, currentVersionVendor)) &&
                        <IconTooltip
                            icon={{name: "warning", colorIcon: Color.ORANGE}}
                            text={textCmpSirdataList("tooltip.warning")}
                        />
                    }
                </TableColumn>
                <TableColumn>{vendor.policyUrl}</TableColumn>
                <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                    {vendor.deletedDate && (
                        currentVersionVendor?.deletedDate ?
                            <IconTooltip
                                icon={{name: "indeterminate_check_box", colorIcon: Color.RED, outlined: true}}
                                text={textCmpSirdataList("vendor_deleted_on_date", {date: Formatter.formatUTCDate(currentVersionVendor.deletedDate, Formatter.DATETIME_FORMAT)})}
                            /> :
                            <IconTooltip
                                icon={{name: "indeterminate_check_box", colorIcon: Color.ORANGE, outlined: true}}
                                text={textCmpSirdataList("vendor_deleted_in_next_version")}
                            />
                    )}
                    {!vendor.deletedDate &&
                        <RestrictedContent allowedTo={Authorization.CMP_SIRDATA_LIST.update}>
                            <IconTooltip
                                icon={Action.EDIT.icon}
                                text={textCommon(Action.EDIT.labelKey)}
                                onClick={onEdit}
                            />
                        </RestrictedContent>
                    }
                    <RestrictedContent allowedTo={Authorization.CMP_SIRDATA_LIST.update}>
                        <ActionsMenu
                            iconTooltip={{icon: Action.MORE.icon, text: textCommon(Action.MORE.labelKey)}}
                            items={[vendor.deletedDate ?
                                {label: textCmpSirdataList("action.restore"), onClick: onRestore} :
                                {label: textCommon(Action.DELETE.labelKey), onClick: onDelete, critical: true}
                            ]}
                        />
                    </RestrictedContent>
                </TableActionColumn>
            </TableRow>
            {isOpenDetails &&
                <TableRow>
                    <TableColumn colSpan={6}>
                        <ContentBlock>
                            {(!!vendor.purposes.length || !!vendor.extraPurposes.length) &&
                                <>
                                    <span>{textCmpSirdataList("vendor_consent")}</span>
                                    <ul>
                                        {sortNumbers(vendor.purposes).map((id) =>
                                            <li key={id}>
                                                <b>{textCmpSirdataList("purpose_with_id", {id: id})}</b>: {globalVendorList.getName(GlobalVendorListField.PURPOSES, id)}
                                            </li>
                                        )}
                                        {sortNumbers(vendor.extraPurposes).map((id) =>
                                            <li key={id}>
                                                <b>{textCmpSirdataList("extra_purpose_with_id", {id: id})}</b>: {extraPurposes.find((it) => it.id === id)?.name || ""}
                                            </li>
                                        )}
                                    </ul>
                                </>
                            }
                            {(!!vendor.legIntPurposes.length || !!vendor.legIntExtraPurposes.length) &&
                                <>
                                    <span>{textCmpSirdataList("vendor_legitimate_interest")}</span>
                                    <ul>
                                        {sortNumbers(vendor.legIntPurposes).map((id) =>
                                            <li key={id}>
                                                <b>{textCmpSirdataList("purpose_with_id", {id: id})}</b>: {globalVendorList.getName(GlobalVendorListField.PURPOSES, id)}
                                            </li>
                                        )}
                                        {sortNumbers(vendor.legIntExtraPurposes).map((id) =>
                                            <li key={id}>
                                                <b>{textCmpSirdataList("extra_purpose_with_id", {id: id})}</b>: {extraPurposes.find((it) => it.id === id)?.name || ""}
                                            </li>
                                        )}
                                    </ul>
                                </>
                            }
                        </ContentBlock>
                    </TableColumn>
                </TableRow>
            }
        </>
    );
};

export default CmpSirdataListV2VendorsEditableRow;
