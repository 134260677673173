import {FunctionComponent, useState} from "react";
import {useTranslation} from "react-i18next";
import {Action, Color, ContentBlock, Icon, IconTooltip, TableColumn, TableRow} from "@sirdata/ui-lib";
import {SirdataListVendor} from "../../../../api/model/cmp/list/sirdata-list-v2/SirdataListVendor";
import {SirdataListPurpose} from "../../../../api/model/cmp/list/sirdata-list-v2/SirdataListPurpose";
import {TranslationPortalFile} from "../../../../utils/constants";
import {Formatter} from "../../../../common/utils/Formatter";
import {SirdataListVendorField} from "../../../../api/model/cmp/list/sirdata-list-v2/SirdataListVendorField";
import {GlobalVendorList} from "../../../../api/model/cmp/list/global-vendor-list/GlobalVendorList";
import {GlobalVendorListField} from "../../../../api/model/cmp/list/global-vendor-list/GlobalVendorListField";
import {sortNumbers} from "../../../../common/utils/helper";

type CmpSirdataListV2VendorsRowProps = {
    vendor: SirdataListVendor;
    globalVendorList: GlobalVendorList;
    extraPurposes: SirdataListPurpose[];
};

const CmpSirdataListV2VendorsRow: FunctionComponent<CmpSirdataListV2VendorsRowProps> = ({vendor, globalVendorList, extraPurposes}) => {
    const {t: textCmpSirdataList} = useTranslation(TranslationPortalFile.CMP_SIRDATA_LIST_V2);
    const [isOpenDetails, setOpenDetails] = useState(false);

    return (
        <>
            <TableRow onClick={() => setOpenDetails((prevState) => !prevState)}>
                <TableColumn>
                    <Icon {...(isOpenDetails ? Action.COLLAPSE.icon : Action.EXPAND.icon)}/>
                </TableColumn>
                <TableColumn>{vendor.id}</TableColumn>
                <TableColumn>
                    {vendor.name}
                    {vendor.premium &&
                        <IconTooltip
                            icon={{name: "card_membership", outlined: true, colorIcon: Color.GREEN}}
                            text={textCmpSirdataList(`field.vendor.${SirdataListVendorField.PREMIUM}`)}
                        />
                    }
                </TableColumn>
                <TableColumn>{vendor.policyUrl}</TableColumn>
                <TableColumn>{vendor.deletedDate ? Formatter.formatUTCDate(vendor.deletedDate, Formatter.DATETIME_FORMAT) : ""}</TableColumn>
            </TableRow>
            {isOpenDetails &&
                <TableRow>
                    <TableColumn colSpan={5}>
                        <ContentBlock>
                            {(!!vendor.purposes.length || !!vendor.extraPurposes.length) &&
                                <>
                                    <span>{textCmpSirdataList("vendor_consent")}</span>
                                    <ul>
                                        {sortNumbers(vendor.purposes).map((id) =>
                                            <li key={id}>
                                                <b>{textCmpSirdataList("purpose_with_id", {id: id})}</b>: {globalVendorList.getName(GlobalVendorListField.PURPOSES, id)}
                                            </li>
                                        )}
                                        {sortNumbers(vendor.extraPurposes).map((id) =>
                                            <li key={id}>
                                                <b>{textCmpSirdataList("extra_purpose_with_id", {id: id})}</b>: {extraPurposes.find((it) => it.id === id)?.name || ""}
                                            </li>
                                        )}
                                    </ul>
                                </>
                            }
                            {(!!vendor.legIntPurposes.length || !!vendor.legIntExtraPurposes.length) &&
                                <>
                                    <span>{textCmpSirdataList("vendor_legitimate_interest")}</span>
                                    <ul>
                                        {sortNumbers(vendor.legIntPurposes).map((id) =>
                                            <li key={id}>
                                                <b>{textCmpSirdataList("purpose_with_id", {id: id})}</b>: {globalVendorList.getName(GlobalVendorListField.PURPOSES, id)}
                                            </li>
                                        )}
                                        {sortNumbers(vendor.legIntExtraPurposes).map((id) =>
                                            <li key={id}>
                                                <b>{textCmpSirdataList("extra_purpose_with_id", {id: id})}</b>: {extraPurposes.find((it) => it.id === id)?.name || ""}
                                            </li>
                                        )}
                                    </ul>
                                </>
                            }
                        </ContentBlock>
                    </TableColumn>
                </TableRow>
            }
        </>
    );
};

export default CmpSirdataListV2VendorsRow;
