import {
    ButtonLinkCancel,
    ButtonValidate,
    FieldBlock,
    Form,
    FormLayoutRows,
    FormValidationType,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    ToggleSwitch
} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {CategorizerConfigEntry} from "../../../api/model/categorizer/CategorizerConfigEntry";
import {CategorizerConfigEntryField} from "../../../api/model/categorizer/CategorizerConfigEntryField";
import {CategorizerConfigType} from "../../../api/model/categorizer/CategorizerConfigType";
import {CategorizerConfigFilter} from "../../../api/model/categorizer/config/CategorizerConfigFilter";
import {CategorizerConfigFilterField} from "../../../api/model/categorizer/config/CategorizerConfigFilterField";
import {TranslationPortalFile} from "../../../utils/constants";
import {CategorizerConfigEntryInfo} from "../../snippet";
import {CategorizerConfigEntryParams} from "../../../api/model/categorizer/CategorizerConfigEntryParams";

type ModalAddCategorizerConfigFilterProps = {
    params: CategorizerConfigEntryParams;
    active: boolean;
    onSubmit: (entry: CategorizerConfigEntry<CategorizerConfigFilter>) => void;
    onClose: () => void;
};

const ModalAddCategorizerConfigFilter: FunctionComponent<ModalAddCategorizerConfigFilterProps> = ({params, active, onSubmit, onClose}) => {
    const {t: textCategorizer} = useTranslation(TranslationPortalFile.CATEGORIZER);
    const [entry, setEntry] = useState<CategorizerConfigEntry<CategorizerConfigFilter>>(CategorizerConfigEntry.forType(CategorizerConfigType.FILTER, params));
    const FORM_ID = "form-add-categorizer-config-filter";

    useEffect(() => {
        if (active) {
            setEntry(CategorizerConfigEntry.forType(CategorizerConfigType.FILTER, params));
        }
    }, [active, params]);

    const handleChange = (field: CategorizerConfigEntryField, value: any) => {
        setEntry((prevState) => new CategorizerConfigEntry<CategorizerConfigFilter>({...prevState, [field]: value}));
    };

    const handleChangeConfig = (field: CategorizerConfigFilterField, value: any) => {
        handleChange(CategorizerConfigEntryField.CONFIG, new CategorizerConfigFilter({...entry.config, [field]: value}));
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmit(entry);
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textCategorizer("modal.add_new_configuration", {type: textCategorizer(`config_type.${CategorizerConfigType.FILTER.name}`)})}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <CategorizerConfigEntryInfo currentEntry={entry}/>
                        <FieldBlock
                            label={textCategorizer(`field.${CategorizerConfigEntryField.CONFIG}.${CategorizerConfigFilterField.FILTERED}`)}
                            name={CategorizerConfigFilterField.FILTERED}
                        >
                            <ToggleSwitch
                                checked={entry.config.filtered}
                                onChange={(value) => handleChangeConfig(CategorizerConfigFilterField.FILTERED, value)}
                            />
                        </FieldBlock>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalAddCategorizerConfigFilter;
